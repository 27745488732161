// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/svg/Checked.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SudjelovanjeNaPredavanjima_raniRazvojHeader__Tpr2C {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.028px;
}
.SudjelovanjeNaPredavanjima_raniRazvojDescription__K-hTD,
.SudjelovanjeNaPredavanjima_raniRazvojDescription2__15TSM {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.014px;
}
.SudjelovanjeNaPredavanjima_sudjelovanjeAccordion__v19M\\+ {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
.SudjelovanjeNaPredavanjima_label__uHsn1 {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.SudjelovanjeNaPredavanjima_checkboxInput__L1TVr {
  display: none;
}
.SudjelovanjeNaPredavanjima_checkboxInput__L1TVr:checked + .SudjelovanjeNaPredavanjima_checkboxCustom__LGhGJ {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
}
.SudjelovanjeNaPredavanjima_checkboxCustom__LGhGJ {
  width: 20px;
  height: 20px;
  border: 1px solid #a5a5a5;
  background-color: white;
  margin-right: 10px;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/evidencija/components/SudjelovanjeNaPredavanjima/SudjelovanjeNaPredavanjima.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;;EAEE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,2EAA6E;AAC/E;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".raniRazvojHeader {\n  color: #4d4d4d;\n  font-family: Inter;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.028px;\n}\n.raniRazvojDescription,\n.raniRazvojDescription2 {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: -0.014px;\n}\n.sudjelovanjeAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n.label {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.checkboxInput {\n  display: none;\n}\n.checkboxInput:checked + .checkboxCustom {\n  background: url(\"../../../../assets/svg/Checked.svg\") center center no-repeat;\n}\n.checkboxCustom {\n  width: 20px;\n  height: 20px;\n  border: 1px solid #a5a5a5;\n  background-color: white;\n  margin-right: 10px;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"raniRazvojHeader": `SudjelovanjeNaPredavanjima_raniRazvojHeader__Tpr2C`,
	"raniRazvojDescription": `SudjelovanjeNaPredavanjima_raniRazvojDescription__K-hTD`,
	"raniRazvojDescription2": `SudjelovanjeNaPredavanjima_raniRazvojDescription2__15TSM`,
	"sudjelovanjeAccordion": `SudjelovanjeNaPredavanjima_sudjelovanjeAccordion__v19M+`,
	"label": `SudjelovanjeNaPredavanjima_label__uHsn1`,
	"checkboxInput": `SudjelovanjeNaPredavanjima_checkboxInput__L1TVr`,
	"checkboxCustom": `SudjelovanjeNaPredavanjima_checkboxCustom__LGhGJ`
};
export default ___CSS_LOADER_EXPORT___;
