import { components } from "react-select";

export const CustomValueContainer = ({ children, ...props }) => {
  const { setMenuIsOpen } = props;

  return (
    <components.ValueContainer {...props}>
      <div
        style={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
          color: "hsl(0, 0%, 50%)",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setMenuIsOpen((prev) => !prev);
        }}
      >
        Odaberi program/e
      </div>
    </components.ValueContainer>
  );
};
