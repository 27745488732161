import axios from "axios";

export const handleImageSelect = (
  event,
  // setMyProfileData,
  isAcademic,
  setSelectedDegreeName,
  setSelectedRecordName,
  setSelectedAcademicDegree,
  setSelectedCriminalRecord
) => {
  const file = event.target.files[0];
  if (!file) {
    console.error("No file selected");
    return;
  }
  console.log("File", file);
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
  if (!allowedTypes.includes(file.type)) {
    alert("Molimo izaberite PNG, JPEG, ili JPG datoteku.");
    return;
  }
  // setMyProfileData((prev) => ({
  //   ...prev,
  //   [isAcademic ? "academicDegree" : "criminalRecord"]: file,
  // }));

  if (isAcademic) {
    setSelectedDegreeName(file.name);
    setSelectedAcademicDegree(file);
  } else {
    setSelectedCriminalRecord(file);
    setSelectedRecordName(file.name);
  }
};
export const handleSingleSave = async (
  file,
  accessToken,
  isAcademic,
  apiUrl,
  userData,
  setLoading,
  setModalContent,
  Success,
  setShowModal,
  setEditMode,
  Error,
  role
) => {
  try {
    setLoading(true);
    const formData = new FormData();
    formData.append("certificate", file);
    formData.append("isAcademic", JSON.stringify(isAcademic));
    formData.append("role", role);
    const response = await axios.put(
      `${apiUrl}/api/user/${userData?.id}/certificate`,
      formData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    if (response.status === 200) {
      setModalContent({
        image: Success,
        description:
          role === "Referada" && file === null
            ? `Uspješno ste izbrisali ${isAcademic ? "akademsku diplomu" : "potvrdu o nekažnjavanju"}`
            : `Uspješno ste spremili ${isAcademic ? "akademsku diplomu" : "potvrdu o nekažnjavanju"}`,
        buttons: [
          {
            label: "U redu",
            action: () => {
              window.location.reload();
              setShowModal(false);
              setEditMode(false);
            },
            className: "modal-btn-success",
          },
        ],
      });
      setLoading(false);
      setShowModal(true);
    }
  } catch (error) {
    console.error(
      `Failed to upload ${isAcademic ? "academic degree" : "criminal record"}:`,
      error
    );
    setModalContent({
      image: Error,
      description: "Dogodila se greška, pokušajte ponovno.",
      buttons: [
        {
          label: "U redu",
          action: () => {
            window.location.reload();
            setShowModal(false);
            setEditMode(false);
          },
          className: "modal-btn-success",
        },
      ],
    });
    setLoading(false);
    setShowModal(true);
  }
};
