// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoContainer_container__4fZpS {
  width: 100%;
  padding-top: 40px;
}
.InfoContainer_container__4fZpS,
.InfoContainer_column__QCyIf {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 14px;
}
.InfoContainer_row__Nd0dw {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.InfoContainer_label__7ugUA,
.InfoContainer_infoNoCapitalize__JT14o,
.InfoContainer_info__X3GKd {
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
}
.InfoContainer_label__7ugUA {
  color: #4d4e4e;
  font-weight: 600;
}
.InfoContainer_info__X3GKd {
  color: #7e818c;
  font-weight: 500;
  text-transform: capitalize;
}
.InfoContainer_infoNoCapitalize__JT14o {
  color: #7e818c;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/PojedinaPrijavnica/PrijavnicaContainer/components/InfoContainer/InfoContainer.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,8BAA8B;AAChC;AACA;;;EAGE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".container {\n  width: 100%;\n  padding-top: 40px;\n}\n.container,\n.column {\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n  justify-content: center;\n  gap: 14px;\n}\n.row {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: space-between;\n}\n.label,\n.infoNoCapitalize,\n.info {\n  font-family: Inter;\n  font-size: 16px;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n}\n.label {\n  color: #4d4e4e;\n  font-weight: 600;\n}\n.info {\n  color: #7e818c;\n  font-weight: 500;\n  text-transform: capitalize;\n}\n.infoNoCapitalize {\n  color: #7e818c;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InfoContainer_container__4fZpS`,
	"column": `InfoContainer_column__QCyIf`,
	"row": `InfoContainer_row__Nd0dw`,
	"label": `InfoContainer_label__7ugUA`,
	"infoNoCapitalize": `InfoContainer_infoNoCapitalize__JT14o`,
	"info": `InfoContainer_info__X3GKd`
};
export default ___CSS_LOADER_EXPORT___;
