import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication";
import IntrospekcijeContainer from "../../pojediniProgrami/SectionContainer/IntrospekcijeContainer";
import AttendeesTable from "../../singleGroup/components/attendeesTable/AttendeesTable";
import SelectDropdown from "../../singleGroup/components/selectDropdown/SelectDropdown";
import { getGroupIntrospekcija } from "../../singleGroup/Introspekcije/API";
import IntrospekcijeTable from "../../singleGroup/Introspekcije/IntrospekcijeTable/IntrospekcijeTable";
import TableAttendees from "../../TableAttendees";
import { updateComments } from "../api/updateComments";
import { fetchData } from "./fetchData";
import { fetchOtherData } from "./fetchOtherData";
import styles from "./MojaMentorstvaVoditelj.module.css";
import {
  cjelineOR,
  cjelineRAR,
  handleCjelinaSelect,
  handlePolaznikSelect,
} from "./utils";

const MojaMentorstvaVoditelj = () => {
  const { groupId } = useParams();
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const [groupData, setGroupData] = useState(null);
  const [attendeesData, setAttendeeData] = useState(null);
  const [selectAttendeesInfo, setSelectAttendeesInfo] = useState([]);
  const [usersIMentor, setUsersIMentor] = useState(null);
  const [userIdsIMentor, setUserIdsIMentor] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedPolaznik, setSelectedPolaznik] = useState(null);
  const [selectedCjelina, setSelectedCjelina] = useState(null);
  const [isBothSelectsSelected, setIsBothSelectsSelected] = useState(false);
  const [introspections, setIntrospections] = useState([]);
  const [program, setProgram] = useState("");
  const [comments, setComments] = useState("");
  const [refetchData, setRefetchData] = useState(false);
  const [introspekcijeData, setIntrospekcijeData] = useState([]);
  const [isAllowed, setIsAllowed] = useState(null);
  const [reRender, setReRender] = useState(true);

  useEffect(() => {
    if (authToken) {
      fetchData(
        authToken,
        setProgram,
        setGroupData,
        groupId,
        setAttendeeData,
        setUsersIMentor,
        setUserIdsIMentor,
        setIsAllowed
      );
    }
  }, [authToken, groupId]);

  useEffect(() => {
    setSelectAttendeesInfo(
      usersIMentor?.map((element) => ({
        value: element.userId,
        label: `${element.user.firstName} ${element.user.lastName}`,
      }))
    );
  }, [usersIMentor]);

  useEffect(() => {
    if (authToken)
      getGroupIntrospekcija(groupId, authToken, setIntrospekcijeData);
  }, [groupId, authToken, refetchData, reRender]);

  useEffect(() => {
    if (selectedPolaznik && authToken) {
      fetchOtherData(selectedPolaznik, authToken, setIntrospections);
    }
  }, [selectedPolaznik, comments, refetchData, reRender]);

  useEffect(() => {
    if (selectedPolaznik && selectedCjelina) {
      setIsBothSelectsSelected(true);
    } else {
      setIsBothSelectsSelected(false);
    }
  }, [selectedPolaznik, selectedCjelina]);

  const handleCommentsUpdate = async (
    introspectionId,
    newComment,
    isCommentDone
  ) => {
    const newData = {
      introspectionId,
      userId: selectedPolaznik,
      mentorComment: newComment,
      isRead: isCommentDone,
    };
    await updateComments(newData, authToken);
    setRefetchData((prev) => !prev);
  };

  return (
    <>
      {groupData && (
        <div>
          <div className={styles.groupHeadContainerMentorships}>
            <div className={styles.groupHeadTextContainerMentorships}>
              <h3 className={styles.groupTitleMentorships}>
                Grupa {groupData?.name ? groupData?.name : "(nema imena)"}
              </h3>
              <p className={styles.groupDateTextMentorships}>
                Datum kreiranja:
                {groupData?.createdDate ? groupData?.createdDate : "nepoznato"}
              </p>
            </div>
          </div>
          {!editMode ? (
            <>
              {usersIMentor?.length > 0 ? (
                <div className={styles.attendeesContainer}>
                  <AttendeesTable
                    userId={userId}
                    groupData={groupData}
                    data={usersIMentor}
                  />
                  <div className={styles.editActionsButtons}>
                    <div
                      className={styles.saveButton}
                      onClick={() => setEditMode(true)}
                    >
                      <div className={styles.saveButtonText}>Uredi</div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className={styles.groupDateTextMentorships}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: 20,
                      marginBottom: 30,
                    }}
                  >
                    Trenutno nema polaznika kojima ste mentor!
                  </div>
                  <div className={styles.editActionsButtons}>
                    <div
                      className={styles.saveButton}
                      onClick={() => setEditMode(true)}
                    >
                      <div className={styles.saveButtonText}>Uredi</div>
                    </div>
                  </div>
                </>
              )}
              <div className={styles.groupHeadContainerMentorships}>
                <div className={styles.groupHeadTextContainerMentorships}>
                  <h3 className={styles.groupTitleMentorships}>
                    Introspekcije polaznika
                  </h3>
                  <p className={styles.resultsTextMentorships}>Rezultat</p>
                </div>
              </div>
              <div className={styles.tableWrapper}>
                <IntrospekcijeTable data={introspekcijeData} />
              </div>
              <div className={styles.groupHeadContainerMentorships}>
                <div className={styles.groupHeadTextContainerMentorships}>
                  <p className={styles.resultsTextMentorships}>
                    Pročitaj introspekciju
                  </p>
                </div>
              </div>
              <div className={styles.selectContainer}>
                <div className={styles.selectDropdowns}>
                  <SelectDropdown
                    options={selectAttendeesInfo}
                    title="Polaznik"
                    initialOption={"Odaberi polaznika"}
                    onChange={(event) =>
                      handlePolaznikSelect(
                        event.target.value,
                        setSelectedPolaznik
                      )
                    }
                  />
                </div>
                <div className={styles.selectDropdowns}>
                  <SelectDropdown
                    options={
                      groupData?.programId === 1 || groupData?.programId === 2
                        ? cjelineRAR
                        : cjelineOR
                    }
                    title="Cjelina"
                    initialOption={"Odaberi cjelinu"}
                    onChange={(event) =>
                      handleCjelinaSelect(
                        event.target.value,
                        setSelectedCjelina
                      )
                    }
                  />
                </div>
              </div>
              {isBothSelectsSelected && (
                <div>
                  {selectedCjelina === "1" && (
                    <>
                      {introspections
                        .filter(
                          (introspection) =>
                            introspection?.programId === groupData?.programId
                        )
                        .slice(
                          0,
                          groupData.programId === 1 || groupData.programId === 2
                            ? 3
                            : 2
                        )
                        .map((introspection, index) => (
                          <IntrospekcijeContainer
                            key={introspection.introspectionId}
                            reRender={reRender}
                            setReRender={setReRender}
                            handleCommentsUpdate={(inputValue, isCommentDone) =>
                              handleCommentsUpdate(
                                groupData?.programId === 1
                                  ? index + 1
                                  : groupData?.programId === 3
                                  ? index + 9
                                  : groupData?.programId === 4
                                  ? index + 15
                                  : groupData?.programId === 5
                                  ? index + 21
                                  : groupData?.programId === 6
                                  ? index + 27
                                  : groupData?.programId === 2
                                  ? index + 33
                                  : groupData?.programId === 7
                                  ? index + 41
                                  : groupData?.programId === 8
                                  ? index + 47
                                  : groupData?.programId === 9
                                  ? index + 53
                                  : groupData?.programId === 10
                                  ? index + 59
                                  : null,
                                inputValue,
                                isCommentDone
                              )
                            }
                            description={introspection.introspection.questions}
                            comments={
                              program === "RAR"
                                ? null
                                : introspection.mentorComment
                            }
                            answers={introspection.userAnswer}
                            isMentorstva={true}
                            polaznikId={selectedPolaznik}
                            groupProgramId={groupData?.programId}
                            introspectionIdMentor={
                              groupData?.programId === 1
                                ? index + 1
                                : groupData?.programId === 3
                                ? index + 9
                                : groupData?.programId === 4
                                ? index + 15
                                : groupData?.programId === 5
                                ? index + 21
                                : groupData?.programId === 6
                                ? index + 27
                                : groupData?.programId === 2
                                ? index + 33
                                : groupData?.programId === 7
                                ? index + 41
                                : groupData?.programId === 8
                                ? index + 47
                                : groupData?.programId === 9
                                ? index + 53
                                : groupData?.programId === 10
                                ? index + 59
                                : null
                            }
                          />
                        ))}
                    </>
                  )}
                  {selectedCjelina === "2" && (
                    <>
                      {introspections
                        .filter(
                          (introspection) =>
                            introspection?.programId === groupData?.programId
                        )
                        .slice(
                          groupData.programId === 1 || groupData.programId === 2
                            ? 3
                            : 2,
                          groupData.programId === 1 || groupData.programId === 2
                            ? 6
                            : 4
                        )
                        .map((introspection, index) => (
                          <IntrospekcijeContainer
                            key={introspection.introspectionId}
                            reRender={reRender}
                            setReRender={setReRender}
                            handleCommentsUpdate={(inputValue, isCommentDone) =>
                              handleCommentsUpdate(
                                groupData?.programId === 1
                                  ? index + 4
                                  : groupData?.programId === 3
                                  ? index + 11
                                  : groupData?.programId === 4
                                  ? index + 17
                                  : groupData?.programId === 5
                                  ? index + 23
                                  : groupData?.programId === 6
                                  ? index + 29
                                  : groupData?.programId === 2
                                  ? index + 36
                                  : groupData?.programId === 7
                                  ? index + 43
                                  : groupData?.programId === 8
                                  ? index + 49
                                  : groupData?.programId === 9
                                  ? index + 55
                                  : groupData?.programId === 10
                                  ? index + 61
                                  : null,
                                inputValue,
                                isCommentDone
                              )
                            }
                            description={introspection.introspection.questions}
                            comments={
                              program === "RAR"
                                ? null
                                : introspection.mentorComment
                            }
                            answers={introspection.userAnswer}
                            isMentorstva={true}
                            polaznikId={selectedPolaznik}
                            groupProgramId={groupData?.programId}
                            introspectionIdMentor={
                              groupData?.programId === 1
                                ? index + 4
                                : groupData?.programId === 3
                                ? index + 11
                                : groupData?.programId === 4
                                ? index + 17
                                : groupData?.programId === 5
                                ? index + 23
                                : groupData?.programId === 6
                                ? index + 29
                                : groupData?.programId === 2
                                ? index + 36
                                : groupData?.programId === 7
                                ? index + 43
                                : groupData?.programId === 8
                                ? index + 49
                                : groupData?.programId === 9
                                ? index + 55
                                : groupData?.programId === 10
                                ? index + 61
                                : null
                            }
                          />
                        ))}
                    </>
                  )}
                  {selectedCjelina === "3" && (
                    <>
                      {introspections
                        .filter(
                          (introspection) =>
                            introspection?.programId === groupData?.programId
                        )
                        .slice(
                          groupData.programId === 1 || groupData.programId === 2
                            ? 6
                            : 4,
                          groupData.programId === 1 || groupData.programId === 2
                            ? 8
                            : 6
                        )
                        .map((introspection, index) => (
                          <IntrospekcijeContainer
                            key={introspection.introspectionId}
                            reRender={reRender}
                            setReRender={setReRender}
                            handleCommentsUpdate={(inputValue, isCommentDone) =>
                              handleCommentsUpdate(
                                groupData?.programId === 1
                                  ? index + 7
                                  : groupData?.programId === 3
                                  ? index + 13
                                  : groupData?.programId === 4
                                  ? index + 19
                                  : groupData?.programId === 5
                                  ? index + 25
                                  : groupData?.programId === 6
                                  ? index + 31
                                  : groupData?.programId === 2
                                  ? index + 39
                                  : groupData?.programId === 7
                                  ? index + 45
                                  : groupData?.programId === 8
                                  ? index + 51
                                  : groupData?.programId === 9
                                  ? index + 57
                                  : groupData?.programId === 10
                                  ? index + 63
                                  : null,
                                inputValue,
                                isCommentDone
                              )
                            }
                            description={introspection.introspection.questions}
                            comments={
                              program === "RAR"
                                ? null
                                : introspection.mentorComment
                            }
                            answers={introspection.userAnswer}
                            isMentorstva={true}
                            polaznikId={selectedPolaznik}
                            groupProgramId={groupData?.programId}
                            introspectionIdMentor={
                              groupData?.programId === 1
                                ? index + 7
                                : groupData?.programId === 3
                                ? index + 13
                                : groupData?.programId === 4
                                ? index + 19
                                : groupData?.programId === 5
                                ? index + 25
                                : groupData?.programId === 6
                                ? index + 31
                                : groupData?.programId === 2
                                ? index + 39
                                : groupData?.programId === 7
                                ? index + 45
                                : groupData?.programId === 8
                                ? index + 51
                                : groupData?.programId === 9
                                ? index + 57
                                : groupData?.programId === 10
                                ? index + 63
                                : null
                            }
                          />
                        ))}
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {attendeesData && (
                <div className={styles.containerTableAttendees}>
                  <div className={styles.groupHeadTextContainerMentorships}>
                    <h3 className={styles.groupTitleMentorships}>
                      Označi polaznike iz grupe koje ćeš mentorirati:
                    </h3>
                  </div>
                  <div>
                    <TableAttendees
                      data={attendeesData}
                      rowsPerPage={10}
                      showCheckbox={true}
                      showAvatars={true}
                      userIdsIMentor={userIdsIMentor}
                      showButtonRow={editMode}
                      onClickOdustani={() => setEditMode(false)}
                      isAssignMentor={true}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MojaMentorstvaVoditelj;
