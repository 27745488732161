import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Arrow from "../../assets/img/BackArrow.png";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import { getProgramsByUserId } from "./api/getProgramsByUserId";
import { getSupervisionEntry } from "./api/getTerapijeModalData";
import DiplomePotvrde from "./components/DiplomePotvrde/DiplomePotvrde";
import NastupnoPredavanje from "./components/NastupnoPredavanje/NastupnoPredavanje";
import OsobniPsihoterapeutskiRad from "./components/OsobniPsihoterapeutskiRad/OsobniPsihoterapeutskiRad";
import OsobniRadKlijentiGrupe from "./components/OsobniRadKlijentiGrupe/OsobniRadKlijentiGrupe";
import Polaznik from "./components/Polaznik/Polaznik";
import SudjelovanjeNaPredavanjima from "./components/SudjelovanjeNaPredavanjima/SudjelovanjeNaPredavanjima";
import Supervizije from "./components/Supervizije/Supervizije";
import ZavrsniRadovi from "./components/ZavrsniRadovi/ZavrsniRadovi";
import styles from "./EvidencijaPage.module.css";
import {
  fetchMySudjelovanje,
  fetchUserSudjelovanje,
  getPolaznikLevel,
} from "./util";

function EvidencijaPage() {
  const navigate = useNavigate();
  const [evidentionData, setEvidentionData] = useState({});
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [supervisionCommentData, setSupervisionCommentData] = useState([]);
  const location = useLocation();
  const localFullName = localStorage.getItem("evidentionFullName");
  const localUserEvidentionID = localStorage.getItem("evidentionUserID");
  const fullName =
    location?.state?.fullName ||
    localFullName ||
    `${userData.firstName} ${userData.lastName}`;

  const userEvidentionID =
    location?.state?.userEvidentionID || localUserEvidentionID || userData.id;

  const [userPrograms, setUserPrograms] = useState({});
  const [loading, setLoading] = useState(false);
  const userId = role;
  const [deleteFlag, setDeleteFlag] = useState([]);

  useEffect(() => {
    if (fullName !== undefined && userId === "Referada") {
      localStorage.setItem("evidentionFullName", fullName);
    } else {
      localStorage.setItem(
        "evidentionFullName",
        `${userData.firstName} ${userData.lastName}`
      );
    }
    if (userEvidentionID !== undefined && userId === "Referada") {
      localStorage.setItem("evidentionUserID", userEvidentionID);
    } else {
      localStorage.setItem("evidentionUserID", userData.id);
    }
  }, [
    fullName,
    userEvidentionID,
    userData.firstName,
    userData.lastName,
    userData.id,
    userId,
  ]);

  useEffect(() => {
    if (authToken) {
      setLoading(true);
      getSupervisionEntry(userEvidentionID, authToken)
        .then((data) => {
          setSupervisionCommentData(
            data.sort(
              (a, b) => new Date(b.otherInfo.date) - new Date(a.otherInfo.date)
            )
          );
        })
        .catch(() => console.log("gg"))
        .finally(() => setLoading(false));
      if (userData.id !== userEvidentionID) {
        fetchUserSudjelovanje(userEvidentionID, authToken, setEvidentionData);
      } else {
        fetchMySudjelovanje(authToken, setEvidentionData);
      }
    }
  }, [userEvidentionID, authToken, deleteFlag, userData.id]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getProgramsByUserId(authToken, userEvidentionID);
        setUserPrograms(response.map((program) => program.id));
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (authToken) {
      fetchUser();
    }
  }, [authToken, userEvidentionID]);

  const polaznikLevel = getPolaznikLevel(
    evidentionData ? evidentionData.zavrsniRadovi : null
  );
  const tpIttData = {
    tp2:
      (evidentionData &&
        evidentionData?.lessonsAndWorkshops &&
        evidentionData?.lessonsAndWorkshops["TP2 - Terapeutski praktikum"]) ||
      null,
    tt5:
      (evidentionData &&
        evidentionData?.lessonsAndWorkshops &&
        evidentionData?.lessonsAndWorkshops["TT5 - Terapeutski trening"]) ||
      null,
    tt6:
      (evidentionData &&
        evidentionData?.lessonsAndWorkshops &&
        evidentionData?.lessonsAndWorkshops["TT6 - Terapeutski trening"]) ||
      null,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.backRow} onClick={() => navigate(-1)}>
        <img alt="" src={Arrow} />
        <p className={styles.back}>Natrag</p>
      </div>
      <Polaznik
        polaznikLevel={polaznikLevel}
        userEvidentionID={userEvidentionID}
      />
      <SudjelovanjeNaPredavanjima
        userId={userId}
        polaznikLevel={polaznikLevel}
        evidentionUserID={userEvidentionID}
        setEvidentionData={setEvidentionData}
        data={evidentionData?.lessonsAndWorkshops}
      />
      <OsobniPsihoterapeutskiRad
        userId={userId}
        polaznikLevel={polaznikLevel}
        data={evidentionData?.personalPsychotherapyWork}
        setDeleteFlag={setDeleteFlag}
        deleteFlag={deleteFlag}
        evidentionUserID={userEvidentionID}
      />
      {polaznikLevel !== "rar" && (
        <Supervizije
          userId={userId}
          id={userData.id}
          polaznikLevel={polaznikLevel}
          role={userData.roleId}
          getSupervisionEntry={async () => {
            setLoading(true);
            await getSupervisionEntry(userEvidentionID, authToken)
              .then((data) => {
                setSupervisionCommentData(
                  data.sort(
                    (a, b) =>
                      new Date(b.otherInfo.date) - new Date(a.otherInfo.date)
                  )
                );
              })
              .catch(() => console.log("gg"))
              .finally(() => setLoading(false));
          }}
          supervisionData={loading ? [] : supervisionCommentData}
          data={evidentionData?.supervisionObject}
          setDeleteFlag={setDeleteFlag}
          authToken={authToken}
          deleteFlag={deleteFlag}
          zavrsniData={evidentionData?.zavrsniRadovi}
          evidentionUserID={userEvidentionID}
          tpIttData={tpIttData}
          shouldShowCertificate={
            evidentionData?.zavrsniRadovi?.supervisorApproval1 &&
            evidentionData?.zavrsniRadovi?.supervisorApproval2
          }
        />
      )}
      {polaznikLevel !== "rar" && (
        <OsobniRadKlijentiGrupe
          userId={userId}
          polaznikLevel={polaznikLevel}
          data={evidentionData?.workWithClients}
          setDeleteFlag={setDeleteFlag}
          deleteFlag={deleteFlag}
          evidentionUserID={userEvidentionID}
        />
      )}
      {userPrograms.length > 0 && userPrograms?.some((num) => num >= 11) && (
        <NastupnoPredavanje
          userId={userId}
          data={evidentionData?.presentations}
          evidentionUserID={userEvidentionID}
        />
      )}
      <ZavrsniRadovi
        userId={userId}
        polaznikLevel={polaznikLevel}
        data={evidentionData?.zavrsniRadovi}
        evidentionUserID={userEvidentionID}
        setDeleteFlag={setDeleteFlag}
        deleteFlag={deleteFlag}
      />
      <DiplomePotvrde userId={userId} polaznikLevel={polaznikLevel} />
    </div>
  );
}

export default EvidencijaPage;
