// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomInput_inputLabel__9rsAb {
  display: flex;
  flex-direction: column;
}
.CustomInput_inputLabelText__Gft-t {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px 0px 8px;
}
.CustomInput_input__RuVAZ {
  border: 1px solid #d8dbdf;
  border-radius: 8px;
  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  height: 46px;
  padding: 0px 16px;
}
.CustomInput_input__RuVAZ::placeholder {
  color: #9fa7b2;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.CustomInput_input__RuVAZ:focus {
  outline: none;
}

@media screen and (max-width: 480px) {
  .CustomInput_input__RuVAZ {
    box-sizing: border-box;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/singleGroup/components/customInput/CustomInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,oDAAoD;EACpD,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".inputLabel {\n  display: flex;\n  flex-direction: column;\n}\n.inputLabelText {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px 0px 8px;\n}\n.input {\n  border: 1px solid #d8dbdf;\n  border-radius: 8px;\n  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  height: 46px;\n  padding: 0px 16px;\n}\n.input::placeholder {\n  color: #9fa7b2;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.input:focus {\n  outline: none;\n}\n\n@media screen and (max-width: 480px) {\n  .input {\n    box-sizing: border-box;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `CustomInput_inputLabel__9rsAb`,
	"inputLabelText": `CustomInput_inputLabelText__Gft-t`,
	"input": `CustomInput_input__RuVAZ`
};
export default ___CSS_LOADER_EXPORT___;
