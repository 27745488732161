export const rooms = [
  {
    value: 2,
    label: "ZG-1",
  },
  {
    value: 4,
    label: "ZG-2",
  },
  {
    value: 5,
    label: "BG-1",
  },
  {
    value: 3,
    label: "LJ-1",
  },
  {
    value: 1,
    label: "ST-1",
  },
  {
    value: 6,
    label: "RI-1",
  },
  {
    value: 7,
    label: "PG-1",
  },
  {
    value: 8,
    label: "eRAR/eOR",
  },
];

export const izaberiDogadjaj = [
  { value: "regular_workshop", label: "Radionica" },
  { value: "meeting_with_mentor", label: "Susret s mentorom" },
  { value: "interactive_lesson", label: "Interaktivna predavanja" },
];

export const izaberiProgram = [
  { value: "RAR", label: "RAR" },
  { value: "eRAR", label: "eRAR" },
  { value: "eOR", label: "eOR" },
  { value: "OR1", label: "OR1" },
  { value: "OR2", label: "OR2" },
  { value: "OR3", label: "OR3" },
  { value: "OR4", label: "OR4" },
  { value: "OR5", label: "OR5" },
  { value: "TP1", label: "TP1" },
  { value: "TP2", label: "TP2" },
  { value: "TT5", label: "TT5" },
  { value: "TT6", label: "TT6" },
];

export const izaberiObjava = [
  { value: true, label: "Da" },
  { value: false, label: "Ne" },
];

export const roomCalendars = [
  {
    name: "ZAGREB 1",
    roomName: "ZG-1",
    roomId: 2,
    headerColor: "#F0F8FF",
    tableHeadColor: "#f0f8ff",
  },
  {
    name: "ZAGREB 2",
    roomId: 4,
    roomName: "ZG-2",
    headerColor: "#FFF4F0",
    tableHeadColor: "#FFE4D9",
  },
  {
    name: "BEOGRAD 1",
    roomName: "BG-1",
    roomId: 5,
    headerColor: "#FFF9ED",
    tableHeadColor: "#FFF2D9",
  },
  {
    name: "BEOGRAD 2",
    roomName: "BG-2",
    roomId: 20,
    headerColor: "#8aa5ff",
    tableHeadColor: "#94adff",
  },
  {
    name: "LJUBLJANA",
    roomName: "LJ-1",
    roomId: 3,
    headerColor: "#F2FFF6",
    tableHeadColor: "#D9FFE4",
  },
  {
    name: "SPLIT",
    roomName: "ST-1",
    roomId: 1,
    headerColor: "#F0F8FF",
    tableHeadColor: "#FFFBD9",
  },
  {
    name: "RIJEKA",
    roomName: "RI-1",
    roomId: 6,
    headerColor: "#F5F6FF",
    tableHeadColor: "#D9DDFF",
  },
  {
    name: "PODGORICA",
    roomName: "PG-1",
    roomId: 7,
    headerColor: "#FEF5FF",
    tableHeadColor: "#F1D9FF",
  },
  {
    name: "e-RAR / e-OR",
    roomName: "eRAR/eOR",
    roomId: 8,
    headerColor: "#FFEBEB",
    tableHeadColor: "#FFD9D9",
  },
];
export const timeSlots = [
  { value: "08:00:00", label: "08:00" },
  { value: "08:15:00", label: "08:15" },
  { value: "08:30:00", label: "08:30" },
  { value: "08:45:00", label: "08:45" },
  { value: "09:00:00", label: "09:00" },
  { value: "09:15:00", label: "09:15" },
  { value: "09:30:00", label: "09:30" },
  { value: "09:45:00", label: "09:45" },
  { value: "10:00:00", label: "10:00" },
  { value: "10:15:00", label: "10:15" },
  { value: "10:30:00", label: "10:30" },
  { value: "10:45:00", label: "10:45" },
  { value: "11:00:00", label: "11:00" },
  { value: "11:15:00", label: "11:15" },
  { value: "11:30:00", label: "11:30" },
  { value: "11:45:00", label: "11:45" },
  { value: "12:00:00", label: "12:00" },
  { value: "12:15:00", label: "12:15" },
  { value: "12:30:00", label: "12:30" },
  { value: "12:45:00", label: "12:45" },
  { value: "13:00:00", label: "13:00" },
  { value: "13:15:00", label: "13:15" },
  { value: "13:30:00", label: "13:30" },
  { value: "13:45:00", label: "13:45" },
  { value: "14:00:00", label: "14:00" },
  { value: "14:15:00", label: "14:15" },
  { value: "14:30:00", label: "14:30" },
  { value: "14:45:00", label: "14:45" },
  { value: "15:00:00", label: "15:00" },
  { value: "15:15:00", label: "15:15" },
  { value: "15:30:00", label: "15:30" },
  { value: "15:45:00", label: "15:45" },
  { value: "16:00:00", label: "16:00" },
  { value: "16:15:00", label: "16:15" },
  { value: "16:30:00", label: "16:30" },
  { value: "16:45:00", label: "16:45" },
  { value: "17:00:00", label: "17:00" },
  { value: "17:15:00", label: "17:15" },
  { value: "17:30:00", label: "17:30" },
  { value: "17:45:00", label: "17:45" },
  { value: "18:00:00", label: "18:00" },
  { value: "18:15:00", label: "18:15" },
  { value: "18:30:00", label: "18:30" },
  { value: "18:45:00", label: "18:45" },
  { value: "19:00:00", label: "19:00" },
  { value: "19:15:00", label: "19:15" },
  { value: "19:30:00", label: "19:30" },
  { value: "19:45:00", label: "19:45" },
  { value: "20:00:00", label: "20:00" },
  { value: "20:15:00", label: "20:15" },
  { value: "20:30:00", label: "20:30" },
  { value: "20:45:00", label: "20:45" },
  { value: "21:00:00", label: "21:00" },
  { value: "21:15:00", label: "21:15" },
  { value: "21:30:00", label: "21:30" },
  { value: "21:45:00", label: "21:45" },
  { value: "22:00:00", label: "22:00" },
];
