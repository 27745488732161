// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  justify-content: space-between;
}

.calendar-container {
  display: inline-block;
  vertical-align: top;
  flex: 0 0 50%;
}

h2 {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/master/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".App {\n  display: flex;\n  justify-content: space-between;\n}\n\n.calendar-container {\n  display: inline-block;\n  vertical-align: top;\n  flex: 0 0 50%;\n}\n\nh2 {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
