import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../../../assets/svg/ArrowLeftIcon";
import InfoIcon from "../../../../assets/svg/InfoIcon.svg";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getAllApplications } from "./components/enrollmentForm/api/getAllApplications";
import Table from "./components/table/Table";
import styles from "./EnrollmentApplications.module.css";
import { programOptions } from "./utils";

const EnrollmentApplications = () => {
  const navigate = useNavigate();
  const { authToken } = useAuthentication();

  const [applications, setApplications] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("3,4,5,6,7,8,9,10");
  const [updatedApplications, setUpdatedApplications] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSelectChange = (event) => {
    setSelectedProgram(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const defaultIds = [3, 4, 5, 6, 7, 8, 9, 10];
    const fetchApplications = async () => {
      const response = await getAllApplications(authToken);
      setApplications(response);
      const defaultData = response?.applications?.filter((application) =>
        defaultIds.includes(application.programId)
      );
      const sortedData = defaultData?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setUpdatedApplications(sortedData);
    };
    if (authToken) fetchApplications();
  }, [authToken]);

  const filterApplicationsByProgram = () => {
    if (selectedProgram === null || selectedProgram === "0") {
      return applications?.applications || [];
    } else {
      const selectedProgramArray = selectedProgram?.split(",")?.map(Number);
      return (
        applications?.applications?.filter((application) =>
          selectedProgramArray.includes(application.programId)
        ) || []
      );
    }
  };

  const filterApplicationsBySearch = (filteredApplications) => {
    if (searchQuery.length === 0) return filteredApplications;

    return filteredApplications.filter((application) => {
      const fullName = `${application.User.firstName || ""} ${
        application.User.lastName || ""
      }`
        .trim()
        .toLowerCase();
      const normalizedQuery = searchQuery.toLowerCase().replace(/\s/g, "");
      return (
        fullName.includes(searchQuery.toLowerCase()) ||
        fullName.replace(/\s/g, "").includes(normalizedQuery)
      );
    });
  };

  useEffect(() => {
    const filteredByProgram = filterApplicationsByProgram();
    const finalFiltered = filterApplicationsBySearch(filteredByProgram);
    setUpdatedApplications(finalFiltered);
  }, [selectedProgram, searchQuery]);

  return (
    <div className={styles.container}>
      <div className={styles.backButton} onClick={() => navigate("/dokumenti")}>
        <ArrowLeftIcon className={styles.backButtonArrow} />
        <p className={styles.backButtonText}>natrag</p>
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={`${styles.searchInput} ${styles.searchInputRef}`}
            placeholder="Pretraži po imenu"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className={styles.selectContainer}>
          <select className={styles.select} onChange={handleSelectChange}>
            {programOptions?.map((program, index) => (
              <option value={program.value} key={index}>
                {program.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.countContainer}>
        <p className={styles.label}>
          Odobreno: {applications.acceptedApplications}
        </p>
        <p className={styles.label}>
          Odbijeno: {applications.declinedApplications}
        </p>
        <p className={styles.label}>
          Neocijenjeno: {applications.undecidedApplications}
        </p>
        <p className={styles.label}>Ukupno: {applications.applicationsCount}</p>
      </div>
      {updatedApplications?.length > 0 ? (
        <Table
          dataLive={updatedApplications}
          selectedProgram={selectedProgram}
        />
      ) : (
        <div className={styles.noGroupsWrapper}>
          <img alt="" src={InfoIcon} className={styles.noGroupsIcon} />
          Trenutno nema aplikacija za ovaj program.
        </div>
      )}
    </div>
  );
};

export default EnrollmentApplications;
