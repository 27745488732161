import axios from "axios";
import { createNotification } from "../../../../pojediniProgrami/SectionContainer/API";
const apiUrl = process.env.REACT_APP_API_URL;

export const acceptProgramApplication = async (
  accessToken,
  applicationId,
  setModalContent,
  Success,
  navigate,
  setShowModal,
  Question,
  setIsLoading,
  setOpenAcceptanceModal,
  fetchApplication,
  notificationData,
  isAccepted
) => {
  try {
    setIsLoading(true);
    const response = await axios.put(
      `${apiUrl}/api/programApplication/${applicationId}`,
      {
        isAccepted: isAccepted,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 200) {
      fetchApplication();
      setIsLoading(false);
      if (isAccepted === true) {
        await createNotification(notificationData, accessToken);
        setModalContent({
          image: Success,
          description: `Uspješno ste odobrili polaznika za upis više godine`,
          buttons: [
            {
              label: "U redu",
              action: () => {
                setShowModal(false);
                setOpenAcceptanceModal(false);
              },
              className: "modal-btn-success",
            },
          ],
        });
      } else {
        setModalContent({
          image: Success,
          description: `Uspješno ste odbili polaznika za upis više godine`,
          buttons: [
            {
              label: "U redu",
              action: () => {
                setShowModal(false);
                setOpenAcceptanceModal(false);
              },
              className: "modal-btn-success",
            },
          ],
        });
      }
      setShowModal(true);
    } else {
      setIsLoading(false);
      setModalContent({
        image: Question,
        description: "Nešto je pošlo po krivu",
        buttons: [
          {
            label: "U redu",
            action: () => navigate(-1),
            className: "modal-btn-success",
          },
        ],
      });
      setShowModal(true);
    }
  } catch (error) {
    console.error("Error updating application data:", error);
    throw error;
  }
};
