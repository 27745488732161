// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrijavljeneAktivnosti_emptyContainer__THu1B {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 32px;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 4px 4px 64px 0px #5f6d7e1a;
  background-color: #fcfcfc;
  box-sizing: border-box;
}
.PrijavljeneAktivnosti_emptySpan__NcCbA {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #5f6d7e;
}

@media screen and (max-width: 420px) {
  .PrijavljeneAktivnosti_emptyContainer__THu1B {
    padding: 13px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/Prijavnice/PolaznikVoditelj/PrijavljeneAktivnosti/PrijavljeneAktivnosti.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,sCAAsC;EACtC,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".emptyContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  padding: 32px;\n  border: 2px solid #e2e2e2;\n  border-radius: 8px;\n  box-shadow: 4px 4px 64px 0px #5f6d7e1a;\n  background-color: #fcfcfc;\n  box-sizing: border-box;\n}\n.emptySpan {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #5f6d7e;\n}\n\n@media screen and (max-width: 420px) {\n  .emptyContainer {\n    padding: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyContainer": `PrijavljeneAktivnosti_emptyContainer__THu1B`,
	"emptySpan": `PrijavljeneAktivnosti_emptySpan__NcCbA`
};
export default ___CSS_LOADER_EXPORT___;
