// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LightBox_lightbox__GOV8k {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000d6;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LightBox_lightbox__GOV8k img {
  max-width: 90%;
  max-height: 80%;
}
.LightBox_lightbox__GOV8k:target {
  display: flex;
}
.LightBox_close__wyHD\\+ {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 30px;
  color: white;
  text-decoration: none;
}
.LightBox_closeBtn__Kix91 {
  position: absolute;
  right: 3%;
  top: 5%;
  cursor: pointer;
  transition: 300ms ease-in-out;
}
.LightBox_closeBtn__Kix91:hover {
  opacity: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/components/Lightbox/LightBox.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,YAAY;EACZ,qBAAqB;AACvB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd","sourcesContent":[".lightbox {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: #000000d6;\n  z-index: 100;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.lightbox img {\n  max-width: 90%;\n  max-height: 80%;\n}\n.lightbox:target {\n  display: flex;\n}\n.close {\n  position: absolute;\n  top: 20px;\n  right: 30px;\n  font-size: 30px;\n  color: white;\n  text-decoration: none;\n}\n.closeBtn {\n  position: absolute;\n  right: 3%;\n  top: 5%;\n  cursor: pointer;\n  transition: 300ms ease-in-out;\n}\n.closeBtn:hover {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lightbox": `LightBox_lightbox__GOV8k`,
	"close": `LightBox_close__wyHD+`,
	"closeBtn": `LightBox_closeBtn__Kix91`
};
export default ___CSS_LOADER_EXPORT___;
