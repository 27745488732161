// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Programi_wrapper__Cx2iL {
  flex-direction: column;
  align-items: baseline;
  margin: 48px 0px;
  width: 100%;
  box-sizing: border-box;
}
.Programi_headerContainer__HKYHL {
  margin-bottom: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/programi/Programi.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,gBAAgB;EAChB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  flex-direction: column;\n  align-items: baseline;\n  margin: 48px 0px;\n  width: 100%;\n  box-sizing: border-box;\n}\n.headerContainer {\n  margin-bottom: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Programi_wrapper__Cx2iL`,
	"headerContainer": `Programi_headerContainer__HKYHL`
};
export default ___CSS_LOADER_EXPORT___;
