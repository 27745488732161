import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CalendarIcon from "../../assets/svg/CalendarIcon.js";
import ChevronRightIcon from "../../assets/svg/ChevronRightIcon";
import MasterCalendarIcon from "../../assets/svg/MasterCalendarIcon.js";
import { useUserState } from "../../context/UserContext.js";
import styles from "../documents/Documents.module.css";

export default function NewCalendar() {
  const navigate = useNavigate();
  const { role } = useUserState();

  useEffect(() => {
    if (role === "Polaznik") {
      navigate("/kalendaripd");
    }
  }, [navigate, role]);

  return (
    <>
      <div className={styles.cards}>
        {(role === "Referada" ||
          role === "Supervizor" ||
          role === "Voditelj" ||
          role === "Polaznik VT-a") && (
          <div
            className={`${styles.cardContainer} ${styles.master}`}
            onClick={() => navigate("/master")}
          >
            <MasterCalendarIcon className={styles.cardIcon} />
            <div className={styles.cardDivider} />
            <div className={styles.cardTextContainer}>
              <p className={styles.cardText}>master kalendar</p>
              <ChevronRightIcon className={styles.cardIcon} />
            </div>
          </div>
        )}
        <div
          className={`${styles.cardContainer} ${styles.calendarIcon}`}
          onClick={() => navigate("/kalendaripd")}
        >
          <CalendarIcon className={styles.cardIcon} />
          <div className={styles.cardDivider} />
          <div className={styles.cardTextContainer}>
            <p className={styles.cardText}>školski kalendar</p>
            <ChevronRightIcon className={styles.cardIcon} />
          </div>
        </div>
      </div>
    </>
  );
}
