import { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Question from "./assets/img/Help.png";
import Layout from "./components/Layout/Layout.jsx";
import Modal from "./components/Modal/Modal.jsx";
import PrivateRoutes from "./components/PrivateRoute.js";
import { ProtectedRoute } from "./components/ProtectedRoute";
import SessionExpired from "./components/sessionExpired/SessionExpired.jsx";
import SideMenu from "./components/sideMenu/SideMenu.js";
import CreateEventProvider from "./context/CreateEventContext.js";
import GroupProvider from "./context/CreateGroupContext.js";
import { RoleProvider } from "./context/PolaznikContext.js";
import UserProvider, { useUserState } from "./context/UserContext.js";
import Arhiviraj from "./scenes/arhiviraj/index.jsx";
import Attendees from "./scenes/attendees/index.jsx";
import Kalendar from "./scenes/calendar/NewCalendar";
import CalendarIpd from "./scenes/calendarIpd/index.jsx";
import Dashboard from "./scenes/dashboard/Dashboard.js";
import Delete from "./scenes/delete/index.jsx";
import DjelatnikProfil from "./scenes/djelatnikProfil/index.jsx";
import EnrollmentForm from "./scenes/documents/components/enrollmentApplications/components/enrollmentForm/EnrollmentForm";
import EnrollmentApplications from "./scenes/documents/components/enrollmentApplications/EnrollmentApplications";
import OdobrenjeAplikacija from "./scenes/documents/components/OdobrenjeAplikacija/OdobrenjeAplikacija.js";
import Regulations from "./scenes/documents/components/regulations/Regulations";
import Documents from "./scenes/documents/Documents";
import EModuliPage from "./scenes/EModuliEditCreate/EModuliPage.jsx";
import AddNastupnoPredavanjePage from "./scenes/evidencija/AddNastupnoPredavanjePage.jsx";
import EvidencijaPage from "./scenes/evidencija/EvidencijaPage.jsx";
import RucniUnosPage from "./scenes/evidencija/RucniUnosPage.jsx";
import EvidencijaDolazaka from "./scenes/evidencijaDolazaka/EvidencijaDolazaka.js";
import EvidencijaPolaznika from "./scenes/evidencijaPolaznika/EvidencijaPolaznika";
import Export from "./scenes/export/index.jsx";
import FakultativnaPage from "./scenes/FakultativnaRadionicaEditCreate/FakultativnaPage.jsx";
import AddPolaznikToTheGroup from "./scenes/groups/components/groupCreate/AddUserToTheGroup/addPolaznikToTheGroup.jsx";
import GroupCreate from "./scenes/groups/components/groupCreate/GroupCreate.js";
import Groups from "./scenes/groups/Groups";
import { useAuthentication } from "./scenes/hooks/useAuthentication.js";
import { useRefreshToken } from "./scenes/hooks/useRefreshToken.js";
import IntenziviPage from "./scenes/IntenziviEditCreate/IntenziviPage.jsx";
import AddEmployeeToGroup from "./scenes/IPDTeam/AddEmployeeToGroup/AddEmployeeToGroup.jsx";
import DodajDjelatnika from "./scenes/IPDTeam/DodajDjelatnika/DodajDjelatnika.jsx";
import IPDTeam from "./scenes/IPDTeam/IPDTeam.jsx";
import ObrisiDjelatnika from "./scenes/IPDTeam/ObrisiDjelatnika/ObrisiDjelatnika.jsx";
import Login from "./scenes/loginPage/Login.js";
import Master from "./scenes/master/index.js";
import MyProfile from "./scenes/myProfile/MyProfile.js";
import ObracunSoba from "./scenes/obracunSoba/ObracunSoba";
import Osvrti from "./scenes/OsvrtiNaVoditelja/OsvrtiNaVoditelja.jsx";
import Payment from "./scenes/payment/Payment";
import PojedinaObavijest from "./scenes/PojedinaObavijest/PojedinaObavijest.jsx";
import PojedinaPrijavnicaPage from "./scenes/PojedinaPrijavnica/PojedinaPrijavnicaPage.jsx";
import PojediniProgram from "./scenes/pojediniProgrami/PojediniProgram.jsx";
import EditIntrospections from "./scenes/pojediniProgrami/ProgramContainer/Components/EditIntrospections/EditIntrospections";
import IntrospekcijeContainer from "./scenes/pojediniProgrami/SectionContainer/IntrospekcijeContainer.jsx";
import Section from "./scenes/pojediniProgrami/SectionContainer/Section.jsx";
import Polaznici from "./scenes/polaznici/index.jsx";
import PolazniciPregled from "./scenes/polazniciPregled/index.jsx";
import EksterniLink from "./scenes/PrijavaNaEksterniLink/EksterniLink.jsx";
import PrijavnicePage from "./scenes/Prijavnice/PrijavnicePage.jsx";
import PrimljeneObavijesti from "./scenes/PrimljeneObavijesti/PrimljeneObavijesti.jsx";
import ProfileSettings from "./scenes/profileSettings/ProfileSettings.js";
import Programi from "./scenes/programi/Programi.jsx";
import Introspekcije from "./scenes/singleGroup/Introspekcije/Introspekcije.jsx";
import SingleGroup from "./scenes/singleGroup/SingleGroup";
import UrediUloge from "./scenes/singleGroup/Voditelj-UrediUloge/UrediUloge.jsx";
import SlanjeObavijesti from "./scenes/slanjeObavijesti/SlanjeObavijesti.js";
import SupervizijePage from "./scenes/SupervizijeEditCreate/SupervizijePage.jsx";
import Terapeuti from "./scenes/terapeuti/Terapeuti.jsx";
import TerapeutProfil from "./scenes/terapeuti/TerapeutProfil/TerapeutProfil.jsx";
import MojaMentorstvaVoditelj from "./scenes/voditelji/components/MojaMentorstvaVoditelj.jsx";

const ALLOWED_ZAKUPITELJ_ROUTES = [
  "/kalendar",
  "/kalendaripd",
  "/master",
  "/sobe",
  "/primljene-obavijesti",
];

function App() {
  useRefreshToken();
  const location = useLocation();
  const isMasterPage = location.pathname === "/master";
  const isEksterniLink = location.pathname === "/eksterni-link";
  const isCalendarIpdPage = location.pathname === "/kalendaripd";
  const pathname = location.pathname;
  const isPolaznikPage = pathname.includes("polaznik");
  const isPolaznikPageTwo = pathname.includes("Polaznik");
  const isVoditeljPage = pathname.includes("voditelj");
  const isVoditeljPageTwo = pathname.includes("Voditelj");
  const isVoditeljPageThree = pathname.includes("djelatnik");
  const isRootPath = location.pathname === "/";
  const isCalendarIpdPagePolaznik = pathname.includes("kalendaripdPolaznik");
  const isCalendarIpdPageVoditelj = pathname.includes("kalendaripdVoditelj");
  const isReferadaSobe = pathname.includes("sobe");
  const [loading, setLoading] = useState(true);
  const { role } = useUserState() || {};

  const [open, setOpen] = useState(true);
  const [openExpired, setOpenExpired] = useState(false);
  const { user } = useAuthentication();

  useEffect(() => {
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);
    const timeToMidnight = midnight.getTime() - now.getTime();

    const timeoutId = setTimeout(() => {
      setOpenExpired(true);
    }, timeToMidnight);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (!user)
      setTimeout(() => {
        setOpen(true);
      }, 2500);
  }, [user]);

  if (loading) return <></>;

  const editMode = false;
  return (
    <UserProvider>
      <RoleProvider>
        <GroupProvider>
          <CreateEventProvider>
            {openExpired && !isRootPath && (
              <Modal
                image={Question}
                description="Ako želiš nastaviti aktivnosti u školskom software-u klikni 'Nastavi'"
                buttons={[
                  {
                    label: "Nastavi",
                    className: "",
                    action: () => {
                      const date = new Date();
                      setOpenExpired(false);
                      localStorage.setItem(
                        "sessionTime",
                        `${date.setHours(date.getHours() + 3)}`
                      );
                      setOpenExpired(false);
                    },
                  },
                ]}
                countdown={true}
              />
            )}
            {!user && open && !isRootPath && (
              <SessionExpired setOpen={setOpen} />
            )}
            <div className="app">
              {!isRootPath &&
                !isCalendarIpdPage &&
                !isMasterPage &&
                !isEksterniLink &&
                !isPolaznikPage &&
                !isPolaznikPageTwo &&
                !isVoditeljPage &&
                !isVoditeljPageTwo &&
                !isVoditeljPageThree &&
                !isVoditeljPageThree &&
                !isReferadaSobe &&
                !isCalendarIpdPageVoditelj && <SideMenu className="sidebar" />}

              {!isCalendarIpdPagePolaznik && isPolaznikPage && (
                <SideMenu className="sidebar" />
              )}

              {!isCalendarIpdPageVoditelj &&
                (isVoditeljPage ||
                  isVoditeljPageTwo ||
                  isVoditeljPageThree) && <SideMenu className="sidebar" />}

              <main
                className="content"
                style={{
                  marginLeft:
                    isRootPath ||
                    isCalendarIpdPage ||
                    isMasterPage ||
                    isEksterniLink ||
                    isCalendarIpdPagePolaznik ||
                    isCalendarIpdPageVoditelj
                      ? 0
                      : 0,
                }}
              >
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route
                    path="/moj-profil"
                    element={
                      <Layout
                        title={editMode ? "Uredi profil" : "Moj profil"}
                        showSearchBox={false}
                      >
                        <MyProfile />
                      </Layout>
                    }
                  />
                  <Route
                    path="/postavke"
                    element={
                      <Layout title={"Postavke profila"} showSearchBox={false}>
                        <ProfileSettings />
                      </Layout>
                    }
                  />
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path="/kalendar"
                      element={
                        <ProtectedRoute
                          allowedRoutes={ALLOWED_ZAKUPITELJ_ROUTES}
                          redirectTo="/kalendar"
                        >
                          <Layout
                            title={"Pregled kalendara"}
                            showSearchBox={false}
                          >
                            <Kalendar />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/kalendaripd"
                      element={
                        <ProtectedRoute
                          allowedRoutes={ALLOWED_ZAKUPITELJ_ROUTES}
                          redirectTo="/kalendar"
                        >
                          <CalendarIpd />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/master"
                      element={
                        <ProtectedRoute
                          allowedRoutes={ALLOWED_ZAKUPITELJ_ROUTES}
                          redirectTo="/kalendar"
                        >
                          <Master />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sobe"
                      element={
                        <ProtectedRoute
                          allowedRoutes={ALLOWED_ZAKUPITELJ_ROUTES}
                          redirectTo="/kalendar"
                        >
                          <ObracunSoba />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/primljene-obavijesti"
                      element={
                        <ProtectedRoute
                          allowedRoutes={ALLOWED_ZAKUPITELJ_ROUTES}
                          redirectTo="/kalendar"
                        >
                          <Layout title={"Obavijesti"} showSearchBox={false}>
                            <PrimljeneObavijesti />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/arhiviraj-polaznika"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled polaznika"}
                            showSearchBox={false}
                          >
                            <Arhiviraj />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/delete-polaznik"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled polaznika"}
                            showSearchBox={false}
                          >
                            <Delete />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/payment"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Plaćanja i narudžbe"}
                            showSearchBox={false}
                          >
                            <Payment />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/export-polaznika"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled polaznika"}
                            showSearchBox={false}
                          >
                            <Export />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/polaznici"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled polaznika"}
                            showSearchBox={false}
                          >
                            <Attendees />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dodaj-polaznika"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled polaznika"}
                            showSearchBox={false}
                          >
                            <Polaznici />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dodaj-polaznika-u-grupu"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled polaznika"}
                            showSearchBox={false}
                          >
                            <AddPolaznikToTheGroup />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/introspekcije-polaznik/:index"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled programa"}
                            showSearchBox={false}
                          >
                            <IntrospekcijeContainer />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/pojedini-polaznik/:pojediniPolaznikID"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled polaznika"}>
                            <PolazniciPregled />{" "}
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/programi/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled programa"}>
                            <Programi />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/fakultativna-radionica-edit-create/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled programa"}>
                            <FakultativnaPage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/grupna-supervizija-edit-create/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled programa"}>
                            <SupervizijePage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/e-moduli-edit-create/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled programa"}>
                            <EModuliPage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/grupe"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled grupa"} showSearchBox={false}>
                            <Groups />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/grupe/:groupId"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={editMode ? "Uredi grupu" : "Pregled grupa"}
                            showSearchBox={editMode ? false : true}
                          >
                            <SingleGroup />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/introspekcije/:groupId"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Introspekcije"}>
                            <Introspekcije />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/voditelj/uredi-uloge"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Uredi uloge u grupi"}
                            showSearchBox={false}
                          >
                            <UrediUloge />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/grupe/nova-grupa"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Kreiraj grupu"} showSearchBox={false}>
                            <GroupCreate />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/grupe/DodajDjelatnika"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <AddEmployeeToGroup />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dokumenti"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={
                              role === "Referada"
                                ? "Potvrde i važni dokumenti"
                                : "Pregled dokumenata"
                            }
                          >
                            <Documents />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dokumenti/pravilnici"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Regulations />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/dokumenti/aplikacije-za-upis"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Aplikacije za upis"}
                            showSearchBox={false}
                          >
                            <EnrollmentApplications />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/odobrenje-aplikacija/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Odobrenje aplikacija za upis"}
                            showSearchBox={true}
                          >
                            <OdobrenjeAplikacija />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dokumenti/aplikacije-za-upis/anketa"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Aplikacije za upis"}
                            showSearchBox={false}
                          >
                            <EnrollmentForm />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dokumenti/aplikacije-za-upis/anketa/:programApplicationId"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Aplikacije za upis"}
                            showSearchBox={false}
                          >
                            <EnrollmentForm />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/evidencija-dolazaka"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Evidencija dolazaka"}
                            showSearchBox={false}
                          >
                            <EvidencijaDolazaka />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/evidencijaVoditeljPolaznik"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Uvid u evidenciju"}
                            showSearchBox={false}
                          >
                            <EvidencijaPolaznika />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/slanje-obavijesti"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Slanje obavijesti"}
                            showSearchBox={false}
                          >
                            <SlanjeObavijesti />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/pojedina-obavijest/:id"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Obavijesti"} showSearchBox={false}>
                            <PojedinaObavijest />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/edit-introspections/:programId/:title"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Uredi introspekcije"}
                            showSearchBox={false}
                          >
                            <EditIntrospections />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/evidencija"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Uvid u evidenciju"}
                            showSearchBox={false}
                          >
                            <EvidencijaPage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dodaj-nastupno-predavanje"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Uvid u evidenciju"}
                            showSearchBox={false}
                          >
                            <AddNastupnoPredavanjePage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/pojediniProgram/:program_id/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled programa"}>
                            <PojediniProgram />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/djelatnici"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"IPD Tim"} showSearchBox={false}>
                            <IPDTeam />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/pojedini-djelatnik/:djelatnikId"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled djelatnika"}
                            showSearchBox={false}
                          >
                            <DjelatnikProfil />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dodajDjelatnika"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Dodaj djelatnika"}
                            showSearchBox={false}
                          >
                            <DodajDjelatnika />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/intenzivi-edit-create/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Pregled programa"}>
                            <IntenziviPage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/evidencija-rucni-unos/:program"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Uvid u evidenciju"}
                            showSearchBox={false}
                          >
                            <RucniUnosPage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/djelatnici/obrisidjelatnika"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Obriši djelatnika"}
                            showSearchBox={false}
                          >
                            <ObrisiDjelatnika />{" "}
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/pojedina-prijavnica/:prijavnica/:id"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Prijavnice"} showSearchBox={false}>
                            <PojedinaPrijavnicaPage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/pojediniProgram-section/:moduleId/:program_id/:id"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Pregled programa"}
                            showSearchBox={false}
                          >
                            <Section />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/prijavnice/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout title={"Prijavnice"}>
                            <PrijavnicePage />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/terapeuti"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Odaberi terapeuta"}
                            showSearchBox={false}
                          >
                            <Terapeuti />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/profile/:roles/:id"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Odaberi terapeuta"}
                            showSearchBox={false}
                          >
                            <TerapeutProfil />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/djelatnik/:roles/:id"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <DjelatnikProfil />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/voditelj-moja-mentorstva/:groupId"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Layout
                            title={"Moja mentorstva"}
                            showSearchBox={false}
                          >
                            <MojaMentorstvaVoditelj />
                          </Layout>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/osvrti/:selectedTab"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <Osvrti />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/eksterni-link"
                      element={
                        <ProtectedRoute redirectTo="/kalendar">
                          <EksterniLink />
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                </Routes>
              </main>
            </div>
            {/* </ZoomProvider> */}
          </CreateEventProvider>
        </GroupProvider>
      </RoleProvider>
    </UserProvider>
  );
}

export default App;
