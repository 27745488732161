import "quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Edit from "../../../assets/img/Edit.png";
import Success from "../../../assets/img/Success.png";
import LeftArrow from "../../../assets/svg/LeftArrow.svg";
import Avatar from "../../../assets/svg/UserAvatarIcon.svg";
import Modal from "../../../components/Modal/Modal";
import QuillEditor from "../../../components/QuillEditor/QuillEditor";
import { useUserState } from "../../../context/UserContext";
import { useAuthentication } from "../../hooks/useAuthentication.js";
import styles from "../../voditelji/components/MojaMentorstvaVoditelj.module.css";
import { getSingleIntrospectionData } from "../API";
import { Title } from "../ProgramContainer/ProgramContainer.styles";
import {
  createIntrospection,
  getMyMentor,
  updateIntrospection,
} from "./API.js";

function IntrospekcijeContainer({
  description,
  comments,
  answers,
  handleCommentsUpdate,
  isMentorstva,
  polaznikId,
  groupProgramId,
  introspectionIdMentor,
  reRender,
  setReRender,
}) {
  const location = useLocation();
  const { index } = useParams();

  const firstIds = [1, 9, 15, 21, 27, 33, 41, 47, 53, 59];
  const lastIds = [8, 14, 20, 26, 32, 40, 46, 52, 58, 65];

  const minCommentSize = 150;

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(comments || "");
  const [introspectionData, setIntrospectionData] = useState([]);
  let isTitle = false;
  let isNavigation = false;
  let title = "";
  const introspectionId = isMentorstva
    ? introspectionIdMentor
    : parseInt(index, 10);

  let programId = isMentorstva ? groupProgramId : location?.state?.programId;

  useEffect(() => {
    setInputValue(comments);
  }, [comments, index]);

  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const [showEditComment, setShowEditComment] = useState(false);
  const [littleComment, setLittleComment] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrevious, setDisabledPrevious] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [userAnswer, setUserAnswer] = useState(answers || "");
  const [commentSize, setCommentSize] = useState(0);
  const [isCommentDone, setIsCommentDone] = useState(false);
  const [isReal, setIsReal] = useState(false);
  const [mentorData, setMentorData] = useState({});
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const userId = role;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSingleIntrospectionData(index, authToken);
        setIntrospectionData(response);
        setIsReal(response.userInIntrospection.isReal === false ? false : true);
      } catch (error) {
        console.error(error);
      }
    };
    if (authToken) fetchData();
  }, [authToken, index]);

  if (!comments && !answers && !description) {
    description = introspectionData?.questions;
    comments = introspectionData?.userInIntrospection?.mentorComment;
    answers = introspectionData?.userInIntrospection?.userAnswer;
    title = location?.state?.title;
    isTitle = true;
    isNavigation = true;
  }

  const items = description?.split(/\d+\.\s+/);
  if (items && items[0] === "") {
    items.shift();
  }

  const handleSaveClick = async () => {
    try {
      let commentLength = inputValue
        ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, "")
        .replace(/\s/g, "").length;
      if (commentLength >= minCommentSize) {
        await handleCommentsUpdate(inputValue, true);
        setShowEditComment(false);
        setLittleComment(false);
      } else {
        await handleCommentsUpdate(inputValue, false);
        setLittleComment(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const descriptionElements = items?.map((item, introspectionId) => (
    <p key={introspectionId}>{`${introspectionId + 1}. ${item}`}</p>
  ));

  useEffect(() => {
    if (firstIds.includes(introspectionId)) {
      setDisabledPrevious(true);
    } else if (lastIds.includes(introspectionId)) {
      setDisabledNext(true);
    } else {
      setDisabledPrevious(false);
      setDisabledNext(false);
    }
  }, [introspectionId]);

  const handleUpdate = async () => {
    const newIntrospectionsData = {
      introspectionId: introspectionId,
      mentorComment: inputValue || "",
      userAnswer: userAnswer,
      userId: isMentorstva ? polaznikId : userData.id,
      isDone: isDone,
    };
    let response;
    if (!isReal) {
      response = await createIntrospection(newIntrospectionsData, authToken);
      if (!response?.introspectionId && !isMentorstva) {
        setIsErrorModalOpen(true);
        return;
      }
      setIsReal(true);
    } else {
      response = await updateIntrospection(newIntrospectionsData, authToken);

      if (response[0] !== 1) {
        setIsErrorModalOpen(true);
        return;
      }
    }
  };

  useEffect(() => {
    let commentLength = inputValue
      ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?]/g, "")
      .replace(/\s/g, "").length;
    if (commentLength < minCommentSize) {
      setCommentSize(commentLength);
      setIsCommentDone(false);
    } else {
      setCommentSize(minCommentSize);
      setIsCommentDone(true);
    }
  }, [inputValue]);

  useEffect(() => {
    const fetchMentor = async () => {
      if (isMentorstva) {
        const response = await getMyMentor(userData.id, programId, authToken);
        setMentorData(response);
      }
    };
    if (authToken) fetchMentor();
  }, [authToken, isMentorstva]);

  return (
    <div className="introspekcije-wrapper">
      {isModalOpen && (
        <Modal
          image={Success}
          description={
            isDone
              ? "Tvoja introspekcija je uspješno spremljena."
              : "Tvoja introspekcija je uspješno spremljena, ali nema dovoljno znakova (min. 1300) da bi bila završena."
          }
          buttons={[
            {
              label: "U redu",
              action: () => {
                if (!isMentorstva) {
                  navigate(`/pojediniprogram/${programId}/one`);
                } else {
                  setIsModalOpen(false);
                }
              },
              className: "modal-btn-success",
            },
          ]}
        />
      )}
      {isErrorModalOpen && (
        <Modal
          image={null}
          description={"Tvoja introspekcija nije spremljena, pokušajte ponovo."}
          buttons={[
            {
              label: "U redu",
              action: () => {
                setIsErrorModalOpen(false);
              },
              className: "modal-btn-success",
            },
          ]}
        />
      )}
      {isTitle && (
        <div style={{ width: "100%", marginTop: "-50px" }}>
          <Title>{title}</Title>
        </div>
      )}
      {isNavigation && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              cursor: disabledPrevious ? "not-allowed" : "pointer",
              opacity: disabledPrevious ? 0.6 : 1,
              userSelect: "none",
            }}
            onClick={() => {
              if (!disabledPrevious) {
                navigate(`/introspekcije-polaznik/${introspectionId - 1}`, {
                  state: { programId: programId },
                });
              }
            }}
          >
            <img src={LeftArrow} alt="" />
            <p
              style={{
                color: "#5F6D7E",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Prethodna
            </p>
          </div>
          <p
            style={{
              color: "#5F6D7E",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 20,
              userSelect: "none",
            }}
          >
            {introspectionId}. Introspekcija
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              userSelect: "none",
              cursor: disabledNext ? "not-allowed" : "pointer",
              opacity: disabledNext ? 0.6 : 1,
            }}
            onClick={() => {
              if (!disabledNext) {
                navigate(`/introspekcije-polaznik/${introspectionId + 1}`, {
                  state: { programId: programId },
                });
              }
            }}
          >
            <p
              style={{
                color: "#5F6D7E",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: 16,
              }}
            >
              Sljedeća
            </p>
            <img
              src={LeftArrow}
              alt=""
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
      )}
      <QuillEditor
        answer={answers}
        description={descriptionElements}
        setUserAnswer={setUserAnswer}
        userId={userId}
        handleUpdate={handleUpdate}
        setIsDone={setIsDone}
        isMentorstva={isMentorstva}
      />
      <div className="introspekcije-save-btn-wrapper">
        <button
          className="introspekcije-save-btn"
          onClick={() => {
            handleUpdate();
            setIsModalOpen(true);
            setReRender(!reRender);
          }}
        >
          Spremi
        </button>
      </div>
      <span className="introspekcije-label">
        {isMentorstva &&
        (userId === "Voditelj" ||
          userId === "Polaznik VT-a" ||
          userId === "Terapeut" ||
          userId === "Učitelj" ||
          userId === "Supervizor")
          ? showEditComment
            ? "Uredi komentar"
            : comments
            ? "Moj komentar"
            : ""
          : "Komentar mentora"}
      </span>
      {littleComment && (
        <p style={{ color: "red" }}>
          Vaš komentar je spremljen, ali da bi bio prikazan polazniku treba
          imati više od 150 slova!
        </p>
      )}
      {comments &&
      comments.length > 0 &&
      !isMentorstva &&
      introspectionData?.isRead ? (
        <div className="introspekcije-comments-row">
          <img
            alt="avatar"
            src={
              isMentorstva ? userData?.imageUrl : mentorData?.imageUrl || Avatar
            }
            className="instrospekcije-avatar-icon"
          />
          <div
            style={
              littleComment
                ? { justifyContent: "space-between", border: "1px solid red" }
                : { justifyContent: "space-between" }
            }
            className="introspekcije-no-comments-container"
          >
            <textarea
              value={inputValue}
              className="introspekcije-no-comments input-text"
              autoFocus={showEditComment}
              disabled={!showEditComment}
              onChange={handleInputChange}
              style={littleComment ? { border: "1px solid red" } : {}}
            />
            {isMentorstva &&
              (userId === "Voditelj" ||
                userId === "Polaznik VT-a" ||
                userId === "Terapeut" ||
                userId === "Učitelj" ||
                userId === "Supervizor") &&
              !showEditComment && (
                <button
                  className="introspekcije-edit-btn"
                  onClick={() => setShowEditComment(true)}
                >
                  <img alt="" src={Edit} />
                </button>
              )}
          </div>
        </div>
      ) : isMentorstva ? (
        <div className="introspekcije-comments-row">
          <img
            alt="avatar"
            src={
              isMentorstva ? userData?.imageUrl : mentorData?.imageUrl || Avatar
            }
            className="instrospekcije-avatar-icon"
          />
          <div
            style={
              littleComment
                ? { justifyContent: "space-between", border: "1px solid red" }
                : { justifyContent: "space-between" }
            }
            className="introspekcije-no-comments-container"
          >
            <textarea
              value={inputValue}
              className="introspekcije-no-comments input-text"
              autoFocus={showEditComment}
              disabled={!showEditComment}
              onChange={handleInputChange}
              style={littleComment ? { border: "1px solid red" } : {}}
            />
            {isMentorstva &&
              (userId === "Voditelj" ||
                userId === "Polaznik VT-a" ||
                userId === "Terapeut" ||
                userId === "Učitelj" ||
                userId === "Supervizor") &&
              !showEditComment && (
                <button
                  className="introspekcije-edit-btn"
                  onClick={() => setShowEditComment(true)}
                >
                  <img alt="" src={Edit} />
                </button>
              )}
          </div>
        </div>
      ) : (comments === "" || !introspectionData?.isRead) &&
        !isMentorstva &&
        (userId !== "Voditelj" ||
          userId !== "Polaznik VT-a" ||
          userId !== "Terapeut" ||
          userId !== "Učitelj" ||
          userId === "Supervizor") ? (
        <div className="introspekcije-no-comments">
          Mentor još nije ostavio komentar
        </div>
      ) : comments === "" &&
        isMentorstva &&
        (userId === "Voditelj" ||
          userId === "Polaznik VT-a" ||
          userId === "Terapeut" ||
          userId === "Učitelj" ||
          userId === "Supervizor") ? (
        <div className="introspekcije-comments-row">
          <img
            alt="avatar"
            src={userData.imageUrl}
            className="instrospekcije-avatar-icon"
          />
          <div
            className="introspekcije-no-comments-container"
            style={
              littleComment
                ? { justifyContent: "space-between", border: "1px solid red" }
                : { justifyContent: "space-between" }
            }
          >
            <textarea
              value={inputValue}
              className="introspekcije-no-comments input-text"
              autoFocus={showEditComment}
              disabled={!showEditComment}
              onChange={handleInputChange}
              placeholder="Ostavi komentar"
            />
            {isMentorstva &&
              (userId === "Voditelj" ||
                userId === "Polaznik VT-a" ||
                userId === "Terapeut" ||
                userId === "Učitelj" ||
                userId === "Supervizor") &&
              !showEditComment && (
                <button
                  className="introspekcije-edit-btn"
                  onClick={() => setShowEditComment(true)}
                >
                  <img alt="" src={Edit} />
                </button>
              )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {showEditComment && (
        <div
          className="introspekcije-description"
          style={{ width: "100%", textAlign: "right", fontSize: 15 }}
        >
          {commentSize}/{minCommentSize}
        </div>
      )}
      {showEditComment && (
        <div className={styles.editModeButtonscontainer}>
          <div
            className={styles.cancelButton}
            onClick={() => {
              setShowEditComment(false);
              setInputValue(comments || "");
              setLittleComment(false);
            }}
          >
            <div className={styles.cancelButtonText}>Odustani </div>
          </div>

          <div className={styles.saveButton}>
            <div className={styles.saveButtonText} onClick={handleSaveClick}>
              Spremi promjene
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IntrospekcijeContainer;
