import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Arrow from "../../../assets/img/BackArrow.png";
import Success from "../../../assets/img/Success.png";
import Modal from "../../../components/Modal/Modal";
import { useAuthentication } from "../../hooks/useAuthentication";
import { updateProgramById } from "../API";
import { fetchProgramData } from "../utils";
import { createResource } from "./api/createResource";
import { updateResource } from "./api/updateResource";
import IskustveniDio from "./Components/IskustveniDIo/IskustveniDio";
import TeorijskiDio from "./Components/TeorijskiDio/TeorijskiDio";
import "./ProgramContainer.css";
import {
  Back,
  BackRow,
  DropdownButton,
  Opis,
  OpisWrapper,
  Title,
  UrediButton,
  Wrapper,
} from "./ProgramContainer.styles";
import { generateUpdateObject } from "./utils";

function ProgramContainer({
  image,
  title,
  opis,
  teorijskiDio = [],
  iskustveniDio = [],
  userId,
  setProgramData,
  datumUpisa,
}) {
  const navigate = useNavigate();
  const [showFullOpis, setShowFullOpis] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { authToken } = useAuthentication();
  const [iskustveniDioData, setIskustveniDioData] = useState(iskustveniDio);
  const [description, setDescription] = useState(opis);
  const location = useLocation();
  const { pathname } = location;
  const { selectedTab } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [newResources, setNewResources] = useState([]);
  const [updatedResources, setUpdatedResources] = useState([]);
  const { program_id } = useParams();
  const [isAllowed, setIsAllowed] = useState(null);
  const toggleOpis = () => {
    setShowFullOpis(!showFullOpis);
  };
  const handleUpdateProgram = async () => {
    try {
      const update = generateUpdateObject(iskustveniDioData, description);
      const response = await updateProgramById(update, authToken, program_id);
      if (newResources.length > 0) {
        for (const resource of newResources) {
          await createResource(resource, authToken);
        }
      }
      if (updatedResources.length > 0) {
        for (const resource of updatedResources) {
          await updateResource(resource, resource.id, authToken);
        }
      }
      if (response === 200 || authToken) {
        setIsEditMode(false);
        setNewResources([]);
        setUpdatedResources([]);
        fetchProgramData(authToken, program_id, setProgramData, setIsAllowed);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error updating pojedini program:", error);
    }
  };
  return (
    <Wrapper>
      <BackRow onClick={() => navigate(`/programi/${selectedTab}`)}>
        <img alt="" src={Arrow} />
        <Back>Natrag na listu programa</Back>
      </BackRow>
      <div className="image-container-pojedini">
        <img className="pojedini-image" src={image} alt="" />
        {/* {(userId === "Polaznik" || userId === "Voditelj") && (
          <UpisRow>
            <UpisStyled>Datum upisa: {datumUpisa}.</UpisStyled>
            <UpisStyled>
              Valjanost upisa do: 01.10.{parseInt(datumUpisa.substr(-4)) + 1}.
            </UpisStyled>
          </UpisRow>
        )} */}
      </div>
      <Title>
        {title}
        {userId === "Referada" && (
          <UrediButton
            onClick={!isEditMode ? () => setIsEditMode(true) : undefined}
            disabled={isEditMode}
          >
            Uredi
          </UrediButton>
        )}
      </Title>
      <OpisWrapper>
        {isEditMode ? (
          <>
            <span className="edit-mode-text">Opis</span>
            <textarea
              className="edit-mode-opis"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </>
        ) : (
          <Opis
            style={{
              WebkitLineClamp: showFullOpis ? "unset" : 4,
              whiteSpace: showFullOpis ? "normal" : "",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {opis}
          </Opis>
        )}
        {!isEditMode && (
          <DropdownButton onClick={toggleOpis}>
            {showFullOpis ? "Prikaži manje" : "Prikaži više"}
          </DropdownButton>
        )}
      </OpisWrapper>

      <TeorijskiDio
        program_id={program_id}
        userId={userId}
        isEditMode={isEditMode}
        teorijskiDio={teorijskiDio}
        pathname={pathname}
        title={title}
        setNewResources={setNewResources}
        newResources={newResources}
        updatedResources={updatedResources}
        setUpdatedResources={setUpdatedResources}
      />
      {/* {!isEditMode && userId === "Referada" && (
        <div
          className={"introspekcije-edit-div"}
          onClick={() =>
            navigate(`/edit-introspections/${program_id}/${title}`)
          }
        >
          Uredi introspekcije
        </div>
      )} */}
      <IskustveniDio
        iskustveniDio={iskustveniDioData}
        isEditMode={isEditMode}
        setIskustveniDioData={setIskustveniDioData}
      />
      {isEditMode && (
        <div
          style={{
            position: "fixed",
            left: 35,
            bottom: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 15,
          }}
          className="btn-container"
        >
          <button
            className="pojedini-program-quit"
            onClick={() => setIsEditMode(false)}
          >
            Odustani
          </button>
          <button
            className="pojedini-program-save"
            onClick={handleUpdateProgram}
          >
            Spremi promjene
          </button>
        </div>
      )}
      {showModal && (
        <Modal
          title={"Podatci uspješno spremljeni!"}
          image={Success}
          buttons={[
            {
              label: "U redu",
              action: () => setShowModal(false),
              className: "modal-btn-success",
            },
          ]}
        />
      )}
    </Wrapper>
  );
}

export default ProgramContainer;
