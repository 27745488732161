// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_carouselContainer__eHc9y {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  overflow: auto;
  touch-action: none;
  height: 400px;
}
.Carousel_leftButton__xk8aa,
.Carousel_rightButton__bjbOw {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  z-index: 1000;
  background-color: transparent;
}

.Carousel_carouselButtons__C3qc5 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Carousel_spacer__7Of5t {
  flex: 1 1;
}
.Carousel_rightButton__bjbOw {
  transform: rotate(180deg);
}
.Carousel_leftButton__xk8aa:hover,
.Carousel_rightButton__bjbOw:hover {
  opacity: 0.79;
}
.Carousel_cardItem__qtQ6f {
  flex: 0 0 auto;
  transition: transform 300ms;
  margin-right: 24px;
}
.Carousel_cardItem__qtQ6f.Carousel_active__8DFHT {
  z-index: 1;
}

.Carousel_cardItem__qtQ6f.Carousel_hidden__Hr-6N {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@media screen and (max-width: 1100px) {
  .Carousel_leftButton__xk8aa,
  .Carousel_rightButton__bjbOw {
    display: none;
  }
}
@media screen and (min-width: 1100px) {
  .Carousel_leftButton__xk8aa {
    margin-right: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/Prijavnice/PolaznikVoditelj/components/Carousel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,aAAa;AACf;AACA;;EAEE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,SAAO;AACT;AACA;EACE,yBAAyB;AAC3B;AACA;;EAEE,aAAa;AACf;AACA;EACE,cAAc;EACd,2BAA2B;EAC3B,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE;;IAEE,aAAa;EACf;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".carouselContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: relative;\n  width: 100%;\n  overflow: auto;\n  touch-action: none;\n  height: 400px;\n}\n.leftButton,\n.rightButton {\n  width: 40px;\n  height: 40px;\n  border: none;\n  cursor: pointer;\n  z-index: 1000;\n  background-color: transparent;\n}\n\n.carouselButtons {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.spacer {\n  flex: 1;\n}\n.rightButton {\n  transform: rotate(180deg);\n}\n.leftButton:hover,\n.rightButton:hover {\n  opacity: 0.79;\n}\n.cardItem {\n  flex: 0 0 auto;\n  transition: transform 300ms;\n  margin-right: 24px;\n}\n.cardItem.active {\n  z-index: 1;\n}\n\n.cardItem.hidden {\n  opacity: 0;\n  visibility: hidden;\n  pointer-events: none;\n}\n\n@media screen and (max-width: 1100px) {\n  .leftButton,\n  .rightButton {\n    display: none;\n  }\n}\n@media screen and (min-width: 1100px) {\n  .leftButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carouselContainer": `Carousel_carouselContainer__eHc9y`,
	"leftButton": `Carousel_leftButton__xk8aa`,
	"rightButton": `Carousel_rightButton__bjbOw`,
	"carouselButtons": `Carousel_carouselButtons__C3qc5`,
	"spacer": `Carousel_spacer__7Of5t`,
	"cardItem": `Carousel_cardItem__qtQ6f`,
	"active": `Carousel_active__8DFHT`,
	"hidden": `Carousel_hidden__Hr-6N`
};
export default ___CSS_LOADER_EXPORT___;
