import { useEffect, useRef, useState } from "react";
import ChevronDownIcon from "../../assets/svg/ChevronDownIcon";
import ChevronUpIcon from "../../assets/svg/ChevronUpIcon";
import { useUserState } from "../../context/UserContext";
import styles from "./UserMenu.module.css";
import UserMenuDropdown from "./components/userMenuDropdown/UserMenuDropdown";
const UserMenu = ({ showArrow = true, rightPosition }) => {
  const { role, userData } = useUserState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        menuRef.current.contains(event.target) &&
        dropRef.current
      ) {
        setOpenDropdown(true);
      } else {
        setOpenDropdown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div
        className={styles.container}
        onClick={() => setOpenDropdown(!openDropdown)}
        ref={menuRef}
      >
        {openDropdown && (
          <UserMenuDropdown dropRef={dropRef} rightPosition={rightPosition} />
        )}
        <img alt="user" src={userData.imageUrl} className={styles.userPhoto} />
        <div className={styles.userInfoContainer}>
          <p className={styles.userNameText}>
            {`${userData.firstName} ${userData.lastName}`}
          </p>
          <p className={styles.userRoleText}>
            {userData.firebaseId !== "h6qPCNut0kM8a7ZFYYcGLSgepii2"
              ? role
              : "Ravnatelj"}
          </p>
        </div>
        {showArrow && (
          <div
            className={
              openDropdown ? styles.dropdownButtonActive : styles.dropdownButton
            }
          >
            {openDropdown ? (
              <ChevronUpIcon className={styles.dropdownIcon} />
            ) : (
              <ChevronDownIcon className={styles.dropdownIcon} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default UserMenu;
