import React from "react";
import CardAccordion from "../../CardAccordionMain/CardAccordion";
import styles from "../Supervizije.module.css";

function EvaluacijeAccordions({
  userId,
  data,
  evidentionUserID,
  change,
  setChange,
  getSupervisionEntry,
}) {
  const columnNames = [
    "Datum",
    "Tip supervizije",
    "Supervizor",
    "Evaluacija",
    "",
  ];
  return (
    <div className={styles.supervizijeAccordion}>
      {data.map((item, index) => {
        return (
          <CardAccordion
            key={index}
            title={item.title}
            userId={userId}
            showItems={false}
            columnNames={columnNames}
            showModulName={false}
            tableData={item.modules?.[0].topics}
            evidentionUserID={evidentionUserID}
            getSupervisionEntry={getSupervisionEntry}
            change={change}
            setChange={setChange}
          />
        );
      })}
    </div>
  );
}

export default EvaluacijeAccordions;
