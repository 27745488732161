// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CertificateContainer_wrapper__n4PXP {
  gap: 56px;
  width: 100%;
  border-radius: 10px;
  padding: 36px 0px 36px 0px;
  border: 1px solid #d1d9e2;
  box-shadow: 0px 1px 2px 0px #1018280d;
  margin-top: 6px;
}
.CertificateContainer_wrapper__n4PXP,
.CertificateContainer_column__4QV5z,
.CertificateContainer_row__qkF2O,
.CertificateContainer_btn__KsmGX {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CertificateContainer_column__4QV5z {
  flex-direction: column;
  gap: 16px;
}
.CertificateContainer_row__qkF2O {
  gap: 10px;
}
.CertificateContainer_icon__EZ937 {
  width: 24px;
  height: 24px;
}
.CertificateContainer_label__HMpu8 {
  text-align: center;
  color: #5f6d7e;
}
.CertificateContainer_label__HMpu8,
.CertificateContainer_btn__KsmGX {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.CertificateContainer_btn__KsmGX {
  border: none;
  color: white;
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  text-align: left;
  height: 40px;
  background-color: #cb9c2d;
  transition: background-color 300ms ease-in-out;
}
.CertificateContainer_btn__KsmGX:hover {
  background-color: #b88d29;
  cursor: pointer;
}
.CertificateContainer_empty__Ko341 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #5f6d7e;
}
@media screen and (max-width: 750px) {
  .CertificateContainer_wrapper__n4PXP {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/evidencija/components/Supervizije/CertificateContainer/CertificateContainer.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,mBAAmB;EACnB,0BAA0B;EAC1B,yBAAyB;EACzB,qCAAqC;EACrC,eAAe;AACjB;AACA;;;;EAIE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,cAAc;AAChB;AACA;;EAEE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,8CAA8C;AAChD;AACA;EACE,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".wrapper {\n  gap: 56px;\n  width: 100%;\n  border-radius: 10px;\n  padding: 36px 0px 36px 0px;\n  border: 1px solid #d1d9e2;\n  box-shadow: 0px 1px 2px 0px #1018280d;\n  margin-top: 6px;\n}\n.wrapper,\n.column,\n.row,\n.btn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.column {\n  flex-direction: column;\n  gap: 16px;\n}\n.row {\n  gap: 10px;\n}\n.icon {\n  width: 24px;\n  height: 24px;\n}\n.label {\n  text-align: center;\n  color: #5f6d7e;\n}\n.label,\n.btn {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.btn {\n  border: none;\n  color: white;\n  padding: 8px 24px 8px 24px;\n  border-radius: 4px;\n  text-align: left;\n  height: 40px;\n  background-color: #cb9c2d;\n  transition: background-color 300ms ease-in-out;\n}\n.btn:hover {\n  background-color: #b88d29;\n  cursor: pointer;\n}\n.empty {\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 17px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #5f6d7e;\n}\n@media screen and (max-width: 750px) {\n  .wrapper {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CertificateContainer_wrapper__n4PXP`,
	"column": `CertificateContainer_column__4QV5z`,
	"row": `CertificateContainer_row__qkF2O`,
	"btn": `CertificateContainer_btn__KsmGX`,
	"icon": `CertificateContainer_icon__EZ937`,
	"label": `CertificateContainer_label__HMpu8`,
	"empty": `CertificateContainer_empty__Ko341`
};
export default ___CSS_LOADER_EXPORT___;
