import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Arrow from "../../../../../assets/img/BackArrow.png";
import Success from "../../../../../assets/img/Success.png";
import Modal from "../../../../../components/Modal/Modal.jsx";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import {
  getProgramsIntrospections,
  updateIntrospectionQuestion,
} from "../../../API";
import styles from "./EditIntrospections.module.css";

function EditIntrospections() {
  const { authToken } = useAuthentication();
  const { programId, title } = useParams();
  const [introspections, setIntrospections] = useState([]);
  const [editedQuestions, setEditedQuestions] = useState({});
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchIntrospections = async () => {
      try {
        const introData = await getProgramsIntrospections(programId, authToken);
        setIntrospections(introData.sort((a, b) => a.id - b.id));
        const initialQuestions = {};
        introData.forEach((item) => {
          initialQuestions[item.id] = item.questions;
        });
        setEditedQuestions(initialQuestions);
      } catch (err) {
        console.error("Error fetching introspections:", err);
      }
    };
    if (authToken) {
      fetchIntrospections();
    }
  }, [authToken, programId]);

  const handleInputChange = (introspectionId, value) => {
    setEditedQuestions((prev) => ({
      ...prev,
      [introspectionId]: value,
    }));
  };

  const handleUpdate = async (introspectionId) => {
    try {
      const response = await updateIntrospectionQuestion(
        introspectionId,
        editedQuestions[introspectionId],
        authToken
      );
      if (response[0] === 1) {
        setIsSuccessModalOpen(true);
      }
      console.log("Introspections successfully updated.");
    } catch (err) {
      console.error("Error updating introspections:", err);
    }
  };

  const successDeleteButtons = [
    {
      label: "Zatvori",
      action: () => {
        setIsSuccessModalOpen(false);
      },
      className: "modal-btn-success",
    },
  ];

  return (
    <div className={styles.wrapper}>
      {isSuccessModalOpen && (
        <Modal
          image={Success}
          description="Tekst introspekcije uspješno uređen!"
          buttons={successDeleteButtons}
          fullWidth={true}
        />
      )}
      <div className={styles.backRow} onClick={() => navigate(-1)}>
        <img alt="" src={Arrow} />
        <p className={styles.back}>Natrag</p>
      </div>
      <h1 className={styles.mainTitle}>{title}</h1>
      {introspections?.length > 0 &&
        introspections?.map((introspection, key) => (
          <div key={introspection.id} className={styles.introItem}>
            <h2 className={styles.title}>Introspekcija {key + 1}.</h2>
            <textarea
              className={styles.textarea}
              value={editedQuestions[introspection.id] || ""}
              onChange={(e) =>
                handleInputChange(introspection.id, e.target.value)
              }
            />
            <div className={styles.btnsContainer}>
              <button
                className={styles.saveButton}
                onClick={() => handleUpdate(introspection.id)}
              >
                Spremi promjene
              </button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default EditIntrospections;
