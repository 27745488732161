// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-filters-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  width: 100%;
}

.mjesto-upisa-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30%;
}

@media screen and (max-width: 950px) {
  .table-filters-container {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 40px;
  }
  .mjesto-upisa-filter {
    flex-direction: column;
    width: 70%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/attendees/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;IACE,sBAAsB;IACtB,SAAS;IACT,mBAAmB;EACrB;EACA;IACE,sBAAsB;IACtB,UAAU;EACZ;AACF","sourcesContent":[".table-filters-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 48px;\n  width: 100%;\n}\n\n.mjesto-upisa-filter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  width: 30%;\n}\n\n@media screen and (max-width: 950px) {\n  .table-filters-container {\n    flex-direction: column;\n    gap: 15px;\n    margin-bottom: 40px;\n  }\n  .mjesto-upisa-filter {\n    flex-direction: column;\n    width: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
