import React from "react";
import Close from "../../../../../assets/img/ExitIcon.png";
import styles from "./AcceptanceModal.module.css";

function AcceptanceModal({ onClose, polaznik, program, onAccept, onReject }) {
  return (
    <div className={styles.overlay}>
      <button className={styles.closeIcon} onClick={onClose}>
        <img alt="" src={Close} />
      </button>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>Upis za višu godinu</div>
          <div className={styles.label}>
            Jeste sigurni da želite prihvatiti polaznika (
            <span style={{ fontWeight: 700, color: "#cb9c2d" }}>
              {polaznik}
            </span>
            ) za upis više godine{" "}
            <span style={{ fontWeight: 700, color: "#cb9c2d" }}>
              {program}-a
            </span>
            ?
          </div>
          <div className={styles.btnRow}>
            <button className={styles.acceptBtn} onClick={onAccept}>
              Prihvaćam upis za višu godinu {program}-a
            </button>
            <button className={styles.rejectBtn} onClick={onReject}>
              Ne prihvaćam upis
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptanceModal;
