// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoModal_wrapper__Imcio {
  height: 100vh;
  width: 100%;
  position: fixed;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoModal_exit__MFc33 {
  position: absolute;
  right: 45px;
  top: 40px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.InfoModal_h1__H9D\\+N {
  width: 100%;
  text-align: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 28px;
  color: #4d4d4d;
}

.InfoModal_modalContainer__UiikS {
  width: 560px;
  height: 250px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 20px 40px;
  box-sizing: border-box;
}

.InfoModal_content__hTOow {
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.InfoModal_labels__KMBsb,
.InfoModal_eventInfos__vucXa {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.InfoModal_label__KPB6V {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  line-height: 22px;
  color: #4d4e4e;
}

.InfoModal_data__VataF {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 22px;
  color: #7e818c;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/calendarIpd/components/InfoModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,eAAe;EACf,mCAAmC;EACnC,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;;EAEE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".wrapper {\n  height: 100vh;\n  width: 100%;\n  position: fixed;\n  background-color: rgb(0, 0, 0, 0.7);\n  z-index: 99999;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.exit {\n  position: absolute;\n  right: 45px;\n  top: 40px;\n  height: 25px;\n  width: 25px;\n  cursor: pointer;\n}\n\n.h1 {\n  width: 100%;\n  text-align: center;\n  font-family: \"Inter\";\n  font-weight: 600;\n  font-size: 28px;\n  color: #4d4d4d;\n}\n\n.modalContainer {\n  width: 560px;\n  height: 250px;\n  background-color: #ffffff;\n  border-radius: 16px;\n  padding: 20px 40px;\n  box-sizing: border-box;\n}\n\n.content {\n  border-top: 1px solid #e0e0e0;\n  display: flex;\n  align-items: center;\n  padding: 15px 0;\n}\n.labels,\n.eventInfos {\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.label {\n  font-size: 16px;\n  font-family: \"Inter\";\n  font-weight: 600;\n  line-height: 22px;\n  color: #4d4e4e;\n}\n\n.data {\n  font-size: 16px;\n  font-family: \"Inter\";\n  font-weight: 500;\n  line-height: 22px;\n  color: #7e818c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `InfoModal_wrapper__Imcio`,
	"exit": `InfoModal_exit__MFc33`,
	"h1": `InfoModal_h1__H9D+N`,
	"modalContainer": `InfoModal_modalContainer__UiikS`,
	"content": `InfoModal_content__hTOow`,
	"labels": `InfoModal_labels__KMBsb`,
	"eventInfos": `InfoModal_eventInfos__vucXa`,
	"label": `InfoModal_label__KPB6V`,
	"data": `InfoModal_data__VataF`
};
export default ___CSS_LOADER_EXPORT___;
