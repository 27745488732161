export const handleSelectChange = (
  selectedOption,
  fieldName,
  setSelectedPrimatelj,
  setSelectedMjesto,
  setSelectedProgram,
  setSelectedGpr,
  setSelectedGrupa,
  setSelectedGprData,
  setSelectedRoleId
) => {
  switch (fieldName) {
    case "Primatelj":
      setSelectedPrimatelj(selectedOption);
      setSelectedMjesto([]);
      setSelectedProgram([]);
      setSelectedGpr({});
      setSelectedGrupa({});
      setSelectedGprData({});
      setSelectedRoleId([]);
      break;
    case "Mjesto":
      setSelectedMjesto(selectedOption);
      break;
    case "Program":
      setSelectedProgram(selectedOption);
      break;
    case "Grupa":
      setSelectedGrupa(selectedOption);
      break;
    case "Gpr":
      setSelectedGpr(selectedOption);
      break;
    case "GprNaziv":
      setSelectedGprData(selectedOption);
      break;
    case "Role":
      setSelectedRoleId(selectedOption);
      break;
    default:
      break;
  }
};

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    width: "100%",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: "1px solid #D1D9E2 !important",
    boxShadow: "4px 4px 64px 0px #5F6D7E1A",
    cursor: "pointer",
    overflow: "auto",
    "&:focus": {
      border: "0 !important",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    height: "auto",
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};

export const errorStyles = {
  control: (styles) => ({
    ...styles,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    height: "45px",
    width: "100%",
    fontWeight: "500",
    fontSize: "16px",
    fontFamily: "Inter",
    borderRadius: "8px",
    border: "2px solid #cb2d2d !important",
    boxShadow: "4px 4px 64px 0px #5F6D7E1A",
    cursor: "pointer",
    overflow: "auto",
    "&:focus": {
      border: "0 !important",
    },
  }),
  option: (styles) => {
    return {
      ...styles,
      color: "#5F6D7EC7",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Inter",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#F2F2F2",
      },
    };
  },
};
