export const cjelineRAR = [
  { value: 1, label: "1. - 3." },
  { value: 2, label: "4. - 6." },
  { value: 3, label: "7. , 8." },
];

export const cjelineOR = [
  { value: 1, label: "1., 2." },
  { value: 2, label: "3., 4." },
  { value: 3, label: "5., 6." },
];

export const programByModule = [
  {
    id: 1,
    moduleIds: [49, 50, 51, 52, 53, 54, 55, 56],
  },
  {
    id: 2,
    moduleIds: [57, 58, 59, 60, 61, 62, 63, 64],
  },
  {
    id: 3,
    moduleIds: [1, 2, 3, 4, 5, 6],
  },
  {
    id: 4,
    moduleIds: [13, 14, 15, 16, 17, 18],
  },
  {
    id: 5,
    moduleIds: [25, 26, 27, 28, 29, 30],
  },
  {
    id: 6,
    moduleIds: [37, 38, 39, 40, 41, 42],
  },
  {
    id: 7,
    moduleIds: [7, 8, 9, 10, 11, 12],
  },
  {
    id: 8,
    moduleIds: [19, 20, 21, 22, 23, 24],
  },
  {
    id: 9,
    moduleIds: [31, 32, 33, 34, 35, 36],
  },
  {
    id: 10,
    moduleIds: [43, 44, 45, 46, 47, 48],
  },
];

export const handlePolaznikSelect = (selectedValue, setSelectedPolaznik) => {
  setSelectedPolaznik(selectedValue);
};

export const handleCjelinaSelect = (selectedValue, setSelectedCjelina) => {
  setSelectedCjelina(selectedValue);
};
