import { getApplicationById } from "./components/enrollmentForm/api/getApplicationById";
import { getUsersApplications } from "./components/enrollmentForm/api/getUsersApplications";

export const programOptions = [
  { value: [3, 4, 5, 6, 7, 8, 9, 10], label: "OR/eOR" },
  { value: [13, 14], label: "TP" },
  { value: [11, 12], label: "TT" },
];

export const applicationProgramOptions = [
  { value: [3, 4, 5, 6], label: "OR" },
  { value: [7, 8, 9, 10], label: "eOR" },
  { value: [13, 14], label: "TP" },
  { value: [11, 12], label: "TT" },
];

export const fetchApplication = async (
  programApplicationId,
  authToken,
  setApplication,
  setVisibleForm,
  formMethodsOne,
  formMethodsTwo,
  formMethodsThree,
  formMethodsFour
) => {
  const response = await getApplicationById(programApplicationId, authToken);
  setApplication(response);
  const visible = [response.course];
  setVisibleForm(visible);
  const parsedApplication = JSON.parse(response.application);
  if (response.course === "OR") {
    Object.entries(parsedApplication).forEach(([key, value]) => {
      formMethodsOne.setValue(key, value);
    });
  } else if (response.course === "eOR") {
    Object.entries(parsedApplication).forEach(([key, value]) => {
      formMethodsTwo.setValue(key, value);
    });
  } else if (response.course === "TP") {
    Object.entries(parsedApplication).forEach(([key, value]) => {
      formMethodsThree.setValue(key, value);
    });
  } else {
    Object.entries(parsedApplication).forEach(([key, value]) => {
      formMethodsFour.setValue(key, value);
    });
  }
};

export const fetchUserApplications = async (
  setVisibleForm,
  userData,
  authToken,
  applicationsCreated,
  formMethodsOne,
  formMethodsTwo,
  formMethodsThree,
  formMethodsFour,
  setApplicationsCreated
) => {
  try {
    const response = await getUsersApplications(userData.id, authToken);
    setVisibleForm(response.enrollmentOptions);
    if (
      response.programApplications &&
      response.programApplications.length > 0
    ) {
      const newApplicationsCreated = { ...applicationsCreated };
      response.programApplications.forEach((programApplication) => {
        const parsedApplication = JSON.parse(programApplication.application);
        const programId = programApplication.programId;
        const program = applicationProgramOptions.find((option) =>
          option.value.includes(programId)
        );
        if (!program) {
          console.error("Program not found for ID:", programId);
          return;
        }
        let formMethods;
        switch (program.label) {
          case "OR":
            formMethods = formMethodsOne;
            break;
          case "eOR":
            formMethods = formMethodsTwo;
            break;
          case "TP":
            formMethods = formMethodsThree;
            break;
          case "TT":
            formMethods = formMethodsFour;
            break;
          default:
            console.error("Unknown program label:", program.label);
            return;
        }
        Object.entries(parsedApplication).forEach(([key, value]) => {
          formMethods.setValue(key, value);
          newApplicationsCreated[program.label] = true;
        });
      });
      setApplicationsCreated((prev) => {
        if (JSON.stringify(prev) === JSON.stringify(newApplicationsCreated)) {
          return prev;
        }
        return newApplicationsCreated;
      });
    } else {
      console.log("No applications found or application field is missing.");
    }
  } catch (error) {
    console.error("Error fetching form data:", error);
  }
};
