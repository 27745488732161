// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_checkboxContainer__VdEC7 {
  column-gap: 8px;
  cursor: pointer;
  display: -webkit-inline-box;
}
.Checkbox_checkbox__t7n64 {
  appearance: none;
  border-radius: 4px;
  border: 1px solid rgba(226, 226, 226, 1);
  cursor: pointer;
  height: 20px;
  transition: all 200ms ease-in-out;
  width: 20px;
}
.Checkbox_checkbox__t7n64:hover {
  box-shadow: 0px 0px 6px 0px rgba(149, 153, 158, 0.7);
}
.Checkbox_checkbox__t7n64:checked {
  appearance: none;
  background-color: #cb9c2d;
  border-radius: 4px;
  border: 0.5px solid #cb9c2d;
  height: 20px;
  transition: all 200ms ease-in-out;
  width: 20px;
}
.Checkbox_checkboxLabelText__jlO7i {
  color: #6e6e6e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 1.5px 0px 0px;
  width: 97%;
}
.Checkbox_iconContainer__R\\+OIz {
  position: absolute;
  margin: 5px 0px 0px 6px;
}
.Checkbox_icon__qzngr {
  stroke: #fff;
  height: 16px;
  width: 16px;
}

@media screen and (max-width: 800px) {
  .Checkbox_checkboxLabelText__jlO7i {
    max-width: 93%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,wCAAwC;EACxC,eAAe;EACf,YAAY;EACZ,iCAAiC;EACjC,WAAW;AACb;AACA;EACE,oDAAoD;AACtD;AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,2BAA2B;EAC3B,YAAY;EACZ,iCAAiC;EACjC,WAAW;AACb;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,qBAAqB;EACrB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".checkboxContainer {\n  column-gap: 8px;\n  cursor: pointer;\n  display: -webkit-inline-box;\n}\n.checkbox {\n  appearance: none;\n  border-radius: 4px;\n  border: 1px solid rgba(226, 226, 226, 1);\n  cursor: pointer;\n  height: 20px;\n  transition: all 200ms ease-in-out;\n  width: 20px;\n}\n.checkbox:hover {\n  box-shadow: 0px 0px 6px 0px rgba(149, 153, 158, 0.7);\n}\n.checkbox:checked {\n  appearance: none;\n  background-color: #cb9c2d;\n  border-radius: 4px;\n  border: 0.5px solid #cb9c2d;\n  height: 20px;\n  transition: all 200ms ease-in-out;\n  width: 20px;\n}\n.checkboxLabelText {\n  color: #6e6e6e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 1.5px 0px 0px;\n  width: 97%;\n}\n.iconContainer {\n  position: absolute;\n  margin: 5px 0px 0px 6px;\n}\n.icon {\n  stroke: #fff;\n  height: 16px;\n  width: 16px;\n}\n\n@media screen and (max-width: 800px) {\n  .checkboxLabelText {\n    max-width: 93%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxContainer": `Checkbox_checkboxContainer__VdEC7`,
	"checkbox": `Checkbox_checkbox__t7n64`,
	"checkboxLabelText": `Checkbox_checkboxLabelText__jlO7i`,
	"iconContainer": `Checkbox_iconContainer__R+OIz`,
	"icon": `Checkbox_icon__qzngr`
};
export default ___CSS_LOADER_EXPORT___;
