// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VikendIntenzivi_title__Wp7ts {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #4e4e4e;
}
.VikendIntenzivi_cardWrapper__G9A3q {
  width: 300px;
}

.VikendIntenzivi_emptyWrapper__BUzMY {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 300px;
  height: 368px;
  border: 1px solid #e0e0e0;
  box-shadow: 4px 4px 64px 0px #5f6d7e1a;
  border-radius: 8px;
}
.VikendIntenzivi_emptyIcon__HAFnS {
  width: 100px;
  height: 100px;
}
.VikendIntenzivi_emptySpan__m2jKf {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.001em;
  text-align: center;
  color: #5f6d7e;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/Prijavnice/PolaznikVoditelj/VikendIntenzivi/VikendIntenzivi.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,sCAAsC;EACtC,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".title {\n  font-family: Inter;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #4e4e4e;\n}\n.cardWrapper {\n  width: 300px;\n}\n\n.emptyWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  width: 300px;\n  height: 368px;\n  border: 1px solid #e0e0e0;\n  box-shadow: 4px 4px 64px 0px #5f6d7e1a;\n  border-radius: 8px;\n}\n.emptyIcon {\n  width: 100px;\n  height: 100px;\n}\n.emptySpan {\n  font-family: Inter;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: -0.001em;\n  text-align: center;\n  color: #5f6d7e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `VikendIntenzivi_title__Wp7ts`,
	"cardWrapper": `VikendIntenzivi_cardWrapper__G9A3q`,
	"emptyWrapper": `VikendIntenzivi_emptyWrapper__BUzMY`,
	"emptyIcon": `VikendIntenzivi_emptyIcon__HAFnS`,
	"emptySpan": `VikendIntenzivi_emptySpan__m2jKf`
};
export default ___CSS_LOADER_EXPORT___;
