// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define a breakpoint for responsiveness */
@media screen and (max-width: 768px) {
  .app {
    flex-direction: column; /* Stack content vertically */
  }

  .sidebar {
    width: 100%; /* Make the sidebar full width */
    height: auto; /* Allow it to expand with content */
    position: static; /* Remove fixed positioning */
  }
}
/* Rest of your existing CSS styles here */
html,
body,
#root,
.content {
  min-height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  overflow-x: hidden;
}

.app {
  display: flex;
  height: 100%;
  overflow-x: hidden;
}

/* Apply to the sidebar */
.sidebar {
  width: 250px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #333;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar on Webkit (Chrome, Safari) */
.sidebar::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA,2CAA2C;AAC3C;EACE;IACE,sBAAsB,EAAE,6BAA6B;EACvD;;EAEA;IACE,WAAW,EAAE,gCAAgC;IAC7C,YAAY,EAAE,oCAAoC;IAClD,gBAAgB,EAAE,6BAA6B;EACjD;AACF;AACA,0CAA0C;AAC1C;;;;EAIE,gBAAgB;EAChB,WAAW;EACX,0CAA0C;EAC1C,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;;AAEA,yBAAyB;AACzB;EACE,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA,8CAA8C;AAC9C;EACE,aAAa;AACf","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap\");\n\n/* Define a breakpoint for responsiveness */\n@media screen and (max-width: 768px) {\n  .app {\n    flex-direction: column; /* Stack content vertically */\n  }\n\n  .sidebar {\n    width: 100%; /* Make the sidebar full width */\n    height: auto; /* Allow it to expand with content */\n    position: static; /* Remove fixed positioning */\n  }\n}\n/* Rest of your existing CSS styles here */\nhtml,\nbody,\n#root,\n.content {\n  min-height: 100%;\n  width: 100%;\n  font-family: \"Source Sans Pro\", sans-serif;\n  margin: 0;\n  overflow-x: hidden;\n}\n\n.app {\n  display: flex;\n  height: 100%;\n  overflow-x: hidden;\n}\n\n/* Apply to the sidebar */\n.sidebar {\n  width: 250px;\n  position: relative;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  background-color: #333;\n  overflow-y: hidden;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n\n/* Hide scrollbar on Webkit (Chrome, Safari) */\n.sidebar::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
