// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/svg/GroupIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupCard_cardContainer__PA3bb {
  background: top right url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  border: 2px solid #d9dce0;
  border-radius: 10px;
  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-width: 259px;
  padding: 40px 24px 24px;
  transition: border 200ms ease-in-out;
}
.GroupCard_cardContainer__PA3bb:hover {
  border: 2px solid #5f6d7e;
  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);
  transition: border 200ms ease-in-out;
}
.GroupCard_cardIcon__f775V {
  stroke: #5f6d7e;
}
.GroupCard_cardDivider__dvZv9 {
  border-top: 2px solid #5f6d7e;
  margin: 16px 0px 32px;
  width: 40px;
}
.GroupCard_cardTextContainer__23ZwZ {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.GroupCard_cardText__0BpNI {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/groups/components/PolaznikGrupe/GroupCard.module.css"],"names":[],"mappings":"AAAA;EACE,uEAA2E;EAC3E,yBAAyB;EACzB,mBAAmB;EACnB,oDAAoD;EACpD,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,oCAAoC;AACtC;AACA;EACE,yBAAyB;EACzB,oDAAoD;EACpD,oCAAoC;AACtC;AACA;EACE,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,yBAAyB;AAC3B","sourcesContent":[".cardContainer {\n  background: top right url(\"../../../../assets/svg/GroupIcon.svg\") no-repeat;\n  border: 2px solid #d9dce0;\n  border-radius: 10px;\n  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  min-width: 259px;\n  padding: 40px 24px 24px;\n  transition: border 200ms ease-in-out;\n}\n.cardContainer:hover {\n  border: 2px solid #5f6d7e;\n  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);\n  transition: border 200ms ease-in-out;\n}\n.cardIcon {\n  stroke: #5f6d7e;\n}\n.cardDivider {\n  border-top: 2px solid #5f6d7e;\n  margin: 16px 0px 32px;\n  width: 40px;\n}\n.cardTextContainer {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n}\n.cardText {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `GroupCard_cardContainer__PA3bb`,
	"cardIcon": `GroupCard_cardIcon__f775V`,
	"cardDivider": `GroupCard_cardDivider__dvZv9`,
	"cardTextContainer": `GroupCard_cardTextContainer__23ZwZ`,
	"cardText": `GroupCard_cardText__0BpNI`
};
export default ___CSS_LOADER_EXPORT___;
