import React, { useEffect, useState } from "react";
import Exit from "../../../../../../assets/img/ExitIcon.png";
import Success from "../../../../../../assets/img/Success.png";
import QuillEditor from "../../../../../../components/QuillEditor/QuillEditor";

import Modal from "../../../../../../components/Modal/Modal";
import { getUserById } from "../../../../../groups/api/getUserById";
import { updateSupervisionComment } from "../../../../api/updateSupervisionComment";
import styles from "./KomentarModal.module.css";
function KomentarModal({
  onClose,
  evidentionUserID,
  authToken,
  supId,
  change,
  setChange,
  commentText,
  getSupervisionEntry,
  readOnly,
  isApplicationReferada,
  polaznik,
}) {
  const [user, setUser] = useState();
  const [comment, setComment] = useState(commentText || "");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });

  const handleInputChange = (e) => {
    setComment(e);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responses = await getUserById(authToken, evidentionUserID);
        setUser(responses);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (authToken) {
      fetchUser();
    }
  }, [authToken]);

  const handleSubmit = async () => {
    const response = await updateSupervisionComment(supId, comment, authToken);
    if (response.data.success === 1) {
      setModalContent({
        image: Success,
        description: "Tvoj komentar je uspješno dodan",
        buttons: [
          {
            label: "Uredu",
            action: async () => {
              handleSubmit();
              await getSupervisionEntry();
              onClose();
            },
            className: "modal-btn-success",
          },
        ],
      });
      setChange?.(!change);
      setShowModal(true);
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <img
        alt=""
        src={Exit}
        className={styles.exitBtn}
        onClick={() => onClose()}
      />
      <div
        className={styles.modalWrapper}
        style={{ overflowY: isApplicationReferada ? "none" : "scroll" }}
      >
        <div className={styles.infoModal}>
          <p className={styles.title}>
            {isApplicationReferada
              ? "Komentar mentora"
              : "Komentar na superviziju"}
          </p>
          <p className={styles.subtitle} style={{ fontSize: 20 }}>
            {isApplicationReferada
              ? `Polaznik: ${polaznik}`
              : `Polaznik: ${user?.firstName + " " + user?.lastName}`}
          </p>
          {!readOnly ? (
            <div className={styles.inputWrapper}>
              <div className={styles.inputColumn}>
                <span className={styles.label}>Komentar</span>
                <QuillEditor
                  setIsDone={() => {}}
                  isContainerShowed={true}
                  answer={commentText}
                  setUserAnswer={(s) => handleInputChange(s)}
                />
              </div>
            </div>
          ) : (
            <span
              dangerouslySetInnerHTML={{ __html: comment }}
              style={{ paddingBottom: isApplicationReferada && 36 }}
            ></span>
          )}
        </div>
        {!readOnly && (
          <div className={styles.btnRow}>
            <button onClick={() => onClose()} className={styles.cancelBtn}>
              Odustani
            </button>
            <button
              className={styles.successBtn}
              onClick={async () => await handleSubmit()}
            >
              Objavi komentar
            </button>
          </div>
        )}
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
          fullWidth
        />
      )}
    </div>
  );
}

export default KomentarModal;
