import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../assets/img/BackArrow.png";
import Export from "../../../assets/img/Export.png";
import Question from "../../../assets/img/Help.png";
import Image from "../../../assets/img/Intenzivi2.png";
import Success from "../../../assets/img/ModalCheckmark.png";
import LoaderModal from "../../../components/LoaderModal/LoaderModal";
import Modal from "../../../components/Modal/Modal";
import { useCreateEventState } from "../../../context/CreateEventContext";
import { useUserState } from "../../../context/UserContext";
import {
  TitleBold,
  VoditeljiContainer,
} from "../../FakultativnaRadionicaEditCreate/EditCreate/FakultativnaRadionicaEdit.styles";
import VoditeljiCard from "../../FakultativnaRadionicaEditCreate/VoditeljiCard/VoditeljiCard";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  DropdownButton,
  Opis,
  OpisWrapper,
} from "../../pojediniProgrami/ProgramContainer/ProgramContainer.styles";
import {
  handleEditButton,
  handleEditEModulButton,
  handleEditIntenzivButton,
  handleEditSupervizijaButton,
} from "../../programi/Referada/utils";
import { addUserToPrijavnica } from "../api/addUserToPrijavnica";
import { deleteUserFromPrijavnica } from "../api/deleteUserFromPrijavnica";
import { getCurrentUser } from "../api/getCurrentUser";
import { getUserProgram } from "../api/getUserProgram";
import { AddToEvidention, downloadCSV } from "../utils";
import EvidentionModal from "./components/AddToEvidentionModal/EvidentionModal";
import AGSModal from "./components/AGSModal/Modal";
import InfoContainer from "./components/InfoContainer/InfoContainer";
import TablePolaznik from "./components/TablicaPolaznik/TablePolaznik";
import styles from "./PrijavnicaContainer.module.css";

function mapIdsToArray(array) {
  return array.map((item) => item.id);
}

const typeMapping = {
  "fakultativne-radionice": "facultativeWorkshopId",
  "grupne-supervizije": "supervisionId",
  "ljetni-intenzivi": "intensiveId",
  "vikend-intenzivi": "intensiveId",
};

const defaultPrijavnicaName = "eModuleLiveId";

const getPrijavnicaName = (type) => typeMapping[type] || defaultPrijavnicaName;

function PrijavnicaContainer({
  location,
  voditelji,
  polaznici,
  prijavnicaType,
  prijavnicaData,
  extractedPolaznici,
  isLoading,
}) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProgram, setCurrentUserProgram] = useState(null);
  const [polazniciIds, setPolazniciIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEvidentionModal, setEvidentionModal] = useState(false);
  const [showFullOpis, setShowFullOpis] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    buttons: [],
  });
  const [polazniciInPr, setPolazniciInPr] = useState([]);
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const { resetCreateEventData } = useCreateEventState();

  useEffect(() => {
    return () => {
      if (!location?.pathname?.includes("/intenzivi-edit-create")) {
        resetCreateEventData();
      }
    };
  }, [location?.pathname]);
  const getCurrent = async () => {
    try {
      const response = await getCurrentUser(authToken);
      setCurrentUser(response?.id);
      const responseProgram = await getUserProgram(authToken);
      setCurrentUserProgram(responseProgram?.title);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (polaznici.length > 0) {
      const polazniciIds = mapIdsToArray(polaznici);
      setPolazniciInPr(polazniciIds);
    }
    if (authToken) getCurrent();
  }, [polaznici, authToken]);

  const prijavnicaName = getPrijavnicaName(prijavnicaType);

  const addUser = async (izlaganje) => {
    let reqBody;
    if (prijavnicaType === "grupne-supervizije") {
      reqBody = {
        [prijavnicaName]: prijavnicaData.id,
        isVoditelj: false,
        izlazemCase: izlaganje || false,
      };
    } else {
      reqBody = {
        [prijavnicaName]: prijavnicaData.id,
        isVoditelj: false,
      };
    }
    try {
      const response = await addUserToPrijavnica(
        prijavnicaType,
        reqBody,
        authToken
      );
    } catch (err) {
      console.log("Error adding user : ", err);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await deleteUserFromPrijavnica(
        prijavnicaType,
        currentUser,
        prijavnicaData.id,
        authToken
      );
    } catch (err) {
      console.log("Error adding user : ", err);
    }
  };

  const prijava = async (izlaganje) => {
    try {
      await addUser(izlaganje);
      setModalContent({
        image: Success,
        title: "Tvoja prijava je uspješna!",
        buttons: [
          {
            label: "U redu",
            action: () => window.location.reload(),
            className: "modal-btn-success",
          },
        ],
      });
      setShowRegisterModal(true);
    } catch (err) {
      console.log("Error adding user : ", err);
    }
  };
  useEffect(() => {
    const extractedIds = polaznici?.map((polaznik) => polaznik.id) ?? [];
    setPolazniciIds(extractedIds);
  }, [polaznici]);

  const handlePrijavaClick = async () => {
    try {
      if (isSupervision) {
        setShowModal(true);
      } else {
        prijava();
      }
    } catch (err) {
      console.log("Error trying to apply : ", err);
    }
  };

  const handleOdustaniClick = async () => {
    try {
      await deleteUser();
      setModalContent({
        image: Success,
        title: "Tvoja odjava je uspješna!",
        buttons: [
          {
            label: "U redu",
            action: () => window.location.reload(),
            className: "modal-btn-success",
          },
        ],
      });
      setShowRegisterModal(true);
    } catch (err) {
      console.log("Error trying to cancel : ", err);
    }
  };

  const toggleOpis = () => {
    setShowFullOpis(!showFullOpis);
  };
  const columnNames = ["Rb.", "Ime i prezime", "Aktivni program/Grupa"];
  if (userData.roleId === 6) {
    columnNames.push("Email");
  }
  const isRegistered = () => {
    if (polazniciInPr.includes(currentUser)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getCurrentPrograms = async () => {
      try {
        const responseProgram = await getUserProgram(authToken);
        setCurrentUserProgram(responseProgram?.[0]?.title);
      } catch (err) {
        console.log(err);
      }
    };

    if (authToken) getCurrentPrograms();
  }, [authToken]);

  const isSupervision =
    prijavnicaType === "grupne-supervizije" &&
    (currentUserProgram === "TP2" ||
      currentUserProgram === "TT5" ||
      currentUserProgram === "TT6")
      ? true
      : false;
  const dateTo = prijavnicaType.includes("intenzivi")
    ? prijavnicaData?.dateTo
      ? prijavnicaData?.dateTo
      : new Date()
    : prijavnicaData?.date
      ? prijavnicaData?.date
      : new Date();
  const dateToParsed = dateTo ? new Date(dateTo) : null;
  const today = new Date();
  const todayWithoutTime = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const dateToWithoutTime = new Date(
    dateToParsed.getFullYear(),
    dateToParsed.getMonth(),
    dateToParsed.getDate()
  );
  return (
    <>
      {" "}
      {isLoading && <LoaderModal />}
      <div className={styles.container}>
        <div className={styles.backRow} onClick={() => navigate(-1)}>
          <img alt="" src={Arrow} />
          <span className={styles.backSpan}>Natrag na popis aktivnosti</span>
        </div>
        <div className={styles.pojedinaImageContainer}>
          <img
            className={styles.pojedinaSekcijaImage}
            src={prijavnicaData.imageURL || Image}
            alt="prijavnica"
          />
        </div>
        <div className={styles.title}>
          {prijavnicaData.name}
          {userData.roleId === 6 &&
          (prijavnicaType === "fakultativne-radionice" ||
            prijavnicaType.includes("intenzivi")) ? (
            <div className={styles.btnRow}>
              <button
                className={
                  todayWithoutTime < dateToWithoutTime
                    ? styles.disabledBtn
                    : styles.prijavaBtn
                }
                onClick={() => setEvidentionModal(true)}
              >
                Dodaj u evidenciju
              </button>

              <button
                className={styles.editBtn}
                onClick={() => {
                  prijavnicaType === "fakultativne-radionice"
                    ? handleEditButton(prijavnicaData?.id, navigate, authToken)
                    : prijavnicaType.includes("intenzivi")
                      ? handleEditIntenzivButton(
                          prijavnicaData?.id,
                          prijavnicaData?.isWeekend,
                          navigate,
                          authToken
                        )
                      : prijavnicaType === "grupne-supervizije"
                        ? handleEditSupervizijaButton(
                            prijavnicaData?.id,
                            navigate,
                            authToken
                          )
                        : prijavnicaType === "moduli-uživo"
                          ? handleEditEModulButton(
                              prijavnicaData?.id,
                              navigate,
                              authToken
                            )
                          : console.log("Greška");
                }}
              >
                Uredi
              </button>
            </div>
          ) : userData.roleId !== 5 ? (
            <>
              {!isRegistered() ? (
                <button
                  className={
                    prijavnicaData.capacity <= polaznici.length
                      ? styles.disabledBtn
                      : styles.prijavaBtn
                  }
                  onClick={() => handlePrijavaClick()}
                  disabled={prijavnicaData.capacity <= polaznici.length}
                >
                  Prijavi se
                </button>
              ) : (
                <button
                  className={styles.odjavaBtn}
                  onClick={() => handleOdustaniClick()}
                >
                  Odustani
                </button>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        {isSupervision && (
          <div>
            <label className={styles.label}>
              <input
                type="checkbox"
                className={styles.checkboxInput}
                checked={prijavnicaData.izlazemCase}
              />
              <span className={styles.checkboxCustom} />
              Na superviziji izlažem svoj case
            </label>
          </div>
        )}
        <div className={styles.topRow}>
          {" "}
          <div className={styles.opisiWrapper}>
            <OpisWrapper style={{ width: "70%" }}>
              <Opis
                style={{
                  WebkitLineClamp: showFullOpis ? "unset" : 3,
                  whiteSpace: showFullOpis ? "normal" : "",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {prijavnicaData?.description
                  ?.split("\n")
                  ?.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </Opis>
              <DropdownButton onClick={toggleOpis}>
                {showFullOpis ? "Prikaži manje" : "Prikaži više"}
              </DropdownButton>
            </OpisWrapper>
            <div className={styles.infoContainer}>
              <InfoContainer
                datumStart={prijavnicaData.dateFrom}
                datumEnd={prijavnicaData.dateTo}
                datum={prijavnicaData.date}
                location={location}
                capacity={prijavnicaData.capacity}
                gprSati={prijavnicaData.gprHours}
                price={prijavnicaData.price}
                prijavnicaType={prijavnicaType}
                timeFrom={prijavnicaData.timeFrom}
                timeTo={prijavnicaData.timeTo}
                supervisionType={prijavnicaData.supervisionType}
                totalPeople={prijavnicaData.attendeesNumber}
              />
            </div>
          </div>
          <OpisWrapper
            style={{
              width: "70%",
              justifyContent: "flex-start",
              height: 300,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {prijavnicaData?.aditionalDescription !== "" && (
              <>
                <Opis
                  style={{
                    WebkitLineClamp: 3,
                    overflowY: "auto",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    height: 300,
                  }}
                >
                  {prijavnicaData.aditionalDescription
                    ?.split("\n")
                    ?.map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </Opis>
              </>
            )}
          </OpisWrapper>
        </div>
        <TitleBold>Voditelji {voditelji.length}</TitleBold>
        <VoditeljiContainer>
          {voditelji &&
            voditelji.length > 0 &&
            voditelji.map((item, index) => (
              <VoditeljiCard
                key={index}
                userId={"Polaznik"}
                voditeljAvatar={item.imageUrl}
                voditeljIme={item.firstName}
                voditeljPrezime={item.lastName}
                voditeljEmail={item.emailAddress}
                onClick={() => navigate(`/djelatnik/4/${item.id}`)}
              />
            ))}
        </VoditeljiContainer>
        <div className={styles.polazniciRow}>
          <TitleBold>Polaznici {polaznici.length}</TitleBold>
          {userData.roleId === 6 && extractedPolaznici?.length > 0 && (
            <button
              className={styles.exportBtn}
              onClick={() =>
                downloadCSV(
                  extractedPolaznici,
                  prijavnicaData.name ? prijavnicaData.name : ""
                )
              }
            >
              Download polaznici{" "}
              <img alt="" src={Export} style={{ rotate: "180deg" }} />
            </button>
          )}
        </div>
        {polaznici.length === 0 ? (
          <Opis>Trenutno nema polaznika.</Opis>
        ) : (
          <TablePolaznik
            columnNames={columnNames}
            data={polaznici}
            userData={userData}
          />
        )}
        {showModal && (
          <AGSModal onClose={() => setShowModal(false)} prijava={prijava} />
        )}
        {showRegisterModal && (
          <Modal
            image={modalContent.image}
            title={modalContent.title}
            buttons={modalContent.buttons}
          />
        )}
        {showEvidentionModal && (
          <EvidentionModal
            saveToEvidention={() =>
              AddToEvidention(
                prijavnicaData?.id,
                prijavnicaType,
                polazniciIds,
                authToken,
                setModalContent,
                Success,
                Question,
                setShowRegisterModal
              )
            }
            eventType={
              prijavnicaType.includes("intenzivi")
                ? "intenziv"
                : "fakultativnu radionicu"
            }
            eventName={prijavnicaData?.name}
            brojPolaznika={polazniciIds.length}
            onClose={() => setEvidentionModal(false)}
            handleEdit={() => {
              prijavnicaType === "fakultativne-radionice"
                ? handleEditButton(
                    prijavnicaData?.id,
                    navigate,
                    authToken,
                    resetCreateEventData,
                    true
                  )
                : prijavnicaType.includes("intenzivi")
                  ? handleEditIntenzivButton(
                      prijavnicaData?.id,
                      prijavnicaData?.isWeekend,
                      navigate,
                      authToken,
                      resetCreateEventData,
                      true
                    )
                  : console.log("Greška");
            }}
          />
        )}
      </div>
    </>
  );
}

export default PrijavnicaContainer;
