import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Down from "../../../../../assets/img/ArrowDown.png";
import Success from "../../../../../assets/img/Success.png";
import AddIcon from "../../../../../assets/svg/AddIcon.svg";
import DeleteIcon from "../../../../../assets/svg/TrashIcon.svg";
import Modal from "../../../../../components/Modal/Modal";
import { useUserState } from "../../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { getAllInteraktivna } from "../../../API";
import { getIconByType } from "../../../utils";
import { deleteResource } from "../../api/deleteResource";
import {
  ColumnWrapper,
  Content,
  ContentRow,
  DropdownArrow,
  SectionDropdown,
  SectionHeader,
  SectionTitle,
  SectionTitleRow,
} from "../../ProgramContainer.styles";
import { handleNavigateToSection } from "../../utils";
import { InputStyle, selectStyles, typeSelect } from "./utils";

function TeorijskiDio({
  userId,
  isEditMode,
  teorijskiDio,
  pathname,
  title,
  program_id,
  newResources,
  setNewResources,
  updatedResources,
  setUpdatedResources,
}) {
  const navigate = useNavigate();
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [teorijskiExpandedSections, setTeorijskiExpandedSections] = useState(
    []
  );
  // const [introspectionsExpandedSections, setIntrospectionsExpandedSections] =
  //   useState([]);
  const [interactiveLessons, setInteractiveLessons] = useState([]);
  const [introspectionsData, setIntrospectionsData] = useState([]);
  const [updateTeorijski, setUpdateTeorijski] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });

  const handleInputChange = (e, moduleId, resourceTempId, resourceId) => {
    const { name, value } = e.target;
    if (resourceId === undefined) {
      setNewResources((prevResources) =>
        prevResources.map((resource) =>
          resource.resourceTempId === resourceTempId
            ? { ...resource, [name]: value }
            : resource
        )
      );

      const moduleIndex = teorijskiDio.findIndex(
        (module) => module.id === moduleId
      );
      if (moduleIndex !== -1) {
        teorijskiDio[moduleIndex].resources = teorijskiDio[
          moduleIndex
        ].resources.map((resource) =>
          resource.resourceTempId === resourceTempId
            ? { ...resource, [name]: value }
            : resource
        );
        setUpdateTeorijski(!updateTeorijski);
      }
    } else {
      setUpdatedResources((prevResources) =>
        prevResources.map((resource) =>
          resource.id === resourceId ? { ...resource, [name]: value } : resource
        )
      );

      const moduleIndex = teorijskiDio.findIndex(
        (module) => module.id === moduleId
      );
      if (moduleIndex !== -1) {
        teorijskiDio[moduleIndex].resources = teorijskiDio[
          moduleIndex
        ].resources.map((resource) =>
          resource.id === resourceId ? { ...resource, [name]: value } : resource
        );
        setUpdateTeorijski(!updateTeorijski);

        const existingResource = updatedResources.find(
          (resource) => resource.id === resourceId
        );
        if (existingResource) {
          setUpdatedResources((prevResources) =>
            prevResources.map((resource) =>
              resource.id === resourceId
                ? { ...resource, [name]: value }
                : resource
            )
          );
        } else {
          const updatedResource = teorijskiDio[moduleIndex].resources.find(
            (resource) => resource.id === resourceId
          );
          setUpdatedResources((prevResources) => [
            ...prevResources,
            updatedResource,
          ]);
        }
      }
    }
  };

  const handleSelectChange = (
    selectedOption,
    moduleId,
    resourceTempId,
    resourceId
  ) => {
    if (resourceId === undefined) {
      setNewResources((prevResources) =>
        prevResources.map((resource) =>
          resource.resourceTempId === resourceTempId
            ? { ...resource, resourceType: selectedOption.value }
            : resource
        )
      );

      const moduleIndex = teorijskiDio.findIndex(
        (module) => module.id === moduleId
      );
      if (moduleIndex !== -1) {
        teorijskiDio[moduleIndex].resources = teorijskiDio[
          moduleIndex
        ].resources.map((resource) =>
          resource.resourceTempId === resourceTempId
            ? { ...resource, resourceType: selectedOption.value }
            : resource
        );
        setUpdateTeorijski(!updateTeorijski);
      }
    } else {
      setUpdatedResources((prevResources) =>
        prevResources.map((resource) =>
          resource.id === resourceId
            ? { ...resource, resourceType: selectedOption.value }
            : resource
        )
      );

      const moduleIndex = teorijskiDio.findIndex(
        (module) => module.id === moduleId
      );
      if (moduleIndex !== -1) {
        teorijskiDio[moduleIndex].resources = teorijskiDio[
          moduleIndex
        ].resources.map((resource) =>
          resource.id === resourceId
            ? { ...resource, resourceType: selectedOption.value }
            : resource
        );
        setUpdateTeorijski(!updateTeorijski);

        const existingResource = updatedResources.find(
          (resource) => resource.id === resourceId
        );
        if (existingResource) {
          setUpdatedResources((prevResources) =>
            prevResources.map((resource) =>
              resource.id === resourceId
                ? { ...resource, resourceType: selectedOption.value }
                : resource
            )
          );
        } else {
          const updatedResource = teorijskiDio[moduleIndex].resources.find(
            (resource) => resource.id === resourceId
          );
          setUpdatedResources((prevResources) => [
            ...prevResources,
            updatedResource,
          ]);
        }
      }
    }
  };

  useEffect(() => {
    const fetchInteractiveLessons = async () => {
      try {
        const response = await getAllInteraktivna(
          teorijskiDio[0]?.programId,
          authToken
        );
        setInteractiveLessons(response);
      } catch (error) {
        console.error("Error in fetch Interactive Lessons:", error);
      }
    };
    // const fetchIntrospectionsData = async () => {
    //   try {
    //     const response = await getIntrospectionData(userData.id, authToken);
    //     const updatedResponse = response.map((introspection) => ({
    //       ...introspection,
    //       programId:
    //         programByModule.find((module) =>
    //           module?.moduleIds?.includes(
    //             introspection?.introspection?.theoreticalModuleId
    //           )
    //         )?.id || null,
    //     }));
    //     setIntrospectionsData(updatedResponse);
    //   } catch (error) {
    //     console.error("Error in fetch introspections:", error);
    //   }
    // };
    if (authToken) {
      fetchInteractiveLessons();
      // fetchIntrospectionsData();
    }
  }, [authToken]);

  const interaktivna = {
    imageURL:
      "https://firebasestorage.googleapis.com/v0/b/ipd-international-dev.appspot.com/o/OR%2FOR2%2F2.1.%2FOsobniRazvoj_2_1-300x225.jpg?alt=media&token=2f53023e-b452-4db2-a219-556e1c854268",
    name: "Interaktivna predavanja",
    resources: interactiveLessons,
  };

  const toggleTeorijskiSection = (index) => {
    const newExpandedSections = [...teorijskiExpandedSections];
    newExpandedSections[index] = !newExpandedSections[index];
    setTeorijskiExpandedSections(newExpandedSections);
  };

  // const toggleIntrospekcija = (index) => {
  //   const newExpandedSections = [...introspectionsExpandedSections];
  //   newExpandedSections[index] = !newExpandedSections[index];
  //   setIntrospectionsExpandedSections(newExpandedSections);
  // };

  const updatedTeorijskiDio =
    interaktivna.resources.length > 0
      ? [...teorijskiDio, interaktivna]
      : teorijskiDio;

  const updatedIntrospections = introspectionsData
    .filter(
      (introspection) => introspection.programId === teorijskiDio[0].programId
    )
    .sort((a, b) => a.introspectionId - b.introspectionId);

  const addNewResource = (theoreticalModuleId) => {
    const newModule = {
      resourceTempId: newResources.length + 1,
      name: "Novi modul",
      resourceType: "video/mp4",
      theoreticalModuleId,
      url: "Ovdje upišite link",
    };
    teorijskiDio
      .find((module) => module.id === theoreticalModuleId)
      .resources.push(newModule);
    setNewResources([...newResources, newModule]);
  };

  const handleDeleteClick = (
    resourceId,
    resourceTempId,
    theoreticalModuleId
  ) => {
    setModalContent({
      image: Success,
      description: "Jeste li sigurni da želite ukloniti ovaj video/pdf?",
      buttons: [
        {
          label: "Odustani",
          action: () => setDeleteModalVisible(false),
          className: "modal-btn-success",
        },
        {
          label: "Ukloni",
          action: () =>
            handleDeleteResource(
              resourceId,
              resourceTempId,
              theoreticalModuleId
            ),
          className: "modal-btn-success",
        },
      ],
    });
    setDeleteModalVisible(true);
  };

  const handleDeleteResource = async (
    resourceId,
    resourceTempId,
    theoreticalModuleId
  ) => {
    if (resourceId === undefined) {
      setNewResources((prevResources) =>
        prevResources.filter((item) => item.resourceTempId !== resourceTempId)
      );
      const moduleIndex = teorijskiDio.findIndex(
        (module) => module.id === theoreticalModuleId
      );
      if (moduleIndex !== -1) {
        teorijskiDio[moduleIndex].resources = teorijskiDio[
          moduleIndex
        ].resources.filter((item) => item.resourceTempId !== resourceTempId);
      }
    } else {
      await deleteResource(resourceId, authToken);
      const moduleIndex = teorijskiDio.findIndex(
        (module) => module.id === theoreticalModuleId
      );
      if (moduleIndex !== -1) {
        teorijskiDio[moduleIndex].resources = teorijskiDio[
          moduleIndex
        ].resources.filter((item) => item.id !== resourceId);
      }
      setUpdateTeorijski(!updateTeorijski);
    }
    setDeleteModalVisible(false);
  };

  return (
    <ColumnWrapper>
      {deleteModalVisible && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
      <SectionHeader>
        {userId === "Referada" ? "Teorijski dio" : "Sadržaj programa"}
      </SectionHeader>
      {updatedTeorijskiDio
        .sort((a, b) => a.id - b.id)
        .map((module, index) => (
          <React.Fragment key={index}>
            <SectionDropdown>
              <SectionTitleRow
                onClick={() => {
                  if (!isEditMode) toggleTeorijskiSection(index);
                }}
              >
                <SectionTitle>
                  {
                    // ovaj false sam doda da je uvjet uvijek false da "naslov" nije nikad input, ako
                    // se bude nekad minjalo da se i za naslove/teme bude triba crud
                  }
                  {isEditMode && false ? (
                    <input
                      type="text"
                      style={InputStyle}
                      // value={module.name}
                      // name="name"
                      // onChange={(e) => handleInputChange(e, module.id, null)}
                    />
                  ) : (
                    ` ${module.name}`
                  )}
                </SectionTitle>
                <DropdownArrow
                  alt=""
                  src={Down}
                  rotated={teorijskiExpandedSections[index]}
                  onClick={() => {
                    if (isEditMode) toggleTeorijskiSection(index);
                  }}
                />
              </SectionTitleRow>
              {teorijskiExpandedSections[index] && module.resources && (
                <div>
                  {module.resources
                    .filter((resource) => resource.name !== "false")
                    .sort((a, b) => a.id - b.id)
                    .map((item, resourceIndex) => (
                      <ContentRow
                        key={resourceIndex}
                        disabled={
                          userId === "Referada" && item.resourceType === "text"
                        }
                        isEditMode={isEditMode}
                      >
                        {!isEditMode && getIconByType(item.resourceType)}
                        <Content
                          isEditMode={isEditMode}
                          onClick={
                            !(
                              userId === "Referada" &&
                              item.resourceType === "text"
                            ) &&
                            (() => {
                              if (!isEditMode) {
                                handleNavigateToSection(
                                  navigate,
                                  item,
                                  updatedTeorijskiDio,
                                  pathname,
                                  title,
                                  program_id
                                );
                              }
                            })
                          }
                          disabled={
                            userId === "Referada" &&
                            item.resourceType === "text"
                          }
                        >
                          {isEditMode ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 7,
                              }}
                            >
                              <input
                                type="text"
                                style={InputStyle}
                                name="name"
                                value={item.name}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    module.id,
                                    item.resourceTempId,
                                    item.id
                                  )
                                }
                              />
                              <input
                                type="text"
                                style={InputStyle}
                                name="url"
                                value={item.url}
                                onChange={(e) =>
                                  handleInputChange(
                                    e,
                                    module.id,
                                    item.resourceTempId,
                                    item.id
                                  )
                                }
                              />
                              <Select
                                defaultValue={{
                                  value: item.resourceType,
                                  label:
                                    item.resourceType === "application/pdf"
                                      ? "PDF"
                                      : item.resourceType === "audio/mp3"
                                        ? "Audio"
                                        : "Video",
                                }}
                                options={typeSelect}
                                onChange={(selectedOption) =>
                                  handleSelectChange(
                                    selectedOption,
                                    module.id,
                                    item.resourceTempId,
                                    item.id
                                  )
                                }
                                styles={selectStyles}
                                isSearchable={false}
                                components={{ IndicatorSeparator: () => null }}
                              />
                            </div>
                          ) : (
                            ` ${item.name}`
                          )}
                        </Content>
                        {isEditMode && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: 40,
                              height: 40,
                              backgroundColor: "#fff",
                              boxShadow: "4px 4px 64px 0px #5F6D7E1A",
                              border: "1px solid #D1D9E2",
                              borderRadius: 6,
                            }}
                            onClick={() =>
                              handleDeleteClick(
                                item.id,
                                item.resourceTempId,
                                module.id
                              )
                            }
                          >
                            <img src={DeleteIcon} alt="" />
                          </div>
                        )}
                      </ContentRow>
                    ))}

                  {isEditMode && (
                    <div
                      style={{
                        width: 100,
                        height: 75,
                        backgroundColor: "#fff",
                        boxShadow: "4px 4px 64px 0px #5F6D7E1A",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: 5,
                        border: "1px solid #D1D9E2",
                        margin: "15px 0 20px 35px",
                      }}
                      onClick={() => addNewResource(module.id)}
                    >
                      <img src={AddIcon} alt="" height={30} width={30} />
                    </div>
                  )}
                </div>
              )}
            </SectionDropdown>
            {/* {updatedIntrospections[index] && (
              <SectionDropdown>
                <SectionTitleRow onClick={() => toggleIntrospekcija(index)}>
                  <SectionTitle>{`INTROSPEKCIJA ${index + 1} `}</SectionTitle>
                  <DropdownArrow
                    alt=""
                    src={Down}
                    rotated={introspectionsExpandedSections[index]}
                  />
                </SectionTitleRow>
                <div>
                  {introspectionsExpandedSections[index] && (
                    <ContentRow disabled={userId === "Referada"}>
                      {getIconByType("introspection")}
                      <Content
                        onClick={() =>
                          navigate(
                            `/introspekcije-polaznik/${
                              program_id == 1
                                ? index + 1
                                : program_id == 3
                                  ? index + 9
                                  : program_id == 4
                                    ? index + 15
                                    : program_id == 5
                                      ? index + 21
                                      : program_id == 6
                                        ? index + 27
                                        : program_id == 2
                                          ? index + 33
                                          : program_id == 7
                                            ? index + 41
                                            : program_id == 8
                                              ? index + 47
                                              : program_id == 9
                                                ? index + 53
                                                : program_id == 10
                                                  ? index + 59
                                                  : null
                            }`,
                            {
                              state: {
                                title: title,
                                programId: program_id,
                                previousRoute: pathname,
                              },
                            }
                          )
                        }
                        disabled={userId === "Referada"}
                      >{` ${index + 1}. Introspekcija`}</Content>
                    </ContentRow>
                  )}
                </div>
              </SectionDropdown>
            )} */}
          </React.Fragment>
        ))}
    </ColumnWrapper>
  );
}

export default TeorijskiDio;
