import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRooms } from "../FakultativnaRadionicaEditCreate/api/getRooms";
import { getAllCities } from "../groups/api/getAllCities";
import { useAuthentication } from "../hooks/useAuthentication";
import {
  getFacultativeById,
  getLjetniById,
  getModuliById,
  getSupervisionById,
  getWeekendById,
} from "./api/getPrijavnicaById";
import PrijavnicaContainer from "./PrijavnicaContainer/PrijavnicaContainer";

function PojedinaPrijavnicaPage() {
  const { someParam, anotherParam, ...restParams } = useParams();
  const [prijavnicaData, setPrijavnicaData] = useState([]);
  const [cities, setCities] = useState([]);
  const { authToken } = useAuthentication();
  const [extractedPolaznici, setExtractedPolaznici] = useState([]);

  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const paramKeys = Object.keys(restParams);

  const lastTwoKeys = paramKeys.slice(-2);

  const prijavnicaType = restParams[lastTwoKeys[0]];
  const prijavnicaId = restParams[lastTwoKeys[1]];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (prijavnicaType === "fakultativne-radionice") {
          response = await getFacultativeById(
            prijavnicaId,
            authToken,
            setIsLoading
          );
        } else if (prijavnicaType === "grupne-supervizije") {
          response = await getSupervisionById(prijavnicaId, authToken);
        } else if (prijavnicaType === "ljetni-intenzivi") {
          response = await getLjetniById(prijavnicaId, authToken, setIsLoading);
        } else if (prijavnicaType === "vikend-intenzivi") {
          response = await getWeekendById(
            prijavnicaId,
            authToken,
            setIsLoading
          );
        } else if (prijavnicaType === "moduli-uživo") {
          response = await getModuliById(prijavnicaId, authToken);
        } else {
          console.log("Invalid prijavnicaType:", prijavnicaType);
          return;
        }
        setPrijavnicaData(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await getAllCities(authToken);
        console.log(response);
        setCities(response);
      } catch (err) {
        console.log("Error fetching cities : ", err);
      }
    };

    const fetchRooms = async () => {
      try {
        const response = await getRooms(authToken);
        const updatedRooms = [
          { value: 99, label: "Bez sobe", isLarge: false },
          ...(response.rooms?.map((room) => ({
            value: room.id,
            cityId: room.cityId,
            label: room.roomName,
            isLarge: room.isLarge,
          })) || []),
        ];

        setRooms(updatedRooms);
      } catch (error) {
        console.log(error);
      }
    };

    if (authToken) {
      fetchRooms();
      fetchData();
      fetchCities();
    }
  }, [authToken, prijavnicaId, prijavnicaType]);

  const userId = "Polaznik";
  const voditeljiData = prijavnicaData.usersResponse;
  const voditelji = voditeljiData ? voditeljiData.voditeljResponse || [] : [];
  const polaznici = React.useMemo(() => {
    return voditeljiData ? voditeljiData.newPolazniciResponse || [] : [];
  }, [voditeljiData]);

  const cityId = rooms?.find((i) => i.value === prijavnicaData.roomId)?.cityId;
  const city = cityId
    ? cities.find((cit) => cit.value === cityId)?.label || ""
    : "Bez sobe";
  useEffect(() => {
    setExtractedPolaznici(
      polaznici.map((item) => ({
        firstName: item.firstName,
        lastName: item.lastName,
        emailAddress: item.emailAddress,
      }))
    );
  }, [polaznici]);
  return (
    <div>
      {prijavnicaData && (
        <PrijavnicaContainer
          userId={userId}
          extractedPolaznici={extractedPolaznici}
          prijavnicaData={prijavnicaData}
          location={city}
          voditelji={voditelji}
          polaznici={polaznici}
          prijavnicaType={prijavnicaType}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default PojedinaPrijavnicaPage;
