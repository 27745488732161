import Quill from "quill";
import "quill/dist/quill.snow.css";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Success from "../../assets/img/Success.png";
import ErrorModal from "../../assets/svg/ErrorModal.svg";
import Modal from "../../components/Modal/Modal";
import { useUserState } from "../../context/UserContext";
import { getAllCities } from "../groups/api/getAllCities";
import { getAllProgramsReferada } from "../groups/api/getAllProgramsReferada";
import { useAuthentication } from "../hooks/useAuthentication";
import {
  GprData,
  PrimateljData,
  RoleData,
} from "./__mocks__/SlanjeObavijestiData";
import { getActiveFacultatives } from "./api/getActiveFacultatives";
import { getActiveIntensives } from "./api/getActiveIntensives";
import { getAllGroups } from "./api/getAllGroups";
import { sendNotifications } from "./api/sendNotifications";
import { CheckboxOption } from "./components/CheckboxOption";
import { CustomValueContainer } from "./components/ValueContainer";
import styles from "./SlanjeObavijesti.module.css";
import { errorStyles, handleSelectChange, selectStyles } from "./utils";

function SlanjeObavijesti() {
  const { authToken } = useAuthentication();
  const { userData, setUserData } = useUserState();
  const [allGroups, setAllGroups] = useState([]);
  const [activeFW, setActiveFW] = useState([]);
  const [activeIntensives, setActiveIntensives] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [cities, setCities] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedPrimatelj, setSelectedPrimatelj] = useState({});
  const [selectedMjesto, setSelectedMjesto] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [selectedGpr, setSelectedGpr] = useState({});
  const [selectedGrupa, setSelectedGrupa] = useState({});
  const [selectedGprData, setSelectedGprData] = useState({});
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [isNoPrimateljiOpen, setIsNoPrimateljiOpen] = useState(false);
  const [isMissingParameter, setIsMissingParameter] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await getAllGroups(authToken);
      const adjustedResponse = response.map((group) => ({
        value: group.id,
        label: group.name,
        ...group,
      }));
      setAllGroups(adjustedResponse);
    };
    if (authToken) fetchGroups();
  }, [authToken]);

  useEffect(() => {
    const fetchCities = async () => {
      const response = await getAllCities(authToken);
      setCities(response);
    };
    if (authToken) fetchCities();
  }, [authToken]);

  useEffect(() => {
    const fetchPrograms = async () => {
      const response = await getAllProgramsReferada(authToken);
      setPrograms(response.sort((a, b) => a.value - b.value));
    };
    if (authToken) fetchPrograms();
  }, [authToken]);

  useEffect(() => {
    const fetchFW = async () => {
      const response = await getActiveFacultatives(authToken);
      const adjustedResponse = response.map((fw) => ({
        value: fw.id,
        label: fw.name,
        ...fw,
      }));
      setActiveFW(adjustedResponse);
    };
    if (authToken) fetchFW();
  }, [authToken]);

  useEffect(() => {
    const fetchIntensives = async () => {
      try {
        const [responseWeekend, responseSummer] = await Promise.all([
          getActiveIntensives(true, authToken),
          getActiveIntensives(false, authToken),
        ]);

        const combinedResponse = [...responseWeekend, ...responseSummer];

        const adjustedResponse = combinedResponse.map((intensive) => ({
          value: intensive.id,
          label: intensive.name,
          ...intensive,
        }));

        setActiveIntensives(adjustedResponse);
      } catch (error) {
        console.error("Failed to fetch intensives: ", error);
      }
    };

    if (authToken) fetchIntensives();
  }, [authToken]);

  const navigate = useNavigate();

  const quillRef = useCallback((node) => {
    if (node) {
      const quill = new Quill(node, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ font: ["serif", "monospace"] }],
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["link", "image"],
          ],
        },
      });

      quill.on("text-change", () => {
        setMessage(quill.root.innerHTML);
      });
    }
  }, []);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let cityIds = selectedMjesto?.map((city) => city.value);
    let programIds = selectedProgram?.map((program) => program.value);
    let roleIds = selectedRoleId?.map((role) => role.value);

    const notificationBody = {
      primatelj: selectedPrimatelj?.label,
      cityId: cityIds,
      program: programIds,
      group: selectedGrupa?.value,
      gpr: selectedGpr?.label,
      gprId: selectedGprData?.value,
      roleId: roleIds,
      subject: subject,
      description: message,
      userId: userData.id,
    };

    const response = await sendNotifications(notificationBody, authToken);
    if (response.status === 200) {
      setIsSuccessOpen(true);
      if (response.data.receiverIds.includes(userData.id)) {
        setUserData({
          ...userData,
          notificationsCount: userData.notificationsCount + 1,
        });
      }
      //pozvat endpoint koji će poslat mailove
    } else if (response.response.status === 400) {
      setIsNoPrimateljiOpen(true);
    } else {
      setIsMissingParameter(true);
      setIsErrorOpen(true);
    }
  };

  useEffect(() => {
    let filtered = allGroups;

    if (selectedMjesto.length > 0) {
      const selectedMjestoIds = selectedMjesto.map((mjesto) => mjesto.value);
      filtered = filtered.filter((group) =>
        selectedMjestoIds.includes(group.cityId)
      );
    }

    if (selectedProgram.length > 0) {
      const selectedProgramIds = selectedProgram.map(
        (program) => program.value
      );
      filtered = filtered.filter((group) =>
        selectedProgramIds.includes(group.programId)
      );
    }

    setFilteredGroups(filtered);
  }, [selectedMjesto, selectedProgram, allGroups]);

  return (
    <>
      {isSuccessOpen && (
        <Modal
          image={Success}
          description="Obavijest uspješno poslana!"
          buttons={[
            {
              label: "Zatvori",
              action: () => {
                navigate("/dashboard");
              },
              className: "modal-btn-success ",
            },
          ]}
        />
      )}
      {isNoPrimateljiOpen && (
        <Modal
          image={Success}
          description="Trenutno ne postoje korisnici koji spadaju u vaše fitere."
          buttons={[
            {
              label: "Pokušaj ponovo",
              action: () => {
                setIsNoPrimateljiOpen(false);
              },
              className: "modal-btn-success ",
            },
          ]}
        />
      )}
      {isErrorOpen && (
        <Modal
          image={ErrorModal}
          description="Ispunite sva polja i pokušajte ponovo..."
          buttons={[
            {
              label: "U redu",
              action: () => {
                setIsErrorOpen(false);
              },
              className: "modal-btn-success ",
            },
          ]}
        />
      )}
      <div className={styles.wrapper} style={{ padding: 5 }}>
        <form>
          <div className={styles.firstRow}>
            <div className={styles.item}>
              <label className={styles.label}>Primatelj(i)</label>
              <Select
                options={PrimateljData}
                onChange={(option) =>
                  handleSelectChange(
                    option,
                    "Primatelj",
                    setSelectedPrimatelj,
                    setSelectedMjesto,
                    setSelectedProgram,
                    setSelectedGpr,
                    setSelectedGrupa,
                    setSelectedGprData,
                    setSelectedRoleId
                  )
                }
                styles={
                  isMissingParameter &&
                  Object.entries(selectedPrimatelj).length === 0
                    ? errorStyles
                    : selectStyles
                }
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                placeholder={"Odaberi"}
              />
              {isMissingParameter &&
                Object.entries(selectedPrimatelj).length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
            </div>
            {selectedPrimatelj.value !== 4 && (
              <div className={styles.item}>
                <label className={styles.label}>Mjesto upisa</label>
                <Select
                  options={cities}
                  onChange={(option) =>
                    handleSelectChange(
                      option,
                      "Mjesto",
                      setSelectedPrimatelj,
                      setSelectedMjesto,
                      setSelectedProgram,
                      setSelectedGpr,
                      setSelectedGrupa,
                      setSelectedGprData,
                      setSelectedRoleId
                    )
                  }
                  styles={
                    isMissingParameter && selectedMjesto.length === 0
                      ? errorStyles
                      : selectStyles
                  }
                  isSearchable={false}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder={"Odaberi"}
                  isDisabled={selectedPrimatelj.value === 7}
                  isMulti
                />
                {isMissingParameter && selectedMjesto.length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
              </div>
            )}
            {selectedPrimatelj.value !== 6 && selectedPrimatelj.value !== 4 && (
              <div className={styles.item}>
                <label className={styles.label}>
                  Godina školovanja/program
                </label>
                <Select
                  options={programs}
                  onChange={(option) =>
                    handleSelectChange(
                      option,
                      "Program",
                      setSelectedPrimatelj,
                      setSelectedMjesto,
                      setSelectedProgram,
                      setSelectedGpr,
                      setSelectedGrupa,
                      setSelectedGprData,
                      setSelectedRoleId
                    )
                  }
                  value={selectedProgram}
                  menuIsOpen={menuIsOpen}
                  onMenuOpen={() => setMenuIsOpen(true)}
                  onMenuClose={() => setMenuIsOpen(false)}
                  styles={
                    isMissingParameter && selectedProgram.length === 0
                      ? errorStyles
                      : selectStyles
                  }
                  isSearchable={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: (props) => <CheckboxOption {...props} />,
                    ValueContainer: (props) => (
                      <CustomValueContainer
                        {...props}
                        setMenuIsOpen={setMenuIsOpen}
                      />
                    ),
                  }}
                  placeholder={"Odaberi"}
                  isDisabled={setSelectedPrimatelj?.value === 7}
                  isMulti
                />
                {isMissingParameter && selectedProgram.length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
              </div>
            )}
            {selectedPrimatelj.value === 4 && (
              <div className={styles.item}>
                <label className={styles.label}>Rola djelatnika</label>
                <Select
                  options={RoleData}
                  onChange={(option) =>
                    handleSelectChange(
                      option,
                      "Role",
                      setSelectedPrimatelj,
                      setSelectedMjesto,
                      setSelectedProgram,
                      setSelectedGpr,
                      setSelectedGrupa,
                      setSelectedGprData,
                      setSelectedRoleId
                    )
                  }
                  styles={
                    isMissingParameter && selectedRoleId.length === 0
                      ? errorStyles
                      : selectStyles
                  }
                  isSearchable={false}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder={"Odaberi"}
                  isDisabled={selectedPrimatelj.value === 5}
                  isMulti
                />
                {isMissingParameter && selectedRoleId.length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
              </div>
            )}
          </div>
          <div
            className={
              selectedPrimatelj.label === "GPR" ? styles.newRow : styles.hidden
            }
          >
            <div className={styles.item}>
              <label htmlFor="gpr" className={styles.label}>
                Gpr
              </label>
              <Select
                options={GprData}
                onChange={(option) =>
                  handleSelectChange(
                    option,
                    "Gpr",
                    setSelectedPrimatelj,
                    setSelectedMjesto,
                    setSelectedProgram,
                    setSelectedGpr,
                    setSelectedGrupa,
                    setSelectedGprData,
                    setSelectedRoleId
                  )
                }
                styles={
                  isMissingParameter && Object.entries(selectedGpr).length === 0
                    ? errorStyles
                    : selectStyles
                }
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                placeholder={"Odaberi"}
              />
              {isMissingParameter &&
                Object.entries(selectedGpr).length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
            </div>
            <div className={styles.item}>
              <label htmlFor="gprNaziv" className={styles.label}>
                Naziv
              </label>
              <Select
                options={selectedGpr.value === 1 ? activeFW : activeIntensives}
                onChange={(option) =>
                  handleSelectChange(
                    option,
                    "GprNaziv",
                    setSelectedPrimatelj,
                    setSelectedMjesto,
                    setSelectedProgram,
                    setSelectedGpr,
                    setSelectedGrupa,
                    setSelectedGprData,
                    setSelectedRoleId
                  )
                }
                styles={
                  isMissingParameter &&
                  Object.entries(selectedGprData).length === 0
                    ? errorStyles
                    : selectStyles
                }
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                placeholder={"Odaberi"}
              />
              {isMissingParameter &&
                Object.entries(selectedGprData).length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
            </div>
          </div>
          <div
            className={
              selectedPrimatelj.label === "Grupa"
                ? styles.newRow
                : styles.hidden
            }
          >
            <div className={styles.item}>
              <label htmlFor="grupa" className={styles.label}>
                Grupa
              </label>
              <Select
                options={filteredGroups}
                onChange={(option) =>
                  handleSelectChange(
                    option,
                    "Grupa",
                    setSelectedPrimatelj,
                    setSelectedMjesto,
                    setSelectedProgram,
                    setSelectedGpr,
                    setSelectedGrupa,
                    setSelectedGprData,
                    setSelectedRoleId
                  )
                }
                styles={
                  isMissingParameter &&
                  Object.entries(selectedGrupa).length === 0
                    ? errorStyles
                    : selectStyles
                }
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                placeholder={"Odaberi"}
              />
              {isMissingParameter &&
                Object.entries(selectedGrupa).length === 0 && (
                  <span className={styles.errorText}>
                    Za slanje obavijesti je potrebno ispuniti ovo polje
                  </span>
                )}
            </div>
          </div>
          <div className={styles.secondRow}>
            <div className={styles.predmet}>
              <label htmlFor="primatelj" className={styles.label}>
                Predmet
              </label>
              <input
                type="text"
                placeholder="Upiši"
                className={
                  isMissingParameter && subject.length === 0
                    ? styles.errorPredmetInput
                    : styles.predmetInput
                }
                onChange={(e) => handleSubjectChange(e)}
              />
              {isMissingParameter && subject.length === 0 && (
                <span className={styles.errorText}>
                  Za slanje obavijesti je potrebno ispuniti ovo polje
                </span>
              )}
            </div>
          </div>
          <div
            className={styles.lastRow}
            style={
              isMissingParameter && message.length === 0
                ? { border: "2px solid #cb2d2d", borderRadius: 8 }
                : {}
            }
          >
            <div
              ref={quillRef}
              style={{
                height: "400px",
                width: "100%",
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
              className={styles.quill}
            ></div>
          </div>
          {isMissingParameter && message.length === 0 && (
            <span className={styles.errorText}>
              Za slanje obavijesti je potrebno ispuniti ovo polje
            </span>
          )}
          <div className={styles.btns}>
            <button
              className={styles.btn1}
              onClick={() => navigate("/dashboard")}
            >
              Odustani
            </button>
            <button
              className={styles.btn2}
              onClick={(e) => handleFormSubmit(e)}
            >
              Pošalji obavijest
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SlanjeObavijesti;
