// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileHeader_wrapper__3LPI7 {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
}

.MobileHeader_firstCol__diCY\\+,
.MobileHeader_secondCol__xdDfD {
  display: flex;
  align-items: center;
}

.MobileHeader_secondCol__xdDfD {
  gap: 30px;
}

.MobileHeader_imgs__GbNaC {
  height: 30px;
  padding-right: 15px;
}
.MobileHeader_imgs2__qobL5 {
  height: 28px;
}

.MobileHeader_imgs__GbNaC:hover,
.MobileHeader_imgs2__qobL5:hover {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .MobileHeader_wrapper__3LPI7 {
    padding: 10px 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MobileHeader/MobileHeader.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;;EAEE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".wrapper {\n  min-height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 30px;\n}\n\n.firstCol,\n.secondCol {\n  display: flex;\n  align-items: center;\n}\n\n.secondCol {\n  gap: 30px;\n}\n\n.imgs {\n  height: 30px;\n  padding-right: 15px;\n}\n.imgs2 {\n  height: 28px;\n}\n\n.imgs:hover,\n.imgs2:hover {\n  cursor: pointer;\n}\n\n@media screen and (max-width: 600px) {\n  .wrapper {\n    padding: 10px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MobileHeader_wrapper__3LPI7`,
	"firstCol": `MobileHeader_firstCol__diCY+`,
	"secondCol": `MobileHeader_secondCol__xdDfD`,
	"imgs": `MobileHeader_imgs__GbNaC`,
	"imgs2": `MobileHeader_imgs2__qobL5`
};
export default ___CSS_LOADER_EXPORT___;
