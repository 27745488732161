// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackComponent_backButton__5GIJj {
  column-gap: 12px;
  display: flex;
  align-items: center;
  height: 32px;
  width: fit-content;
  margin-top: -20px;
  margin-bottom: 20px;
}
.BackComponent_backButtonArrow__dpjyu {
  cursor: pointer;
  fill: #7e818c;
  transition: fill 200ms ease-in-out;
  height: 25px;
  width: 25px;
}
.BackComponent_backButton__5GIJj:hover .BackComponent_backButtonArrow__dpjyu {
  fill: #cb9c2d;
  transition: fill 200ms ease-in-out;
}
.BackComponent_backButtonText__DE720 {
  color: #7e818c;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
  transition: color 200ms ease-in-out;
}
.BackComponent_backButton__5GIJj:hover .BackComponent_backButtonText__DE720 {
  color: #cb9c2d;
  cursor: pointer;
  transition: color 200ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/BackComponent/BackComponent.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,aAAa;EACb,kCAAkC;EAClC,YAAY;EACZ,WAAW;AACb;AACA;EACE,aAAa;EACb,kCAAkC;AACpC;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,mCAAmC;AACrC;AACA;EACE,cAAc;EACd,eAAe;EACf,mCAAmC;AACrC","sourcesContent":[".backButton {\n  column-gap: 12px;\n  display: flex;\n  align-items: center;\n  height: 32px;\n  width: fit-content;\n  margin-top: -20px;\n  margin-bottom: 20px;\n}\n.backButtonArrow {\n  cursor: pointer;\n  fill: #7e818c;\n  transition: fill 200ms ease-in-out;\n  height: 25px;\n  width: 25px;\n}\n.backButton:hover .backButtonArrow {\n  fill: #cb9c2d;\n  transition: fill 200ms ease-in-out;\n}\n.backButtonText {\n  color: #7e818c;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n  transition: color 200ms ease-in-out;\n}\n.backButton:hover .backButtonText {\n  color: #cb9c2d;\n  cursor: pointer;\n  transition: color 200ms ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": `BackComponent_backButton__5GIJj`,
	"backButtonArrow": `BackComponent_backButtonArrow__dpjyu`,
	"backButtonText": `BackComponent_backButtonText__DE720`
};
export default ___CSS_LOADER_EXPORT___;
