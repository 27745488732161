import React, { useEffect, useRef, useState } from "react";
import Error from "../../../../assets/img/Help.png";
import Success from "../../../../assets/img/Success.png";
import Open from "../../../../assets/svg/Open.svg";
import LightBox from "../../../../components/Lightbox/LightBox";
import Input from "./Input";
import styles from "./Upload.module.css";
import { handleSingleSave } from "./utils";

function Upload({
  isMultiple,
  image,
  isAcademic,
  editMode,
  setSelectedDegreeName,
  selectedDegreeName,
  setSelectedRecordName,
  selectedRecordName,
  setSelectedAcademicDegree,
  selectedAcademicDegree,
  setSelectedCriminalRecord,
  selectedCriminalRecord,
  selectedValue,
  myProfileData,
  setEmptyFields,
  userData,
  authToken,
  setModalContent,
  setShowModal,
  setUserData,
  setLoading,
  numericPhoneNumber,
  uploadedFile,
  apiUrl,
  navigate,
  changeIsFirstLogin,
  setEditMode,
  setMyProfileData,
  isFirstLogin,
  handleReset,
  userId,
  tabName,
}) {
  const fileInputRef = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const [imageToDisplay, setImageToDisplay] = useState("");
  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (tabName === "academicDegree") {
      if (selectedAcademicDegree) {
        if (selectedAcademicDegree instanceof File) {
          setImageToDisplay(URL.createObjectURL(selectedAcademicDegree));
        } else {
          setImageToDisplay(selectedAcademicDegree);
        }
        if (!editMode && userData?.academicDegree) {
          setImageToDisplay(userData?.academicDegree);
        }
      } else if (userData?.academicDegree) {
        setImageToDisplay(userData.academicDegree);
      } else if (!userData?.academicDegree && !selectedAcademicDegree) {
        setImageToDisplay("");
      }
    } else {
      if (selectedCriminalRecord) {
        if (selectedCriminalRecord instanceof File) {
          setImageToDisplay(URL.createObjectURL(selectedCriminalRecord));
        } else {
          setImageToDisplay(selectedCriminalRecord);
        }
        if (!editMode && userData?.criminalRecord) {
          setImageToDisplay(userData?.criminalRecord);
        }
      } else if (userData?.criminalRecord) {
        setImageToDisplay(userData.criminalRecord);
      } else if (!userData?.criminalRecord && !selectedCriminalRecord) {
        setImageToDisplay("");
      }
    }
  }, [
    tabName,
    userData,
    selectedAcademicDegree,
    selectedCriminalRecord,
    setImageToDisplay,
    editMode,
  ]);

  return (
    <div className={styles.uploadContainer}>
      {!editMode && image ? (
        <div
          className={styles.imageContainer}
          onClick={() => setShowImage(true)}
        >
          <span className={styles.emptySpan}>
            {isAcademic
              ? "Akademska diploma"
              : "Potvrda o nepostojanju kaznene evidencije"}
          </span>
          <img alt="open" src={Open} className={styles.icon} />
        </div>
      ) : editMode && !image ? (
        <div className={styles.existingContainer}>
          <div
            className={styles.imageContainer}
            onClick={() => imageToDisplay && setShowImage(true)}
          >
            <span className={styles.emptySpan}>
              {editMode && !image && (
                <>
                  {isAcademic && !selectedDegreeName
                    ? "Trenutno niste uploadali diplomu"
                    : isAcademic && selectedDegreeName
                      ? selectedDegreeName
                      : selectedRecordName && !isAcademic
                        ? selectedRecordName
                        : "Trenutno niste uploadali potvrdu."}
                </>
              )}
              {editMode && image && (
                <>
                  {isAcademic && !selectedDegreeName
                    ? "Akademska diploma"
                    : isAcademic && selectedDegreeName
                      ? selectedDegreeName
                      : selectedRecordName && !isAcademic
                        ? selectedRecordName
                        : "Potvrda o nepostojanju kaznene evidencije"}
                </>
              )}
            </span>
            <img alt="open" src={Open} className={styles.icon} />
          </div>
          <Input
            fileInputRef={fileInputRef}
            isMultiple={isMultiple}
            isAcademic={isAcademic}
            setSelectedDegreeName={setSelectedDegreeName}
            setSelectedRecordName={setSelectedRecordName}
            setSelectedAcademicDegree={setSelectedAcademicDegree}
            setSelectedCriminalRecord={setSelectedCriminalRecord}
            selectedDegreeName={selectedDegreeName}
            selectedRecordName={selectedRecordName}
            polaznikData={userData}
            handleImageClick={handleImageClick}
            tabName={tabName}
            handleSave={(role) =>
              handleSingleSave(
                isAcademic ? selectedAcademicDegree : selectedCriminalRecord,
                authToken,
                isAcademic,
                apiUrl,
                userData,
                setLoading,
                setModalContent,
                Success,
                setShowModal,
                setEditMode,
                Error,
                role
              )
            }
          />
        </div>
      ) : editMode && image ? (
        <div className={styles.existingContainer}>
          <div
            className={styles.imageContainer}
            onClick={() => setShowImage(true)}
          >
            <span className={styles.emptySpan}>
              {isAcademic && !selectedDegreeName
                ? "Akademska diploma"
                : isAcademic && selectedDegreeName
                  ? selectedDegreeName
                  : selectedRecordName && !isAcademic
                    ? selectedRecordName
                    : "Potvrda o nepostojanju kaznene evidencije"}
            </span>
            <img alt="open" src={Open} className={styles.icon} />
          </div>
          <Input
            fileInputRef={fileInputRef}
            isMultiple={isMultiple}
            setMyProfileData={setMyProfileData}
            polaznikData={userData}
            isAcademic={isAcademic}
            setSelectedDegreeName={setSelectedDegreeName}
            setSelectedRecordName={setSelectedRecordName}
            setSelectedAcademicDegree={setSelectedAcademicDegree}
            setSelectedCriminalRecord={setSelectedCriminalRecord}
            selectedDegreeName={selectedDegreeName}
            selectedRecordName={selectedRecordName}
            handleImageClick={handleImageClick}
            tabName={tabName}
            handleSave={(role) =>
              handleSingleSave(
                isAcademic ? selectedAcademicDegree : selectedCriminalRecord,
                authToken,
                isAcademic,
                apiUrl,
                userData,
                setLoading,
                setModalContent,
                Success,
                setShowModal,
                setEditMode,
                Error,
                role
              )
            }
          />
        </div>
      ) : (
        <div className={styles.emptyContainer}>
          <span className={styles.emptySpan}>
            {isAcademic
              ? "Trenutno niste uploadali diplomu"
              : "Trenutno niste uploadali potvrdu."}
          </span>
        </div>
      )}
      {showImage && (
        <LightBox image={imageToDisplay} onClose={() => setShowImage(false)} />
      )}
      {/* ovo vratiti kada bude obavezan upload potvrda 
     {editMode && (
        <div className={styles.profileFormButtons}>
          {!isFirstLogin && (
            <div
              onClick={() => {
                if (!isFirstLogin) {
                  setEditMode(false);
                  handleReset();
                }
              }}
              className={styles.closeButton}
            >
              <p className={styles.buttonText} style={{ color: "#808080" }}>
                Odustani
              </p>
            </div>
          )}
          <div
            className={styles.saveButton}
            onClick={() =>
              handleSave(
                selectedValue,
                myProfileData,
                setEmptyFields,
                userData,
                authToken,
                setModalContent,
                setShowModal,
                setUserData,
                setLoading,
                numericPhoneNumber,
                uploadedFile,
                apiUrl,
                navigate,
                changeIsFirstLogin,
                setEditMode,
                myProfileData?.academicDegree,
                myProfileData?.criminalRecord
              )
            }
          >
            <p className={styles.buttonText}>Spremi promjene</p>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default Upload;
