// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal {
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
}
.modal-content {
  padding: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.yellow-border {
  border: 2px solid yellow;
}

.cell.highlighted {
  border: 2px solid yellow;
}

.highlighted {
  border: 2px solid yellow;
  padding: 4px;
  border-radius: 4px;
  margin: 2px;
}

.week-table {
  margin: 0;
  padding: 0;
}
.week-table td {
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/master/WeekTable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;AACA;EACE,aAAa;EACb,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":[".custom-modal {\n  width: 200px;\n  max-width: 100%;\n  margin: 0 auto;\n}\n.modal-content {\n  padding: 20px;\n  height: 200px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.yellow-border {\n  border: 2px solid yellow;\n}\n\n.cell.highlighted {\n  border: 2px solid yellow;\n}\n\n.highlighted {\n  border: 2px solid yellow;\n  padding: 4px;\n  border-radius: 4px;\n  margin: 2px;\n}\n\n.week-table {\n  margin: 0;\n  padding: 0;\n}\n.week-table td {\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
