export const formatEvents = (data) => {
  return [
    ...data.facultativeWorkshops.map((item) => ({
      ...item,
      eventType: "facultativeWorkshops",
    })),
    // ...data.interactiveLessons.map((item) => ({
    //   ...item,
    //   eventType: "interactiveLessons",
    // })),
    // ...data.regularWorkshops.map((item) => ({
    //   ...item,
    //   eventType: "regularWorkshops",
    // })),
    ...data.superivisions.map((item) => ({
      ...item,
      eventType: "individual_supervision",
    })),
    ...data.therapies.map((item) => ({ ...item, eventType: "therapy" })),
    ...data.otherEvents.map((item) => ({ ...item, eventType: "other" })),
  ].map((item) => ({
    ...item,
    title: item.name,
    start: item.date?.split("T")[0] + "T" + item.timeFrom + ".000Z",
    end: item.date?.split("T")[0] + "T" + item.timeTo + ".000Z",
  }));
};
