// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleNotification_notification__d8yFB {
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
}
.SingleNotification_notification__d8yFB,
.SingleNotification_pill__eZq1j,
.SingleNotification_rightSide__RscA6 {
  display: flex;
  align-items: center;
}
.SingleNotification_notification__d8yFB:hover {
  background-color: #e5e6e8;
}
.SingleNotification_leftSide__imEQk {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 6px;
}
.SingleNotification_leftSide__imEQk,
.SingleNotification_pill__eZq1j,
.SingleNotification_rightSide__RscA6 {
  justify-content: center;
}
.SingleNotification_notificationTitle__Z4P\\+D,
.SingleNotification_time__83TPo {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.SingleNotification_notificationTitle__Z4P\\+D,
.SingleNotification_time__83TPo,
.SingleNotification_pill__eZq1j,
.SingleNotification_date__IukVY {
  font-family: Inter;
  text-align: left;
}
.SingleNotification_notificationTitle__Z4P\\+D {
  color: #4d4d4d;
}
.SingleNotification_time__83TPo {
  color: #95999e;
}
.SingleNotification_pill__eZq1j {
  width: 52px;
  height: 19px;
  padding: 2px;
  border-radius: 15.5px;
  background-color: #cb2d2d;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  color: #ffffff;
}
.SingleNotification_date__IukVY {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #2e3646;
}
.SingleNotification_rightSide__RscA6 {
  gap: 64px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SingleNotification/SingleNotification.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,WAAW;EACX,aAAa;EACb,eAAe;EACf,8CAA8C;AAChD;AACA;;;EAGE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,QAAQ;AACV;AACA;;;EAGE,uBAAuB;AACzB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;;;;EAIE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,qBAAqB;EACrB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,SAAS;AACX","sourcesContent":[".notification {\n  justify-content: space-between;\n  width: 100%;\n  padding: 12px;\n  cursor: pointer;\n  transition: background-color 300ms ease-in-out;\n}\n.notification,\n.pill,\n.rightSide {\n  display: flex;\n  align-items: center;\n}\n.notification:hover {\n  background-color: #e5e6e8;\n}\n.leftSide {\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n  gap: 6px;\n}\n.leftSide,\n.pill,\n.rightSide {\n  justify-content: center;\n}\n.notificationTitle,\n.time {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.notificationTitle,\n.time,\n.pill,\n.date {\n  font-family: Inter;\n  text-align: left;\n}\n.notificationTitle {\n  color: #4d4d4d;\n}\n.time {\n  color: #95999e;\n}\n.pill {\n  width: 52px;\n  height: 19px;\n  padding: 2px;\n  border-radius: 15.5px;\n  background-color: #cb2d2d;\n  font-size: 12px;\n  font-weight: 600;\n  line-height: 15px;\n  letter-spacing: 0em;\n  color: #ffffff;\n}\n.date {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 17px;\n  letter-spacing: 0em;\n  color: #2e3646;\n}\n.rightSide {\n  gap: 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `SingleNotification_notification__d8yFB`,
	"pill": `SingleNotification_pill__eZq1j`,
	"rightSide": `SingleNotification_rightSide__RscA6`,
	"leftSide": `SingleNotification_leftSide__imEQk`,
	"notificationTitle": `SingleNotification_notificationTitle__Z4P+D`,
	"time": `SingleNotification_time__83TPo`,
	"date": `SingleNotification_date__IukVY`
};
export default ___CSS_LOADER_EXPORT___;
