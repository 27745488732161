export const polaznikRAROptions = [
  // {
  //   label: "Individualne programske terapije",
  //   value: "individualneTerapije",
  // },
  // {
  //   label: "Fakultativne radionice",
  //   value: "fakultativne",
  // },
  // {
  //   label: "Intenzivi retroaktivno",
  //   value: "intenzivi",
  // },
];
export const polaznikORTTOptions = [
  {
    label: "Grupne supervizije",
    value: "grupneSupervizije",
  },
  // {
  //   label: "Individualne supervizije",
  //   value: "individualneSupervizije",
  // },
  // {
  //   label: "Moj rad s klijentima - aktualno",
  //   value: "klijentiAktualno",
  // },
  // {
  //   label: "Moj rad s klijentima - retroaktivno",
  //   value: "klijentiRetroaktivno",
  // },
  {
    label: "Osobni rad s grupom",
    value: "osobniRad",
  },
];

export const polaznikRetarded = [
  {
    label: "Individualne programske terapije",
    value: "individualneTerapije",
  },
  {
    label: "Grupne supervizije",
    value: "grupneSupervizije",
  },
  {
    label: "Individualne supervizije",
    value: "individualneSupervizije",
  },
  {
    label: "Moj rad s klijentima - aktualno",
    value: "klijentiAktualno",
  },
  {
    label: "Moj rad s klijentima - retroaktivno",
    value: "klijentiRetroaktivno",
  },
  {
    label: "Osobni rad s grupom",
    value: "osobniRad",
  },
  {
    label: "Fakultativne radionice",
    value: "fakultativne",
  },
  {
    label: "Intenzivi retroaktivno",
    value: "intenzivi",
  },
];

export const handleNavigateToRucniUnos = async (
  navigate,
  program,
  polaznikLevel,
  userEvidentionID
) => {
  navigate(`/evidencija-rucni-unos/${program}`, {
    state: {
      userEvidentionID: userEvidentionID,
      polaznikLevel: polaznikLevel,
    },
  });
};
