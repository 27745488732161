// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-btn-deletePage {
  border-radius: 4px;
  border: none;
  padding: 8px 16px;
  height: 52px;
  width: 100%;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: center;
  cursor: pointer;
}
.modal-btn-delete-deletePage {
  background-color: #cb2d2d;
  color: #ffffff;
}
.modal-btn-discontinue-deletePage {
  background-color: #ebebeb;
  color: #808080;
}

.PolazniciTable {
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/delete/Delete.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal-btn-deletePage {\n  border-radius: 4px;\n  border: none;\n  padding: 8px 16px;\n  height: 52px;\n  width: 100%;\n  font-family: Inter;\n  font-size: 15px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: center;\n  cursor: pointer;\n}\n.modal-btn-delete-deletePage {\n  background-color: #cb2d2d;\n  color: #ffffff;\n}\n.modal-btn-discontinue-deletePage {\n  background-color: #ebebeb;\n  color: #808080;\n}\n\n.PolazniciTable {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
