import React from "react";
import { useUserState } from "../../../../context/UserContext";
import styles from "./Upload.module.css";
import { handleImageSelect } from "./utils";
function Input({
  fileInputRef,
  isMultiple,
  setMyProfileData,
  isAcademic,
  setSelectedDegreeName,
  setSelectedRecordName,
  handleImageClick,
  setSelectedAcademicDegree,
  setSelectedCriminalRecord,
  handleSave,
  selectedDegreeName,
  selectedRecordName,
  tabName,
  polaznikData,
}) {
  const { role } = useUserState();
  return (
    <>
      {" "}
      <input
        ref={fileInputRef}
        id="dropzone-file"
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        multiple={isMultiple}
        style={{ display: "none" }}
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            handleImageSelect(
              event,
              // setMyProfileData,
              isAcademic,
              setSelectedDegreeName,
              setSelectedRecordName,
              setSelectedAcademicDegree,
              setSelectedCriminalRecord
            );
          }
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
      <div className={styles.btnRow}>
        <div className={styles.saveButton} onClick={handleImageClick}>
          <p className={styles.saveButtonText}>
            Dodajte {isAcademic ? "diplomu" : "potvrdu"}
          </p>
        </div>
        {role === "Referada" &&
          polaznikData?.criminalRecord !== null &&
          tabName === "criminalRecord" && (
            <div
              className={
                styles[
                  !selectedRecordName ? "deleteButton" : "disabledDeleteButton"
                ]
              }
              onClick={() => handleSave(role)}
            >
              <p className={styles.saveButtonText}>
                Izbrišite {isAcademic ? "diplomu" : "potvrdu"}
              </p>
            </div>
          )}
        {role === "Referada" &&
          polaznikData?.academicDegree !== null &&
          tabName === "academicDegree" && (
            <div
              className={
                styles[
                  !selectedDegreeName ? "deleteButton" : "disabledDeleteButton"
                ]
              }
              onClick={() => handleSave(role)}
            >
              <p className={styles.saveButtonText}>
                Izbrišite {isAcademic ? "diplomu" : "potvrdu"}
              </p>
            </div>
          )}

        <div
          className={
            styles[
              selectedDegreeName || selectedRecordName
                ? "saveButton"
                : "disabledButton"
            ]
          }
          onClick={() => handleSave(role)}
        >
          <p className={styles.saveButtonText}>
            Spremite {isAcademic ? "diplomu" : "potvrdu"}
          </p>
        </div>
      </div>
    </>
  );
}

export default Input;
