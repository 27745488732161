// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/svg/ChevronDownIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectDropdown_container__ec8oN {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.SelectDropdown_containerRow__lIFFU {
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
.SelectDropdown_label__f3SC- {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
  margin-block-end: 11px;
  margin-block-start: 11px;
}
.SelectDropdown_select__4-p4P {
  appearance: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-position: right 16px center;
  border: 1px solid #d8dbdf;
  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);
  border-radius: 8px;
  color: #5f6d7e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  height: 46px;
  padding: 0px 16px;
  min-width: 200px;
}
.SelectDropdown_select__4-p4P:focus {
  outline: none;
}

.SelectDropdown_disabled__0Yhbr {
  background-color: #f4f4f4;
  color: #787e86;
  cursor: not-allowed;
}

.SelectDropdown_labelDisabled__K\\+TV1 {
  color: #9fa7b2;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/singleGroup/components/selectDropdown/SelectDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,sBAAsB;EACtB,wBAAwB;AAC1B;AACA;EAGE,gBAAgB;EAChB,6DAAuE;EACvE,sCAAsC;EACtC,yBAAyB;EACzB,oDAAoD;EACpD,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n.containerRow {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  column-gap: 8px;\n}\n.label {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n  margin-block-end: 11px;\n  margin-block-start: 11px;\n}\n.select {\n  -moz-appearance: none;\n  -webkit-appearance: none;\n  appearance: none;\n  background: url(\"../../../../assets/svg/ChevronDownIcon.svg\") no-repeat;\n  background-position: right 16px center;\n  border: 1px solid #d8dbdf;\n  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);\n  border-radius: 8px;\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  height: 46px;\n  padding: 0px 16px;\n  min-width: 200px;\n}\n.select:focus {\n  outline: none;\n}\n\n.disabled {\n  background-color: #f4f4f4;\n  color: #787e86;\n  cursor: not-allowed;\n}\n\n.labelDisabled {\n  color: #9fa7b2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SelectDropdown_container__ec8oN`,
	"containerRow": `SelectDropdown_containerRow__lIFFU`,
	"label": `SelectDropdown_label__f3SC-`,
	"select": `SelectDropdown_select__4-p4P`,
	"disabled": `SelectDropdown_disabled__0Yhbr`,
	"labelDisabled": `SelectDropdown_labelDisabled__K+TV1`
};
export default ___CSS_LOADER_EXPORT___;
