// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewForm_leftProfileBox__is1BF {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  row-gap: 16px;
  width: 478px;
}
.ViewForm_profileInfoRow__XkV16 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.ViewForm_profileItemText__rtQVN {
  color: #7e818c;
}
.ViewForm_profileItemTitle__Yg6Tx {
  color: #4d4d4d;
}
.ViewForm_profileItemTitle__Yg6Tx,
.ViewForm_profileItemText__rtQVN {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
  cursor: default;
}
@media screen and (max-width: 900px) {
  .ViewForm_profileInfoRow__XkV16 {
    flex-direction: column;
    align-items: baseline;
    width: 100%;
  }
  .ViewForm_profileItemTitle__Yg6Tx,
  .ViewForm_profileItemText__rtQVN {
    font-size: 12px;
  }
  .ViewForm_leftProfileBox__is1BF {
    gap: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/myProfile/components/ViewForm/ViewForm.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,aAAa;EACb,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;;EAEE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,eAAe;AACjB;AACA;EACE;IACE,sBAAsB;IACtB,qBAAqB;IACrB,WAAW;EACb;EACA;;IAEE,eAAe;EACjB;EACA;IACE,SAAS;EACX;AACF","sourcesContent":[".leftProfileBox {\n  display: flex;\n  flex-direction: column;\n  margin-top: 15px;\n  row-gap: 16px;\n  width: 478px;\n}\n.profileInfoRow {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n}\n.profileItemText {\n  color: #7e818c;\n}\n.profileItemTitle {\n  color: #4d4d4d;\n}\n.profileItemTitle,\n.profileItemText {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n  cursor: default;\n}\n@media screen and (max-width: 900px) {\n  .profileInfoRow {\n    flex-direction: column;\n    align-items: baseline;\n    width: 100%;\n  }\n  .profileItemTitle,\n  .profileItemText {\n    font-size: 12px;\n  }\n  .leftProfileBox {\n    gap: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftProfileBox": `ViewForm_leftProfileBox__is1BF`,
	"profileInfoRow": `ViewForm_profileInfoRow__XkV16`,
	"profileItemText": `ViewForm_profileItemText__rtQVN`,
	"profileItemTitle": `ViewForm_profileItemTitle__Yg6Tx`
};
export default ___CSS_LOADER_EXPORT___;
