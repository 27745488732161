// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Supervizije_raniRazvojPrvaSekcija2__LeLpE {
  margin-top: 64px;
  background-color: rgba(241, 32, 32, 0.1);
  border-radius: 8px;
  padding: 20px;
}
.Supervizije_raniRazvojHeader__dije8 {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.028px;
}

.Supervizije_raniRazvojDescription__cIPy8,
.Supervizije_raniRazvojDescription2__X21jQ {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.014px;
}
.Supervizije_supervizijeAccordion__yjuIb {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
.Supervizije_label__zt4A9 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #4d4d4d;
}
.Supervizije_obracunAccordion__kEkas {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/evidencija/components/Supervizije/Supervizije.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wCAAwC;EACxC,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;;EAEE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".raniRazvojPrvaSekcija2 {\n  margin-top: 64px;\n  background-color: rgba(241, 32, 32, 0.1);\n  border-radius: 8px;\n  padding: 20px;\n}\n.raniRazvojHeader {\n  color: #4d4d4d;\n  font-family: Inter;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.028px;\n}\n\n.raniRazvojDescription,\n.raniRazvojDescription2 {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: -0.014px;\n}\n.supervizijeAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n.label {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #4d4d4d;\n}\n.obracunAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"raniRazvojPrvaSekcija2": `Supervizije_raniRazvojPrvaSekcija2__LeLpE`,
	"raniRazvojHeader": `Supervizije_raniRazvojHeader__dije8`,
	"raniRazvojDescription": `Supervizije_raniRazvojDescription__cIPy8`,
	"raniRazvojDescription2": `Supervizije_raniRazvojDescription2__X21jQ`,
	"supervizijeAccordion": `Supervizije_supervizijeAccordion__yjuIb`,
	"label": `Supervizije_label__zt4A9`,
	"obracunAccordion": `Supervizije_obracunAccordion__kEkas`
};
export default ___CSS_LOADER_EXPORT___;
