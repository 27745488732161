// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DostupneTerapijeCard_card__4h7Hr {
  max-width: 330px;
  border: 1px solid #d1d9e2;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px #1018280a;
}

.DostupneTerapijeCard_titleRow__ir2Pf {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #5f6d7ec7;
  background-color: #f8f9fb;
  padding: 15px;
}

.DostupneTerapijeCard_row__jn-Sv {
  display: flex;
  justify-content: space-around;
  background-color: #f8f9fb;
  padding: 15px;
  border-bottom: 1px solid #d1d9e2;
}
.DostupneTerapijeCard_rowTwo__JkKie {
  display: flex;
  justify-content: space-around;
  background-color: white;
  padding: 12px;
}

.DostupneTerapijeCard_text__YEqn7 {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #818c99;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/terapeuti/TerapeutProfil/components/DostupneTerapijeCard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,yBAAyB;EACzB,aAAa;EACb,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,6BAA6B;EAC7B,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".card {\n  max-width: 330px;\n  border: 1px solid #d1d9e2;\n  border-radius: 4px;\n  box-shadow: 0px 1px 2px 0px #1018280a;\n}\n\n.titleRow {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-family: \"Inter\";\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #5f6d7ec7;\n  background-color: #f8f9fb;\n  padding: 15px;\n}\n\n.row {\n  display: flex;\n  justify-content: space-around;\n  background-color: #f8f9fb;\n  padding: 15px;\n  border-bottom: 1px solid #d1d9e2;\n}\n.rowTwo {\n  display: flex;\n  justify-content: space-around;\n  background-color: white;\n  padding: 12px;\n}\n\n.text {\n  font-family: \"Inter\";\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 17px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #818c99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `DostupneTerapijeCard_card__4h7Hr`,
	"titleRow": `DostupneTerapijeCard_titleRow__ir2Pf`,
	"row": `DostupneTerapijeCard_row__jn-Sv`,
	"rowTwo": `DostupneTerapijeCard_rowTwo__JkKie`,
	"text": `DostupneTerapijeCard_text__YEqn7`
};
export default ___CSS_LOADER_EXPORT___;
