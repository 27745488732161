import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Success from "../../assets/img/Success.png";
import Checkbox from "../../components/checkbox/Checkbox";
import Modal from "../../components/Modal/Modal";
import ToggleSwitch from "../../components/toggleSwitch/ToggleSwitch";
import { getZakupiteljById } from "../../components/ZakupiteljChoice/api/getZakupiteljById";
import ZakupiteljChoice from "../../components/ZakupiteljChoice/ZakupiteljChoice";
import { useUserState } from "../../context/UserContext";
import { getAllCities } from "../groups/api/getAllCities";
import { useAuthentication } from "../hooks/useAuthentication";
import { fetchData } from "../myProfile/utils";
import {
  changeSettingsSaved,
  getNotificationPreference,
  postNotificationPreference,
  updateNotificationPreference,
} from "./API";
import styles from "./ProfileSettings.module.css";
import { cities } from "./utils";

const apiUrl = process.env.REACT_APP_API_URL;

const backgroundColorMap = {
  RAR: "#87AD76",
  OR: "#CBA855",
  TT: "#A35254",
  TP: "#D64D51",
  Roditeljstvo: "#7BBA9F",
  VT: "#B8834F",
  RPO: "#3A54A1",
};

const ProfileSettings = () => {
  const { userData, role } = useUserState();
  const { authToken } = useAuthentication();
  const [settingsData, setSettingsData] = useState(null);
  const [groupData, setGroupData] = useState();
  const [formData, setFormData] = useState({
    mailingZagreb: false,
    mailingSplit: false,
    mailingBeograd: false,
    mailingLjubljana: false,
    mailingPodgorica: false,
    mailingRijeka: false,
    mailingOnline: false,
    calendar: false,
  });
  const [isChanged, setIsChanged] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [zakupitelj, setZakupitelj] = useState([]);
  const location = useLocation();
  const firstSettingsSave = location?.state?.firstSettingsSave;
  const [isModalOpen, setIsModalOpen] = useState(firstSettingsSave);
  const navigate = useNavigate();
  const [debouncedFormData, setDebouncedFormData] = useState(formData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allSettingsData = await getNotificationPreference(
          userData.id,
          authToken
        );
        if (!allSettingsData) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            mailingZagreb: false,
            mailingSplit: false,
            mailingBeograd: false,
            mailingLjubljana: false,
            mailingPodgorica: false,
            mailingRijeka: false,
            mailingOnline: false,
            calendar: false,
          }));
        } else {
          setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData };
            cities.forEach((city) => {
              const cityKey = `mailing${city.cityName}`;
              updatedFormData[cityKey] = allSettingsData.cityIds.includes(
                city.id
              );
            });
            updatedFormData.calendar = allSettingsData.calendarSync;
            return updatedFormData;
          });
        }
        setSettingsData(allSettingsData);
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    if (settingsData === null && authToken) {
      fetchData();
    }
  }, [userData.id, authToken, settingsData]);

  const handleInputChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedFormData(formData);
    }, 500);
    return () => clearTimeout(debounceTimeout);
  }, [formData]);

  useEffect(() => {
    const makeApiRequest = async () => {
      const requestBody = {
        userId: userData.id,
        cityIds: cities
          .filter((city) => debouncedFormData[`mailing${city.cityName}`])
          .map((city) => city.id),
        calendarSync: debouncedFormData.calendar,
      };
      try {
        if (!settingsData && isChanged) {
          await postNotificationPreference(authToken, userData.id, requestBody);
        } else if (!isEqual(debouncedFormData, settingsData)) {
          await updateNotificationPreference(
            authToken,
            userData.id,
            requestBody
          );
        }
      } catch (error) {
        console.error("Error saving preferences:", error);
        if (error.response) {
          console.error("Response from server:", error.response.data);
        }
      }
    };

    if (formData && authToken) makeApiRequest();
  }, [debouncedFormData, userData.id, authToken, settingsData]);

  useEffect(() => {
    if (authToken) fetchData(apiUrl, authToken, setGroupData, 0, userData);
  }, [authToken, userData]);

  useEffect(() => {
    const fetchCities = async () => {
      const cities = await getAllCities(authToken);
      setAllCities(cities);
    };

    if (authToken) fetchCities();
  }, [authToken]);

  useEffect(() => {
    const fetchZakupitelj = async () => {
      const zakupitelj = await getZakupiteljById(userData.id, authToken);
      setZakupitelj({
        cities: zakupitelj[0].cities.map((city) => ({
          ...allCities[city.cityId - 1],
        })),
        choices: zakupitelj[0].cities.reduce((acc, city) => {
          acc[city.cityId] = city.zakupiteljType;
          return acc;
        }, {}),
      });
    };

    if (authToken) fetchZakupitelj();
  }, [authToken, allCities]);

  const getBackgroundColor = (name) => {
    const prefix = name
      ?.split("-")[1]
      ?.split("-")[0]
      ?.replace(/[^a-zA-Z]/g, "");
    return backgroundColorMap[prefix] || "";
  };

  function filterDuplicates(array) {
    const uniqueNames = new Set();
    return array.filter((obj) => {
      if (!uniqueNames.has(obj.name)) {
        uniqueNames.add(obj.name);
        return true;
      }
      return false;
    });
  }

  const handleSave = async () => {
    await changeSettingsSaved(authToken);
    if (userData.roleId === 1) {
      navigate("/kalendar");
    } else {
      navigate("/dashboard");
    }
    window.location.reload();
  };

  const isAnyFieldChecked = () => {
    return Object.values(formData).some((value) => value === true);
  };

  return (
    <div>
      {!userData.settingsSaved && !firstSettingsSave && (
        <div className={styles.step}>2. korak</div>
      )}
      {firstSettingsSave && isModalOpen && (
        // <div className={styles.alert}>
        //   Molimo vas da prije nastavka korištenja označite gradove za koje
        //   želite primati obavijesti u izborniku ispod.
        // </div>
        <Modal
          image={Success}
          description="Molimo vas da prije nastavka korištenja označite gradove za koje želite primati obavijesti u izborniku ispod."
          buttons={[
            {
              label: "U redu",
              action: () => {
                setIsModalOpen(false);
              },
              className: "modal-btn-success",
            },
          ]}
        />
      )}
      {!userData.settingsSaved && (
        <div className={styles.saveContainer}>
          <button
            className={
              !isAnyFieldChecked() ? styles.disabledBtn : styles.saveButton
            }
            onClick={handleSave}
            disabled={!isAnyFieldChecked()}
          >
            Spremi postavke
          </button>
        </div>
      )}
      <div className={styles.profileContainer}>
        <div className={styles.imageContainer}>
          <img
            alt="profile_photo"
            src={userData.imageUrl}
            className={styles.image}
          />
        </div>
        <div>
          <div className={styles.fullNameRow}>
            <div className={styles.leftBox}>
              <h3 className={styles.nameText}>
                {`${userData.firstName} ${userData.lastName}`}
              </h3>
              {role === "Polaznik" &&
                groupData &&
                groupData.groups?.length > 0 &&
                filterDuplicates(
                  groupData?.groups?.map((item) => {
                    const prefix = item?.name
                      ?.split("-")[1]
                      ?.split("-")[0]
                      ?.replace(/[^a-zA-Z]/g, "");
                    return {
                      color: getBackgroundColor(item.name),
                      name: `Polaznik ${
                        prefix === undefined ? "" : prefix + "-a"
                      }`,
                    };
                  })
                ).map((item, index) => (
                  <div
                    className={styles.badge}
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    <p className={styles.badgeText}>{item.name}</p>
                  </div>
                ))}
              {role === "Voditelj" &&
                groupData &&
                groupData.groups?.length > 0 &&
                filterDuplicates(
                  groupData?.groups?.map((item) => {
                    const prefix = item?.name
                      ?.split("-")[1]
                      ?.split("-")[0]
                      ?.replace(/[^a-zA-Z]/g, "");
                    return {
                      color: getBackgroundColor(item.name),
                      name: `Voditelj ${
                        prefix === undefined ? "" : prefix + "-a"
                      }`,
                    };
                  })
                ).map((item, index) => (
                  <div
                    className={styles.badge}
                    key={index}
                    style={{ backgroundColor: item.color }}
                  >
                    <p className={styles.badgeText}>{item.name}</p>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.settingsSections}>
            <div className={styles.settingsSectionContainer}>
              <h3 className={styles.settingsSectionTitle}>
                Postavke u školskom mailing sistemu
              </h3>
              <p className={styles.settingsSectionText}>
                Odaberi gradove za koje želiš primati mailom informacije o
                školskim aktivnostima &#40;fakultativne radionice, predavanja i
                sl.&#41;:
              </p>
              <div className={styles.settingsSectionControllerContainer}>
                <Checkbox
                  name="mailingZagreb"
                  value={formData.mailingZagreb}
                  label="Zagreb"
                  onChange={handleInputChange}
                />
                <Checkbox
                  name="mailingSplit"
                  value={formData.mailingSplit}
                  label="Split"
                  onChange={handleInputChange}
                />
                <Checkbox
                  name="mailingBeograd"
                  value={formData.mailingBeograd}
                  label="Beograd"
                  onChange={handleInputChange}
                />
                <Checkbox
                  name="mailingLjubljana"
                  value={formData.mailingLjubljana}
                  label="Ljubljana"
                  onChange={handleInputChange}
                />
                <Checkbox
                  name="mailingPodgorica"
                  value={formData.mailingPodgorica}
                  label="Podgorica"
                  onChange={handleInputChange}
                />
                {/* <Checkbox
                  name="mailingRijeka"
                  value={formData.mailingRijeka}
                  label="Rijeka"
                  onChange={handleInputChange}
                /> */}
                <Checkbox
                  name="mailingOnline"
                  value={formData.mailingOnline}
                  label="Online"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {userData.isZakupitelj && (
              <div className={styles.settingsSectionContainer}>
                <ZakupiteljChoice
                  cities={allCities.filter(
                    (city) => ![1, 5, 6, 7].includes(city.value)
                  )}
                  userData={userData}
                  zakupitelj={zakupitelj}
                  authToken={authToken}
                />
              </div>
            )}
            <div className={styles.settingsSectionContainer}>
              <h3 className={styles.settingsSectionTitle}>
                Postavke profila i lozinke
              </h3>
              <p className={styles.settingsSectionText}>
                Obzirom da prijavu u školski sistem radiš preko svog privatnog
                Google ili Apple računa, lozinku i snagu zaštite školskog računa
                možeš podesiti u svom Google ili Apple računu.
              </p>
            </div>
            <div className={styles.settingsSectionContainer}>
              <h3 className={styles.settingsSectionTitle}>
                Postavke kalendara
              </h3>
              <p className={styles.settingsSectionText}>
                Ukoliko želiš da se tvoji događaji na školskom kalendaru prenesu
                i na Google ili Apple kalendar, možeš to učiniti ispod
                uključivanjem sinkronizacije. (Uskoro će biti u funkciji o ćemu
                ćeš dobiti obavijest)
              </p>
              <ToggleSwitch
                name="calendar"
                value={formData.calendar}
                label="Sinkronizacija kalendara"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileSettings;
