import React from "react";
import AddIcon from "../../assets/AddIcon.svg";
import ExpandIcon from "../../assets/ExpandIcon.svg";
import groupsIcon from "../../assets/GroupsIcon.svg";
import ProfileIcon from "../../assets/ProfileIcon.svg";
import styles from "../../PolazniciPregled.module.css";
import { formatDate, handleGetUserEvidention } from "../../utils/utils";

function ViewMode({
  userData,
  polozeniProgrami,
  userId,
  setShowEditMode,
  setIsArhiviraj,
  setIsEdit,
  navigate,
  pojediniPolaznikID,
  userInGroups,
  setIsModalGrupaOpen,
}) {
  return (
    <>
      <div className={styles.columns}>
        <div className={styles["left-column"]}>
          <table>
            <tbody>
              <tr>
                <td className={styles["span"]}>Datum rođenja</td>
                <td className={styles["spanData"]}>
                  {formatDate(userData?.dateOfBirth)}{" "}
                </td>
              </tr>
              <tr>
                <td className={styles["span"]}>Spol</td>
                <td className={styles["spanData"]}>{userData?.gender}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Mjesto stanovanja</td>
                <td className={styles["spanData"]}>{userData?.city}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Adresa stanovanja</td>
                <td className={styles["spanData"]}>{userData?.address}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Država stanovanja</td>
                <td className={styles["spanData"]}>{userData?.country}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Grad u kojem pohađa program</td>
                <td className={styles["spanData"]}>{userData?.programCity}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Stručna sprema</td>
                <td className={styles["spanData"]}>
                  {userData?.qualification}
                </td>
              </tr>
              <tr>
                <td className={styles["span"]}>Zvanje</td>
                <td className={styles["spanData"]}>{userData?.title}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Identifikacijski broj</td>
                <td className={styles["spanData"]}>{userData?.OIB}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Broj mobitela</td>
                <td className={styles["spanData"]}>{userData?.phoneNumber}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>E-mail adresa</td>
                <td className={styles["spanData"]}>{userData?.emailAddress}</td>
              </tr>
              <tr>
                <td className={styles["span"]}>Položeni programi na IPD-u</td>
                <td className={styles["spanData"]}>
                  {polozeniProgrami.map((program, index) => (
                    <span key={program}>
                      {program}
                      {index < polozeniProgrami.length - 1 ? ", " : ""}
                    </span>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles["right-column"]}>
          <div className={styles.content}>
            {userId === "Referada" && (
              <button
                className={styles.urediBtn}
                onClick={() => {
                  setShowEditMode(true);
                  setIsArhiviraj(false);
                  setIsEdit(true);
                }}
              >
                Uredi profil
              </button>
            )}
            <button
              className={styles.evidencijaBtn}
              onClick={() =>
                handleGetUserEvidention(
                  navigate,
                  pojediniPolaznikID,
                  ` ${userData?.firstName} ${userData?.lastName}`
                )
              }
            >
              Pogledaj evidenciju <img src={ExpandIcon} alt="expand icon" />
            </button>
            <div className={styles.groupContent}>
              <div className={styles.groupH1}> Član grupa </div>
              <img src={groupsIcon} alt="group icon" />
            </div>
            {userInGroups.length === 0 ? (
              <>
                <div className={styles.iconContainer}>
                  <img src={ProfileIcon} alt="profile icon" />
                </div>
                <p className={styles.groupText}>
                  Polaznik trenutno ne pripada nijednoj grupi.
                </p>
                {userId === "Referada" && (
                  <button
                    className={styles.addToGroupBtn}
                    onClick={() => setIsModalGrupaOpen(true)}
                  >
                    Dodaj u grupu <img src={AddIcon} alt="add icon" />
                  </button>
                )}
              </>
            ) : (
              <div className={styles.groupsBadges}>
                {userInGroups.map((item, index) => (
                  <div className={styles.groupBadge} key={index}>
                    <p className={styles.groupBadgeText}>{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewMode;
