import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const intensiveURL = "addIntensiveToEvidention";
const workshopURL = "addFacultativeWorkshopToEvidention";

export const addEventToEvidention = async (
  eventType,
  eventId,
  usersArray,
  accessToken
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/${eventType === "fakultativne-radionice" ? workshopURL : intensiveURL}`,
      {
        id: eventId,
        polaznici: usersArray,
        isVoditelj: false,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error adding user: ", error);
    throw error;
  }
};
