import React, { useEffect, useState } from "react";
import Success from "../../../../assets/img/CheckRing.png";
import Error from "../../../../assets/img/Failcheck.jpg";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { handleCheckboxChange } from "../../util";
import styles from "./ZavrsniRadovi.module.css";

function ZavrsniRadovi({
  userId,
  polaznikLevel,
  data,
  evidentionUserID,
  setDeleteFlag,
  deleteFlag,
}) {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [formValues, setFormValues] = useState({
    zavrsniOR: false,
    caseStudy1: false,
    caseStudy2: false,
    supervisorApproval1: false,
    supervisorApproval2: false,
  });

  useEffect(() => {
    if (data) {
      setFormValues({
        zavrsniOR: data?.zavrsniOR,
        caseStudy1: data?.caseStudy1,
        caseStudy2: data?.caseStudy2,
        supervisorApproval1: data?.supervisorApproval1,
        supervisorApproval2: data?.supervisorApproval2,
      });
    }
  }, [data]);

  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <h1 className={styles.raniRazvojHeader}>Završni radovi - ICS</h1>
      <p className={styles.raniRazvojDescription}>
        Za postizanje statusa
        {polaznikLevel === "praktičar"
          ? " Praktičara"
          : polaznikLevel === "psihoterapeut"
          ? " Psihoterapeuta"
          : ""}{" "}
        ITP-a potrebno je napisati Završni rad na kraju OR-a i Studiju slučaja 1
        na kraju TP-a
        {polaznikLevel === "psihoterapeut" &&
          ` i Studiju slučaja 2 na kraju TT-a`}
        .
      </p>
      <div
        className={styles.zavrsniWrapper}
        style={{ flexDirection: userId === "Referada" ? "row" : "column" }}
      >
        <span className={styles.label}>Završni rad OR-a</span>
        {userData.roleId !== 4 && userData.roleId !== 6 ? (
          <img
            alt=""
            src={formValues.zavrsniOR ? Success : Error}
            className={styles.success}
          />
        ) : (
          <div>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                className={styles.checkboxInput}
                name="zavrsniOR"
                checked={formValues.zavrsniOR}
                onChange={(e) => {
                  handleCheckboxChange(
                    "zavrsniOR",
                    e.target.checked,
                    setFormValues,
                    evidentionUserID,
                    authToken,
                    formValues
                  );
                  setDeleteFlag(!deleteFlag);
                }}
              />
              <span className={styles.checkboxCustom} />
              Odrađen
            </label>
          </div>
        )}
        <span className={styles.label}>Studija slučaja 1</span>
        {userData.roleId !== 5 && userData.roleId !== 6 ? (
          <img
            alt=""
            src={formValues?.caseStudy1 ? Success : Error}
            className={styles.success}
          />
        ) : (
          <div>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                className={styles.checkboxInput}
                name="caseStudy1"
                checked={formValues?.caseStudy1}
                onChange={(e) => {
                  handleCheckboxChange(
                    "caseStudy1",
                    e.target.checked,
                    setFormValues,
                    evidentionUserID,
                    authToken,
                    formValues
                  );
                  setDeleteFlag(!deleteFlag);
                }}
              />
              <span className={styles.checkboxCustom} />
              Odrađen
            </label>
          </div>
        )}
        <>
          <span className={styles.label}>Studija slučaja 2</span>
          {userData.roleId !== 5 && userData.roleId !== 6 ? (
            <img
              alt=""
              src={formValues?.caseStudy2 ? Success : Error}
              className={styles.success}
            />
          ) : (
            <div>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  className={styles.checkboxInput}
                  name="caseStudy2"
                  checked={formValues?.caseStudy2}
                  onChange={(e) =>
                    handleCheckboxChange(
                      "caseStudy2",
                      e.target.checked,
                      setFormValues,
                      evidentionUserID,
                      authToken,
                      formValues
                    )
                  }
                />
                <span className={styles.checkboxCustom} />
                Odrađen
              </label>
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default ZavrsniRadovi;
