// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserMenuDropdown_container__umfGU {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 17px 0px rgba(95, 109, 126, 0.16);
  min-width: 244px;
  padding: 8px 0px;
  position: absolute;
  top: 108px;
  z-index: 10;
}
.UserMenuDropdown_dropdownItem__kjdCb {
  padding: 12px 16px;
  transition: background-color 200ms ease-in-out;
}
.UserMenuDropdown_dropdownItem__kjdCb:hover {
  background-color: #f2f2f2;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
}
.UserMenuDropdown_dropdownItemText__jZ8z\\+ {
  color: rgba(95, 109, 126, 0.78);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/userMenu/components/userMenuDropdown/UserMenuDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,qDAAqD;EACrD,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,8CAA8C;AAChD;AACA;EACE,yBAAyB;EACzB,eAAe;EACf,8CAA8C;AAChD;AACA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;AACb","sourcesContent":[".container {\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0px 4px 17px 0px rgba(95, 109, 126, 0.16);\n  min-width: 244px;\n  padding: 8px 0px;\n  position: absolute;\n  top: 108px;\n  z-index: 10;\n}\n.dropdownItem {\n  padding: 12px 16px;\n  transition: background-color 200ms ease-in-out;\n}\n.dropdownItem:hover {\n  background-color: #f2f2f2;\n  cursor: pointer;\n  transition: background-color 200ms ease-in-out;\n}\n.dropdownItemText {\n  color: rgba(95, 109, 126, 0.78);\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UserMenuDropdown_container__umfGU`,
	"dropdownItem": `UserMenuDropdown_dropdownItem__kjdCb`,
	"dropdownItemText": `UserMenuDropdown_dropdownItemText__jZ8z+`
};
export default ___CSS_LOADER_EXPORT___;
