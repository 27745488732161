// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-supervizije-suglasnost {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  border: 1px solid #e6e9ec;
  box-shadow: 0px 1px 2px 0px #1018280a;
  border-radius: 8px;
}
.table-supervizije-suglasnost th,
.table-supervizije-suglasnost td {
  padding: 16px 32px 16px 32px;
  border-bottom: 1px solid #d1d9e2;
}
.table-supervizije-suglasnost tr:last-child {
  border-bottom: none;
}
.table-supervizije-suglasnost th,
.table-supervizije-suglasnost tr {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.table-supervizije-suglasnost th,
.table-supervizije-suglasnost tr {
  text-align: left;
  font-family: Inter;
  letter-spacing: -0.001em;
  color: #5f6d7e;
}
.table-supervizije-suglasnost-custom-border {
  border-right: 1px solid #d1d9e2;
}
.table-supervizije-suglasnost th {
  padding: 16px 0px 16px 24px;
}
.table-supervizije-suglasnost tr {
  font-weight: 500;
}
.table-supervizije-suglasnost-avatar {
  width: 32px;
  height: 32px;
  border-radius: 100px;
}
.table-supervizije-suglasnost-row {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/evidencija/components/Supervizije/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,qCAAqC;EACrC,kBAAkB;AACpB;AACA;;EAEE,4BAA4B;EAC5B,gCAAgC;AAClC;AACA;EACE,mBAAmB;AACrB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;;EAEE,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,cAAc;AAChB;AACA;EACE,+BAA+B;AACjC;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,QAAQ;AACV","sourcesContent":[".table-supervizije-suglasnost {\n  width: 100%;\n  border-collapse: collapse;\n  border-radius: 10px;\n  border: 1px solid #e6e9ec;\n  box-shadow: 0px 1px 2px 0px #1018280a;\n  border-radius: 8px;\n}\n.table-supervizije-suglasnost th,\n.table-supervizije-suglasnost td {\n  padding: 16px 32px 16px 32px;\n  border-bottom: 1px solid #d1d9e2;\n}\n.table-supervizije-suglasnost tr:last-child {\n  border-bottom: none;\n}\n.table-supervizije-suglasnost th,\n.table-supervizije-suglasnost tr {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n}\n.table-supervizije-suglasnost th,\n.table-supervizije-suglasnost tr {\n  text-align: left;\n  font-family: Inter;\n  letter-spacing: -0.001em;\n  color: #5f6d7e;\n}\n.table-supervizije-suglasnost-custom-border {\n  border-right: 1px solid #d1d9e2;\n}\n.table-supervizije-suglasnost th {\n  padding: 16px 0px 16px 24px;\n}\n.table-supervizije-suglasnost tr {\n  font-weight: 500;\n}\n.table-supervizije-suglasnost-avatar {\n  width: 32px;\n  height: 32px;\n  border-radius: 100px;\n}\n.table-supervizije-suglasnost-row {\n  display: flex;\n  align-items: center;\n  justify-content: left;\n  gap: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
