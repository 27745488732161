import React from "react";
import { useNavigate } from "react-router-dom";
import Anonymous from "../../../../../assets/img/Anonymous.png";
import { useUserState } from "../../../../../context/UserContext";

function RenderTableData({ data = [] }) {
  const navigate = useNavigate();
  const { role } = useUserState();
  const handleLinkClick = (voditeljId) => {
    if (voditeljId) {
      const newReferadaUrl = `/osvrti/one`;
      const newVoditeljUrl = `/osvrti/three`;
      const newState = {
        voditeljId: voditeljId,
        isPojediniVoditelj: true,
      };
      const searchParams = new URLSearchParams(newState).toString();
      if (role === "Voditelj"||role==="Polaznik VT-a"||role==="Supervizor") {
        navigate(`${newVoditeljUrl}?${searchParams}`);
      }
      if (role === "Referada") {
        navigate(`${newReferadaUrl}?${searchParams}`);
      }
    }
  };
  return (
    <>
      {data.map((item, index) => (
        <tr key={index}>
          <td className="table-osvrti-voditelja-row">
            <img
              alt=""
              src={item.imageUrl === "" ? Anonymous : item.imageUrl}
            />
            <span>
              {item.firstName} {item.lastName}
            </span>
          </td>
          <td>
            <span className="table-osvrti-voditelja-grade">
              {item.averageGrade?.toFixed(2) || "0.00"}
            </span>
          </td>
          <td>
            <span className="table-osvrti-voditelja-grade">
              {item.gradeCount || "0"}
            </span>
          </td>
          <td>
            <span
              className="table-osvrti-voditelja-btn"
              onClick={() => handleLinkClick(item.id)}
            >
              Pročitaj osvrt
            </span>
          </td>
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
