import axios from "axios";
import Success from "../../assets/img/Success.png";
import { fetchCities } from "./apiUtils";

export const PozivniBrojevi = [
  { value: "+1", label: "+1" },
  { value: "+20", label: "+20" },
  { value: "+211", label: "+211" },
  { value: "+213", label: "+213" },
  { value: "+216", label: "+216" },
  { value: "+218", label: "+218" },
  { value: "+220", label: "+220" },
  { value: "+221", label: "+221" },
  { value: "+222", label: "+222" },
  { value: "+223", label: "+223" },
  { value: "+224", label: "+224" },
  { value: "+225", label: "+225" },
  { value: "+226", label: "+226" },
  { value: "+227", label: "+227" },
  { value: "+228", label: "+228" },
  { value: "+229", label: "+229" },
  { value: "+230", label: "+230" },
  { value: "+231", label: "+231" },
  { value: "+232", label: "+232" },
  { value: "+233", label: "+233" },
  { value: "+234", label: "+234" },
  { value: "+235", label: "+235" },
  { value: "+236", label: "+236" },
  { value: "+237", label: "+237" },
  { value: "+238", label: "+238" },
  { value: "+239", label: "+239" },
  { value: "+240", label: "+240" },
  { value: "+241", label: "+241" },
  { value: "+242", label: "+242" },
  { value: "+243", label: "+243" },
  { value: "+244", label: "+244" },
  { value: "+245", label: "+245" },
  { value: "+246", label: "+246" },
  { value: "+247", label: "+247" },
  { value: "+248", label: "+248" },
  { value: "+249", label: "+249" },
  { value: "+250", label: "+250" },
  { value: "+251", label: "+251" },
  { value: "+252", label: "+252" },
  { value: "+253", label: "+253" },
  { value: "+254", label: "+254" },
  { value: "+255", label: "+255" },
  { value: "+256", label: "+256" },
  { value: "+257", label: "+257" },
  { value: "+258", label: "+258" },
  { value: "+260", label: "+260" },
  { value: "+261", label: "+261" },
  { value: "+262", label: "+262" },
  { value: "+263", label: "+263" },
  { value: "+264", label: "+264" },
  { value: "+265", label: "+265" },
  { value: "+266", label: "+266" },
  { value: "+267", label: "+267" },
  { value: "+268", label: "+268" },
  { value: "+269", label: "+269" },
  { value: "+27", label: "+27" },
  { value: "+290", label: "+290" },
  { value: "+291", label: "+291" },
  { value: "+297", label: "+297" },
  { value: "+298", label: "+298" },
  { value: "+299", label: "+299" },
  { value: "+30", label: "+30" },
  { value: "+31", label: "+31" },
  { value: "+32", label: "+32" },
  { value: "+33", label: "+33" },
  { value: "+34", label: "+34" },
  { value: "+350", label: "+350" },
  { value: "+351", label: "+351" },
  { value: "+352", label: "+352" },
  { value: "+353", label: "+353" },
  { value: "+354", label: "+354" },
  { value: "+355", label: "+355" },
  { value: "+356", label: "+356" },
  { value: "+357", label: "+357" },
  { value: "+358", label: "+358" },
  { value: "+359", label: "+359" },
  { value: "+36", label: "+36" },
  { value: "+370", label: "+370" },
  { value: "+371", label: "+371" },
  { value: "+372", label: "+372" },
  { value: "+373", label: "+373" },
  { value: "+374", label: "+374" },
  { value: "+375", label: "+375" },
  { value: "+376", label: "+376" },
  { value: "+377", label: "+377" },
  { value: "+378", label: "+378" },
  { value: "+379", label: "+379" },
  { value: "+380", label: "+380" },
  { value: "+381", label: "+381" },
  { value: "+382", label: "+382" },
  { value: "+385", label: "+385" },
  { value: "+386", label: "+386" },
  { value: "+387", label: "+387" },
  { value: "+389", label: "+389" },
  { value: "+39", label: "+39" },
  { value: "+40", label: "+40" },
  { value: "+41", label: "+41" },
  { value: "+420", label: "+420" },
  { value: "+421", label: "+421" },
  { value: "+422", label: "+422" },
  { value: "+423", label: "+423" },
  { value: "+43", label: "+43" },
  { value: "+44", label: "+44" },
  { value: "+45", label: "+45" },
  { value: "+46", label: "+46" },
  { value: "+47", label: "+47" },
  { value: "+48", label: "+48" },
  { value: "+49", label: "+49" },
  { value: "+500", label: "+500" },
  { value: "+501", label: "+501" },
  { value: "+502", label: "+502" },
  { value: "+503", label: "+503" },
  { value: "+504", label: "+504" },
  { value: "+505", label: "+505" },
  { value: "+506", label: "+506" },
  { value: "+507", label: "+507" },
  { value: "+508", label: "+508" },
  { value: "+509", label: "+509" },
  { value: "+51", label: "+51" },
  { value: "+52", label: "+52" },
  { value: "+53", label: "+53" },
  { value: "+54", label: "+54" },
  { value: "+55", label: "+55" },
  { value: "+56", label: "+56" },
  { value: "+57", label: "+57" },
  { value: "+58", label: "+58" },
  { value: "+590", label: "+590" },
  { value: "+591", label: "+591" },
  { value: "+592", label: "+592" },
  { value: "+593", label: "+593" },
  { value: "+594", label: "+594" },
  { value: "+595", label: "+595" },
  { value: "+596", label: "+596" },
  { value: "+597", label: "+597" },
  { value: "+598", label: "+598" },
  { value: "+599", label: "+599" },
  { value: "+60", label: "+60" },
  { value: "+61", label: "+61" },
  { value: "+62", label: "+62" },
  { value: "+63", label: "+63" },
  { value: "+64", label: "+64" },
  { value: "+65", label: "+65" },
  { value: "+66", label: "+66" },
  { value: "+670", label: "+670" },
  { value: "+672", label: "+672" },
  { value: "+673", label: "+673" },
  { value: "+674", label: "+674" },
  { value: "+675", label: "+675" },
  { value: "+676", label: "+676" },
  { value: "+677", label: "+677" },
  { value: "+678", label: "+678" },
  { value: "+679", label: "+679" },
  { value: "+680", label: "+680" },
  { value: "+681", label: "+681" },
  { value: "+682", label: "+682" },
  { value: "+683", label: "+683" },
  { value: "+685", label: "+685" },
  { value: "+686", label: "+686" },
  { value: "+687", label: "+687" },
  { value: "+688", label: "+688" },
  { value: "+689", label: "+689" },
  { value: "+690", label: "+690" },
  { value: "+691", label: "+691" },
  { value: "+692", label: "+692" },
  { value: "+7", label: "+7" },
  { value: "+9", label: "+9" },
];

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
};

export function convertToDateSortableFormat(dateString) {
  const croatianMonths = [
    "siječanj",
    "veljača",
    "ožujak",
    "travanj",
    "svibanj",
    "lipanj",
    "srpanj",
    "kolovoz",
    "rujan",
    "listopad",
    "studeni",
    "prosinac",
  ];
  const parts = dateString?.split("-");
  const year = parts && parseInt(parts[0], 10);
  const monthIndex = parts && parseInt(parts[1], 10) - 1;
  const day = parts && parseInt(parts[2], 10).toString().padStart(2, "0");

  if (monthIndex >= 0 && monthIndex < croatianMonths.length) {
    const month = croatianMonths[monthIndex];
    const formattedDate = `${day}.${month} ${year}.`;
    return formattedDate;
  } else {
    console.error("Invalid month index:", monthIndex);
    return "Invalid Date";
  }
}
export const strucnaSpremaOptions = [
  { label: "Odaberi", value: "" },
  { label: "Doktor znanosti", value: "Doktor znanosti" },
  { label: "Magistar znanosti", value: "Magistar znanosti" },
  { label: "Sveučilišni specijalist", value: "Sveučilišni specijalist" },
  { label: "Magistar struke", value: "Magistar struke" },
  { label: "Stručni specijalist", value: "Stručni specijalist" },
  { label: "VSS", value: "VSS" },
  { label: "Sveučilišni prvostupnik", value: "Sveučilišni Prvostupnik" },
  { label: "Stručni prvostupnik", value: "Stručni Prvostupnik" },
  { label: "VŠS", value: "VŠS" },
  {
    label: "Četverogodišnja srednja škola",
    value: "Četverogodisnja srednja škola",
  },
  { label: "Trogodišnja srednja škola", value: "Trogodišnja srednja škola" },
  { label: "Osnovna škola i manje", value: "Osnovna škola i manje" },
];
export const handleSave = async (
  selectedValue,
  myProfileData,
  setEmptyFields,
  userData,
  authToken,
  setModalContent,
  setShowModal,
  setUserData,
  setLoading,
  numericPhoneNumber,
  uploadedFile,
  apiUrl,
  navigate,
  changeIsFirstLogin,
  setEditMode
  // selectedAcademicDegree,
  // selectedCriminalRecord
) => {
  const newEmptyFields = [];
  if (!selectedValue) newEmptyFields.push("pozivni broj");
  Object.entries(myProfileData).forEach(([key, value]) => {
    if (value === "" || value === null || value.length === 0) {
      newEmptyFields.push(key);
    }
  });
  setEmptyFields(newEmptyFields);
  if (newEmptyFields.length !== 0) {
    const updatedEmptyFields = newEmptyFields.map((key) => {
      switch (key) {
        case "firstName":
          return "ime";
        case "lastName":
          return "prezime";
        case "dateOfBirth":
          return "datum rođenja";
        case "gender":
          return "spol";
        case "city":
          return "mjesto stanovanja";
        case "address":
          return "adresa stanovanja";
        case "country":
          return "država stanovanja";
        case "programCity":
          return "grad pohađanja programa";
        case "qualification":
          return "stručna sprema";
        case "title":
          return "zvanje";
        case "phoneNumber":
          return "broj mobitela";
        case "imageUrl":
          return "slika";
        // case "academicDegree":
        //   return "Akademska diploma";
        // case "criminalRecord":
        //   return "Potvrda o nekažnjavanju";
        default:
          return key;
      }
    });
    alert(
      "Molimo vas da ispunite sva polja (" + updatedEmptyFields.join(", ") + ")"
    );
  } else {
    await handleSubmit(
      userData,
      myProfileData,
      authToken,
      setModalContent,
      setShowModal,
      setUserData,
      setLoading,
      selectedValue,
      numericPhoneNumber,
      uploadedFile,
      apiUrl,
      navigate,
      changeIsFirstLogin,
      setEditMode
      // selectedAcademicDegree,
      // selectedCriminalRecord
    );
  }
};
export const handleSubmit = async (
  userData,
  myProfileData,
  accessToken,
  setModalContent,
  setShowModal,
  setUserData,
  setLoading,
  selectedValue,
  numericPhoneNumber,
  uploadedFile,
  apiUrl,
  navigate,
  changeIsFirstLogin,
  setEditMode
  // selectedAcademicDegree,
  // selectedCriminalRecord
) => {
  try {
    const newData = {
      id: userData?.id,
      bio: userData?.bio,
      therapyType: userData?.therapyType,
      firstName: myProfileData.firstName,
      lastName: myProfileData.lastName,
      dateOfBirth: myProfileData.dateOfBirth,
      gender: myProfileData.gender,
      city: myProfileData.city,
      address: myProfileData.address,
      country: myProfileData.country,
      programCity: myProfileData.programCity,
      qualification: myProfileData.qualification,
      title: myProfileData.title,
      OIB: myProfileData.OIB,
      phoneNumber: selectedValue + myProfileData.phoneNumber,
      emailAddress: myProfileData.emailAddress,
      isZakupitelj: userData?.isZakupitelj,
      firebaseId: userData?.firebaseId,
      roleId: userData?.roleId,
    };
    const formData = new FormData();
    formData.append("image", uploadedFile);

    if (uploadedFile) {
      await axios.put(`${apiUrl}/api/user/${userData?.id}/image`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    // Ovo vratiti kada bude potreban obavezan upload potvrda
    // const certificates = [
    //   {
    //     file:
    //       selectedAcademicDegree && selectedAcademicDegree instanceof File
    //         ? selectedAcademicDegree
    //         : null,
    //     isAcademic: true,
    //     previousFile: userData?.academicDegree,
    //   },
    //   {
    //     file:
    //       selectedCriminalRecord && selectedCriminalRecord instanceof File
    //         ? selectedCriminalRecord
    //         : null,
    //     isAcademic: false,
    //     previousFile: userData?.criminalRecord,
    //   },
    // ];
    // for (const { file, isAcademic, previousFile } of certificates) {
    //   if (!file) {
    //     console.log(
    //       `Skipping upload for ${isAcademic ? "Academic Degree" : "Criminal Record"} - No new file selected.`
    //     );
    //     continue;
    //   }

    //   const previousFileName = previousFile
    //     ? previousFile.split("/").pop().split("?")[0]
    //     : null;
    //   if (previousFileName && file.name === previousFileName) {
    //     console.log(
    //       `Skipping upload for ${isAcademic ? "Academic Degree" : "Criminal Record"} - No change in file.`
    //     );
    //     continue;
    //   }
    //   try {
    //     const formData = new FormData();
    //     formData.append("certificate", file);
    //     formData.append("isAcademic", JSON.stringify(isAcademic));

    //     const response = await axios.put(
    //       `${apiUrl}/api/user/${userData?.id}/certificate`,
    //       formData,
    //       {
    //         headers: { Authorization: `Bearer ${accessToken}` },
    //       }
    //     );

    //     console.log("Certificate uploaded successfully:", response.data);
    //   } catch (error) {
    //     console.error(
    //       `Failed to upload ${isAcademic ? "academic degree" : "criminal record"}:`,
    //       error
    //     );
    //   }
    // }
    const response = await axios.put(
      `${apiUrl}/api/user/${userData?.id}`,
      newData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const responseData = response.data;
    if (response.status === 200) {
      setModalContent({
        image: Success,
        description: "Tvoje promjene su uspješno zabilježene!",
        buttons: [
          {
            label: "U redu",
            action: () => {
              setShowModal(false);
              setEditMode(false);
              if (!responseData.firstLogin) {
                window.location.reload();
              }
            },
            className: "modal-btn-success",
          },
        ],
      });
      if (!responseData.firstLogin) {
        setShowModal(true);
        await axios
          .get(`${process.env.REACT_APP_API_URL}/api/user`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(async (data) => {
            setUserData(data.data);
          });
      } else {
        setEditMode(false);
        changeIsFirstLogin(accessToken)
          .then(() => {
            navigate("/postavke");
          })
          .catch(() => console.log("error"));
      }
    } else {
      console.log("Error occurred:", responseData.opis);
    }
  } catch (error) {
    console.error("Error occurred during submission:", error);
  } finally {
    setLoading(false);
  }
};
export const fetchData = async (
  apiUrl,
  accessToken,
  setGroupData,
  setMjestoUpisaOptions,
  userData
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/groups/user?userId=${userData?.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const resData = response.data;
    setGroupData(resData);
    if (setMjestoUpisaOptions !== 0) {
      const updatedMjestoUpisa = await fetchCities(accessToken);
      setMjestoUpisaOptions(updatedMjestoUpisa);
    }
  } catch (error) {
    console.error("Error in fetchData:", error);
  }
};

export const getActivePrograms = async (
  apiUrl,
  accessToken,
  setActivePrograms
) => {
  try {
    const response = await axios.get(`${apiUrl}/api/active-programs`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resData = response.data;
    setActivePrograms(resData);
  } catch (error) {
    console.error("Error getting active programs:", error);
  }
};

export const getActiveProgramsByUserId = async (
  apiUrl,
  accessToken,
  setActivePrograms,
  id
) => {
  try {
    const response = await axios.get(`${apiUrl}/api/active-programs/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const resData = response.data;
    setActivePrograms(resData);
  } catch (error) {
    console.error("Error getting active programs:", error);
  }
};

export const programsByIds = [
  { value: [1, 2], label: "RAR" },
  { value: [3, 4, 5, 6, 7, 8, 9, 10], label: "OR" },
  { value: [13, 14], label: "TP" },
  { value: [11, 12], label: "TT" },
  { value: [15], label: "Roditeljstvo" },
  { value: [16], label: "RPO" },
  { value: [17], label: "VT" },
];

export const backgroundColorMap = {
  RAR: "#87AD76",
  OR: "#CBA855",
  TT: "#A35254",
  TP: "#D64D51",
  Roditeljstvo: "#7BBA9F",
  VT: "#B8834F",
  RPO: "#3A54A1",
};

export const myProfileTabs = [
  { value: "one", label: "Osnovni podatci" },
  { value: "two", label: "Akademska diploma" },
  { value: "three", label: "Potvrda o nekažnjavanju" },
];
export const handleReset = (
  setMyProfileData,
  userData,
  setSelectedDegreeName,
  setSelectedRecordName
) => {
  setMyProfileData((prev) => ({
    ...prev,
    academicDegree: userData?.academicDegree,
    criminalRecord: userData?.criminalRecord,
  }));
  setSelectedDegreeName("");
  setSelectedRecordName("");
};
