// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObracunComponent_wrapper__E\\+XXr {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-top: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 24px;
}
.ObracunComponent_wrapper__E\\+XXr,
.ObracunComponent_alert__EtNMg {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
}
.ObracunComponent_title__GbtlO {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}
.ObracunComponent_label__hM6Pg {
  color: #ffffff;
}
.ObracunComponent_title__GbtlO,
.ObracunComponent_label__hM6Pg,
.ObracunComponent_alert__EtNMg {
  font-family: Inter;
  letter-spacing: -0.001em;
  text-align: left;
}
.ObracunComponent_label__hM6Pg,
.ObracunComponent_alert__EtNMg {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.ObracunComponent_alert__EtNMg {
  width: 97.2%;
  gap: 16px;
  padding: 12px 24px 12px 24px;
  background-color: #cfe3c5;
  color: #5f6d7e;
}
.ObracunComponent_alertIcon__vW1sc {
  width: 16px;
  height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/obracunSoba/components/ObracunComponent/ObracunComponent.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;;;EAGE,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;AAClB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,SAAS;EACT,4BAA4B;EAC5B,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".wrapper {\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n  padding-top: 24px;\n  border-radius: 8px;\n  margin-bottom: 24px;\n  margin-top: 24px;\n}\n.wrapper,\n.alert {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow-x: scroll;\n}\n.title {\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 24px;\n  color: #ffffff;\n}\n.label {\n  color: #ffffff;\n}\n.title,\n.label,\n.alert {\n  font-family: Inter;\n  letter-spacing: -0.001em;\n  text-align: left;\n}\n.label,\n.alert {\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n}\n.alert {\n  width: 97.2%;\n  gap: 16px;\n  padding: 12px 24px 12px 24px;\n  background-color: #cfe3c5;\n  color: #5f6d7e;\n}\n.alertIcon {\n  width: 16px;\n  height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ObracunComponent_wrapper__E+XXr`,
	"alert": `ObracunComponent_alert__EtNMg`,
	"title": `ObracunComponent_title__GbtlO`,
	"label": `ObracunComponent_label__hM6Pg`,
	"alertIcon": `ObracunComponent_alertIcon__vW1sc`
};
export default ___CSS_LOADER_EXPORT___;
