import React, { useState } from "react";
import { useUserState } from "../../../../../../context/UserContext";
import { useAuthentication } from "../../../../../hooks/useAuthentication";
import { formatDate } from "../../../../../myProfile/utils";
import KomentarModal from "../KomentarModal/KomentarModal";

function RenderTableData({
  data = [],
  evidentionUserID,
  change,
  setChange,
  getSupervisionEntry,
}) {
  const { userData } = useUserState();
  const { authToken } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [reacOnly, setReadOnly] = useState(false);
  const [supId, setSupId] = useState();

  const getSupervisionType = (type) => {
    switch (type) {
      case "individual_supervision":
        return "Individualna supervizija";
      case "individual":
        return "Individualna supervizija";
      case "ags":
        return "AGS";
      case "eper":
        return "EPER";
      case "trijada":
        return "Trijada";
      default:
        return "Nema podataka";
    }
  };
  return (
    <>
      {data.map((item, index) => {
        return (
          <tr key={index}>
            <td>
              {item?.otherInfo.date
                ? formatDate(item?.otherInfo.date)
                : "Nema podataka"}
            </td>
            <td>
              {item?.type ? getSupervisionType(item?.type) : "Nema podataka"}
            </td>
            <td>
              <div className="table-evaluacije-row">
                {item?.otherInfo?.fullName
                  ? `${item?.otherInfo?.fullName} `
                  : "Nema podataka"}
              </div>
            </td>
            <td>
              {
                <>
                  {item?.comment ? (
                    <>
                      <span
                        className="table-nastupna-predavanja-comment-span"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "48ch",
                        }}
                      >
                        {"Evaluacija je dostupna kliknite prikaži više"}
                      </span>
                      {item?.comment && (
                        <div
                          onClick={() => {
                            setSelectedText(item?.comment);
                            setReadOnly(true);
                            setShowModal(true);
                          }}
                          className="table-nastupna-predavanja-toggle"
                        >
                          {"Prikaži više"}
                        </div>
                      )}
                    </>
                  ) : (
                    <span className="table-evaluacije-comment">
                      Evaluacija trenutno nije dodana.
                    </span>
                  )}
                </>
              }
            </td>
            <td>
              {
                <>
                  {(`${userData.firstName} ${userData.lastName}` ===
                    item?.otherInfo?.fullName ||
                    userData.roleId === 6) && (
                    <button
                      className="table-nastupna-predavanja-btn"
                      onClick={() => {
                        setSelectedText(item?.comment);
                        setSupId(item?.id);
                        setReadOnly(false);
                        setShowModal(true);
                      }}
                    >
                      {item.comment ? "Uredi evaluaciju" : "Dodaj evaluaciju"}
                    </button>
                  )}
                </>
              }
            </td>
          </tr>
        );
      })}
      {showModal && (
        <KomentarModal
          onClose={() => setShowModal(false)}
          evidentionUserID={evidentionUserID}
          authToken={authToken}
          commentText={selectedText}
          readOnly={reacOnly}
          supId={supId}
          getSupervisionEntry={getSupervisionEntry}
          change={change}
          setChange={setChange}
        />
      )}
    </>
  );
}

export default RenderTableData;
