// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Attendees_dropdownMoreOptionsRow__qd8MH {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.Attendees_paginationContainer__B7tnJ {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #d1d9e2;
  border-right: 1px solid #d1d9e2;
  border-bottom: 1px solid #d1d9e2;
  padding: 16px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/attendees/Attendees.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B;EAC9B,+BAA+B;EAC/B,gCAAgC;EAChC,iBAAiB;AACnB","sourcesContent":[".dropdownMoreOptionsRow {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 12px;\n}\n.paginationContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-left: 1px solid #d1d9e2;\n  border-right: 1px solid #d1d9e2;\n  border-bottom: 1px solid #d1d9e2;\n  padding: 16px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownMoreOptionsRow": `Attendees_dropdownMoreOptionsRow__qd8MH`,
	"paginationContainer": `Attendees_paginationContainer__B7tnJ`
};
export default ___CSS_LOADER_EXPORT___;
