// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OsobniRadKlijentiGrupe_raniRazvojPrvaSekcija2__WqZhN {
  margin-top: 64px;
  background-color: rgba(55, 150, 252, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}
.OsobniRadKlijentiGrupe_raniRazvojHeader__OUMuY {
  color: #4d4d4d;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.028px;
}
.OsobniRadKlijentiGrupe_raniRazvojDescription__xGhv1,
.OsobniRadKlijentiGrupe_raniRazvojDescription2__s-qjE {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.014px;
}
.OsobniRadKlijentiGrupe_radKlijentiGrupeAccordion__j7pZ4 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
.OsobniRadKlijentiGrupe_osobniRadKlijentiGrupAccordion__RT38b {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/evidencija/components/OsobniRadKlijentiGrupe/OsobniRadKlijentiGrupe.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;;EAEE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB","sourcesContent":[".raniRazvojPrvaSekcija2 {\n  margin-top: 64px;\n  background-color: rgba(55, 150, 252, 0.1);\n  border-radius: 8px;\n  padding: 20px;\n  margin-bottom: 20px;\n}\n.raniRazvojHeader {\n  color: #4d4d4d;\n  font-family: Inter;\n  font-size: 28px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.028px;\n}\n.raniRazvojDescription,\n.raniRazvojDescription2 {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px;\n  letter-spacing: -0.014px;\n}\n.radKlijentiGrupeAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n.osobniRadKlijentiGrupAccordion {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"raniRazvojPrvaSekcija2": `OsobniRadKlijentiGrupe_raniRazvojPrvaSekcija2__WqZhN`,
	"raniRazvojHeader": `OsobniRadKlijentiGrupe_raniRazvojHeader__OUMuY`,
	"raniRazvojDescription": `OsobniRadKlijentiGrupe_raniRazvojDescription__xGhv1`,
	"raniRazvojDescription2": `OsobniRadKlijentiGrupe_raniRazvojDescription2__s-qjE`,
	"radKlijentiGrupeAccordion": `OsobniRadKlijentiGrupe_radKlijentiGrupeAccordion__j7pZ4`,
	"osobniRadKlijentiGrupAccordion": `OsobniRadKlijentiGrupe_osobniRadKlijentiGrupAccordion__RT38b`
};
export default ___CSS_LOADER_EXPORT___;
