// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Groups_cardWrapper__nNjXE {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}
.Groups_numberOfMentorships__RVX6t {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.016px;
  color: #9fa7b2;
}
.Groups_numberOfMentorships__RVX6t,
.Groups_noGroupsWrapper__ZhR4h {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
}
.Groups_noGroupsWrapper__ZhR4h {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 58px;
  background-color: #ffebeb;
  padding: 12px 24px;
  width: 100%;
  color: #5f6d7e;
  font-size: 14px;
  line-height: normal;
}
.Groups_noGroupsIcon__eNs8B {
  width: 16px;
  height: 16px;
}

.Groups_loadingContainer__7SUjc {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/groups/Groups.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,SAAS;EACT,WAAW;AACb;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,cAAc;AAChB;AACA;;EAEE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,cAAc;EACd,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".cardWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  gap: 16px;\n  width: 100%;\n}\n.numberOfMentorships {\n  font-size: 16px;\n  line-height: 22px;\n  letter-spacing: -0.016px;\n  color: #9fa7b2;\n}\n.numberOfMentorships,\n.noGroupsWrapper {\n  font-family: Inter;\n  font-style: normal;\n  font-weight: 500;\n}\n.noGroupsWrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  gap: 16px;\n  margin-top: 58px;\n  background-color: #ffebeb;\n  padding: 12px 24px;\n  width: 100%;\n  color: #5f6d7e;\n  font-size: 14px;\n  line-height: normal;\n}\n.noGroupsIcon {\n  width: 16px;\n  height: 16px;\n}\n\n.loadingContainer {\n  height: 90vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": `Groups_cardWrapper__nNjXE`,
	"numberOfMentorships": `Groups_numberOfMentorships__RVX6t`,
	"noGroupsWrapper": `Groups_noGroupsWrapper__ZhR4h`,
	"noGroupsIcon": `Groups_noGroupsIcon__eNs8B`,
	"loadingContainer": `Groups_loadingContainer__7SUjc`
};
export default ___CSS_LOADER_EXPORT___;
