// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalOverlay__ZrB5u {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: #000000d6;
  z-index: 100;
}
.Modal_modalWrapper__ncah9 {
  flex-direction: column;
}
.Modal_modalOverlay__ZrB5u,
.Modal_modalWrapper__ncah9,
.Modal_modalButtons__aiLDz,
.Modal_infoModal__z\\+B9i {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Modal_infoModal__z\\+B9i {
  flex-direction: column;
  gap: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #ffffff;
}
.Modal_modalTitle__JvsLa {
  font-size: 20px;
}
.Modal_modalDescription__uJ4eb {
  font-size: 16px;
}
.Modal_modalButtons__aiLDz {
  width: 100%;
  gap: 16px;
}
.Modal_modalImage__jVpCY {
  width: 72px;
  height: 72px;
}

@media screen and (max-width: 1300px) {
  .Modal_modalOverlay__ZrB5u {
    width: 100% !important;
    left: 0 !important;
  }
  .Modal_modalWrapper__ncah9 {
    padding: 0 5%;
  }
  .Modal_modalTitle__JvsLa,
  .Modal_modalDescription__uJ4eb {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,YAAY;EACZ,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,sBAAsB;AACxB;AACA;;;;EAIE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;EACX,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,aAAa;EACf;EACA;;IAEE,kBAAkB;EACpB;AACF","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  height: 100%;\n  background-color: #000000d6;\n  z-index: 100;\n}\n.modalWrapper {\n  flex-direction: column;\n}\n.modalOverlay,\n.modalWrapper,\n.modalButtons,\n.infoModal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.infoModal {\n  flex-direction: column;\n  gap: 16px;\n  font-family: Inter;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: -0.02px;\n  color: #ffffff;\n}\n.modalTitle {\n  font-size: 20px;\n}\n.modalDescription {\n  font-size: 16px;\n}\n.modalButtons {\n  width: 100%;\n  gap: 16px;\n}\n.modalImage {\n  width: 72px;\n  height: 72px;\n}\n\n@media screen and (max-width: 1300px) {\n  .modalOverlay {\n    width: 100% !important;\n    left: 0 !important;\n  }\n  .modalWrapper {\n    padding: 0 5%;\n  }\n  .modalTitle,\n  .modalDescription {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `Modal_modalOverlay__ZrB5u`,
	"modalWrapper": `Modal_modalWrapper__ncah9`,
	"modalButtons": `Modal_modalButtons__aiLDz`,
	"infoModal": `Modal_infoModal__z+B9i`,
	"modalTitle": `Modal_modalTitle__JvsLa`,
	"modalDescription": `Modal_modalDescription__uJ4eb`,
	"modalImage": `Modal_modalImage__jVpCY`
};
export default ___CSS_LOADER_EXPORT___;
