// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Završeni_emptyContainer__fW8ln {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 32px;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 4px 4px 64px 0px #5f6d7e1a;
  background-color: #fcfcfc;
  box-sizing: border-box;
}
.Završeni_emptySpan__k6MAQ {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #5f6d7e;
}
.Završeni_wrapper__\\+6DJk {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
@media screen and (max-width: 1300px) {
  .Završeni_emptyContainer__fW8ln {
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/programi/PolaznikVoditelj/Završeni/Zavr%C5%A1eni.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,sCAAsC;EACtC,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;EACX,SAAS;AACX;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".emptyContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  padding: 32px;\n  border: 2px solid #e2e2e2;\n  border-radius: 8px;\n  box-shadow: 4px 4px 64px 0px #5f6d7e1a;\n  background-color: #fcfcfc;\n  box-sizing: border-box;\n}\n.emptySpan {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #5f6d7e;\n}\n.wrapper {\n  display: flex;\n  align-items: baseline;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n  gap: 24px;\n}\n@media screen and (max-width: 1300px) {\n  .emptyContainer {\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyContainer": `Završeni_emptyContainer__fW8ln`,
	"emptySpan": `Završeni_emptySpan__k6MAQ`,
	"wrapper": `Završeni_wrapper__+6DJk`
};
export default ___CSS_LOADER_EXPORT___;
