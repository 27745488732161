import Quill from "quill";
import ImageCompress from "quill-image-compress";
import "quill/dist/quill.snow.css";
import React, { useEffect, useRef, useState } from "react";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import "./QuillEditor.css";

function QuillEditor({
  answer,
  description,
  setUserAnswer,
  userId,
  handleUpdate,
  setIsDone,
  isMentorstva,
  index,
  isContainerShowed,
  isReadOnly,
  setIsMaxFlag,
}) {
  Quill.register("modules/imageCompress", ImageCompress);
  let quillContainerRef = useRef(null);
  let quillInstanceRef = useRef(null);

  const minCharsCount = 1300; //minimalni broj slova u introspekciji
  const maxCharsCount = 4000; //maksimalan broj slova u introspekciji

  const [prevAnswer, setPrevAnswer] = useState("");
  const [testis, setTestis] = useState("");
  const [charsCount, setCharsCount] = useState(0);
  const [maxFlag, setMaxFlag] = useState(false);

  useEffect(() => {
    if (answer) setPrevAnswer(answer);
  }, [answer]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        prevAnswer &&
        testis !== prevAnswer &&
        !isMentorstva &&
        charsCount < maxCharsCount
      ) {
        setPrevAnswer(testis);
        handleUpdate();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [testis, prevAnswer, charsCount]);

  useEffect(() => {
    if (
      quillContainerRef.current &&
      !quillInstanceRef.current &&
      isContainerShowed
    ) {
      quillInstanceRef.current = new Quill(quillContainerRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ font: ["serif", "monospace"] }],
            [{ header: [1, 2, 3, 4, false] }],
            ["bold", "italic", "underline", "blockquote"],
            [{ color: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            ["image"],
            [".quill-toolbar"],
          ],
          imageCompress: {
            quality: 0.7,
            maxWidth: 1000,
            maxHeight: 1000,
            imageType: "image/jpeg",
            debug: false,
            suppressErrorLogging: false,
            insertIntoEditor: undefined,
          },
        },
        readOnly: false,
      });

      const handleKeydown = (event) => {
        if (event.key === "Enter") {
          updateCharacterCount();
        }
      };

      quillInstanceRef.current.on("text-change", (delta) => {
        const plainText = quillInstanceRef.current
          .getText()
          .replace(/\s/g, "")
          .trim();
        const textLength = plainText.length;

        if (textLength > maxCharsCount) {
          quillInstanceRef.current.deleteText(maxCharsCount, textLength);
        }

        if (textLength >= maxCharsCount && delta.ops.some((op) => op.insert)) {
          quillInstanceRef.current.undo();
        } else {
          const html = quillInstanceRef.current.root.innerHTML;
          setUserAnswer(html);
          setTestis(html);
          updateCharacterCount();
          setMaxFlag(false);
        }
      });
      quillInstanceRef.current.root.addEventListener("keydown", handleKeydown);

      return () => {
        quillInstanceRef.current.root.removeEventListener(
          "keydown",
          handleKeydown
        );
      };
    }

    if (quillInstanceRef.current) {
      quillInstanceRef.current.root.innerHTML = answer;
    }
  }, [quillContainerRef, answer, userId]);

  const updateCharacterCount = () => {
    const plainText = quillInstanceRef.current.root.innerText.trim();
    const textLength = plainText.length;

    setCharsCount(textLength);

    if (textLength > maxCharsCount) {
      setMaxFlag(true);
      setIsDone(false);
      setIsMaxFlag(true);
    } else if (textLength < minCharsCount) {
      setIsDone(false);
    } else {
      setIsDone(true);
      setIsMaxFlag(false);
    }
  };

  return (
    <>
      {description && description[0]?.props?.children?.length > 0 && (
        <div className="introspekcije-description">
          {description[0].props.children.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      )}
      {isContainerShowed ? (
        !isMentorstva ? (
          <>
            <div id="quill-toolbar" className="quill-toolbar"></div>
            <div
              translate="no"
              ref={quillContainerRef}
              id="quill-container"
              style={
                maxFlag
                  ? {
                      height: "400px",
                      width: "100%",
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                      border: "2px solid #f00",
                    }
                  : {
                      height: "400px",
                      width: "100%",
                      borderBottomLeftRadius: 8,
                      borderBottomRightRadius: 8,
                    }
              }
            />
            {userId === "Polaznik" && (
              <div
                className="introspekcije-description"
                style={{ width: "100%", textAlign: "right", fontSize: 15 }}
              >
                <span
                  style={
                    charsCount < minCharsCount || charsCount > maxCharsCount
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {charsCount}
                </span>
                /<span>{maxCharsCount}</span>
              </div>
            )}
          </>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: answer }}></span>
        )
      ) : (
        <div
          style={{
            fontFamily: "Inter",
            fontWeight: 500,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 16,
            backgroundColor: "#ffebeb",
            padding: "12px 24px",
            width: "96.5%",
            color: "#5f6d7e",
            fontSize: 14,
            lineHeight: "normal",
            borderRadius: 6,
            marginTop: 20,
          }}
        >
          <img alt="" src={InfoIcon} style={{ width: 16, height: 16 }} />
          Korisnik još nije napisao ovu introspekciju!
        </div>
      )}
    </>
  );
}

export default QuillEditor;
