import React, { useEffect, useMemo, useState } from "react";
import SearchIcon from "../../../../assets/svg/SearchIcon.svg";
import Pagination from "../../../../components/Pagination/Pagination";
import { useUserState } from "../../../../context/UserContext";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { getSchoolYear } from "../../api/getSchoolYear";
import { getVoditelji } from "../../api/getVoditelji";
import { updateSchoolYear } from "../../api/updateSchoolYear";
import styles from "./PregledOstalihVoditelja.module.css";
import TableOsvrti from "./Table/TableOsvrti";

function PregledOstalihVoditelja() {
  const { authToken } = useAuthentication();
  const { userData, role } = useUserState();
  const columnNames = ["Ime i prezime", "Ukupna evaluacija", "Link na osvrt"];
  const [currentPage, setCurrentPage] = useState(1);
  const [schoolYear, setSchoolYear] = useState("");
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [voditeljiData, setVoditeljiData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getVoditelji(authToken);
        const updatedResponse = response.filter(
          (voditelj) => voditelj.id !== userData.id
        );
        setVoditeljiData(updatedResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchData();
  }, [authToken]);

  useEffect(() => {
    const fetchSchoolYear = async () => {
      try {
        const response = await getSchoolYear(authToken);
        setSchoolYear(response[0].date);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (authToken) fetchSchoolYear();
  }, [authToken, isDateChanged]);

  const onDateChange = (e) => {
    const date = e.target.value;
    setNewDate(date);
    setIsDateChanged(true);
  };

  const onSave = async () => {
    const date = {
      date: newDate,
    };
    await updateSchoolYear(date, authToken);
    setIsDateChanged(false);
  };

  const filteredVoditelji = useMemo(() => {
    return voditeljiData.filter((voditelj) => {
      const fullName =
        `${voditelj.firstName} ${voditelj.lastName}`.toLowerCase();
      return (
        voditelj.firstName.toLowerCase().includes(searchText) ||
        voditelj.lastName.toLowerCase().includes(searchText) ||
        fullName.includes(searchText)
      );
    });
  }, [voditeljiData, searchText]);

  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOffers = filteredVoditelji.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <div style={{ padding: "10px" }}>
      <label>
        <img alt="search" src={SearchIcon} className={styles.searchIcon} />
        <input
          type="search"
          placeholder="Pretraži"
          className={styles.searchInput}
          onChange={handleSearchChange}
          value={searchText}
        />
      </label>
      {role === "Referada" && (
        <div className={styles.dateChangeContainer}>
          <span className={styles.dateChangeText}>
            Promjeni datum polugodišta:
          </span>{" "}
          <input
            type="date"
            className={styles.dateChangeInput}
            value={isDateChanged ? newDate : schoolYear}
            onChange={onDateChange}
          />
          {isDateChanged && (
            <div className={styles.btnContainer}>
              <button
                onClick={() => {
                  setIsDateChanged(false);
                  setNewDate(schoolYear);
                }}
                className={styles.cancelButton}
              >
                Odustani
              </button>
              <button className={styles.saveButton} onClick={() => onSave()}>
                Spremi
              </button>
            </div>
          )}
        </div>
      )}
      <TableOsvrti columnNames={columnNames} data={currentOffers} />
      <div className={styles.paginationContainer}>
        <Pagination
          itemsToDisplay={filteredVoditelji}
          setCurrent={setCurrentPage}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </div>
  );
}

export default PregledOstalihVoditelja;
