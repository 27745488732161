export const questionMappingOR = {
  questionOr1: "Opiši svoje trenutno radno mjesto",
  questionOr2:
    "Navedi ako imaš dosadašnje iskustvo u savjetodavnim, psihoterapijskim ili sličnim edukacijama za rad s ljudima kao i u neposrednom stručnom radu s ljudima.",
  questionOr3:
    "Navedi ako imaš svoja osobna iskustva u radu na sebi i psihoterapiji.",
  questionOr4: "Opiši kratko što je tvoja motivacija za upis OR-a.",
  questionOr5:
    "Da li već sada znaš da namjeravaš upisati trening za psihoterapeute ITP-a koji počinje na 3. godini s programom TP i nastavlja se na petoj i šestoj godini s programom TT.",
  questionOr6:
    "Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje.",
  checkOr1:
    "Ovime potvrđujem da nemam trenutno nikakvo fizičko ili psiho-emocionalno stanje koje bi moglo biti u kontraindikaciji s programom Osobni razvoj.U slučaju da se pitaš da li je neko tvoje fizičko ili psiho-emocionalno stanje ili neki lijekovi koje uzimaš u kontraindikaciji sa upisom OR-a javi nam se na referada@ipd-center.eu kako bi dogovorili evaluacijsku terapiju s voditeljem grupe OR-a.",
  checkOr2:
    "Ovime potvrđujem da u ovoj fazi života (ukoliko ne dođe do nekih nepredviđenih okolnosti) sam u mogućnosti financirati pohađanje programa četverogodišnji Osobni razvoj i potrebne individualne terapije",
};

export const questionMappingEOR = {
  question1: "Opiši svoje trenutno radno mjesto",
  question2:
    "Navedi ako imaš dosadašnje iskustvo u savjetodavnim, psihoterapijskim ili sličnim edukacijama za rad s ljudima kao i u neposrednom stručnom radu s ljudima:",
  question3:
    "Navedi ako imaš svoja osobna iskustva u radu na sebi i psihoterapiji.",
  question4: "Opiši kratko što je tvoja motivacija za upis eOR-a.",
  choice:
    "Da li je tvoja namjera dolaziti 1x godišnje na modul uživo u Zagreb?",
  question5:
    "Da li već sada znaš da namjeravaš upisati trening za psihoterapeute ITP-a koji počinje na 3. godini s programom TP i nastavlja se na petoj i šestoj godini s programom TT.",
  question6:
    "Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje",
  check1:
    "Ovime potvrđujem da nemam trenutno nikakvo fizičko ili psiho-emocionalno stanje koje bi moglo biti u kontraindikaciji s programom Osobni razvoj.U slučaju da se pitaš da li je neko tvoje fizičko ili psiho-emocionalno stanje ili neki lijekovi koje uzimaš u kontraindikaciji sa upisom OR-a javi nam se na referada@ipd-center.eu kako bi dogovorili evaluacijsku terapiju s voditeljem grupe OR-a.",
  check2:
    "Ovime potvrđujem da u ovoj fazi života (ukoliko ne dođe do nekih nepredviđenih okolnosti) sam u mogućnosti financirati pohađanje programa četverogodišnji Osobni razvoj i potrebne individualne terapije",
};

export const questionMappingTP = {
  questionTP1: "Ime i prezime",
  questionTP2: "Školska sprema",
  questionTP3: "Grad stanovanja",
  checkTP1:
    "Potvrđujem da nisam pravomoćno osuđen/a ili optužen/a za neko od kaznenih djela navedenih u Zakonu o djelatnosti psihoterapije.",
  questionTP4:
    "Navedi ako imaš dodatne edukacije iz područja psihoterapije ili nekog sličnog rada na sebi.",
  choiceTP1:
    "Imaš li riješene sve financijske obaveze prema školi od prethodnih godina što je preduvjet za upis TP-a?",
  choiceTP2:
    "Uvjet za upis TP-a su ispunjene sve introspekcije do zadnjeg predavanja OR-a koje si imao/la. Imaš li ispunjene sve introspekcije do sada?",
  questionTP5: "Broj tvojih programskih terapija (IPR-a) do današnjeg datuma.",
  questionTP6:
    "Opiši: kako sada vidiš svoje trenutne kapacitete da budeš na TP-u, što misliš sada na čemu trebaš raditi kod sebe kako bi na TP2 mogao/la početi raditi s ljudima?",
  questionTP7:
    "Opiši zašto želiš upisati TP, što je tvoja motivacija da budeš terapeut?",
  choiceTP3:
    "Označi u kojem terminu bi želio/željela pohađati radionice TP1 (napomena: odabirom termina, ne garantiramo da ćeš u tom terminu pohađati TP. Nakon što završe prijave i oforme se grupe, potvrdit ćemo ti s kojom grupom ćeš pohađati TP shodno mogućnostima i broju prijava)",
  selectTP1:
    "U slučaju da će neke grupe biti popunjene i neki će morati prijeći u druge grupe navedi koja bi ti alternativna grupa odgovarala?",
  selectTP2: "Mentor",
  questionTP8:
    "Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje.",
};

export const questionMappingTT = {
  question1: "Trenutno zanimanje",
  select1: "Navedi ime svog 1.mentora.",
  select2: "Navedi ime svog 2.mentora.",
  question2:
    "Ovdje možeš upisati svoju napomenu ili eventualno dodatno pitanje.",
  check:
    "Potvrđujem da sam upoznat sa Zakonom o djelatnosti psihoterapije u Hrvatskoj koji kaže: Djelatnost psihoterapije obavlja ovlašteni psihoterapeut ili ovlašteni savjetodavni terapeut. Za status savjetodavnog terapeuta potrebno je imati minimalno status Sveučilišnog prvostupnika (Baccalaureus) stručnog prvostupnik ili VŠS (prije Bologne) i uz to imati završenu Propedeutiku psihoterapije. Za status psihoterapeuta neki od navedenih fakulteta (medicinske znanosti, kliničke medicinske znanosti, javnog zdravstva i zdravstvene zaštite psihologije, socijalne djelatnosti, edukacijsko-rehabilitacijske znanosti, pedagogije i logopedije). U Srbiji i Sloveniji djelatnost psihoterapije još nije regulirana zakonski, ali je za očekivati da će u narednim godinama zakoni izgledati slično kao u Hrvatskoj.",
};
export const questionMappings = {
  OR: questionMappingOR,
  eOR: questionMappingEOR,
  TP: questionMappingTP,
  TT: questionMappingTT,
};
