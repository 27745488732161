// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Introspekcije_wrapper__A4H4x {
  width: 100%;
  margin-top: -48px;
}
.Introspekcije_description__5kQh0,
.Introspekcije_tableWrapper__a3lKm {
  width: 100%;
}
.Introspekcije_tableWrapper__a3lKm {
  overflow-x: scroll;
}
.Introspekcije_description__5kQh0 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 16px;
}
.Introspekcije_navigationRow__ftc-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Introspekcije_navigationRow__ftc-1 {
  gap: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: -6px;
  margin-top: 48px;
  color: #5f6d7e;
}
.Introspekcije_navigationRow__ftc-1:hover {
  cursor: pointer;
  text-decoration: underline;
}
.Introspekcije_backArrow__NIXue {
  width: 24px;
  height: 24px;
}
.Introspekcije_predavanjeTitle__5QUmu,
.Introspekcije_paragraph__ycrSw,
.Introspekcije_navigationRow__ftc-1 {
  font-family: Inter;
  letter-spacing: -0.001em;
  text-align: left;
}
.Introspekcije_title__l\\+r6c {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #4d4d4d;
}
.Introspekcije_label__poqNj {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #818c99;
}
.Introspekcije_tableWrapper__a3lKm {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/singleGroup/Introspekcije/Introspekcije.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;;EAEE,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;;;EAGE,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  width: 100%;\n  margin-top: -48px;\n}\n.description,\n.tableWrapper {\n  width: 100%;\n}\n.tableWrapper {\n  overflow-x: scroll;\n}\n.description {\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n  justify-content: center;\n  gap: 16px;\n}\n.navigationRow {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.navigationRow {\n  gap: 16px;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  margin-bottom: -6px;\n  margin-top: 48px;\n  color: #5f6d7e;\n}\n.navigationRow:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n.backArrow {\n  width: 24px;\n  height: 24px;\n}\n.predavanjeTitle,\n.paragraph,\n.navigationRow {\n  font-family: Inter;\n  letter-spacing: -0.001em;\n  text-align: left;\n}\n.title {\n  font-family: Inter;\n  font-size: 28px;\n  font-weight: 600;\n  line-height: 34px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #4d4d4d;\n}\n.label {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #818c99;\n}\n.tableWrapper {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Introspekcije_wrapper__A4H4x`,
	"description": `Introspekcije_description__5kQh0`,
	"tableWrapper": `Introspekcije_tableWrapper__a3lKm`,
	"navigationRow": `Introspekcije_navigationRow__ftc-1`,
	"backArrow": `Introspekcije_backArrow__NIXue`,
	"predavanjeTitle": `Introspekcije_predavanjeTitle__5QUmu`,
	"paragraph": `Introspekcije_paragraph__ycrSw`,
	"title": `Introspekcije_title__l+r6c`,
	"label": `Introspekcije_label__poqNj`
};
export default ___CSS_LOADER_EXPORT___;
