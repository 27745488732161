import { useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import ExportIcon from "../../../../../../assets/svg/ExportIcon";
import UserIcon from "../../../../../../assets/svg/UserIcon";
import Pagination from "../../../../../../components/Pagination/Pagination";
import KomentarModal from "../../../../../evidencija/components/Supervizije/Accordions/KomentarModal/KomentarModal";
import { questionMappings } from "../enrollmentForm/utils";
import styles from "./Table.module.css";

const Table = ({ dataLive, selectedProgram }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [polaznik, setPolaznik] = useState("");
  const itemsPerPage = 5;
  const safeData = Array.isArray(dataLive) ? dataLive : [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = safeData.slice(indexOfFirstItem, indexOfLastItem);

  function formatData(rawData) {
    return rawData?.map((item) => {
      const application = JSON.parse(item.application);
      const course = item.course;
      const questionMap = questionMappings[course] || {};
      const formattedApplication = Object.entries(application).reduce(
        (acc, [key, value]) => {
          const question = questionMap[key] || key;
          acc[question] = value;
          return acc;
        },
        {}
      );
      return {
        imeIPrezime: `${item.User.firstName} ${item.User.lastName}`,
        gradPrograma: item.User.programCity || null,
        strucnaSprema: item.User.qualification || null,
        brojTerapija: item.numberOfTherapies || null,
        mjestoStanovanja: item.User.city || null,
        odobreno:
          item?.isAccepted === true
            ? "Odobreno"
            : item?.isAccepted === false
              ? "Neodobreno"
              : "Neocijenjeno",
        komentarMentor: item?.mentorReview
          ? item?.mentorReview
          : "Nema komentara",
        ...formattedApplication,
      };
    });
  }
  const modifiedData = formatData(dataLive);
  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeaderRow}>
              <th className={styles.nameSurnameHeaderCell}>
                <p className={styles.headerCellLabel}>Ime i prezime</p>
              </th>
              <th className={styles.linkHeaderCell}>
                <p className={styles.headerCellLabel}>
                  Aplikacije za{" "}
                  {selectedProgram === "3,4,5,6,7,8,9,10"
                    ? "OR/eOR"
                    : selectedProgram === "11,12"
                      ? "TT"
                      : selectedProgram === "13,14"
                        ? "TP"
                        : "sve"}
                </p>
              </th>
              {selectedProgram === "11,12" && (
                <>
                  <th className={styles.descriptionHeaderCell}>
                    <p className={styles.headerCellLabel}>
                      Suglasnost Supervizora 1
                    </p>
                  </th>
                  <th className={styles.descriptionHeaderCell}>
                    <p className={styles.headerCellLabel}>
                      Suglasnost Supervizora 2
                    </p>
                  </th>
                </>
              )}
              {selectedProgram === "3,4,5,6,7,8,9,10" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Odobrenje mentora</p>
                </th>
              )}
              {selectedProgram === "13,14" && (
                <th className={styles.descriptionHeaderCell}>
                  <p className={styles.headerCellLabel}>Odobrenje mentora</p>
                </th>
              )}
              <th>
                <p className={styles.headerCellLabel}>Komentar</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentUsers?.map((item, index) => (
              <tr
                className={styles.tableDataRow}
                key={index}
                style={{
                  backgroundColor: item?.isAccepted
                    ? "#E8FEE8"
                    : item?.isAccepted === false
                      ? "#FEE8E8"
                      : "",
                }}
              >
                <td
                  className={styles.nameSurnameCell}
                  onClick={() => navigate(`/pojedini-polaznik/${item.User.id}`)}
                >
                  {item.User.imageUrl === "" ? (
                    <div className={styles.emptyProfilePhoto}>
                      <UserIcon className={styles.profilePhotoIcon} />
                    </div>
                  ) : (
                    <img
                      alt="user"
                      src={item.User.imageUrl}
                      className={styles.userPhoto}
                    />
                  )}
                  <p
                    className={styles.nameSurnameText}
                    onClick={() =>
                      navigate(`/pojedini-polaznik/${item.User.id}`)
                    }
                  >
                    {item.User.firstName} {item.User.lastName}
                  </p>
                </td>
                <td className={styles.tableLinkCell}>
                  <p
                    onClick={() =>
                      navigate(
                        `/dokumenti/aplikacije-za-upis/anketa/${item.id}`
                      )
                    }
                    className={styles.tableLinkText}
                  >
                    Link na anketu
                  </p>
                </td>
                {selectedProgram === "11,12" && (
                  <>
                    <td className={styles.tableSupervisorCell}>
                      <p className={styles.tableSupervisorText}>
                        {!item.supervisor1 ? "------" : item.supervisor1}
                      </p>
                    </td>
                    <td className={styles.tableSupervisorCell}>
                      <p className={styles.tableSupervisorText}>
                        {!item.supervisor2 ? "------" : item.supervisor2}
                      </p>
                    </td>
                  </>
                )}
                {(selectedProgram === "3,4,5,6,7,8,9,10" ||
                  selectedProgram === "13,14") && (
                  <td className={styles.tableSupervisorCell}>
                    <p className={styles.tableSupervisorText}>
                      {item.isAccepted === null
                        ? "------"
                        : item.isAccepted === true
                          ? "Odobreno"
                          : "Nije odobreno"}
                    </p>
                  </td>
                )}
                <td>
                  <p className={styles.tableSupervisorText}>
                    {item?.mentorReview ? (
                      <>
                        <span
                          style={{
                            cursor:
                              item?.mentorReview?.length > 100
                                ? "pointer"
                                : "not-allowed",
                            textDecoration:
                              item?.mentorReview?.length > 100
                                ? "underline"
                                : "",
                          }}
                          className={styles.comment}
                          onClick={() => {
                            setPolaznik(
                              `${item?.User.firstName} ${item?.User.lastName}`
                            );
                            setSelectedText(item?.mentorReview);
                            setReadOnly(true);
                            setShowModal(true);
                          }}
                        >
                          {item?.mentorReview}
                        </span>
                      </>
                    ) : (
                      <span className={styles.tableSupervisorText}>
                        Nema komentara
                      </span>
                    )}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsToDisplay={dataLive}
        setCurrent={setCurrentPage}
        currentPage={currentPage}
        customWidth={"100%"}
        itemsPerPage={itemsPerPage}
      />
      {modifiedData?.length > 0 && (
        <CSVLink
          data={modifiedData}
          target="_blank"
          filename={"AplikacijeZaUpis.csv"}
          className={styles.exportButton}
        >
          <p className={styles.exportButtonText}>Export tablice s odgovorima</p>
          <ExportIcon className={styles.exportButtonIcon} />
        </CSVLink>
      )}
      {showModal && (
        <KomentarModal
          onClose={() => setShowModal(false)}
          commentText={selectedText}
          readOnly={readOnly}
          isApplicationReferada
          polaznik={polaznik}
        />
      )}
    </div>
  );
};
export default Table;
