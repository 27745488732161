// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchGroups_searchGroupsInput__99ZXz {
  border: 1px solid #d9dce0;
  width: 438px;
  height: 46px;
  padding: 8px 16px 8px 46px;
  border-radius: 8px;
  cursor: pointer;
}
.SearchGroups_searchGroupsInput__99ZXz::placeholder {
  color: rgba(95, 109, 126, 0.6);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.SearchGroups_searchGroupsInput__99ZXz:focus {
  border: 1px solid rgba(203, 156, 45, 1);
  outline: none;
}
.SearchGroups_searchGroupsIcon__lNLue {
  position: absolute;
  height: 24px;
  width: 24px;
  margin: 10px 16px;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/groups/utils/SearchGroups/SearchGroups.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,uCAAuC;EACvC,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".searchGroupsInput {\n  border: 1px solid #d9dce0;\n  width: 438px;\n  height: 46px;\n  padding: 8px 16px 8px 46px;\n  border-radius: 8px;\n  cursor: pointer;\n}\n.searchGroupsInput::placeholder {\n  color: rgba(95, 109, 126, 0.6);\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.searchGroupsInput:focus {\n  border: 1px solid rgba(203, 156, 45, 1);\n  outline: none;\n}\n.searchGroupsIcon {\n  position: absolute;\n  height: 24px;\n  width: 24px;\n  margin: 10px 16px;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchGroupsInput": `SearchGroups_searchGroupsInput__99ZXz`,
	"searchGroupsIcon": `SearchGroups_searchGroupsIcon__lNLue`
};
export default ___CSS_LOADER_EXPORT___;
