// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ObrisiDjelatnika_cardContainer__XpY45 {
  margin-top: 2%;
  width: 90%;
}
.ObrisiDjelatnika_buttonContainer__Npaor {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 20px;
}

.ObrisiDjelatnika_cancelButton__BOjvb {
  width: 212px;
  height: 52px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  gap: 8px;
  background: #ebebeb;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: center;
  border: none;
  color: #808080;
  cursor: pointer;
}
.ObrisiDjelatnika_nextButton__2aZ8Z {
  width: 212px;
  height: 52px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  gap: 8px;
  background: #cb9c2d;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: center;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ObrisiDjelatnika_disabledBtn__m8ht7 {
  cursor: not-allowed !important;
  background: #cbba92 !important;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/IPDTeam/ObrisiDjelatnika/ObrisiDjelatnika.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,UAAU;AACZ;AACA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,QAAQ;EACR,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,QAAQ;EACR,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC","sourcesContent":[".cardContainer {\n  margin-top: 2%;\n  width: 90%;\n}\n.buttonContainer {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding: 10px;\n  gap: 20px;\n}\n\n.cancelButton {\n  width: 212px;\n  height: 52px;\n  padding: 8px 16px 8px 16px;\n  border-radius: 4px;\n  gap: 8px;\n  background: #ebebeb;\n  font-family: \"Inter\";\n  font-size: 15px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: center;\n  border: none;\n  color: #808080;\n  cursor: pointer;\n}\n.nextButton {\n  width: 212px;\n  height: 52px;\n  padding: 8px 16px 8px 16px;\n  border-radius: 4px;\n  gap: 8px;\n  background: #cb9c2d;\n  font-family: \"Inter\";\n  font-size: 15px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: center;\n  border: none;\n  color: #ffffff;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.disabledBtn {\n  cursor: not-allowed !important;\n  background: #cbba92 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `ObrisiDjelatnika_cardContainer__XpY45`,
	"buttonContainer": `ObrisiDjelatnika_buttonContainer__Npaor`,
	"cancelButton": `ObrisiDjelatnika_cancelButton__BOjvb`,
	"nextButton": `ObrisiDjelatnika_nextButton__2aZ8Z`,
	"disabledBtn": `ObrisiDjelatnika_disabledBtn__m8ht7`
};
export default ___CSS_LOADER_EXPORT___;
