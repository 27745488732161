import React from "react";
import Close from "../../../../../assets/img/ExitIcon.png";
import styles from "./EvidentionModal.module.css";

function EvidentionModal({
  onClose,
  brojPolaznika,
  saveToEvidention,
  eventType,
  eventName,
  handleEdit,
}) {
  return (
    <div className={styles.overlay}>
      <button className={styles.closeIcon} onClick={onClose}>
        <img alt="" src={Close} />
      </button>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            Dodajte {eventType} ({eventName}) u evidenciju{" "}
          </div>
          <div className={styles.label}>
            Imate prijavljeno{" "}
            <span style={{ color: "#cb9c2d", fontWeight: 700 }}>
              {brojPolaznika}
            </span>{" "}
            polaznika. Je li to točno?
          </div>
          <div className={styles.checkboxWrapper}></div>
          <div className={styles.btnRow}>
            <button className={styles.acceptBtn} onClick={saveToEvidention}>
              Spremi u evidenciju
            </button>
            <button className={styles.acceptBtn} onClick={handleEdit}>
              Ne (uredite broj polaznika)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EvidentionModal;
