// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_btnRow__shIqg {
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #5f6d7e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Pagination_navigationRow__1L9-B,
.Pagination_disabledBtn__YWZxJ {
  gap: 16px;
  cursor: pointer;
}
.Pagination_disabledBtn__YWZxJ,
.Pagination_numberContainer__bbs3k,
.Pagination_activeNumber__Um7S7,
.Pagination_pageNumber__31992,
.Pagination_navigationRow__1L9-B {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Pagination_numberContainer__bbs3k {
  gap: 16px;
}
.Pagination_pageNumber__31992,
.Pagination_activeNumber__Um7S7 {
  width: 40px;
  height: 40px;
  padding: 0px, 16px, 0px, 16px;
  border-radius: 5px;
  cursor: pointer;
}
.Pagination_disabledBtn__YWZxJ {
  opacity: 0.4;
  cursor: not-allowed;
}
.Pagination_activeNumber__Um7S7 {
  background-color: #5f6d7e;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .Pagination_numberContainer__bbs3k {
    gap: 0px;
  }
  .Pagination_pageNumber__31992 {
    width: 30px;
    height: 30px;
  }
  .Pagination_activeNumber__Um7S7 {
    width: 30px;
    height: 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;;EAEE,SAAS;EACT,eAAe;AACjB;AACA;;;;;EAKE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,SAAS;AACX;AACA;;EAEE,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE;IACE,QAAQ;EACV;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".btnRow {\n  width: 100%;\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 20px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #5f6d7e;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.navigationRow,\n.disabledBtn {\n  gap: 16px;\n  cursor: pointer;\n}\n.disabledBtn,\n.numberContainer,\n.activeNumber,\n.pageNumber,\n.navigationRow {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.numberContainer {\n  gap: 16px;\n}\n.pageNumber,\n.activeNumber {\n  width: 40px;\n  height: 40px;\n  padding: 0px, 16px, 0px, 16px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n.disabledBtn {\n  opacity: 0.4;\n  cursor: not-allowed;\n}\n.activeNumber {\n  background-color: #5f6d7e;\n  color: #fff;\n}\n@media screen and (max-width: 600px) {\n  .numberContainer {\n    gap: 0px;\n  }\n  .pageNumber {\n    width: 30px;\n    height: 30px;\n  }\n  .activeNumber {\n    width: 30px;\n    height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnRow": `Pagination_btnRow__shIqg`,
	"navigationRow": `Pagination_navigationRow__1L9-B`,
	"disabledBtn": `Pagination_disabledBtn__YWZxJ`,
	"numberContainer": `Pagination_numberContainer__bbs3k`,
	"activeNumber": `Pagination_activeNumber__Um7S7`,
	"pageNumber": `Pagination_pageNumber__31992`
};
export default ___CSS_LOADER_EXPORT___;
