// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Payment_wrapper__sfyLa {
  padding: 0 25px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.Payment_formContainer__PG4Td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
/* .leftContainer,
.rightContainer {
  width: 45%;
} */

.Payment_title__4OesK {
  font-family: Inter;
  font-size: 30px;
  letter-spacing: -0.001em;
  color: #5f6d7e;
  font-weight: 600;
  margin-bottom: 30px;
}

.Payment_submitBtnContainer__esuTU {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
  width: 550px;
}

.Payment_submit__gzWg1 {
  display: flex;
  background-color: #cb9c2d;
  border-radius: 4px;
  cursor: pointer;
  height: 52px;
  align-items: center;
  justify-content: center;
  width: 212px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/payment/Payment.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf;;;2BAGyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,SAAS;AACX;AACA;;;GAGG;;AAEH;EACE,kBAAkB;EAClB,eAAe;EACf,wBAAwB;EACxB,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,YAAY;AACd","sourcesContent":[".wrapper {\n  padding: 0 25px;\n  /* display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column; */\n}\n\n.formContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 24px;\n}\n/* .leftContainer,\n.rightContainer {\n  width: 45%;\n} */\n\n.title {\n  font-family: Inter;\n  font-size: 30px;\n  letter-spacing: -0.001em;\n  color: #5f6d7e;\n  font-weight: 600;\n  margin-bottom: 30px;\n}\n\n.submitBtnContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 24px;\n  margin-top: 24px;\n  width: 550px;\n}\n\n.submit {\n  display: flex;\n  background-color: #cb9c2d;\n  border-radius: 4px;\n  cursor: pointer;\n  height: 52px;\n  align-items: center;\n  justify-content: center;\n  width: 212px;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Payment_wrapper__sfyLa`,
	"formContainer": `Payment_formContainer__PG4Td`,
	"title": `Payment_title__4OesK`,
	"submitBtnContainer": `Payment_submitBtnContainer__esuTU`,
	"submit": `Payment_submit__gzWg1`
};
export default ___CSS_LOADER_EXPORT___;
