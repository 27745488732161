import React, { useEffect, useState } from "react";
import Success from "../../../../assets/img/CheckRing.png";
import Error from "../../../../assets/img/Failcheck.jpg";
import { handleCheckboxChange } from "../../util";

import EvaluacijeAccordions from "./Accordions/EvaluacijeAccordions";
import ObracunAccordions from "./Accordions/ObracunAccordions";
import CertificateContainer from "./CertificateContainer/CertificateContainer";
import styles from "./Supervizije.module.css";
import RenderTable from "./Table/RenderTable";
import { disableSupervisionInput } from "./disableSupervision";

function Supervizije({
  userId,
  polaznikLevel,
  data,
  zavrsniData,
  deleteFlag,
  setDeleteFlag,
  authToken,
  evidentionUserID,
  change,
  setChange,
  tpIttData,
  shouldShowCertificate,
  supervisionData,
  getSupervisionEntry,
  role,
  id,
}) {
  const [formValues, setFormValues] = useState({
    zavrsniOR: false,
    caseStudy1: false,
    caseStudy2: false,
    supervisorApproval1: null,
    supervisorApproval2: null,
  });
  useEffect(() => {
    if (zavrsniData) {
      setFormValues({
        zavrsniOR: zavrsniData?.zavrsniOR,
        caseStudy1: zavrsniData?.caseStudy1,
        caseStudy2: zavrsniData?.caseStudy2,
        supervisorApproval1: zavrsniData?.supervisorApproval1,
        supervisorApproval2: zavrsniData?.supervisorApproval2,
      });
    }
  }, [zavrsniData]);
  const minSupervizijeSatiPraktičar = 60;
  const minSupervizijeSatiTerapeut = 150;
  const [supervizijeArray, setSupervizijeArray] = useState([]);
  const [obracunInfo, setObracunInfo] = useState([]);
  const [
    evaluationOfIndividualSupervisionsArray,
    setEvaluationOfIndividualSupervisionsArray,
  ] = useState([]);
  useEffect(() => {
    if (data) {
      const array = Object.values(data);
      setSupervizijeArray([
        { evaluationOfIndividualSupervisions: array[0] },
        { supervisorConsent: array[1] },
        { groupSupervision: array[2] },
        { individualSupervision: array[3] },
        { individualMetodologije: array[4] },
        { groupMetodologije: array[5] },
      ]);
    }
  }, [data, supervisionData]);

  const currentIndividualneSati =
    supervizijeArray?.[3]?.individualSupervision.individualSupervision +
    // supervizijeArray?.[3]?.individualSupervision.therapistTrijada +
    supervizijeArray?.[3]?.individualSupervision.supervisantAGS;
  const remainingIndividualneSati =
    supervizijeArray?.[3]?.individualSupervision.minAttendance -
    currentIndividualneSati;
  const currentGrupneSati =
    supervizijeArray?.[2]?.groupSupervision.groupSupervisionSectionCount +
    supervizijeArray?.[2]?.groupSupervision.tp2Count +
    supervizijeArray?.[2]?.groupSupervision.tt5Count +
    supervizijeArray?.[2]?.groupSupervision.tt6Count +
    (tpIttData?.tp2?.modules[0]?.filter((module) => module.attendance === true)
      ?.length || 0) +
    ((tpIttData?.tt5?.modules || [])
      ?.flat()
      ?.filter((module) => module.attendance === true)?.length > 24
      ? 10
      : 0) +
    ((tpIttData?.tt6?.modules || [])
      ?.flat()
      ?.filter((module) => module.attendance === true)?.length > 24
      ? 10
      : 0);
  const remainingGrupneSati =
    supervizijeArray?.[2]?.groupSupervision.minAttendance - currentGrupneSati;
  useEffect(() => {
    if (supervizijeArray.length > 0) {
      const groupSupervision = supervizijeArray[2]?.groupSupervision || {};
      const individualSupervision =
        supervizijeArray[3]?.individualSupervision || {};
      const evaluationOfIndividualSupervisions = supervisionData || [];
      const groupSupervisionTopicsArray = [
        {
          name: "Grupna supervizija",
          hours: groupSupervision.groupSupervisionSectionCount || 0,
          isNameClickable: true,
          eventType: "group_supervision",
        },
        {
          name: "TP2",
          hours: groupSupervision.tp2Count || 0,
          isNameClickable: false,
          eventType: "tp2",
        },
        {
          name: "TT5",
          hours: groupSupervision.tt5Count || 0,
          isNameClickable: false,
          eventType: "tt5",
        },
        {
          name: "TT6",
          hours: groupSupervision.tt6Count || 0,
          isNameClickable: false,
          eventType: "tt6",
        },
      ];
      const individualSupervisionTopicsArray = [
        {
          name: "Individualna supervizija 1 na 1",
          hours: individualSupervision.individualSupervision || 0,
          isNameClickable: true,
          eventType: "individual_supervision",
        },
        {
          name: "Supervizant na AGS-u",
          hours: individualSupervision.supervisantAGS || 0,
          isNameClickable: true,
          eventType: "ags_supervisant",
        },
        {
          name: "Terapeut na Trijadi - radionica metodologije",
          hours: individualSupervision.therapistTrijada || 0,
          isNameClickable: true,
          eventType: "trijada_therapist",
        },
      ];

      setObracunInfo([
        {
          title: "Individualne supervizije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: individualSupervisionTopicsArray,
              maxTopObracun:
                supervizijeArray?.[3]?.individualSupervision.minAttendance,
            },
          ],
        },
        {
          title: "Grupne supervizije - Konačan obračun",
          modules: [
            {
              moduleName: "Modul 1",
              topics: groupSupervisionTopicsArray,
              maxBottomObracun:
                supervizijeArray?.[2]?.groupSupervision?.minAttendance,
            },
          ],
        },
      ]);
      setEvaluationOfIndividualSupervisionsArray([
        {
          title: "Supervizorske evaluacije",
          modules: [
            {
              topics: evaluationOfIndividualSupervisions,
              showTable: true,
            },
          ],
        },
      ]);
    }
  }, [supervizijeArray]);

  return (
    <div className={styles.raniRazvojPrvaSekcija2}>
      <div>
        <div>
          <h1 className={styles.raniRazvojHeader}>
            Supervizije i metodologije
          </h1>
          <p className={styles.raniRazvojDescription}>
            Sati Metodologije (Trijade) imaju za cilj učenje samog terapijskog
            procesa kao i evaluaciju edukanta s ciljem dobivanja Potvrde o
            statusu edukanta kroz suglasnost dva supervizora za rad s klijentima
            pri čemu je važna sama spremnost edukanta, a ne određen broj sati.
          </p>{" "}
          <p className={styles.raniRazvojDescription2}>
            Moje trenutno stanje sati Metodologije:
            <br />
            1) Individualno – Trenutno stanje:{" "}
            {supervizijeArray?.[4]?.individualMetodologije} h
            <br />
            2) Grupno – Trenutno stanje:{" "}
            {supervizijeArray?.[5]?.groupMetodologije} h
          </p>
          <p className={styles.raniRazvojDescription}>
            Za postizanje statusa{" "}
            {polaznikLevel === "praktičar" ? "Praktičara" : "Psihoterapeuta"}{" "}
            ITP-a potrebno je ostvariti minimalno{" "}
            {polaznikLevel === "praktičar"
              ? minSupervizijeSatiPraktičar
              : minSupervizijeSatiTerapeut}{" "}
            sati supervizija od čega min{" "}
            {polaznikLevel === "praktičar" ? 15 : 40} sati individualnih
            supervizija.
          </p>
          <p className={styles.raniRazvojDescription2}>
            Moje trenutno stanje sati supervizija: <br /> 1) Individualne
            supervizije {polaznikLevel === "praktičar" && ""}
            {/**/} : {currentIndividualneSati}h{" "}
            {remainingIndividualneSati <= 0
              ? "(Uvijet ispunjen)"
              : `(Nedostaje ${remainingIndividualneSati}h)`}{" "}
            {polaznikLevel !== "praktičar" && ""}
            (u ovaj obračun se ne računaju sati Terapeut na Trijadi)
            <br />
            2) Grupna supervizija {polaznikLevel === "praktičar" && ""}:{" "}
            {currentGrupneSati}h{" "}
            {remainingGrupneSati <= 0
              ? "(Uvijet ispunjen)"
              : `(Nedostaje ${remainingGrupneSati} h)`}
            <br /> <br />
            TP2- 7 sati grupne supervizije će ti se automatski upisati nakon što
            poklikaš svoje sudjelovanje na svih 7 radionica TP2
            <br /> TT5 - 10 sati grupne supervizije će ti se automatski upisati
            nakon što poklikaš svoje sudjelovanje na min 25 radionica TT5 <br />
            TT6 - 10 sati grupne supervizije će ti se automatski upisati nakon
            što poklikaš svoje sudjelovanje na min 25 radionica TT6
          </p>
        </div>
        <span className={styles.label}>Suglasnost supervizora</span>
        <RenderTable
          data={supervizijeArray?.[1]?.supervisorConsent}
          role={userId}
          evidentionUserID={evidentionUserID}
          change={change}
          setChange={setChange}
        />
        {shouldShowCertificate && (
          <>
            <span className={styles.label}>
              Preuzmi svoju potvrdu o statusu edukanta u školi ITP-a
            </span>
            <CertificateContainer />
          </>
        )}
        <ObracunAccordions
          data={obracunInfo}
          userId={userId}
          deleteFlag={deleteFlag}
          setDeleteFlag={setDeleteFlag}
          evidentionUserID={evidentionUserID}
          tpIttData={tpIttData}
        />
        <EvaluacijeAccordions
          userId={userId}
          getSupervisionEntry={getSupervisionEntry}
          data={evaluationOfIndividualSupervisionsArray}
          evidentionUserID={evidentionUserID}
          change={change}
          setChange={setChange}
        />
        <div className={styles.raniRazvojPrvaSekcija2}>
          <p className={styles.raniRazvojDescription}>
            Suglasnosti supervizora za rad edukanta s klijentima. (Nakon što ti
            oba supervizora klikom daju suglasnost kreirat će ti se Potvrda o
            statusu edukanta koju si možeš skinuti na jeziku koji ti treba.)
          </p>
          <div
            className={styles.zavrsniWrapper}
            style={{ flexDirection: userId === "Referada" ? "row" : "column" }}
          >
            <span className={styles.label}>Supervizor 1</span>
            {role !== 5 && role !== 6 ? (
              <img
                alt=""
                src={formValues.supervisorApproval1 ? Success : Error}
                className={styles.success}
              />
            ) : (
              <div>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    className={styles.checkboxInput}
                    disabled={disableSupervisionInput(
                      userId,
                      formValues,
                      1,
                      role
                    )}
                    name="supervisorApproval1"
                    checked={formValues.supervisorApproval1 ? true : false}
                    onChange={(e) => {
                      handleCheckboxChange(
                        "supervisorApproval1",
                        e.target.checked,
                        setFormValues,
                        evidentionUserID,
                        authToken,
                        formValues,
                        id ?? userId
                      );
                      setDeleteFlag(!deleteFlag);
                    }}
                  />
                  <span className={styles.checkboxCustom} />
                  Potvrda
                </label>
              </div>
            )}
            <span className={styles.label}>Supervizor 2</span>
            {role !== 5 && role !== 6 ? (
              <img
                alt=""
                src={formValues?.supervisorApproval2 ? Success : Error}
                className={styles.success}
              />
            ) : (
              <div>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    className={styles.checkboxInput}
                    disabled={disableSupervisionInput(
                      userId,
                      formValues,
                      2,
                      role
                    )}
                    name="supervisorApproval2"
                    checked={formValues.supervisorApproval2 ? true : false}
                    onChange={(e) => {
                      handleCheckboxChange(
                        "supervisorApproval2",
                        e.target.checked,
                        setFormValues,
                        evidentionUserID,
                        authToken,
                        formValues,
                        id ?? userId
                      );
                      setDeleteFlag(!deleteFlag);
                    }}
                  />
                  <span className={styles.checkboxCustom} />
                  Potvrda
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supervizije;
