// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Accordion_accordionContainer__9D51W {
  display: flex;
  flex-direction: column;
}
.Accordion_disabled__s8Kyf {
  opacity: 0.4;
  cursor: not-allowed;
}
.Accordion_accordionHeader__KI5Hq {
  align-items: center;
  background-color: #f8f9fb;
  border: 1px solid #d1d9e2;
  border-radius: 10px;
  column-gap: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
}
.Accordion_accordionHeaderActive__oG6D1 {
  align-items: center;
  background-color: #f8f9fb;
  border-color: #d1d9e2;
  border-style: solid;
  border-width: 1px 1px 0px;
  border-radius: 10px 10px 0px 0px;
  column-gap: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 15px;
  box-sizing: border-box;
}
.Accordion_accordionHeaderText__P\\+MdW {
  color: #5f6d7e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.Accordion_accordionExpandIcon__mZCoC {
  stroke: #5f6d7e;
}
.Accordion_accordionContent__oEJ-8 {
  display: flex;
  border: 1px solid #d1d9e2;
  flex-direction: column;
  border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width: 600px) {
  .Accordion_accordionHeaderText__P\\+MdW {
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/documents/components/enrollmentApplications/components/accordion/Accordion.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB;EACzB,gCAAgC;EAChC,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,gCAAgC;AAClC;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".accordionContainer {\n  display: flex;\n  flex-direction: column;\n}\n.disabled {\n  opacity: 0.4;\n  cursor: not-allowed;\n}\n.accordionHeader {\n  align-items: center;\n  background-color: #f8f9fb;\n  border: 1px solid #d1d9e2;\n  border-radius: 10px;\n  column-gap: 16px;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: 0px 15px;\n  box-sizing: border-box;\n}\n.accordionHeaderActive {\n  align-items: center;\n  background-color: #f8f9fb;\n  border-color: #d1d9e2;\n  border-style: solid;\n  border-width: 1px 1px 0px;\n  border-radius: 10px 10px 0px 0px;\n  column-gap: 16px;\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: 0px 15px;\n  box-sizing: border-box;\n}\n.accordionHeaderText {\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.accordionExpandIcon {\n  stroke: #5f6d7e;\n}\n.accordionContent {\n  display: flex;\n  border: 1px solid #d1d9e2;\n  flex-direction: column;\n  border-radius: 0px 0px 10px 10px;\n}\n\n@media screen and (max-width: 600px) {\n  .accordionHeaderText {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionContainer": `Accordion_accordionContainer__9D51W`,
	"disabled": `Accordion_disabled__s8Kyf`,
	"accordionHeader": `Accordion_accordionHeader__KI5Hq`,
	"accordionHeaderActive": `Accordion_accordionHeaderActive__oG6D1`,
	"accordionHeaderText": `Accordion_accordionHeaderText__P+MdW`,
	"accordionExpandIcon": `Accordion_accordionExpandIcon__mZCoC`,
	"accordionContent": `Accordion_accordionContent__oEJ-8`
};
export default ___CSS_LOADER_EXPORT___;
