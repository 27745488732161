import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import Alert from "../../assets/img/AlertIcon.png";
import BackArrow from "../../assets/img/BackArrow.png";
import Icon from "../../assets/png/IPDLogo.png";
import IPDLogo from "../../assets/svg/IPDLogo2.png";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import UserMenu from "../../components/userMenu/UserMenu";
import { useUserState } from "../../context/UserContext";
import useWindowResize from "../Hooks/ResizeHook";
import styles from "./CustomHeader.module.css";

function CustomHeader({ title }) {
  const { role } = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const width = useWindowResize();
  return (
    <>
      <div className={`${styles.nav} ${styles.kalendarNav}`}>
        <div
          className={styles.back}
          onClick={
            role === "Polaznik" && currentPath === "/kalendaripd"
              ? () => navigate("/dashboard")
              : (role !== "Polaznik" && currentPath === "/kalendaripd") ||
                  currentPath === "/master"
                ? () => navigate("/kalendar")
                : role === "Zakupitelj"
                  ? () => navigate("/kalendar")
                  : () => navigate("/dashboard")
          }
        >
          <div className={styles.backToHome}>
            <img src={BackArrow} alt="" className={styles.backArrow} />
            <h2 className={styles.h2Calendar}>Natrag</h2>
          </div>
          <h1 className={styles.h1}>{title}</h1>
        </div>
        <div className={styles.logo}>
          <img
            src={width > 424 ? IPDLogo : Icon}
            alt=""
            className={styles.logoImg}
          />
        </div>
        <div className={styles.user}>
          <div className={styles.userRow}>
            {/* <button
              className={styles.notificationBtn}
              onClick={() => navigate("/primljene-obavijesti")}
            >
              <div className={styles.bellColumn}>
                <img alt="" src={Alert} className={styles.bell} />
                <div className={styles.pill}>
                  {userData.notificationsCount >= 0
                    ? userData.notificationsCount
                    : 0}
                </div>
              </div>
            </button> */}
            <UserMenu
              showArrow={width < 679 ? false : true}
              rightPosition={10}
            />
          </div>
        </div>
      </div>
      <MobileHeader />
    </>
  );
}

export default CustomHeader;
