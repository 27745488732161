// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserCardSearch_searchUsersInput__83eXi {
  border: 1px solid #d9dce0;
  width: 438px;
  height: 46px;
  padding: 8px 16px 8px 46px;
  border-radius: 8px;
  cursor: pointer;
}
.UserCardSearch_searchUsersInput__83eXi::placeholder {
  color: rgba(95, 109, 126, 0.6);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.UserCardSearch_searchUsersInput__83eXi:focus {
  border: 1px solid rgba(203, 156, 45, 1);
  outline: none;
}
.UserCardSearch_searchUsersIcon__6BXUI {
  position: absolute;
  height: 24px;
  width: 24px;
  margin: 10px 20px;
}

@media screen and (max-width: 460px) {
  .UserCardSearch_searchInputContainer__zzpYr {
    display: flex;
    justify-content: center;
  }
  .UserCardSearch_searchUsersInput__83eXi {
    width: 100%;
    padding: 8px 70px 8px 70px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/IPDTeam/components/SearchUserCards/UserCardSearch.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,uCAAuC;EACvC,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;EACzB;EACA;IACE,WAAW;IACX,0BAA0B;EAC5B;AACF","sourcesContent":[".searchUsersInput {\n  border: 1px solid #d9dce0;\n  width: 438px;\n  height: 46px;\n  padding: 8px 16px 8px 46px;\n  border-radius: 8px;\n  cursor: pointer;\n}\n.searchUsersInput::placeholder {\n  color: rgba(95, 109, 126, 0.6);\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.searchUsersInput:focus {\n  border: 1px solid rgba(203, 156, 45, 1);\n  outline: none;\n}\n.searchUsersIcon {\n  position: absolute;\n  height: 24px;\n  width: 24px;\n  margin: 10px 20px;\n}\n\n@media screen and (max-width: 460px) {\n  .searchInputContainer {\n    display: flex;\n    justify-content: center;\n  }\n  .searchUsersInput {\n    width: 100%;\n    padding: 8px 70px 8px 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchUsersInput": `UserCardSearch_searchUsersInput__83eXi`,
	"searchUsersIcon": `UserCardSearch_searchUsersIcon__6BXUI`,
	"searchInputContainer": `UserCardSearch_searchInputContainer__zzpYr`
};
export default ___CSS_LOADER_EXPORT___;
