import React, { useState } from "react";
function RenderTableData({ data, userData }) {
  const [copiedIndex, setCopiedIndex] = useState(null);
  const copyToClipboard = (data, firstName, lastName, emailAddress) => {
    const textToCopy = `${firstName} ${lastName} (${emailAddress})`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedIndex(data.findIndex((p) => p.emailAddress === emailAddress));
        setTimeout(() => setCopiedIndex(null), 2000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };
  return (
    <>
      {data.map((polaznik, index) => (
        <tr
          key={index}
          onClick={
            userData.roleId === 6
              ? () =>
                  copyToClipboard(
                    data,
                    polaznik.firstName,
                    polaznik.lastName,
                    polaznik.emailAddress
                  )
              : null
          }
          style={{
            cursor: userData.roleId === 6 ? "pointer" : "default",
          }}
        >
          <td style={{ color: "#2E3646" }}>
            <div className="table-polaznici-row">{index + 1}.</div>
          </td>
          <td style={{ color: "#2E3646" }}>
            <div
              className="table-polaznici-row"
              style={{ fontWeight: copiedIndex === index ? "800" : "" }}
            >
              <div className="table-polaznici-avatar">
                <img alt="" src={polaznik.imageUrl} />
              </div>
              {polaznik.firstName} {polaznik.lastName}
            </div>
          </td>
          <td style={{ color: "#5F6D7E" }}>
            <div className="table-polaznici-column">
              {polaznik.aktivniProgramResponse[0] && (
                <>
                  <p style={{ marginBlockEnd: 5, marginBlockStart: 5 }}>
                    {polaznik.aktivniProgramResponse[0].title}
                  </p>
                  <p style={{ marginBlockEnd: 5, marginBlockStart: 5 }}>
                    {polaznik.aktivniProgramResponse[0].name}
                  </p>
                </>
              )}
            </div>
          </td>
          {userData.roleId === 6 && (
            <td style={{ color: "#5F6D7E" }}>
              <div
                className=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "flex-start",
                  fontWeight: copiedIndex === index ? "800" : "",
                }}
              >
                {polaznik.emailAddress && <p>{polaznik.emailAddress}</p>}
              </div>
            </td>
          )}
        </tr>
      ))}
    </>
  );
}

export default RenderTableData;
