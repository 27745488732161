import { addEventToEvidention } from "./api/addEventToEvidention";

export const downloadCSV = (data, name) => {
  if (!data.length) return;

  const csvRows = [];
  const headers = Object.keys(data[0]).join(",");
  csvRows.push(headers);

  data.forEach((row) => {
    const values = Object.values(row)
      .map((value) => `"${value}"`)
      .join(",");
    csvRows.push(values);
  });

  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${name}_Polaznici.csv`;
  document.body.appendChild(a);
  a.click();

  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const AddToEvidention = async (
  eventId,
  eventType,
  usersArray,
  accessToken,
  setModalContent,
  Success,
  Error,
  setShowRegisterModal
) => {
  if (!Array.isArray(usersArray) || usersArray.length === 0) {
    console.error("Error: usersArray is not valid", usersArray);
    setModalContent({
      image: Error,
      title: "Nešto je pošlo po krivu, pokušajte ponovo.",
      buttons: [
        {
          label: "U redu",
          action: () => window.location.reload(),
          className: "modal-btn-success",
        },
      ],
    });
    setShowRegisterModal(true);
    return;
  }
  try {
    const response = await addEventToEvidention(
      eventType,
      eventId,
      usersArray,
      accessToken
    );
    if (response.status === 200) {
      setModalContent({
        image: Success,
        title: `Uspješno ste dodali ${eventType === "fakultativne-radionice" ? "fakultativnu radionicu" : "intenziv"} u evidenciju`,
        buttons: [
          {
            label: "U redu",
            action: () => window.location.reload(),
            className: "modal-btn-success",
          },
        ],
      });
      setShowRegisterModal(true);
    } else {
      setModalContent({
        image: Error,
        title: "Nešto je pošlo po krivu",
        buttons: [
          {
            label: "U redu",
            action: () => window.location.reload(),
          },
        ],
      });
      setShowRegisterModal(true);
    }
  } catch (err) {
    console.log("Error adding user : ", err);
  }
};
