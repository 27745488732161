import React from "react";
import Close from "../../assets/img/ExitIcon.png";
import styles from "./LightBox.module.css";

function LightBox({ image, onClose }) {
  return (
    <div id="lightbox" className={styles.lightbox}>
      <img
        alt="close"
        src={Close}
        className={styles.closeBtn}
        onClick={onClose}
      />
      <img src={image} alt="Fullscreen" />
    </div>
  );
}

export default LightBox;
