// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomInput_inputLabel__gAfvC {
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.CustomInput_inputLabelText__s0iBh {
  color: #5f6d7e;
}
.CustomInput_inputLabel__gAfvC,
.CustomInput_disabledLabel__yyXsV {
  font-weight: 600;
  margin: 0px 0px 8px;
}
.CustomInput_disabledLabel__yyXsV {
  color: #9fa7b2;
}
.CustomInput_input__QwvFD {
  border: 1px solid #d8dbdf;
  border-radius: 8px;
  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);
  height: 46px;
  font-weight: 500;
  padding: 0px 16px;
}
.CustomInput_red__KT0it {
  border: 2px solid #d30404;
}
.CustomInput_input__QwvFD,
.CustomInput_input__QwvFD::placeholder,
.CustomInput_inputLabelText__s0iBh,
.CustomInput_disabledLabel__yyXsV {
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.001em;
}
.CustomInput_input__QwvFD::placeholder {
  color: #9fa7b2;
  font-weight: 500;
}
.CustomInput_input__QwvFD:focus {
  outline: none;
}
.CustomInput_input__QwvFD:disabled {
  background-color: #f4f4f4;
  color: #9fa7b2;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/myProfile/components/customInput/CustomInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;AACA;EACE,cAAc;AAChB;AACA;;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,oDAAoD;EACpD,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;;;;EAIE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".inputLabel {\n  display: flex;\n  flex-direction: column;\n  z-index: 1;\n}\n.inputLabelText {\n  color: #5f6d7e;\n}\n.inputLabel,\n.disabledLabel {\n  font-weight: 600;\n  margin: 0px 0px 8px;\n}\n.disabledLabel {\n  color: #9fa7b2;\n}\n.input {\n  border: 1px solid #d8dbdf;\n  border-radius: 8px;\n  box-shadow: 4px 4px 64px 0px rgba(95, 109, 126, 0.1);\n  height: 46px;\n  font-weight: 500;\n  padding: 0px 16px;\n}\n.red {\n  border: 2px solid #d30404;\n}\n.input,\n.input::placeholder,\n.inputLabelText,\n.disabledLabel {\n  font-family: Inter;\n  font-size: 16px;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n}\n.input::placeholder {\n  color: #9fa7b2;\n  font-weight: 500;\n}\n.input:focus {\n  outline: none;\n}\n.input:disabled {\n  background-color: #f4f4f4;\n  color: #9fa7b2;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": `CustomInput_inputLabel__gAfvC`,
	"inputLabelText": `CustomInput_inputLabelText__s0iBh`,
	"disabledLabel": `CustomInput_disabledLabel__yyXsV`,
	"input": `CustomInput_input__QwvFD`,
	"red": `CustomInput_red__KT0it`
};
export default ___CSS_LOADER_EXPORT___;
