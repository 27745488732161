import { Radio, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../OsvrtiNaVoditelja.module.css";

function EvaluacijaItem({
  title,
  description,
  index,
  evaluationValues,
  setEvaluationValues,
}) {
  const [selectedValue, setSelectedValue] = useState(evaluationValues[index]);

  useEffect(() => {
    setSelectedValue(evaluationValues[index]);
  }, [evaluationValues, index]);

  const handleRadioChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedValue(newValue);
    const newEvaluationValues = [...evaluationValues];
    newEvaluationValues[index] = newValue;
    setEvaluationValues(newEvaluationValues);
  };

  return (
    <div className={styles.evaluationBtnsContainer}>
      <div className={styles.evaluationText}>
        <p className={styles.evaluationTitle}>{title}</p>
        <p className={styles.evaluationDescription}>{description}</p>
      </div>
      <div className={styles.btns}>
        {[5, 4, 3, 2, 1].map((value) => (
          <div key={value} className={styles.evaluationItem}>
            <Radio
              value={value}
              checked={selectedValue === value}
              onChange={handleRadioChange}
              className={`${styles.customRadio} ${
                selectedValue === value ? styles.customRadioChecked : ""
              }`}
            />
            <Typography className={styles.radioLabel}>
              {value === 5
                ? "Odlično"
                : value === 4
                ? "Vrlo dobro"
                : value === 3
                ? "Osrednje dobo"
                : value === 2
                ? "Jedva dovoljno"
                : "Nedovoljno"}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EvaluacijaItem;
