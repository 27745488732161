// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrimljeneObavijesti_wrapper__h\\+unj,
.PrimljeneObavijesti_date__olIt0,
.PrimljeneObavijesti_topTitle__saztH {
  gap: 16px;
}
.PrimljeneObavijesti_wrapper__h\\+unj,
.PrimljeneObavijesti_notificationColumn__HFc4a {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}
.PrimljeneObavijesti_topTitle__saztH {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #2e3646;
}
.PrimljeneObavijesti_emptySpan__omEhg {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  color: #5f6d7e;
}
.PrimljeneObavijesti_topTitle__saztH,
.PrimljeneObavijesti_emptySpan__omEhg {
  font-family: Inter;
  text-align: left;
}
.PrimljeneObavijesti_wrapper__h\\+unj,
.PrimljeneObavijesti_horizontal__xmh6i,
.PrimljeneObavijesti_notificationColumn__HFc4a,
.PrimljeneObavijesti_readColumn__L-HFc,
.PrimljeneObavijesti_topTitle__saztH,
.PrimljeneObavijesti_emptyContainer__b0e3Q {
  width: 100%;
}
.PrimljeneObavijesti_topTitle__saztH,
.PrimljeneObavijesti_readColumn__L-HFc,
.PrimljeneObavijesti_date__olIt0 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PrimljeneObavijesti_horizontal__xmh6i {
  border: 1px solid #e2e2e2;
}
.PrimljeneObavijesti_notificationColumn__HFc4a,
.PrimljeneObavijesti_readColumn__L-HFc {
  font-family: Inter;
  color: #4d4d4d;
}
.PrimljeneObavijesti_readColumn__L-HFc {
  flex-direction: column;
  margin-top: 24px;
}
.PrimljeneObavijesti_date__olIt0 {
  text-transform: uppercase;
  width: 20%;
}
.PrimljeneObavijesti_emptyContainer__b0e3Q {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  border: 2px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 4px 4px 64px 0px #5f6d7e1a;
  background-color: #fcfcfc;
  box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/PrimljeneObavijesti/PrimljeneObavijesti.module.css"],"names":[],"mappings":"AAAA;;;EAGE,SAAS;AACX;AACA;;EAEE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,cAAc;AAChB;AACA;;EAEE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;;;;;EAME,WAAW;AACb;AACA;;;EAGE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,yBAAyB;AAC3B;AACA;;EAEE,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,sCAAsC;EACtC,yBAAyB;EACzB,sBAAsB;AACxB","sourcesContent":[".wrapper,\n.date,\n.topTitle {\n  gap: 16px;\n}\n.wrapper,\n.notificationColumn {\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n  justify-content: center;\n}\n.topTitle {\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 17px;\n  letter-spacing: 0em;\n  color: #2e3646;\n}\n.emptySpan {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  color: #5f6d7e;\n}\n.topTitle,\n.emptySpan {\n  font-family: Inter;\n  text-align: left;\n}\n.wrapper,\n.horizontal,\n.notificationColumn,\n.readColumn,\n.topTitle,\n.emptyContainer {\n  width: 100%;\n}\n.topTitle,\n.readColumn,\n.date {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.horizontal {\n  border: 1px solid #e2e2e2;\n}\n.notificationColumn,\n.readColumn {\n  font-family: Inter;\n  color: #4d4d4d;\n}\n.readColumn {\n  flex-direction: column;\n  margin-top: 24px;\n}\n.date {\n  text-transform: uppercase;\n  width: 20%;\n}\n.emptyContainer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 32px;\n  border: 2px solid #e2e2e2;\n  border-radius: 8px;\n  box-shadow: 4px 4px 64px 0px #5f6d7e1a;\n  background-color: #fcfcfc;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PrimljeneObavijesti_wrapper__h+unj`,
	"date": `PrimljeneObavijesti_date__olIt0`,
	"topTitle": `PrimljeneObavijesti_topTitle__saztH`,
	"notificationColumn": `PrimljeneObavijesti_notificationColumn__HFc4a`,
	"emptySpan": `PrimljeneObavijesti_emptySpan__omEhg`,
	"horizontal": `PrimljeneObavijesti_horizontal__xmh6i`,
	"readColumn": `PrimljeneObavijesti_readColumn__L-HFc`,
	"emptyContainer": `PrimljeneObavijesti_emptyContainer__b0e3Q`
};
export default ___CSS_LOADER_EXPORT___;
