// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-obracun-soba {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  border: 1px solid #d1d9e2;
  border-top: none;
  box-shadow: 0px 1px 2px 0px #1018280a;
  border-radius: 8px;
  background-color: white;
  min-width: fit-content;
  margin-top: 16px;
}
.table-obracun-soba th,
.table-obracun-soba td {
  padding: 32px;
  border-bottom: 1px solid #d1d9e2;
}
.table-obracun-soba td {
  padding: 24px 24px 24px 32px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #5f6d7e;
}
.table-obracun-soba tr:last-child {
  border-bottom: none;
}
.table-obracun-soba th,
.table-obracun-soba tr {
  text-align: -webkit-center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #ffffff;
}
.table-obracun-soba th {
  padding: 16px 16px 16px 48px;
}
.table-obracun-soba tr {
  font-weight: 500;
  width: 100%;
}
@media screen and (max-width: 500px) {
  .table-obracun-soba {
    margin-left: 100px;
  }
}

@media screen and (max-width: 420px) {
  .table-obracun-soba {
    margin-left: 140px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/scenes/obracunSoba/components/ObracunTable/ObracunTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,qCAAqC;EACrC,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;AAClB;AACA;;EAEE,aAAa;EACb,gCAAgC;AAClC;AACA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;;EAEE,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".table-obracun-soba {\n  width: 100%;\n  border-collapse: collapse;\n  border-radius: 10px;\n  border: 1px solid #d1d9e2;\n  border-top: none;\n  box-shadow: 0px 1px 2px 0px #1018280a;\n  border-radius: 8px;\n  background-color: white;\n  min-width: fit-content;\n  margin-top: 16px;\n}\n.table-obracun-soba th,\n.table-obracun-soba td {\n  padding: 32px;\n  border-bottom: 1px solid #d1d9e2;\n}\n.table-obracun-soba td {\n  padding: 24px 24px 24px 32px;\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 17px;\n  letter-spacing: 0em;\n  text-align: center;\n  color: #5f6d7e;\n}\n.table-obracun-soba tr:last-child {\n  border-bottom: none;\n}\n.table-obracun-soba th,\n.table-obracun-soba tr {\n  text-align: -webkit-center;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: 0em;\n  color: #ffffff;\n}\n.table-obracun-soba th {\n  padding: 16px 16px 16px 48px;\n}\n.table-obracun-soba tr {\n  font-weight: 500;\n  width: 100%;\n}\n@media screen and (max-width: 500px) {\n  .table-obracun-soba {\n    margin-left: 100px;\n  }\n}\n\n@media screen and (max-width: 420px) {\n  .table-obracun-soba {\n    margin-left: 140px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
