import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const getVoditelji = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/getAllOrVoditelji`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting voditelji data", error);
    throw error;
  }
};
export const getMyVoditelji = async (accessToken) => {
  try {
    const response = await axios.get(`${apiUrl}/api/getMyOrVoditelji`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting voditelji data", error);
    throw error;
  }
};
