import { Navigate } from "react-router-dom";
import { useUserState } from "../context/UserContext";

export const ProtectedRoute = ({ children, allowedRoutes, redirectTo }) => {
  const { userData } = useUserState();
  if (
    userData.roleId === 1 &&
    !allowedRoutes?.includes(window.location.pathname)
  ) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};
