// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../assets/svg/ChevronDownIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectDropdown_container__v\\+6r- {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.SelectDropdown_containerRow__mnxYw {
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
.SelectDropdown_label__xP4xS {
  color: #828d9a;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
  text-align: left;
}
.SelectDropdown_select__kZgtU {
  appearance: none;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  background-position: right 16px center;
  border: 1px solid #d8dbdf;
  border-radius: 8px;
  color: #5f6d7e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  height: 40px;
  padding: 0px 16px;
  min-width: 200px;
}
.SelectDropdown_select__kZgtU:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/documents/components/enrollmentApplications/components/selectDropdown/SelectDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,gBAAgB;AAClB;AACA;EAGE,gBAAgB;EAChB,6DAA6E;EAC7E,sCAAsC;EACtC,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;AACf","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n.containerRow {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  column-gap: 8px;\n}\n.label {\n  color: #828d9a;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n  text-align: left;\n}\n.select {\n  -moz-appearance: none;\n  -webkit-appearance: none;\n  appearance: none;\n  background: url(\"../../../../../../assets/svg/ChevronDownIcon.svg\") no-repeat;\n  background-position: right 16px center;\n  border: 1px solid #d8dbdf;\n  border-radius: 8px;\n  color: #5f6d7e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  height: 40px;\n  padding: 0px 16px;\n  min-width: 200px;\n}\n.select:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SelectDropdown_container__v+6r-`,
	"containerRow": `SelectDropdown_containerRow__mnxYw`,
	"label": `SelectDropdown_label__xP4xS`,
	"select": `SelectDropdown_select__kZgtU`
};
export default ___CSS_LOADER_EXPORT___;
