// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EvidencijaDolazaka_info__OU9gD {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #ffebeb;
  border-radius: 8px;
  padding: 0 20px;
}

.EvidencijaDolazaka_infoP__EPrLO {
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  color: #5f6d7e;
}

.EvidencijaDolazaka_loadingContainer__LveFT {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/evidencijaDolazaka/EvidencijaDolazaka.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".info {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  background-color: #ffebeb;\n  border-radius: 8px;\n  padding: 0 20px;\n}\n\n.infoP {\n  font-size: 14px;\n  font-weight: 500;\n  font-family: \"Inter\";\n  color: #5f6d7e;\n}\n\n.loadingContainer {\n  height: 90vh;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `EvidencijaDolazaka_info__OU9gD`,
	"infoP": `EvidencijaDolazaka_infoP__EPrLO`,
	"loadingContainer": `EvidencijaDolazaka_loadingContainer__LveFT`
};
export default ___CSS_LOADER_EXPORT___;
