import React from "react";
import Text from "../../../../../assets/img/TextIcon.png";
import ENG from "../../../../../assets/potvrdeIDiplome/CertificateENG.pdf";
import HR from "../../../../../assets/potvrdeIDiplome/CertificateHR.pdf";
import SLO from "../../../../../assets/potvrdeIDiplome/CertificateSLO.pdf";
import SRB from "../../../../../assets/potvrdeIDiplome/CertificateSRB.pdf";
import { useUserState } from "../../../../../context/UserContext";
import { SupervizijeSuglasnostMock } from "../Table/MockData";
import styles from "./CertificateContainer.module.css";
import { makePDFCertificate } from "./makePDFCertificate";
import { handleDownload } from "./utils";

function CertificateContainer() {
  const { userData, role } = useUserState();
  const fullName = localStorage.getItem("evidentionFullName");
  const userName =
    role === "Referada"
      ? fullName
      : `${userData.firstName} ${userData.lastName}`;

  return (
    <div className={styles.wrapper}>
      {SupervizijeSuglasnostMock.length === 2 ? (
        <>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Potvrda (HRV)</span>
            </div>
            <button
              className={styles.btn}
              onClick={() =>
                handleDownload(makePDFCertificate, HR, userName, "Potvrda")
              }
            >
              Preuzmi
            </button>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Potrdilo(SLO)</span>
            </div>
            <button
              className={styles.btn}
              onClick={() =>
                handleDownload(makePDFCertificate, SLO, userName, "Potrdilo")
              }
            >
              Prenesi
            </button>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Uverenje(SRB)</span>
            </div>
            <button
              className={styles.btn}
              onClick={() =>
                handleDownload(makePDFCertificate, SRB, userName, "Uverenje")
              }
            >
              Preuzmi
            </button>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <img alt="" className={styles.icon} src={Text} />
              <span className={styles.label}>Certificate (ENG)</span>
            </div>
            <button
              className={styles.btn}
              onClick={() =>
                handleDownload(makePDFCertificate, ENG, userName, "Certificate")
              }
            >
              Download
            </button>
          </div>
        </>
      ) : (
        <span className={styles.empty}>
          Potvrdu ćeš moći preuzeti nakon što dobiješ suglasnost supervizora
        </span>
      )}
    </div>
  );
}

export default CertificateContainer;
