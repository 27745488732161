import React, { useEffect, useState } from "react";
import Checkbox from "../checkbox/Checkbox";
import styles from "./ZakupiteljChoice.module.css";
import { updateZakupitelj } from "./api/updateZakupitelj";

function ZakupiteljChoice({ cities, userData, zakupitelj, authToken }) {
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (zakupitelj) {
      setSelectedCities(zakupitelj?.cities || []);
      setSelectedChoices(zakupitelj?.choices || {});
    }
  }, [zakupitelj]);

  const handleCitySelection = (city) => {
    const isCityLocked = zakupitelj?.cities?.some(
      (c) => c.value === city.value
    );
    if (isCityLocked) return;

    const isCitySelected = selectedCities?.some((c) => c.value === city.value);
    if (isCitySelected) {
      setSelectedCities(selectedCities?.filter((c) => c.value !== city.value));
      const updatedChoices = { ...selectedChoices };
      delete updatedChoices[city.value];
      setSelectedChoices(updatedChoices);
    } else {
      setSelectedCities([...selectedCities, city]);
      setSelectedChoices({ ...selectedChoices, [city.value]: null });
    }
  };

  const handleChoiceChange = (cityValue, choice) => {
    const isChoiceLocked = zakupitelj?.cities?.some(
      (c) => c.value === cityValue
    );
    if (isChoiceLocked) return;

    setSelectedChoices({
      ...selectedChoices,
      [cityValue]: choice,
    });
  };

  const handleOdustani = () => {
    setSelectedCities(zakupitelj ? zakupitelj?.cities : []);
    setSelectedChoices(zakupitelj ? zakupitelj?.choices : []);
    setEditMode(false);
  };

  const handlePotvrdi = async () => {
    if (selectedCities.length === 0) {
      alert("Morate odabrati gradove.");
      return;
    } else if (selectedCities?.some((city) => !selectedChoices[city.value])) {
      alert("Morate odabrati vrstu zakupljivanja za svaki grad.");
      return;
    } else {
      const cities = selectedCities.map((city) => ({
        ...city,
        choice: selectedChoices[city.value],
      }));

      const hasChanges =
        JSON.stringify(cities) !== JSON.stringify(zakupitelj?.cities);

      if (hasChanges) {
        await updateZakupitelj({ userId: userData.id, cities }, authToken);
      }

      setEditMode(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Status zakupitelja</h1>
      <p className={styles.text}>
        U ovom izborniku odaberi gradove u kojima želiš biti zakupitelj tijekom
        školske godine (prije odabira pritisnite gumb "Uredi"):
      </p>
      <div className={styles.container}>
        <div className={styles.checkCitiesContainer}>
          {cities?.length > 0 &&
            cities.map((city) => {
              const isLocked = zakupitelj?.cities?.some(
                (c) => c.value === city.value
              );
              return (
                <Checkbox
                  key={city.value}
                  onChange={() => handleCitySelection(city)}
                  value={selectedCities?.some((c) => c.value === city.value)}
                  label={city.label}
                  disabled={!editMode || isLocked}
                />
              );
            })}
        </div>

        {selectedCities?.length > 0 && (
          <>
            <p className={styles.text}>
              U ovom izborniku odaberi svoj status zakupitelja za pojedini grad:
            </p>
            <p className={styles.text} style={{ margin: "10px 0px" }}>
              - Premium zakupitelj (samo supervizori i diplomirani Voditelji
              VT-a) - zakupitelji koji jednom godišnje po pozivu referade
              (unutar nekoliko dana) mogu zauzeti nizove termina u sobama u
              određene sate i dane po povlaštenoj cijeni koje kasnije
              popunjavaju imenima klijenata. Premium korisnik može otkazati
              termin najmanje 7 dana ranije. Ako prilikom rezervacije termina
              početkom školske godine ne možeš planirati i zauzeti niz
              zakupljenih termina za određeni grad znači da u ovoj školskoj
              godini trebaš za taj grad odabrati status Basic.
            </p>
            <p className={styles.text}>
              - Basic zakupitelj – zauzima jedan po jedan termin uz mogućnost
              besplatnog otkazivanja više od 24 sata, a nakon 24 sata do 15%
              zakazanih termina. Svi zakupitelji imaju obavezu što ranije
              obavijestiti WP grupu o otkazanim terminima kako bi ostali
              terapeuti mogli koristiti otkazane sobe. Status koji odabereš na
              početku školske godine se ne može mijenjati do sljedećeg odabira
              statusa u 9. mjesecu.
            </p>
            <div className={styles.selectedCitiesContainer}>
              {selectedCities?.map((city) => {
                const isLocked = zakupitelj?.cities?.some(
                  (c) => c.value === city.value
                );
                return (
                  <div key={city.value} className={styles.cityInputContainer}>
                    <p className={styles.text}>{city.label}</p>
                    <div className={styles.radioContainer}>
                      <div className={styles.radioRowContainer}>
                        <input
                          type="radio"
                          className={styles.radioInput}
                          name={`choice-${city.value}`}
                          value="Basic"
                          checked={selectedChoices[city.value] === "Basic"}
                          onChange={() =>
                            handleChoiceChange(city.value, "Basic")
                          }
                          disabled={!editMode || isLocked}
                        />
                        <label className={styles.text}>Basic</label>
                      </div>
                      {(userData.roleId === 5 || userData.roleId === 4) && (
                        <div className={styles.radioRowContainer}>
                          <input
                            type="radio"
                            className={styles.radioInput}
                            name={`choice-${city.value}`}
                            value="Premium"
                            checked={selectedChoices[city.value] === "Premium"}
                            onChange={() =>
                              handleChoiceChange(city.value, "Premium")
                            }
                            disabled={!editMode || isLocked}
                          />
                          <label className={styles.text}>Premium</label>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      {!editMode && (
        <div className={styles.btnsContainer}>
          <button
            className={styles.submitButton}
            onClick={() => setEditMode(true)}
          >
            Uredi
          </button>
        </div>
      )}
      {editMode && (
        <div className={styles.btnsContainer}>
          <button className={styles.odustaniButton} onClick={handleOdustani}>
            Odustani
          </button>
          <button className={styles.submitButton} onClick={handlePotvrdi}>
            Potvrdi
          </button>
        </div>
      )}
    </div>
  );
}

export default ZakupiteljChoice;
