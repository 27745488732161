// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleSwitch_container__jvyDE {
  align-items: center;
  column-gap: 16px;
  display: flex;
}
.ToggleSwitch_label__YbzPD {
  color: #4d4e4e;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
}
.ToggleSwitch_input__QEwND {
  display: none;
}
.ToggleSwitch_toggleSwitchOff__-3G\\+J {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 64px;
  width: 48px;
  height: 22px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.ToggleSwitch_toggleSwitchOn__yvLnN {
  background-color: #cb9c2d;
  display: flex;
  align-items: center;
  border-radius: 64px;
  width: 48px;
  height: 22px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.ToggleSwitch_slider__39DBD {
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s ease;
  margin: 1.5px;
}
.ToggleSwitch_toggleSwitchOn__yvLnN .ToggleSwitch_slider__39DBD {
  transform: translateX(26px);
}
`, "",{"version":3,"sources":["webpack://./src/components/toggleSwitch/ToggleSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,sCAAsC;AACxC;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,sCAAsC;AACxC;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,+BAA+B;EAC/B,aAAa;AACf;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".container {\n  align-items: center;\n  column-gap: 16px;\n  display: flex;\n}\n.label {\n  color: #4d4e4e;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n}\n.input {\n  display: none;\n}\n.toggleSwitchOff {\n  display: flex;\n  align-items: center;\n  background-color: #e0e0e0;\n  border-radius: 64px;\n  width: 48px;\n  height: 22px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n.toggleSwitchOn {\n  background-color: #cb9c2d;\n  display: flex;\n  align-items: center;\n  border-radius: 64px;\n  width: 48px;\n  height: 22px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n.slider {\n  position: relative;\n  width: 17px;\n  height: 17px;\n  border-radius: 50%;\n  background-color: white;\n  transition: transform 0.3s ease;\n  margin: 1.5px;\n}\n.toggleSwitchOn .slider {\n  transform: translateX(26px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ToggleSwitch_container__jvyDE`,
	"label": `ToggleSwitch_label__YbzPD`,
	"input": `ToggleSwitch_input__QEwND`,
	"toggleSwitchOff": `ToggleSwitch_toggleSwitchOff__-3G+J`,
	"toggleSwitchOn": `ToggleSwitch_toggleSwitchOn__yvLnN`,
	"slider": `ToggleSwitch_slider__39DBD`
};
export default ___CSS_LOADER_EXPORT___;
