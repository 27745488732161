import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Question from "../../../../../assets/img/Help.png";
import Success from "../../../../../assets/img/Success.png";
import LoaderModal from "../../../../../components/LoaderModal/LoaderModal";
import Modal from "../../../../../components/Modal/Modal";
import { useUserState } from "../../../../../context/UserContext";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import AcceptanceModal from "../AcceptanceModal/AcceptanceModal";
import { acceptProgramApplication } from "../API/acceptProgramApplication";
import { postMentorReview } from "../API/postMentorComment";
import styles from "./OdobrenjeKomentar.module.css";

function OdobrenjeKomentar({
  onClose,
  firstName,
  lastName,
  userId,
  applicationId,
  mentorReview,
  isAccepted,
  course,
  fetchApplication,
}) {
  const { authToken } = useAuthentication();
  const { userData } = useUserState();
  const navigate = useNavigate();
  const [review, setReview] = useState(mentorReview || "");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openAcceptanceModal, setOpenAcceptanceModal] = useState(false);
  const [isSuccesfullAcceptance, setIsSuccessfulAcceptance] =
    useState(isAccepted);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  useEffect(() => {
    setReview(mentorReview || "");
    setIsSuccessfulAcceptance(isAccepted);
    if (mentorReview !== undefined && isAccepted !== undefined) {
      setIsLoading(false);
    }
  }, [mentorReview, isAccepted]);
  let notificationData = {
    primatelj: userId,
    subject: `Aplikacija za upis`,
    description: `Aplikacija za upis više godine ${course}-a je prihvaćena.`,
    userId: userData.id,
  };
  const handleApplicationResponse = (isAccepted) => {
    acceptProgramApplication(
      authToken,
      applicationId,
      setModalContent,
      Success,
      navigate,
      setShowModal,
      Question,
      setIsLoading,
      setOpenAcceptanceModal,
      fetchApplication,
      notificationData,
      isAccepted
    );
  };
  return (
    <div>
      {isLoading && <LoaderModal />}
      <div className={styles.modalWrapper}>
        <div className={styles.infoModal}>
          <p className={styles.title}>Komentar za upis više godine</p>
          <p className={styles.subtitle}>
            Polaznik: {`${firstName} ${lastName}` || "Mislav Berić"}
          </p>
          <div className={styles.inputWrapper}>
            <button
              className={
                styles[
                  isSuccesfullAcceptance === false
                    ? "FalseBtn"
                    : isSuccesfullAcceptance === true
                      ? "TrueBtn"
                      : "successBtn"
                ]
              }
              onClick={() => setOpenAcceptanceModal(true)}
            >
              {isSuccesfullAcceptance === false
                ? "Odbijen upis za višu godinu"
                : isSuccesfullAcceptance === true
                  ? "Odobren upis za višu godinu"
                  : "Prihvatite upis za višu godinu"}
            </button>
            <textarea
              placeholder="Upiši svoj komentar"
              className={styles.input}
              name="review"
              value={review}
              onChange={
                !mentorReview ? (e) => setReview(e.target.value) : undefined
              }
              disabled={Boolean(mentorReview)}
            />
          </div>
          {!mentorReview && (
            <div className={styles.btnRow}>
              <button onClick={() => onClose()} className={styles.cancelBtn}>
                Odustani
              </button>
              <button
                className={styles.successBtn}
                onClick={() =>
                  postMentorReview(
                    authToken,
                    userId,
                    applicationId,
                    review,
                    setModalContent,
                    Success,
                    setShowModal,
                    Question,
                    navigate,
                    fetchApplication
                  )
                }
              >
                Dodaj komentar
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          image={modalContent.image}
          description={modalContent.description}
          buttons={modalContent.buttons}
        />
      )}
      {openAcceptanceModal && (
        <AcceptanceModal
          onAccept={() => handleApplicationResponse(true)}
          onClose={() => setOpenAcceptanceModal(false)}
          polaznik={`${firstName} ${lastName}`}
          program={course}
          fetchApplication={fetchApplication}
          onReject={() => handleApplicationResponse(false)}
        />
      )}
    </div>
  );
}

export default OdobrenjeKomentar;
