import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Down from "../../../assets/img/ArrowDown.png";
import Question from "../../../assets/img/Help.png";
import Plus from "../../../assets/img/PlusIcon.png";
import Success from "../../../assets/img/Success.png";
import Error from "../../../assets/svg/ErrorModal.svg";
import Trash from "../../../assets/svg/TrashIcon2.svg";
import LoaderModal from "../../../components/LoaderModal/LoaderModal";
import Modal from "../../../components/Modal/Modal";
import { useCreateEventState } from "../../../context/CreateEventContext";
import { getRooms } from "../../FakultativnaRadionicaEditCreate/api/getRooms";
import {
  AddVoditeljButton,
  BackgroundImage,
  ButtonColumn,
  DatePlaceRow,
  DropdownArrow,
  EditButton,
  EditFooter,
  Header,
  Input,
  InputContainer,
  InputWrapper,
  ProgramImage,
  SectionTitle,
  SectionTitleRow,
  Select,
  TableContainer,
  TextArea,
  Title,
  TitleBold,
  UrediButton,
  VoditeljiContainer,
  Wrapper,
} from "../../FakultativnaRadionicaEditCreate/EditCreate/FakultativnaRadionicaEdit.styles";
import TablePolaznici from "../../FakultativnaRadionicaEditCreate/PolazniciTable/TablePolaznici";
import { columnNames } from "../../FakultativnaRadionicaEditCreate/utils";
import VoditeljiCard from "../../FakultativnaRadionicaEditCreate/VoditeljiCard/VoditeljiCard";
import { useAuthentication } from "../../hooks/useAuthentication";
import { addEventToEvidention } from "../../PojedinaPrijavnica/api/addEventToEvidention";
import { addUsersToIntensive } from "../api/addUsersToIntensive";
import { createIntensive } from "../api/createIntensive";
import { deleteIntensive } from "../api/deleteIntensive";
import { updateIntensive } from "../api/updateIntensive";
import { getSubcategoryOptions, vrstaIntenziva } from "../utils";
import { ImageButton } from "./IntenziviEditCreate.styles";

function IntenziviEditCreate() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authToken } = useAuthentication();
  const [selectedImage, setSelectedImage] = useState(null);
  const [rooms, setRooms] = useState([]);
  const isCreateMode = location?.state?.isCreateMode || false;
  const isEditMode = location?.state?.isEditMode || false;
  const intenzivData = isEditMode && location?.state?.intenzivData;
  const isEditUsersArrayBeforeEvidention =
    location?.state?.isEditUsersArrayBeforeEvidention || false;
  const [weekend, setWeekend] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [polazniciIds, setPolazniciIds] = useState([]);
  const [showImage, setShowImage] = useState(null);
  const { createEventData, updateCreateEventData, resetCreateEventData } =
    useCreateEventState();
  const allowedRoutes = [
    "/intenzivi-edit-create/six",
    "/dodaj-polaznika-u-grupu",
  ];
  const sectionRef = useRef(null);
  useEffect(() => {
    if (isEditUsersArrayBeforeEvidention) {
      sectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isEditUsersArrayBeforeEvidention]);
  useEffect(() => {
    return () => {
      setTimeout(() => {
        if (
          !allowedRoutes.some((route) => location.pathname.startsWith(route))
        ) {
          resetCreateEventData();
        }
      }, 0);
    };
  }, [location.pathname]);
  useEffect(() => {
    const handleBeforeUnload = () => {
      resetCreateEventData();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);
  useEffect(() => {
    if (
      createEventData.voditeljiData?.length === 0 &&
      createEventData.polazniciData?.length === 0
    ) {
      updateCreateEventData({
        ...createEventData,
        voditeljiData: intenzivData.usersResponse?.voditeljResponse,
        polazniciData: intenzivData.usersResponse?.newPolazniciResponse,
        showImage: intenzivData.imageURL,
      });
    }
  }, [
    createEventData,
    intenzivData.usersResponse,
    updateCreateEventData,
    intenzivData.imageURL,
  ]);
  useEffect(() => {
    const extractedIds =
      createEventData?.polazniciData?.map((polaznik) => polaznik.id) ?? [];
    setPolazniciIds(extractedIds);
  }, [createEventData?.polazniciData]);

  const toggleDropdown = () => {
    setShowTable(!showTable);
  };
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    description: "",
    buttons: [],
  });
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      const formData = new FormData();
      formData.append("image", file);
      const imageFile = formData.get("image");
      setShowImage(imageUrl);
      setSelectedImage(imageFile);
      updateCreateEventData({
        ...createEventData,
        imageURL: imageFile,
        showImage: imageUrl,
      });
    }
  };
  const clearSelectedImage = () => {
    setSelectedImage(null);
    setShowImage(null);
    updateCreateEventData({
      ...createEventData,
      imageURL: null,
      showImage: null,
    });
  };
  const [formData, setFormData] = useState({
    name: "",
    dateFrom: "",
    dateTo: "",
    roomId: 99,
    gprHours: "",
    price: "",
    description: "",
    aditionalDescription: "",
    isWeekend: weekend,
    intensiveType: "",
    capacity: "",
    imageURL: "",
    masterAbbreviation: "",
  });

  const handleDeleteIntensive = async () => {
    try {
      await deleteIntensive(intenzivData.id, authToken);
      navigate("/programi/six");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await getRooms(authToken);
        const updatedRooms = [
          { value: 99, label: "Bez sobe", isLarge: false },
          ...(response.rooms?.map((room) => ({
            value: room.id,
            label: room.roomName,
            isLarge: room.isLarge,
          })) || []),
        ];

        setRooms(updatedRooms);
      } catch (error) {
        console.log(error);
      }
    };

    if (authToken) fetchRooms();
  }, [authToken]);

  const handleRoomSelection = (selectedRoomId) => {
    setFormData({
      ...formData,
      roomId: selectedRoomId,
    });
    updateCreateEventData({
      ...createEventData,
      roomId: selectedRoomId,
    });
  };

  useEffect(() => {
    if (isEditMode && intenzivData) {
      setFormData({
        type: intenzivData.isWeekend ? "vikend" : "ljetni",
        name: intenzivData.name || "",
        dateFrom: intenzivData.dateFrom || "",
        dateTo: intenzivData.dateTo || "",
        roomId: intenzivData.roomId || 99,
        gprHours: intenzivData.gprHours || "",
        isWeekend: intenzivData.isWeekend || "",
        capacity: intenzivData.capacity || "",
        intensiveType: intenzivData.intensiveType || "",
        price: intenzivData.price || "",
        description: intenzivData.description || "",
        aditionalDescription: intenzivData.aditionalDescription || "",
        imageURL: intenzivData.imageURL || "",
        masterAbbreviation: intenzivData.masterAbbreviation || "",
      });
    }
  }, [isEditMode, intenzivData]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "type") {
      const isWeekendSelected = value === "vikend";
      setWeekend(isWeekendSelected);
      setFormData({
        ...formData,
        [name]: value,
        isWeekend: isWeekendSelected,
      });
      updateCreateEventData({
        ...createEventData,
        [name]: value,
        isWeekend: isWeekendSelected,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      updateCreateEventData({
        ...createEventData,
        [name]: value,
      });
    }
  };

  const deleteVoditelj = (id) => {
    updateCreateEventData((prevState) => ({
      ...prevState,
      voditeljiData: prevState.voditeljiData.filter((item) => item.id !== id),
    }));
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (isCreateMode) {
        try {
          const polaznici = createEventData.polazniciData?.map(
            (polaznik) => polaznik.id
          );
          const voditelji = createEventData.voditeljiData?.map(
            (voditelj) => voditelj.id
          );
          const newData = {
            name: createEventData.name,
            dateFrom: createEventData.dateFrom,
            dateTo: createEventData.dateTo,
            roomId: createEventData.roomId || 99,
            gprHours: createEventData.gprHours,
            isWeekend: weekend,
            capacity: createEventData.capacity,
            intensiveType: createEventData.intensiveType,
            price: createEventData.price,
            description: createEventData.description,
            aditionalDescription: createEventData.aditionalDescription,
            imageURL: createEventData.imageURL,
            masterAbbreviation: createEventData.masterAbbreviation,
            polaznici,
            voditelji,
          };
          const formData = new FormData();

          Object.entries(newData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          });

          const response = await createIntensive(formData, authToken);
          const responseData = response.data;
          if (response.status === 200) {
            setIsLoading(false);
            setModalContent({
              image: Success,
              description: "Uspješno ste kreirali intenziv",
              buttons: [
                {
                  label: "U redu",
                  action: () => navigate(-1),
                  className: "modal-btn-success",
                },
              ],
            });
            setShowModal(true);
          } else {
            setIsLoading(false);
            setModalContent({
              image: Error,
              description: "Nešto je pošlo po krivu. Pokušajte ponovo",
              buttons: [
                {
                  label: "U redu",
                  action: () => navigate(-1),
                  className: "",
                },
              ],
            });
            setShowModal(true);
            console.log("Error occurred:", responseData.opis);
          }
          resetCreateEventData();
          return responseData;
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          return null;
        }
      } else if (isEditMode) {
        try {
          const polaznici = createEventData.polazniciData?.map(
            (polaznik) => polaznik.id
          );
          const voditelji = createEventData.voditeljiData?.map(
            (voditelj) => voditelj.id
          );
          const updatedData = {
            name: createEventData.name || intenzivData.name,
            dateFrom: createEventData.dateFrom || intenzivData.dateFrom,
            dateTo: createEventData.dateTo || intenzivData.dateTo,
            roomId: createEventData.roomId || intenzivData.roomId || 99,
            gprHours: createEventData.gprHours || intenzivData.gprHours,
            isWeekend: createEventData.isWeekend || intenzivData.isWeekend,
            capacity: createEventData.capacity || intenzivData.capacity,
            intensiveType:
              createEventData.intensiveType || intenzivData.intensiveType,
            price: createEventData.price || intenzivData.price,
            description:
              createEventData.description || intenzivData.description,
            aditionalDescription: createEventData.aditionalDescription,
            imageURL: createEventData.imageURL || intenzivData.imageURL,
          };

          const formData = new FormData();

          Object.entries(updatedData).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, value);
            }
          });

          const response = await updateIntensive(
            intenzivData.id,
            formData,
            authToken
          );

          const userData = {
            intensiveId: intenzivData.id,
            polaznici: polaznici,
            voditelji: voditelji,
          };

          await addUsersToIntensive(userData, authToken);

          const responseData = response.data;
          if (response.status === 200) {
            if (isEditUsersArrayBeforeEvidention) {
              try {
                const response = await addEventToEvidention(
                  "intenziv",
                  intenzivData?.id,
                  polazniciIds,
                  authToken
                );
                if (response.status === 200) {
                  setIsLoading(false);
                  setModalContent({
                    image: Success,
                    description: "Uspješno ste dodali intenziv u evidenciju.",
                    buttons: [
                      {
                        label: "U redu",
                        action: () => navigate(-1),
                        className: "modal-btn-success",
                      },
                    ],
                  });
                  setShowModal(true);
                }
              } catch (err) {
                console.log("Error adding user : ", err);
                setIsLoading(false);
                setModalContent({
                  image: Error,
                  description: "Nešto je pošlo po krivu",
                  buttons: [
                    {
                      label: "U redu",
                      action: () => navigate(-1),
                      className: "modal-btn-success",
                    },
                  ],
                });
                setShowModal(true);
              }
            } else {
              setIsLoading(false);
              setModalContent({
                image: Success,
                description: "Promjene su spremljene",
                buttons: [
                  {
                    label: "U redu",
                    action: () => navigate(-1),
                    className: "modal-btn-success",
                  },
                ],
              });
              setShowModal(true);
            }
          } else {
            setIsLoading(false);
            setModalContent({
              image: Question,
              description: "Nešto je pošlo po krivu. Pokušajte ponovo",
              buttons: [
                {
                  label: "U redu",
                  action: () => navigate(-1),
                  className: "",
                },
              ],
            });
            setShowModal(true);
            console.log("Error occurred:", responseData.opis);
          }
          resetCreateEventData();
          return responseData;
        } catch (error) {
          setIsLoading(false);
          console.log(error);
          return null;
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (formData.type === "ljetni") {
      setFormData({ ...formData, gprHours: 25 });
      updateCreateEventData({
        ...createEventData,
        gprHours: 25,
      });
    } else if (formData.type === "vikend") {
      setFormData({
        ...formData,
        gprHours:
          createEventData.intensiveType === "povratakSebi" ||
          formData.intensiveType === "povratakSebi"
            ? 13
            : 12,
      });
      updateCreateEventData({
        ...createEventData,
        gprHours:
          createEventData.intensiveType === "povratakSebi" ||
          formData.intensiveType === "povratakSebi"
            ? 13
            : 12,
      });
    }
  }, [
    formData.type,
    createEventData.intensiveType,
    formData.intensiveType,
    createEventData,
    updateCreateEventData,
    formData,
  ]);

  return (
    <>
      {isLoading && <LoaderModal />}
      <Wrapper>
        <Header>
          <BackgroundImage />
          {isCreateMode || isEditMode ? (
            <>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                style={{ display: "none", cursor: "pointer" }}
                onChange={handleImageUpload}
              />
              <ImageButton
                selectedImage={createEventData.showImage}
                onClick={clearSelectedImage}
              >
                <label htmlFor="imageInput">
                  {!createEventData.showImage &&
                  !showImage &&
                  !selectedImage &&
                  !createEventData.imageURL ? (
                    <ButtonColumn>
                      <img alt="" src={Plus} />
                      Dodaj sliku
                    </ButtonColumn>
                  ) : null}
                </label>
              </ImageButton>
            </>
          ) : (
            <ProgramImage backgroundimage={intenzivData.imageURL} />
          )}
        </Header>
        <Title>
          {isCreateMode && <Title>Novi intenziv</Title>}
          {isEditMode && (
            <>
              {formData.name}
              <UrediButton disabled>Uredi</UrediButton>
            </>
          )}
        </Title>
        <InputWrapper>
          <InputContainer>
            <SectionTitle>Naziv intenziva</SectionTitle>
            <Input
              type="text"
              name="name"
              placeholder="Upiši"
              defaultValue={createEventData.name || formData.name}
              onChange={handleInputChange}
            />
          </InputContainer>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Od datuma</SectionTitle>
              <Input
                type="date"
                name="dateFrom"
                value={
                  isEditMode
                    ? createEventData.dateFrom ||
                      intenzivData.dateFrom.split("T")[0]
                    : createEventData.dateFrom || formData.dateFrom
                }
                placeholder="Odaberi"
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <SectionTitle>Do datuma</SectionTitle>
              <Input
                type="date"
                name="dateTo"
                value={
                  isEditMode
                    ? createEventData.dateTo ||
                      intenzivData.dateTo.split("T")[0]
                    : createEventData.dateTo || formData.dateTo
                }
                placeholder="Odaberi"
                onChange={handleInputChange}
              />
            </InputContainer>
          </DatePlaceRow>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Mjesto održavanja</SectionTitle>
              <Select
                type="text"
                name="roomId"
                value={createEventData.roomId || formData.roomId}
                onChange={(e) => handleRoomSelection(e.target.value)}
                style={{ width: "100%" }}
              >
                <option disabled selected>
                  Odaberi sobu
                </option>
                {rooms?.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <SectionTitle>Vrsta intenziva</SectionTitle>
              <Select
                type="text"
                name="type"
                value={
                  isEditMode
                    ? formData.isWeekend
                      ? vrstaIntenziva[1].value
                      : vrstaIntenziva[0].value
                    : formData?.intensiveType
                }
                onChange={(e) => {
                  handleInputChange(e);
                }}
                disabled={isEditMode}
                style={{ width: "100%" }}
              >
                <option>Odaberi</option>
                {vrstaIntenziva.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </DatePlaceRow>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Podkategorija intenziva</SectionTitle>
              <Select
                type="text"
                name="intensiveType"
                value={formData.intensiveType}
                onChange={handleInputChange}
                style={{ width: "100%" }}
                disabled={isEditMode}
              >
                <option>Odaberi</option>
                {getSubcategoryOptions(formData).map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </InputContainer>
            <InputContainer>
              <SectionTitle>Broj sati GPR-a</SectionTitle>
              <Input
                type="number"
                placeholder="Upiši"
                name="gprHours"
                defaultValue={createEventData.gprHours || formData.gprHours}
                onChange={handleInputChange}
                disabled
              />
            </InputContainer>
          </DatePlaceRow>
          <DatePlaceRow>
            <InputContainer>
              <SectionTitle>Broj dostupnih mjesta</SectionTitle>
              <Input
                type="number"
                placeholder="Upiši"
                name="capacity"
                defaultValue={createEventData.capacity || formData.capacity}
                onChange={handleInputChange}
              />
            </InputContainer>
            <InputContainer>
              <SectionTitle>Kratica za Master kalendar</SectionTitle>
              <Input
                type="text"
                placeholder="----"
                name="masterAbbreviation"
                defaultValue={
                  createEventData.masterAbbreviation ||
                  formData.masterAbbreviation
                }
                onChange={handleInputChange}
              />
            </InputContainer>
          </DatePlaceRow>
          <InputContainer>
            <SectionTitle>Cijena intenziva (€)</SectionTitle>
            <Input
              type="number"
              placeholder="Upiši"
              name="price"
              defaultValue={createEventData.price || formData.price}
              onChange={handleInputChange}
            />
          </InputContainer>
          <SectionTitle>Opis</SectionTitle>
          <TextArea
            placeholder="Dodaj opis"
            name="description"
            defaultValue={createEventData.description || formData.description}
            rows={5}
            cols={50}
            onChange={handleInputChange}
          />
          <SectionTitle>Dodatni opis</SectionTitle>
          <TextArea
            placeholder="Dodaj dodatne informacije"
            name="aditionalDescription"
            value={formData.aditionalDescription}
            rows={5}
            cols={50}
            onChange={handleInputChange}
          />
        </InputWrapper>
        <TitleBold>
          Voditelji{" "}
          {createEventData?.voditeljiData?.length
            ? createEventData?.voditeljiData?.length
            : 0}
        </TitleBold>
        <VoditeljiContainer>
          {createEventData?.voditeljiData?.length > 0 ? (
            <>
              {createEventData?.voditeljiData.map((item, index) => (
                <VoditeljiCard
                  key={index}
                  voditeljAvatar={item.imageUrl}
                  voditeljIme={item.firstName}
                  voditeljPrezime={item.lastName}
                  voditeljBiografija={item.bio}
                  voditeljEmail={item.emailAddress}
                  deleteVoditelj={() => deleteVoditelj(item.id)}
                />
              ))}
              <AddVoditeljButton>
                <ButtonColumn
                  onClick={() =>
                    navigate("/djelatnici", {
                      state: {
                        isAddVoditeljToEvent: true,
                        isAddVoditelj: false,
                        voditeljiNow: createEventData.voditeljiData,
                      },
                    })
                  }
                >
                  <img alt="" src={Plus} />
                  Dodaj voditelja
                </ButtonColumn>
              </AddVoditeljButton>
            </>
          ) : (
            <AddVoditeljButton>
              <ButtonColumn
                onClick={() =>
                  navigate("/djelatnici", {
                    state: {
                      isAddVoditeljToEvent: true,
                      isAddVoditelj: false,
                      voditeljiNow: createEventData.voditeljiData,
                    },
                  })
                }
              >
                <img alt="" src={Plus} />
                Dodaj voditelja
              </ButtonColumn>
            </AddVoditeljButton>
          )}
        </VoditeljiContainer>
        <SectionTitleRow onClick={() => toggleDropdown()}>
          <TitleBold>
            Polaznici{" "}
            {createEventData?.polazniciData?.length
              ? createEventData?.polazniciData?.length
              : 0}
          </TitleBold>
          <DropdownArrow alt="" src={Down} rotated={showTable} />
        </SectionTitleRow>
        {(showTable || isEditUsersArrayBeforeEvidention) && (
          <TableContainer ref={sectionRef}>
            <TablePolaznici
              columnNames={columnNames}
              showButton={
                (createEventData?.polazniciData?.length || 0) <
                (intenzivData?.capacity || 0)
              }
              data={createEventData?.polazniciData}
              intensiveId={intenzivData?.id}
            />
          </TableContainer>
        )}
        <EditFooter>
          {!isCreateMode && (
            <EditButton
              color="#ffffff"
              backgroundColor="#CB2D2D"
              onClick={() => handleDeleteIntensive()}
              style={{ display: "flex", alignItems: "center", gap: 5 }}
            >
              Delete
              <img src={Trash} alt="" />
            </EditButton>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 10,
              width: "100%",
            }}
          >
            <EditButton
              color="#808080"
              backgroundColor="#EBEBEB"
              onClick={() => {
                navigate(-1);
                resetCreateEventData();
              }}
            >
              Odustani
            </EditButton>
            <EditButton
              color="#FFFFFF"
              backgroundColor="#CB9C2D"
              // disabled={!areAllFieldsFilled(formData)}
              onClick={handleSubmit}
            >
              {isCreateMode
                ? "Kreiraj radionicu"
                : isEditUsersArrayBeforeEvidention
                  ? "Spremi u evidenciju"
                  : " Spremi promjene"}
            </EditButton>
          </div>
        </EditFooter>
        {showModal && (
          <Modal
            image={modalContent.image}
            description={modalContent.description}
            buttons={modalContent.buttons}
            fullWidth={true}
          />
        )}
      </Wrapper>
    </>
  );
}

export default IntenziviEditCreate;
