import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomHeader from "../../components/CustomHeader/CustomHeader";
import LoaderModal from "../../components/LoaderModal/LoaderModal";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import "./App.css";
import Calendar from "./Calendar";
import AddEventDialog from "./calendar-utils/AddEventDialog/AddEventDialog";
import { getCalendarData } from "./calendar-utils/calendar.api";
import { roomCalendars } from "./calendar-utils/calendar.mocks";
import { academicYears } from "./calendar-utils/calendar.utils";

const Master = () => {
  const { authToken } = useAuthentication();
  const { userData } = useUserState();
  const [calendarData, setCalendarData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [groups, setGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmptyTimeSlot, setSelectedEmptyTimeSlot] = useState(false);
  const [eventData, setEventData] = useState();
  const [actionType, setActionType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(
    academicYears[3]
  );
  const startYear = selectedAcademicYear.start.getFullYear();
  const endYear = selectedAcademicYear.end.getFullYear();
  useEffect(() => {
    if (authToken)
      getCalendarData(
        authToken,
        startYear,
        endYear,
        setCalendarData,
        setIsLoading
      );
  }, [authToken, startYear, endYear]);
  return (
    <div>
      {isLoading && <LoaderModal />}
      {openModal && userData.roleId === 6 && (
        <AddEventDialog
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setSelectedEmptyTimeSlot(undefined);
            setEventData(undefined);
            setActionType(undefined);
          }}
          type={actionType}
          clickedEvent={eventData}
          selectedEmptyTimeSlot={selectedEmptyTimeSlot}
          startYear={startYear}
          endYear={endYear}
          setCalendarData={setCalendarData}
          setOpenModal={setOpenModal}
          setIsLoading={setIsLoading}
        />
      )}
      <div style={{ width: "400%" }}>
        <CustomHeader title={"Master kalendar"} />
      </div>
      <Box m="20px" width={"100%"} sx={{ overflow: "scroll" }}>
        <div className="App">
          {/* First Calendar */}
          {roomCalendars.map((item, index) => (
            <div className="calendar-container" key={index}>
              <Calendar
                key={item.room}
                name={item.name}
                room={item.roomId}
                setOpenModal={() => {
                  setOpenModal(true);
                }}
                setActionType={setActionType}
                headerColor={item.headerColor}
                groupList={groups}
                tableHeadColor={item.tableHeadColor}
                setSelectedEmptyTimeSlot={setSelectedEmptyTimeSlot}
                setEventData={setEventData}
                selectedYear={selectedYear}
                selectedAcademicYear={selectedAcademicYear}
                masterObject={calendarData.masterObject?.[item.roomName]}
                showToggleForFirstIndex={index === 0}
                startYear={startYear}
                endYear={endYear}
                setCalendarData={setCalendarData}
              />
            </div>
          ))}
        </div>
      </Box>
    </div>
  );
};

export default Master;
