import React from "react";
import Checkbox from "../../../components/checkbox/Checkbox";

export const CheckboxOption = (props) => {
  const {
    data,
    innerRef,
    innerProps,
    isSelected,
    selectOption,
    deselectOption,
  } = props;

  const handleCheckboxChange = () => {
    if (isSelected) {
      deselectOption(data);
    } else {
      selectOption(data);
    }
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ display: "flex", alignItems: "center", padding: "5px" }}
    >
      <Checkbox
        name={data.value}
        label={data.label}
        value={isSelected}
        onChange={handleCheckboxChange}
      />
    </div>
  );
};
