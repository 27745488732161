import React from "react";
import styles from "./InfoContainer.module.css";
function InfoContainer({
  datumStart,
  datumEnd,
  location,
  capacity,
  seatsLeft,
  gprSati,
  price,
  prijavnicaType,
  datum,
  timeFrom,
  timeTo,
  supervisionType,
  totalPeople,
}) {
  const formattedDate = new Date(datum)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  const formattedDateStart = new Date(datumStart)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  const formattedDateEnd = new Date(datumEnd)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .split("/")
    .join(".");

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          {prijavnicaType === "fakultativne-radionice" ||
          prijavnicaType === "grupne-supervizije" ? (
            <div className={styles.label}>Datum održavanja</div>
          ) : (
            <>
              <div className={styles.label}>Od datuma</div>
              <div className={styles.label}>Do datuma</div>
            </>
          )}
          {location && location !== "Bez sobe" && (
            <div className={styles.label}>Mjesto održavanja</div>
          )}
          {prijavnicaType !== "grupne-supervizije" && (
            <>
              <div className={styles.label}>Broj dostupnih mjesta</div>
              <div className={styles.label}>Broj sati GPR-a</div>
              <div className={styles.label}>Cijena radionice</div>
            </>
          )}
          {prijavnicaType === "grupne-supervizije" && (
            <>
              <div className={styles.label}>Vrijeme održavanja</div>
            </>
          )}
          {prijavnicaType === "grupne-supervizije" && (
            <>
              <div className={styles.label}>Vrsta supervizije</div>
            </>
          )}
        </div>
        <div className={styles.column}>
          {prijavnicaType === "fakultativne-radionice" ||
          prijavnicaType === "grupne-supervizije" ? (
            <div className={styles.info}>
              {formattedDate || "12.listopad 2022."}
            </div>
          ) : (
            <>
              <div className={styles.info}>
                {formattedDateStart || "12.listopad 2022."}
              </div>
              <div className={styles.info}>
                {formattedDateEnd || "16.listopad 2022."}
              </div>
            </>
          )}

          {location && location !== "Bez sobe" && (
            <div className={styles.info}>{location || "ZG1"}</div>
          )}
          {prijavnicaType !== "grupne-supervizije" && (
            <>
              <div className={styles.infoNoCapitalize}>
                {`preostalo ${capacity - totalPeople} od (${capacity})` || 55}
              </div>
              <div className={styles.info}>{gprSati || 55}</div>
              <div className={styles.info}>{`${price} €` || "150.00 €"}</div>
            </>
          )}
          {prijavnicaType === "grupne-supervizije" && (
            <>
              <div className={styles.info}>
                {timeFrom || "12:00"} - {timeTo || "13:00"}h
              </div>
            </>
          )}
          {prijavnicaType === "grupne-supervizije" && (
            <>
              <div className={styles.info}>
                {supervisionType ? supervisionType : ""}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfoContainer;
