import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import LoaderModal from "../../components/LoaderModal/LoaderModal";
import Modal from "../../components/Modal/Modal";
import ReusableTabs from "../../components/TabMenu/Tabs";
import { useUserState } from "../../context/UserContext";
import { useAuthentication } from "../hooks/useAuthentication";
import Upload from "../myProfile/components/Upload/Upload";
import {
  getActiveProgramsByUserId,
  myProfileTabs,
  programsByIds,
} from "../myProfile/utils";
import styles from "./PolazniciPregled.module.css";
import backToArrow from "./assets/BackToArrow.svg";
import AddUserToModal from "./components/AddUserToModal/AddUserToModal";
import ArhiviraniButton from "./components/ArhiviraniButton/ArhiviraniButton";
import EditMode from "./components/EditMode/EditMode";
import ViewMode from "./components/ViewMode/ViewMode";
import ZakupiteljButton from "./components/ZakupiteljButton/ZakupiteljButton";
import { fetchData } from "./utils/fetchData";
import { handleSuccessRemoveZakupiteljClick } from "./utils/utils";

const apiUrl = process.env.REACT_APP_API_URL;

const backgroundColorMap = {
  RAR: "#87AD76",
  OR: "#CBA855",
  TT: "#A35254",
  TP: "#D64D51",
  Roditeljstvo: "#7BBA9F",
  VT: "#B8834F",
  RPO: "#3A54A1",
};

const PolazniciPregled = () => {
  const [userData, setUserData] = useState("");
  const { pojediniPolaznikID } = useParams();
  const [userInGroups, setUserInGroups] = useState("");
  const { role } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const { selectedTab } = useParams();
  const [selectedAcademicDegree, setSelectedAcademicDegree] = useState(null);
  const [selectedCriminalRecord, setSelectedCriminalRecord] = useState(null);
  const [selectedDegreeName, setSelectedDegreeName] = useState("");
  const [selectedRecordName, setSelectedRecordName] = useState("");
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedTab || "one");
  const [isModalZakupiteljOpen, setIsModalZakupiteljOpen] = useState(false);
  const [mjestoUpisaOptions, setMjestoUpisaOptions] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]);
  const [isModalGrupaOpen, setIsModalGrupaOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [isZakupitelj, setIsZakupitelj] = useState();
  const [zakupiteljType, setZakupiteljType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [polozeniProgrami, setPolozeniProgram] = useState([]);
  const [activePrograms, setActivePrograms] = useState("");
  const [isArhiviraj, setIsArhiviraj] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [notAllowed, setNotAllowed] = useState(false);
  const [modalContent, setModalContent] = useState({
    image: "",
    title: "",
    description: "",
    buttons: [],
  });
  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };
  useEffect(() => {
    if (authToken)
      fetchData(
        pojediniPolaznikID,
        authToken,
        setUserData,
        setUserInGroups,
        setPolozeniProgram,
        setIsZakupitelj,
        setMjestoUpisaOptions,
        setAllPrograms,
        setZakupiteljType,
        setNotAllowed,
        setIsLoading
      );
  }, [pojediniPolaznikID, authToken]);

  useEffect(() => {
    const getActive = async () => {
      await getActiveProgramsByUserId(
        apiUrl,
        authToken,
        setActivePrograms,
        pojediniPolaznikID
      );
    };
    if (authToken) getActive();
  }, [authToken]);

  const getBackgroundColor = (name) => {
    return backgroundColorMap[name] || "";
  };
  function filterDuplicates(array) {
    const uniqueNames = new Set();
    return array.filter((obj) => {
      if (!uniqueNames.has(obj.name)) {
        uniqueNames.add(obj.name);
        return true;
      }
      return false;
    });
  }

  return (
    <div>
      {notAllowed ? (
        <div
          style={{
            display: "flex",
            gap: 10,
            backgroundColor: "#ffebeb",
            width: "100%",
            height: 40,
            borderRadius: 6,
            fontSize: 15,
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            color: "#5f6d7e",
            marginTop: 20,
          }}
        >
          <img alt="" src={InfoIcon} height={18} width={18} />
          Pregled ovog polaznika vam trenutno nije omogućen!
        </div>
      ) : (
        <>
          {isLoading ? (
            <LoaderModal />
          ) : (
            <>
              <div
                className={styles.backToContainer}
                onClick={() => navigate(-1)}
              >
                <img
                  src={backToArrow}
                  alt="go back"
                  className={styles.backToArrow}
                />
                <h1 className={styles.backToh1}>Natrag na listu polaznika</h1>
              </div>
              <div className={styles.background} />
              <div
                className={styles.imageContainer}
                style={{ backgroundImage: `url(${userData?.imageUrl}` }}
              />
              <div className={styles.container}>
                {!showEditMode && (
                  <div className={styles.headingContainer}>
                    <h1 className={styles.heading}>
                      {userData?.firstName} {userData?.lastName}
                    </h1>
                    <div className={styles.headingButtonContainer}>
                      {userData?.isArchived && userId === "Referada" ? (
                        <ArhiviraniButton
                          onClick={() => {
                            setShowEditMode(true);
                            setIsArhiviraj(true);
                            setIsEdit(false);
                          }}
                        />
                      ) : (
                        <>
                          {userId === "Polaznik" &&
                            activePrograms &&
                            activePrograms.length > 0 &&
                            filterDuplicates(
                              activePrograms.map((item) => {
                                const programId = item.id;
                                const prefix = programsByIds.find((program) =>
                                  program.value.includes(programId)
                                );
                                return {
                                  color: getBackgroundColor(prefix.label),
                                  name: `Polaznik ${
                                    prefix === undefined
                                      ? ""
                                      : prefix.label + "-a"
                                  }`,
                                };
                              })
                            ).map((item, index) => (
                              <div
                                className={styles.badge}
                                key={index}
                                style={{ backgroundColor: item.color }}
                              >
                                <p className={styles.badgeText}>{item.name}</p>
                              </div>
                            ))}
                          {userId === "Voditelj" &&
                            activePrograms &&
                            activePrograms.length > 0 &&
                            filterDuplicates(
                              activePrograms.map((item) => {
                                const programId = item.id;
                                const prefix = programsByIds.find((program) =>
                                  program.value.includes(programId)
                                );
                                return {
                                  color: getBackgroundColor(prefix.label),
                                  name: `Voditelj ${
                                    prefix === undefined
                                      ? ""
                                      : prefix.label + "-a"
                                  }`,
                                };
                              })
                            ).map((item, index) => (
                              <div
                                className={styles.badge}
                                key={index}
                                style={{ backgroundColor: item.color }}
                              >
                                <p className={styles.badgeText}>{item.name}</p>
                              </div>
                            ))}
                          {userId === "Referada" && (
                            <ZakupiteljButton
                              onClick={() => setIsModalZakupiteljOpen(true)}
                              isZakupitelj={isZakupitelj}
                              zakupiteljType={zakupiteljType}
                              onClickRemoveZakupitelj={() =>
                                handleSuccessRemoveZakupiteljClick(
                                  setModalContent,
                                  setSuccessModal,
                                  pojediniPolaznikID,
                                  authToken,
                                  setUserData,
                                  setUserInGroups,
                                  setPolozeniProgram,
                                  setIsZakupitelj,
                                  setMjestoUpisaOptions,
                                  setAllPrograms,
                                  setZakupiteljType
                                )
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                <ReusableTabs
                  currentTabIndex={currentTabIndex}
                  handleTabChange={handleTabChange}
                  tabsData={myProfileTabs}
                />
                {currentTabIndex === "one" && (
                  <>
                    {showEditMode ? (
                      <EditMode
                        userData={userData}
                        mjestoUpisaOptions={mjestoUpisaOptions}
                        allPrograms={allPrograms}
                        onClickOdustani={() => setShowEditMode(false)}
                        isArhiviraj={isArhiviraj}
                        isEdit={isEdit}
                      />
                    ) : (
                      <ViewMode
                        userData={userData}
                        polozeniProgrami={polozeniProgrami}
                        userId={userId}
                        setShowEditMode={setShowEditMode}
                        setIsArhiviraj={setIsArhiviraj}
                        setIsEdit={setIsEdit}
                        navigate={navigate}
                        pojediniPolaznikID={pojediniPolaznikID}
                        userInGroups={userInGroups}
                        setIsModalGrupaOpen={setIsModalGrupaOpen}
                      />
                    )}
                  </>
                )}
                {["two", "three"].includes(currentTabIndex) && (
                  <Upload
                    image={
                      currentTabIndex === "two"
                        ? userData?.academicDegree
                        : userData?.criminalRecord
                    }
                    userId={userData.id}
                    accessToken={authToken}
                    isAcademic={currentTabIndex === "two"}
                    editMode={showEditMode}
                    role={userId}
                    userData={userData}
                    authToken={authToken}
                    setModalContent={setModalContent}
                    setShowModal={setSuccessModal}
                    setUserData={setUserData}
                    setLoading={setIsLoading}
                    apiUrl={apiUrl}
                    tabName={
                      currentTabIndex === "two"
                        ? "academicDegree"
                        : "criminalRecord"
                    }
                    navigate={navigate}
                    setEditMode={showEditMode}
                    {...(currentTabIndex === "two"
                      ? {
                          selectedDegreeName,
                          setSelectedDegreeName,
                          setSelectedAcademicDegree,
                          selectedAcademicDegree,
                        }
                      : {
                          selectedRecordName,
                          setSelectedRecordName,
                          setSelectedCriminalRecord,
                          selectedCriminalRecord,
                        })}
                  />
                )}
              </div>
              {isModalZakupiteljOpen && (
                <AddUserToModal
                  onClose={() => setIsModalZakupiteljOpen(false)}
                  setIsModalZakupiteljOpen={setIsModalZakupiteljOpen}
                  setModalContent={setModalContent}
                  setSuccessModal={setSuccessModal}
                  pojediniPolaznikID={pojediniPolaznikID}
                  title={"Dodaj polaznika u zakupitelje"}
                  addPolaznikToZakupitelj={true}
                  data={userData}
                  setUserData={setUserData}
                  setUserInGroups={setUserInGroups}
                  setPolozeniProgram={setPolozeniProgram}
                  setIsZakupitelj={setIsZakupitelj}
                  setMjestoUpisaOptions={setMjestoUpisaOptions}
                  setAllPrograms={setAllPrograms}
                  setZakupiteljType={setZakupiteljType}
                />
              )}
              {isModalGrupaOpen && (
                <AddUserToModal
                  onClose={() => setIsModalGrupaOpen(false)}
                  title={"Dodaj polaznika u grupu"}
                  addPolaznikToGroup={true}
                  data={userData}
                />
              )}
              {successModal && (
                <Modal
                  fullWidth={true}
                  image={modalContent.image}
                  title={modalContent.title}
                  description={modalContent.description}
                  buttons={modalContent.buttons}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PolazniciPregled;
