// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardProgrami_wrapper__oiAe6 {
  display: flex;
}

.CardProgrami_progImage__tpYxd {
  display: "flex";
  justify-content: center;
  align-items: center;
  height: 248px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 765px) {
  .CardProgrami_wrapper__oiAe6 {
    flex-direction: column;
    width: 400px;
  }
}

@media screen and (max-width: 455px) {
  .CardProgrami_wrapper__oiAe6 {
    width: 330px;
  }
  .CardProgrami_progImage__tpYxd {
    height: 200px;
  }
}

@media screen and (max-width: 390px) {
  .CardProgrami_progImage__tpYxd {
    height: 175px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgramCard/CardProgrami.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE;IACE,sBAAsB;IACtB,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".wrapper {\n  display: flex;\n}\n\n.progImage {\n  display: \"flex\";\n  justify-content: center;\n  align-items: center;\n  height: 248px;\n  width: 100%;\n  box-sizing: border-box;\n}\n\n@media screen and (max-width: 765px) {\n  .wrapper {\n    flex-direction: column;\n    width: 400px;\n  }\n}\n\n@media screen and (max-width: 455px) {\n  .wrapper {\n    width: 330px;\n  }\n  .progImage {\n    height: 200px;\n  }\n}\n\n@media screen and (max-width: 390px) {\n  .progImage {\n    height: 175px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CardProgrami_wrapper__oiAe6`,
	"progImage": `CardProgrami_progImage__tpYxd`
};
export default ___CSS_LOADER_EXPORT___;
