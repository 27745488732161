export const disableSupervisionInput = (userId, zavrsni, inputNumber, role) => {
  if (role === 6) return false;

  const { supervisorApproval1, supervisorApproval2 } = zavrsni;
  console.log(inputNumber, zavrsni, userId, "gg", supervisorApproval1);
  if (!supervisorApproval1 && !supervisorApproval2) return false;

  if (inputNumber === 1) {
    return supervisorApproval1 !== userId || supervisorApproval2 === userId;
  } else if (inputNumber === 2) {
    return supervisorApproval2 !== userId || !!supervisorApproval1 === userId;
  }

  return true;
};
