import { useEffect, useState } from "react";
import InputColor from "react-input-color";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ArrowRight from "../../assets/img/ArrowRight.png";
import Question from "../../assets/img/Help.png";
import Trash from "../../assets/img/TrashIcon.png";
import ArchiveIcon from "../../assets/svg/ArchiveIcon";
import ArrowLeftIcon from "../../assets/svg/ArrowLeftIcon";
import CheckIcon from "../../assets/svg/CheckIcon";
import ChevronRightIcon from "../../assets/svg/ChevronRightIcon";
import CloseIcon from "../../assets/svg/CloseIcon";
import CrossIcon from "../../assets/svg/CrossIcon";
import InfoIcon from "../../assets/svg/InfoIcon.svg";
import VerticalDots from "../../assets/svg/VerticalDots";
import Modal from "../../components/Modal/Modal";
import { useUserState } from "../../context/UserContext";
import { selectStyles } from "../groups/components/groupCreate/utils";
import { useAuthentication } from "../hooks/useAuthentication";
import styles from "./SingleGroup.module.css";
import { archiveUser } from "./api/archiveUserById";
import { fetchData } from "./api/fetchData";
import { getUserBios } from "./api/getUserBios";
import { apiCallToRemoveStudent } from "./api/removeStudent";
import { apiCallToRemoveTeacher } from "./api/removeTeacher";
import { updateGroup } from "./api/updateGroup";
import DuplicateModal from "./components/DuplicateGroupModal/DuplicateModal";
import AttendeesTable from "./components/attendeesTable/AttendeesTable";
import CustomInput from "./components/customInput/CustomInput";
import TeacherCard from "./components/teacherCard/TeacherCard";
import {
  handleAddVoditeljClick,
  handleButtonDropdown,
  handleEditGroup,
  handleGroupArchive,
  handleGroupDelete,
  handleInputChange,
  handleIntrospekcijeClick,
  handleNext,
  handleRemoveAndArchiveItem,
  handleRemoveAttendeeItem,
  handleRemoveTeacherItem,
} from "./utils/Utils";

const uciteljiProgramsArray = [11, 12, 13, 14, 17];

const SingleGroup = () => {
  const navigate = useNavigate();
  const { role, userData } = useUserState();
  const { authToken } = useAuthentication();
  const userId = role;
  const { groupId } = useParams();
  const [deleteModal, setDeleteModal] = useState(false);
  const [teacherRemoveModal, setTeacherRemoveModal] = useState(false);
  const [attendeesRemoveModal, setAttendeesRemoveModal] = useState(false);
  const [removeAndArchiveModal, setRemoveAndArchiveModal] = useState(false);
  const [duplicateGroupModal, setDuplicateGroupModal] = useState(false);
  const [archiveGroupModal, setArchiveGroupModal] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [teachersData, setTeachersData] = useState([]);
  const [userBios, setUserBios] = useState([]);
  const [attendeesData, setAttendeeData] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [buttonDropdown, setButtonDropdown] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [boxColorPicker, setBoxColorPicker] = useState(false);
  const [textColorPicker, setTextColorPicker] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [allPrograms, setAllPrograms] = useState([]);
  const [isAllowed, setIsAllowed] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const itemsPerPage = editMode ? 3 : 4;
  const [formValues, setFormValues] = useState({
    name: groupData?.name || "",
    programId: groupData?.programId || null,
    cityId: groupData?.cityId || null,
    calendarFieldColour: groupData?.calendarFieldColour || "",
    calendarTextColour: groupData?.calendarTextColour || "",
  });

  useEffect(() => {
    if (authToken)
      fetchData(
        groupId,
        authToken,
        setGroupData,
        setFormValues,
        setAttendeeData,
        setTeachersData,
        setAllCities,
        setAllPrograms,
        setIsAllowed
      );
  }, [authToken, groupId]);

  useEffect(() => {
    const getTeachersBios = async () => {
      if (teachersData && teachersData?.length > 0) {
        const userIds = teachersData?.map((teacher) => teacher.id);
        const bios = await getUserBios(userIds, authToken);
        setUserBios(bios);
      }
    };
    if (authToken) getTeachersBios();
  }, [authToken, teachersData]);

  const handleSaveChanges = async () => {
    await updateGroup(groupId, formValues, authToken, setFormValues);
    setEditMode(false);
    if (authToken)
      fetchData(
        groupId,
        authToken,
        setGroupData,
        setFormValues,
        setAttendeeData,
        setTeachersData,
        setAllCities,
        setAllPrograms
      );
  };

  let isVoditeljVisible = false;
  const voditeljiIds = groupData?.voditelji?.map((voditelji) => voditelji.id);
  if (voditeljiIds?.includes(userData.id)) {
    isVoditeljVisible = true;
  }
  return (
    <>
      {teacherRemoveModal && (
        <Modal
          title={"Jeste li sigurni da želite ukloniti  voditelja iz grupe?"}
          buttons={[
            {
              label: "Odustani",
              action: () => setTeacherRemoveModal(false),
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Ukloni",
              action: () => {
                apiCallToRemoveTeacher(
                  selectedItemIndex,
                  groupId,
                  authToken,
                  setTeachersData,
                  setTeacherRemoveModal,
                  setIsAllowed
                );
              },
              className: "modal-btn-deletePage modal-btn-delete-deletePage",
            },
          ]}
          image={Question}
        />
      )}
      {attendeesRemoveModal && (
        <Modal
          title={"Jeste li sigurni da želite ukloniti  polaznika iz grupe?"}
          buttons={[
            {
              label: "Odustani",
              action: () => setAttendeesRemoveModal(false),
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Ukloni",
              action: () =>
                apiCallToRemoveStudent(
                  selectedItemIndex,
                  groupId,
                  authToken,
                  setAttendeeData,
                  setAttendeesRemoveModal,
                  setIsAllowed
                ),
              className: "modal-btn-deletePage modal-btn-delete-deletePage",
            },
          ]}
          image={Question}
        />
      )}
      {removeAndArchiveModal && (
        <Modal
          title={
            "Jeste li sigurni da želite arhivirati polaznika i ukloniti ga iz grupe?"
          }
          buttons={[
            {
              label: "Odustani",
              action: () => setRemoveAndArchiveModal(false),
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Ukloni",
              action: async () => {
                await apiCallToRemoveStudent(
                  selectedItemIndex,
                  groupId,
                  authToken,
                  setAttendeeData,
                  setRemoveAndArchiveModal,
                  setIsAllowed
                );
                await archiveUser(selectedItemIndex, authToken);
              },
              className: "modal-btn-deletePage modal-btn-delete-deletePage",
            },
          ]}
          image={Question}
        />
      )}
      {deleteModal && (
        <Modal
          title={"Jeste li sigurni da želite obrisati grupu?"}
          description={
            "Ako obrišeš grupu, trajno ćeš izgubiti podatke o grupi i njenim sudionicima"
          }
          buttons={[
            {
              label: "Odustani",
              action: () => setDeleteModal(false),
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Svejedno obriši",
              action: () => handleGroupDelete(groupId, authToken, navigate),
              className: "modal-btn-deletePage modal-btn-delete-deletePage",
            },
          ]}
          image={Question}
        />
      )}
      {archiveGroupModal && (
        <Modal
          title={"Jeste li sigurni da želite arhivirati grupu?"}
          description={
            "Kada arhiviraš grupu, više je nećeš moći uređivati, već samo pregledavati."
          }
          buttons={[
            {
              label: "Odustani",
              action: () => setArchiveGroupModal(false),
              className:
                "modal-btn-deletePage modal-btn-discontinue-deletePage",
            },
            {
              label: "Arhiviraj",
              action: () => handleGroupArchive(groupId, authToken, navigate),
              className: "modal-btn-deletePage modal-btn-delete-deletePage",
            },
          ]}
          image={Question}
        />
      )}
      {duplicateGroupModal && (
        <DuplicateModal
          onClose={() => setDuplicateGroupModal(false)}
          title={groupData.name}
          groupId={groupData.id}
          onSuccess={() => setDuplicateGroupModal(false)}
        />
      )}
      {isAllowed === true ? (
        <>
          {groupData && (
            <div>
              {!editMode && (
                <div
                  className={styles.backButton}
                  onClick={() => navigate("/grupe")}
                >
                  <ArrowLeftIcon className={styles.backButtonArrow} />
                  <p className={styles.backButtonText}>Natrag na listu</p>
                </div>
              )}
              <div className={styles.groupHeadContainer}>
                <div className={styles.groupHeadTextContainer}>
                  <div className={styles.groupHeadTitleContainer}>
                    {groupData.isArchived === true && <ArchiveIcon />}
                    <h3 className={styles.groupTitle}>
                      Grupa {groupData.name ? groupData.name : "(nema imena)"}
                    </h3>
                  </div>

                  <p className={styles.groupDateText}>
                    Datum kreiranja:{" "}
                    {groupData.createdDate
                      ? groupData.createdDate
                      : "nepoznato"}
                  </p>
                </div>
                <div className={styles.groupHeadButtonsContainer}>
                  {userId === "Referada" && (
                    <div
                      className={
                        editMode
                          ? styles.introspectionsButtonDisabled
                          : styles.introspectionsButton
                      }
                      onClick={() =>
                        handleIntrospekcijeClick(
                          navigate,
                          groupId,
                          groupData.name,
                          groupData.createdDate
                        )
                      }
                    >
                      <p className={styles.introspectionsButtonText}>
                        Introspekcije
                      </p>
                      <ChevronRightIcon
                        className={styles.introspectionsButtonIcon}
                      />
                    </div>
                  )}
                  {userId === "Referada" && (
                    <div className={styles.moreButtonContainer}>
                      {!groupData.isArchived ? (
                        <div
                          onClick={
                            !editMode
                              ? () =>
                                  handleButtonDropdown(
                                    setButtonDropdown,
                                    buttonDropdown
                                  )
                              : null
                          }
                          className={
                            editMode
                              ? styles.moreButtonDisabled
                              : styles.moreButton
                          }
                        >
                          <p className={styles.moreButtonText}>
                            Više mogućnosti
                          </p>
                          <VerticalDots className={styles.moreButtonIcon} />
                        </div>
                      ) : (
                        <div
                          className={styles.deleteBtn}
                          onClick={() => {
                            setButtonDropdown(false);
                            setDeleteModal(true);
                          }}
                        >
                          <div className={styles.deleteBtnText}>
                            Obriši grupu
                          </div>
                          <img alt="" src={Trash} />
                        </div>
                      )}
                      {buttonDropdown && (
                        <div className={styles.dropdownContainer}>
                          <div
                            onClick={() =>
                              handleEditGroup(setEditMode, setButtonDropdown)
                            }
                            className={styles.dropdownItem}
                          >
                            <p className={styles.text}>Uredi</p>
                          </div>
                          <div
                            onClick={() => {
                              setButtonDropdown(false);
                              setDuplicateGroupModal(true);
                            }}
                            className={styles.dropdownItem}
                          >
                            <p className={styles.text}>Dupliciraj grupu</p>
                          </div>
                          <div
                            className={styles.dropdownItem}
                            onClick={() => {
                              setButtonDropdown(false);
                              setArchiveGroupModal(true);
                            }}
                          >
                            <p className={styles.text}>Arhiviraj</p>
                          </div>
                          <div
                            onClick={() => {
                              setButtonDropdown(false);
                              setDeleteModal(true);
                            }}
                            className={styles.dropdownItem}
                          >
                            <p className={styles.deleteText}>Obriši</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {isVoditeljVisible && (
                    <>
                      <div
                        className={styles.introspectionsButton}
                        onClick={() =>
                          navigate(`/voditelj-moja-mentorstva/${groupId}`)
                        }
                      >
                        <p className={styles.introspectionsButtonText}>
                          Moja mentorstva
                        </p>
                        <ChevronRightIcon
                          className={styles.introspectionsButtonIcon}
                        />
                      </div>
                      {/* <div
                        className={styles.modalCancelButton}
                        onClick={() =>
                          handleUredi(
                            navigate,
                            groupData,
                            attendeesData,
                            teachersData
                          )
                        }
                      >
                        <p className={styles.modalCancelText}>
                          Uredi uloge u grupi
                        </p>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
              {editMode && (
                <div className={styles.editContainer}>
                  <div className={styles.editContainerBox}>
                    <CustomInput
                      type="text"
                      name="name"
                      value={formValues.name}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.name,
                          e.target.value,
                          setFormValues
                        )
                      }
                      labelText="Naziv grupe"
                      placeholder={groupData.class_name}
                    />
                    <div className={styles.programContainerBox}>
                      <p
                        className={styles.colorLabel}
                        style={{ margin: "5px 0px" }}
                      >
                        Grad upisa
                      </p>
                      <Select
                        placeholder="Odaberi"
                        options={allCities}
                        name="cityId"
                        value={allCities.find(
                          (city) => city.value === formValues.cityId
                        )}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            "cityId",
                            selectedOption ? selectedOption.value : null,
                            setFormValues
                          )
                        }
                        isSearchable={false}
                        styles={selectStyles}
                        components={{ IndicatorSeparator: () => null }}
                      />
                    </div>
                  </div>
                  <div className={styles.editContainerBox}>
                    <div className={styles.programContainerBox}>
                      <p
                        className={styles.colorLabel}
                        style={{ margin: "5px 0px" }}
                      >
                        Grupa programa
                      </p>
                      <Select
                        placeholder="Odaberi"
                        options={allPrograms}
                        name="programId"
                        value={allPrograms.find(
                          (program) => program.value === formValues.programId
                        )}
                        onChange={(selectedOption) =>
                          handleInputChange(
                            "programId",
                            selectedOption ? selectedOption.value : null,
                            setFormValues
                          )
                        }
                        isSearchable={false}
                        styles={selectStyles}
                        components={{ IndicatorSeparator: () => null }}
                      />
                    </div>
                    <div className={styles.colorsContainer}>
                      <div className={styles.colorContainer}>
                        <p className={styles.colorLabel}>
                          Kalendar - boja polja
                        </p>
                        {boxColorPicker ? (
                          <div className={styles.colorPickInput}>
                            <InputColor
                              initialValue="#ffffff"
                              onChange={(color) =>
                                handleInputChange(
                                  "calendarFieldColour",
                                  color.hex,
                                  setFormValues
                                )
                              }
                              placement="right"
                            />
                            <div
                              onClick={() => {
                                setBoxColorPicker(false);
                              }}
                              className={styles.colorPickIconBox}
                            >
                              <CheckIcon className={styles.colorPickIcon} />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={styles.colorBox}
                            onClick={() => {
                              setBoxColorPicker(true);
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: formValues.calendarFieldColour,
                              }}
                              className={styles.color}
                            />
                          </div>
                        )}
                      </div>
                      <div className={styles.colorContainer}>
                        <div className={styles.colorContainer}>
                          <p className={styles.colorLabel}>
                            Kalendar - boja teksta
                          </p>
                          {textColorPicker ? (
                            <div className={styles.colorPickInput}>
                              <InputColor
                                initialValue="#ffffff"
                                onChange={(color) =>
                                  handleInputChange(
                                    "calendarTextColour",
                                    color.hex,
                                    setFormValues
                                  )
                                }
                                placement="right"
                              />
                              <div
                                onClick={() => {
                                  setTextColorPicker(false);
                                }}
                                className={styles.colorPickIconBox}
                              >
                                <CheckIcon className={styles.colorPickIcon} />
                              </div>
                            </div>
                          ) : (
                            <div
                              className={styles.colorBox}
                              onClick={() => {
                                setTextColorPicker(true);
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor:
                                    formValues.calendarTextColour,
                                }}
                                className={styles.color}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.leadersContainer}>
                <div className={styles.leadersTitleContainer}>
                  <h3 className={styles.leadersTitle}>
                    {uciteljiProgramsArray.includes(groupData.programId)
                      ? "Učitelji"
                      : "Voditelji grupe"}
                  </h3>
                  <p className={styles.leadersNumber}>
                    {teachersData ? teachersData.length : "?"}
                  </p>
                </div>
                <div className={styles.cardsContainer}>
                  {teachersData &&
                    teachersData
                      .slice(activeIndex, activeIndex + itemsPerPage)
                      .map((item, index) => {
                        const isActive = index === 0;
                        const isPrevious = false;
                        const isNext = index === 1;
                        const isHidden = false;
                        return (
                          <div
                            key={item.userId}
                            className={`${styles.teachersCard} ${
                              isActive ? styles.active : ""
                            } ${isPrevious ? styles.previous : ""} ${
                              isNext ? styles.next : ""
                            } ${isHidden ? styles.hidden : ""}`}
                          >
                            {editMode && (
                              <div
                                className={styles.removeItemButton}
                                onClick={() => {
                                  handleRemoveTeacherItem(
                                    item.id,
                                    setSelectedItemIndex,
                                    setTeacherRemoveModal,
                                    teacherRemoveModal
                                  );
                                }}
                              >
                                <CloseIcon className={styles.removeItemIcon} />
                              </div>
                            )}
                            <TeacherCard
                              isMentor={
                                item.isMyMentor !== undefined
                                  ? item.isMyMentor
                                  : false
                              }
                              photo={
                                item?.imageUrl
                                  ? item?.imageUrl
                                  : item?.element?.imageUrl
                              }
                              index={item.id}
                              firstName={
                                item?.firstName
                                  ? item?.firstName
                                  : item?.element?.firstName
                              }
                              lastName={
                                item?.lastName
                                  ? item?.lastName
                                  : item?.element?.lastName
                              }
                              email={
                                item?.emailAddress
                                  ? item?.emailAddress
                                  : item?.element?.emailAddress
                              }
                              biography={userBios?.filter(
                                (bio) => bio?.userId === item.id
                              )}
                              roles={item.role}
                            />
                          </div>
                        );
                      })}
                  {editMode && (
                    <div
                      onClick={() =>
                        handleAddVoditeljClick(navigate, groupId, teachersData)
                      }
                      className={styles.addCard}
                    >
                      <CrossIcon className={styles.addCardIcon} />
                      <p className={styles.addCardText}>Dodaj voditelja</p>
                    </div>
                  )}
                  {((teachersData.length > 3 && editMode) ||
                    teachersData.length > 4) && (
                    <div className={styles.teachersCardCarouselButtons}>
                      <div className={styles.spacer} />
                      <button
                        onClick={() =>
                          handleNext(setActiveIndex, teachersData, itemsPerPage)
                        }
                        className={styles.teachersCardNextBtn}
                      >
                        <img alt="" src={ArrowRight} height={30} width={30} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.attendeesContainer}>
                <div className={styles.attendeesTitleContainer}>
                  <h3 className={styles.attendeesTitle}>
                    Edukanti - polaznici
                  </h3>
                  <p className={styles.attendeesNumber}>
                    {attendeesData ? attendeesData.length : "?"}
                  </p>
                </div>
                <AttendeesTable
                  userId={userId}
                  groupData={groupData}
                  data={attendeesData}
                  editMode={editMode}
                  handleRemoveItem={(index) =>
                    handleRemoveAttendeeItem(
                      index,
                      setSelectedItemIndex,
                      setAttendeesRemoveModal,
                      attendeesRemoveModal
                    )
                  }
                  handleRemoveAndArchive={(index) =>
                    handleRemoveAndArchiveItem(
                      index,
                      setSelectedItemIndex,
                      setRemoveAndArchiveModal,
                      removeAndArchiveModal
                    )
                  }
                />
                {editMode && (
                  <div className={styles.editActionsButtons}>
                    <div
                      onClick={() => {
                        setEditMode(false);
                      }}
                      className={styles.cancelButton}
                    >
                      <div className={styles.cancelButtonText}>odustani</div>
                    </div>
                    <div
                      className={styles.saveButton}
                      onClick={handleSaveChanges}
                    >
                      <div className={styles.saveButtonText}>
                        spremi promjene
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : isAllowed === false ? (
        <div
          style={{
            display: "flex",
            gap: 10,
            backgroundColor: "#ffebeb",
            width: "100%",
            height: 40,
            borderRadius: 6,
            fontSize: 15,
            fontFamily: "Inter",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            color: "#5f6d7e",
          }}
        >
          <img alt="" src={InfoIcon} height={18} width={18} />
          Trenutno vam nije omogućen pristup ovoj grupi!
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default SingleGroup;
