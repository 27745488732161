import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import Trash from "../../../../../assets/img/TrashIcon.png";
import { useAuthentication } from "../../../../hooks/useAuthentication";
import { formatDate } from "../../../../myProfile/utils";
import { handleDeleteEntry, radionicaType } from "../utils";

function RenderTableData({
  data = [],
  userId,
  eventType,
  setModalData,
  setCloseModal,
  setDeleteFlag,
  deleteFlag,
  evidentionUserID,
  topic,
  userData,
}) {
  const { authToken } = useAuthentication();
  const [loadingEventRow, setLoadingEventRow] = useState({});
  return (
    <>
      {data.map((item, index) => (
        <>
          {loadingEventRow[index] ? (
            <div
              style={{
                padding: "32px 32px 32px 48px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BeatLoader color="#cb9c2d" />
            </div>
          ) : (
            <tr key={index}>
              <td>
                {item?.otherInfo?.dateGrupa
                  ? item?.otherInfo?.dateGrupa
                  : item?.otherInfo?.date
                    ? formatDate(item?.otherInfo?.date)
                    : item?.otherInfo?.year ||
                        formatDate(item?.otherInfo?.date) === ""
                      ? item?.otherInfo?.year
                      : "Nema podataka"}
              </td>
              {item?.otherInfo?.tipGrupneSupervizije &&
                !(
                  topic.includes("na Trijadi") ||
                  topic.includes("Supervizant") ||
                  item?.otherInfo?.tipGrupneSupervizije.includes("tp2") ||
                  item?.otherInfo?.tipGrupneSupervizije.includes("individual")
                ) && (
                  <td>
                    {item?.otherInfo?.tipGrupneSupervizije
                      ? item?.otherInfo?.tipGrupneSupervizije === "trijada"
                        ? "Trijada"
                        : item?.otherInfo?.tipGrupneSupervizije === "eper"
                          ? "EPeR"
                          : item?.otherInfo?.tipGrupneSupervizije === "ags"
                            ? "AGS"
                            : item?.otherInfo?.tipGrupneSupervizije ===
                                "ags_supervisant"
                              ? "AGS(supervizant)"
                              : item?.otherInfo?.tipGrupneSupervizije ===
                                  "trijada_therapist"
                                ? "Trijada(terapeut)"
                                : "Nema podataka"
                      : null}
                  </td>
                )}

              {item?.otherInfo?.vrstaRadionicePrograma &&
                item?.otherInfo?.vrstaRadionicePrograma !== "" && (
                  <td>
                    {radionicaType(item?.otherInfo?.vrstaRadionicePrograma)}
                  </td>
                )}

              <td>
                {item?.otherInfo?.name?.includes("Supervizor:")
                  ? item?.otherInfo?.name.split(";")[0].split(":")[1]
                  : item?.otherInfo?.name
                    ? item?.otherInfo?.name
                    : item?.otherInfo?.fullName === "Vanjski supervizor" &&
                        item?.otherInfo?.vanjskiSupervizor
                      ? item?.otherInfo?.vanjskiSupervizor
                      : item?.otherInfo?.fullName === "Vanjski terapeut" &&
                          item?.otherInfo?.vanjskiTerapeut
                        ? item?.otherInfo?.vanjskiTerapeut
                        : item?.otherInfo?.fullName
                          ? item?.otherInfo?.fullName
                          : item?.otherInfo?.uloga
                            ? item?.otherInfo?.uloga
                            : item?.otherInfo?.Supervizor
                              ? item?.otherInfo?.Supervizor
                              : item?.otherInfo?.outsideClient
                                ? item?.otherInfo?.outsideClient
                                : "Nema podataka"}
              </td>
              <td style={{ paddingLeft: 86 }}>
                {topic?.includes("Grupna") &&
                item?.otherInfo?.satiGrupneSupervizije
                  ? item?.otherInfo?.satiGrupneSupervizije
                  : (topic?.includes("trijadi") || topic?.includes("AGS")) &&
                      item?.otherInfo?.satiIndividualneSupervizije
                    ? item?.otherInfo?.satiIndividualneSupervizije
                    : item?.otherInfo?.gprSati
                      ? item?.otherInfo?.gprSati
                      : item?.otherInfo?.brojSati
                        ? item?.otherInfo?.brojSati
                        : 1}
              </td>
              <td style={{ paddingLeft: 70 }}>
                <button
                  className={"table-terapije-btn"}
                  onClick={() => {
                    if (
                      userId === "Referada" ||
                      (userId === "Polaznik" && topic.includes("Grupna"))
                    )
                      handleDeleteEntry(
                        item.eventId,
                        evidentionUserID,
                        authToken,
                        eventType,
                        setModalData,
                        setCloseModal,
                        setDeleteFlag,
                        deleteFlag,
                        item,
                        setLoadingEventRow,
                        index
                      );
                  }}
                  disabled={
                    (!topic.includes("Grupna") && userId !== "Referada") ||
                    (userId !== "Referada" &&
                      userId !== "Polaznik" &&
                      topic.includes("Grupna")) ||
                    ((eventType === "therapy_automatic" ||
                      eventType === "therapy_manual") &&
                      userData.roleId === 2 &&
                      new Date(item?.otherInfo?.date) > new Date("2025-01-01"))
                  }
                >
                  <img
                    alt=""
                    src={Trash}
                    className="table-terapije-trash-icon"
                  />
                </button>
              </td>
            </tr>
          )}
        </>
      ))}
    </>
  );
}

export default RenderTableData;
