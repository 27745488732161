// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArhiviraniButton_arhiviraniPolaznikButton__8G2PL {
  padding: 6px 12px;
  gap: 4px;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: -0.014px;
  height: 29px;
}
.ArhiviraniButton_arhiviraniPolaznikButton__8G2PL,
.ArhiviraniButton_dropdownWrapper__T8cOF {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms ease-in-out;
  font-family: Inter;
  font-size: 16px;
  cursor: pointer;
}

.ArhiviraniButton_arhiviraniPolaznikButtonIcon__WVPtf {
  width: 16px;
  height: 16px;
}

.ArhiviraniButton_arhiviraniPolaznikButtonIcon__WVPtf {
  width: 16px;
  height: 16px;
}
.ArhiviraniButton_arhiviraniPolaznikButton__8G2PL {
  background-color: #4d4d4d;
  color: #ffffff;
}
.ArhiviraniButton_arhiviraniPolaznikButton__8G2PL:hover {
  background-color: #6e6e6e;
}
.ArhiviraniButton_dropdownWrapper__T8cOF {
  box-shadow: 0px 4px 17px 0px #5f6d7e29;
  border-radius: 8px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #5f6d7ec7;
  position: absolute;
  height: 62px;
  width: 10%;
  margin-top: 16px;
  background-color: white;
}
.ArhiviraniButton_dropdownWrapper__T8cOF:hover {
  background-color: rgb(230, 229, 229);
}
`, "",{"version":3,"sources":["webpack://./src/scenes/polazniciPregled/components/ArhiviraniButton/ArhiviraniButton.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB,wBAAwB;EACxB,YAAY;AACd;AACA;;EAEE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8CAA8C;EAC9C,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".arhiviraniPolaznikButton {\n  padding: 6px 12px;\n  gap: 4px;\n  border-radius: 4px;\n  font-weight: 600;\n  letter-spacing: -0.014px;\n  height: 29px;\n}\n.arhiviraniPolaznikButton,\n.dropdownWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: background-color 300ms ease-in-out;\n  font-family: Inter;\n  font-size: 16px;\n  cursor: pointer;\n}\n\n.arhiviraniPolaznikButtonIcon {\n  width: 16px;\n  height: 16px;\n}\n\n.arhiviraniPolaznikButtonIcon {\n  width: 16px;\n  height: 16px;\n}\n.arhiviraniPolaznikButton {\n  background-color: #4d4d4d;\n  color: #ffffff;\n}\n.arhiviraniPolaznikButton:hover {\n  background-color: #6e6e6e;\n}\n.dropdownWrapper {\n  box-shadow: 0px 4px 17px 0px #5f6d7e29;\n  border-radius: 8px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #5f6d7ec7;\n  position: absolute;\n  height: 62px;\n  width: 10%;\n  margin-top: 16px;\n  background-color: white;\n}\n.dropdownWrapper:hover {\n  background-color: rgb(230, 229, 229);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arhiviraniPolaznikButton": `ArhiviraniButton_arhiviraniPolaznikButton__8G2PL`,
	"dropdownWrapper": `ArhiviraniButton_dropdownWrapper__T8cOF`,
	"arhiviraniPolaznikButtonIcon": `ArhiviraniButton_arhiviraniPolaznikButtonIcon__WVPtf`
};
export default ___CSS_LOADER_EXPORT___;
