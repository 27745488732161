// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_wrapper__LJlRM {
  margin-top: -48px;
}
.Form_wrapper__LJlRM,
.Form_profileFormRow__nwwdk {
  display: flex;
  justify-content: space-between;
}
.Form_profileForm__0zYlo {
  width: 744px;
}
.Form_profileFormRow__nwwdk {
  margin: 24px 0px 16px;
}
.Form_profileFormColumn__IKTt3 {
  row-gap: 16px;
  width: 340px;
}
.Form_profileFormColumn__IKTt3,
.Form_profileForm__0zYlo {
  display: flex;
  flex-direction: column;
}
.Form_mobileColumn__4e4FC {
  align-items: baseline;
  justify-content: center;
}
.Form_label__VhyA- {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  text-align: left;
  color: #828d9a;
}
.Form_mobileRow__kZN1X {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/PrijavaNaEksterniLink/Form/Form.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;;EAEE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,YAAY;AACd;AACA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,YAAY;AACd;AACA;;EAEE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;AACb","sourcesContent":[".wrapper {\n  margin-top: -48px;\n}\n.wrapper,\n.profileFormRow {\n  display: flex;\n  justify-content: space-between;\n}\n.profileForm {\n  width: 744px;\n}\n.profileFormRow {\n  margin: 24px 0px 16px;\n}\n.profileFormColumn {\n  row-gap: 16px;\n  width: 340px;\n}\n.profileFormColumn,\n.profileForm {\n  display: flex;\n  flex-direction: column;\n}\n.mobileColumn {\n  align-items: baseline;\n  justify-content: center;\n}\n.label {\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  text-align: left;\n  color: #828d9a;\n}\n.mobileRow {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Form_wrapper__LJlRM`,
	"profileFormRow": `Form_profileFormRow__nwwdk`,
	"profileForm": `Form_profileForm__0zYlo`,
	"profileFormColumn": `Form_profileFormColumn__IKTt3`,
	"mobileColumn": `Form_mobileColumn__4e4FC`,
	"label": `Form_label__VhyA-`,
	"mobileRow": `Form_mobileRow__kZN1X`
};
export default ___CSS_LOADER_EXPORT___;
