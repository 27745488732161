// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PozivniDropdown_mobileDropdown__cpMjJ {
  border: 1px solid #d8dbdf;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  height: 48px;
  padding-left: 4px;
  width: 90px;
  background-color: #f3f3f3;
  text-align: left;
  color: #828d9a;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.001em;
  appearance: none;
  cursor: pointer;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/myProfile/components/PozivniDropdown/PozivniDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,2BAA2B;EAC3B,YAAY;EACZ,iBAAiB;EACjB,WAAW;EACX,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EAGxB,gBAAgB;EAChB,eAAe;EACf,aAAa;AACf","sourcesContent":[".mobileDropdown {\n  border: 1px solid #d8dbdf;\n  border-bottom-left-radius: 8px;\n  border-top-left-radius: 8px;\n  height: 48px;\n  padding-left: 4px;\n  width: 90px;\n  background-color: #f3f3f3;\n  text-align: left;\n  color: #828d9a;\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 600;\n  letter-spacing: -0.001em;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  cursor: pointer;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileDropdown": `PozivniDropdown_mobileDropdown__cpMjJ`
};
export default ___CSS_LOADER_EXPORT___;
