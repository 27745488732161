// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioChoices_container__GWCzS {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.RadioChoices_title__KktmI {
  color: #828d9a;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
}
.RadioChoices_option__x1jLw {
  appearance: none;
  background-color: transparent;
  border: 1px solid #cb9c2d;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  margin: 0px;
  padding: 0px;
  position: relative;
  width: 24px;
}
.RadioChoices_option__x1jLw:checked {
  background-color: transparent;
}
.RadioChoices_option__x1jLw:checked::before {
  background-color: #cb9c2d;
  border-radius: 64px;
  content: "";
  height: 16px;
  left: 3.24px;
  position: absolute;
  top: 3px;
  width: 16px;
}
.RadioChoices_optionLabel__VrbWz {
  cursor: pointer;
  display: flex;
  column-gap: 8px;
}
.RadioChoices_optionLabelText__xBb92 {
  color: #828d9a;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.001em;
  margin: 0px;
  max-width: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/components/radioChoices/RadioChoices.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,WAAW;AACb;AACA;EACE,eAAe;EACf,aAAa;EACb,eAAe;AACjB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n.title {\n  color: #828d9a;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n}\n.option {\n  appearance: none;\n  background-color: transparent;\n  border: 1px solid #cb9c2d;\n  border-radius: 50%;\n  cursor: pointer;\n  height: 24px;\n  margin: 0px;\n  padding: 0px;\n  position: relative;\n  width: 24px;\n}\n.option:checked {\n  background-color: transparent;\n}\n.option:checked::before {\n  background-color: #cb9c2d;\n  border-radius: 64px;\n  content: \"\";\n  height: 16px;\n  left: 3.24px;\n  position: absolute;\n  top: 3px;\n  width: 16px;\n}\n.optionLabel {\n  cursor: pointer;\n  display: flex;\n  column-gap: 8px;\n}\n.optionLabelText {\n  color: #828d9a;\n  font-family: Inter;\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 22px;\n  letter-spacing: -0.001em;\n  margin: 0px;\n  max-width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RadioChoices_container__GWCzS`,
	"title": `RadioChoices_title__KktmI`,
	"option": `RadioChoices_option__x1jLw`,
	"optionLabel": `RadioChoices_optionLabel__VrbWz`,
	"optionLabelText": `RadioChoices_optionLabelText__xBb92`
};
export default ___CSS_LOADER_EXPORT___;
