import React, { useState } from "react";
import Export from "../../assets/img/Export.png";
import LoaderModal from "../../components/LoaderModal/LoaderModal";
import { useAuthentication } from "../hooks/useAuthentication";
import { formatDate } from "../polazniciPregled/utils/utils";
import { handlePotpuniObracunSoba } from "./API/exportPotpuniObracunKoristenjaSoba";
import { getPotpuniObracunSoba } from "./API/fetchPotpuniObracunSoba";
import styles from "./ObracunSoba.module.css";
import ObracunComponent from "./components/ObracunComponent/ObracunComponent";
import DatePicker from "./components/SingleInputDatePicker/DatePicker";
import { potpuniObracunKoristenjaSobaColumnNames } from "./utils/columnNames";

function PotpuniObracunKoristenjaSoba() {
  const today = new Date();

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [loading, setLoading] = useState(false);

  const formatDateForPicker = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [potpuniObracunSoba, setPotpuniObracunSoba] = useState({});
  const [startDate, setStartDate] = useState(
    formatDateForPicker(firstDayOfMonth)
  );
  const [endDate, setEndDate] = useState(formatDateForPicker(lastDayOfMonth));
  const { authToken } = useAuthentication();
  const handleDateChange = (newDates) => {
    setStartDate(newDates[0]);
    setEndDate(newDates[1]);
  };

  return (
    <div className={styles.obracunWrapper}>
      <span className={styles.title}>Potpuni obračun korištenja soba</span>
      <span className={styles.label}>Vremenski raspon</span>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChange}
      />
      <button
        className={styles.button}
        onClick={() => {
          setLoading(true);
          if (authToken)
            getPotpuniObracunSoba(authToken, startDate, endDate, (data) => {
              setLoading(false);
              setPotpuniObracunSoba(data);
            });
        }}
      >
        prikaži podatke
      </button>
      {loading && <LoaderModal />}
      <ObracunComponent
        title={`Potpuni obračun za period (${formatDate(
          startDate
        )} - ${formatDate(endDate)})`}
        alert={
          "Napomena: Zakupitelji koji za dati period nemaju terapija rezerviranih putem IPD Kalendara neće biti prikazani u tabeli ispod!"
        }
        columnNames={potpuniObracunKoristenjaSobaColumnNames}
        data={potpuniObracunSoba?.statistics}
        backgroundColor={"#87AD77"}
        isObracunPoSobama
        isPotpuniObracun
        showSum
      />
      <button
        className={styles.exportBtn}
        onClick={() => {
          setLoading(true);
          handlePotpuniObracunSoba(authToken, startDate, endDate, setLoading);
        }}
      >
        Export tablice <img alt="" src={Export} />
      </button>
    </div>
  );
}

export default PotpuniObracunKoristenjaSoba;
